export const TF_EXPORT_SECTION_NAME = {
  SELECT_ALL: '(Select All)',

  BASIC_INFO: 'Basic Info',
  STAFF_ID: 'Staff ID',
  STAFF_NAME: 'Staff Name',
  POSITION_ID: 'Position ID',
  POSITION_NAME: 'Position Name',

  DEMOGRAPHY: 'Demography',
  AGE: 'Age',
  GENDER: 'Gender',
  NATIONALITY: 'Nationality',
  STATE_OF_BIRTH: 'State of Birth',
  CURRENT_BUSINESS_EXPERIENCE: 'Current Business',
  CURRENT_FUNCTIONAL_EXPERIENCE: 'Current Functional',

  EXPERIENCE: 'Experience',
  BUSINESS_EXPERIENCE: 'Business Experience',
  FUNCTIONAL_EXPERIENCE: 'Functional Experience',
  COMPLEMENTARY_EXPERIENCE: 'Complementary Experience',

  LEADERSHIP: 'Leadership',
  ATTRIBUTES: 'Attributes',
  EDGE_SMA: 'EDGE (SMA)',
  EDGE_MODEL: 'EDGE (Model)',
  EDGE_EXTERNAL_ASSESSMENT: 'EDGE (External Assessment)',
  PERSONALITY: 'Personality',

  SUCCESSION_TOP_TALENT_STATUS: 'Succession & Top Talent Status',
  SUCCESSOR: 'Successor',
  VICE_PRESIDENT: 'VP Successor',
  SENIOR_GENERAL_MANAGER: 'SGM Successor',
  GENERAL_MANAGER: 'GM Successor',
  SENIOR_MANAGER: 'SM Successor',
  MANAGER: 'Mgr Successor',
  EXECUTIVE: 'Exec Successor',
  TOP_TALENT_STATUS: 'Top Talent Status',

  EMPLOYMENT_INFO: 'Employment Info',
  JOB_GRADE: 'Job Grade',
  SALARY_GRADE: 'Salary Grade',
  YEARS_TO_RETIRE: 'Years To Retire',
  YEARS_IN_SALARY_GRADE: 'Years In Salary Grade',
  YEARS_IN_POSITION: 'Years In Position',

  EXTERNAL_ASSESSMENT: 'External Assessment',
  LEARNING_AGILITY: 'Learning Agility',
  CHANGE_AGILITY: 'Change Agility',
  MENTAL_AGILITY: 'Mental Agility',
  PEOPLE_AGILITY: 'People Agility',
  RESULTS_AGILITY: 'Results Agility',
  SITUATIONAL_SELF_AWARENESS_EXTERNAL_ASSESSMENT: 'External Assessment>Situational Self-Awareness',

  TRAITS: 'Traits',
  ADAPTABILITY: 'Adaptability',
  AFFILIATION: 'Affiliation',
  ASSERTIVENESS: 'Assertiveness',
  COMPOSURE: 'Composure',
  CONFIDENCE: 'Confidence',
  CREDIBILITY: 'Credibility',
  CURIOSITY: 'Curiosity',
  EMPATHY: 'Empathy',
  FOCUS: 'Focus',
  HUMILITY: 'Humility',
  INFLUENCE: 'Influence',
  NEED_FOR_ACHIEVEMENT: 'Need for Achievement',
  OPENNESS_TO_DIFFERENCES: 'Openness to Differences',
  OPTIMISM: 'Optimism',
  PERSISTENCE: 'Persistence',
  RISK_TAKING: 'Risk-taking',
  SITUATIONAL_SELF_AWARENESS_TRAITS: 'Traits>Situational Self-Awareness',
  SOCIABILITY: 'Sociability',
  TOLERANCE_OF_AMBIGUITY: 'Tolerance of Ambiguity',
  TRUST: 'Trust',

  COMPETENCIES: 'Competencies',
  ACTION_ORIENTED: 'Action Oriented',
  BALANCES_STAKEHOLDERS: 'Balances Stakeholders',
  BEING_RESILIENT: 'Being Resilient',
  BUILDS_EFFECTIVE_TEAMS: 'Builds Effective Teams',
  BUILDS_NETWORKS: 'Builds Networks',
  COLLABORATE: 'Collaborate',
  COMMUNICATES_EFFECTIVELY: 'Communicates Effectively',
  COURAGE: 'Courage',
  CULTIVATES_INNOVATION: 'Cultivates Innovation',
  CUSTOMER_FOCUS: 'Customer Focus',
  DECISION_QUALITY: 'Decision Quality',
  DEVELOPS_TALENT: 'Develops Talent',
  DIRECTS_WORK: 'Directs Work',
  DRIVES_ENGAGEMENT: 'Drives Engagement',
  DRIVES_RESULTS: 'Drives Results',
  ENSURES_ACCOUNTABILITY: 'Ensures Accountability',
  GLOBAL_PERSPECTIVE: 'Global Perspective',
  INSTILLS_TRUST: 'Instills Trust',
  INTERPERSONAL_SAVVY: 'Interpersonal Savvy',
  MANAGES_AMBIGUITY: 'Manages Ambiguity',
  MANAGES_CONFLICT: 'Manages Conflict',
  NIMBLE_LEARNING: 'Nimble Learning',
  OPTIMIZES_WORK_PROCESSES: 'Optimizes Work Processes',
  PERSUADES: 'Persuades',
  PLANS_AND_ALIGNS: 'Plans And Aligns',
  RESOURCEFULNESS: 'Resourcefulness',
  SELF_DEVELOPMENT: 'Self Development',
  SITUATIONAL_ADAPTABILITY: 'Situational Adaptability',
  STRATEGIC_MINDSET: 'Strategic Mindset',
  VALUES_DIFFERENCES: 'Values Differences',

  DRIVERS: 'Drivers',
  CHALLENGE: 'Challenge',
  COLLABORATION: 'Collaboration',
  POWER: 'Power',

  DERAILMENT_RISKS: 'Derailment Risks',
  AVOIDANT: 'Avoidant',
  CLOSED: 'Closed',
  DEFENSIVE: 'Defensive',
  EGOTISTIC: 'Egotistic',
  MICRO_MANAGING: 'Micro Managing',
  OPPORTUNISTIC: 'Opportunistic',
  RESTRAINED: 'Restrained',
  SOCIAL_PLEASER: 'Social Pleaser',
  SOLITARY: 'Solitary',
  SUSPICIOUS: 'Suspicious',
  VOLATILE: 'Volatile',
};

export const TF_EXPORT_OPTIONS = [
  {
    label: TF_EXPORT_SECTION_NAME.SELECT_ALL,
    value: TF_EXPORT_SECTION_NAME.SELECT_ALL,
  },
  {
    label: TF_EXPORT_SECTION_NAME.BASIC_INFO,
    value: TF_EXPORT_SECTION_NAME.BASIC_INFO,
    children: [
      { label: TF_EXPORT_SECTION_NAME.STAFF_NAME, value: TF_EXPORT_SECTION_NAME.STAFF_NAME },
      { label: TF_EXPORT_SECTION_NAME.STAFF_ID, value: TF_EXPORT_SECTION_NAME.STAFF_ID },
      { label: TF_EXPORT_SECTION_NAME.POSITION_ID, value: TF_EXPORT_SECTION_NAME.POSITION_ID },
      { label: TF_EXPORT_SECTION_NAME.POSITION_NAME, value: TF_EXPORT_SECTION_NAME.POSITION_NAME },
    ],
  },
  {
    label: TF_EXPORT_SECTION_NAME.DEMOGRAPHY,
    value: TF_EXPORT_SECTION_NAME.DEMOGRAPHY,
    children: [
      { label: TF_EXPORT_SECTION_NAME.AGE, value: TF_EXPORT_SECTION_NAME.AGE },
      { label: TF_EXPORT_SECTION_NAME.GENDER, value: TF_EXPORT_SECTION_NAME.GENDER },
      { label: TF_EXPORT_SECTION_NAME.NATIONALITY, value: TF_EXPORT_SECTION_NAME.NATIONALITY },
      { label: TF_EXPORT_SECTION_NAME.STATE_OF_BIRTH, value: TF_EXPORT_SECTION_NAME.STATE_OF_BIRTH },
      { label: TF_EXPORT_SECTION_NAME.CURRENT_BUSINESS_EXPERIENCE, value: TF_EXPORT_SECTION_NAME.CURRENT_BUSINESS_EXPERIENCE },
      { label: TF_EXPORT_SECTION_NAME.CURRENT_FUNCTIONAL_EXPERIENCE, value: TF_EXPORT_SECTION_NAME.CURRENT_FUNCTIONAL_EXPERIENCE },
    ],
  },
  {
    label: TF_EXPORT_SECTION_NAME.EXPERIENCE,
    value: TF_EXPORT_SECTION_NAME.EXPERIENCE,
    children: [
      { label: TF_EXPORT_SECTION_NAME.BUSINESS_EXPERIENCE, value: TF_EXPORT_SECTION_NAME.BUSINESS_EXPERIENCE },
      { label: TF_EXPORT_SECTION_NAME.FUNCTIONAL_EXPERIENCE, value: TF_EXPORT_SECTION_NAME.FUNCTIONAL_EXPERIENCE },
      { label: TF_EXPORT_SECTION_NAME.COMPLEMENTARY_EXPERIENCE, value: TF_EXPORT_SECTION_NAME.COMPLEMENTARY_EXPERIENCE },
    ],
  },
  {
    label: TF_EXPORT_SECTION_NAME.LEADERSHIP,
    value: TF_EXPORT_SECTION_NAME.LEADERSHIP,
    children: [
      { label: TF_EXPORT_SECTION_NAME.ATTRIBUTES, value: TF_EXPORT_SECTION_NAME.ATTRIBUTES },
      { label: TF_EXPORT_SECTION_NAME.EDGE_SMA, value: TF_EXPORT_SECTION_NAME.EDGE_SMA },
      { label: TF_EXPORT_SECTION_NAME.EDGE_MODEL, value: TF_EXPORT_SECTION_NAME.EDGE_MODEL },
      { label: TF_EXPORT_SECTION_NAME.EDGE_EXTERNAL_ASSESSMENT, value: TF_EXPORT_SECTION_NAME.EDGE_EXTERNAL_ASSESSMENT },
      { label: TF_EXPORT_SECTION_NAME.PERSONALITY, value: TF_EXPORT_SECTION_NAME.PERSONALITY },
    ],
  },
  {
    label: TF_EXPORT_SECTION_NAME.SUCCESSION_TOP_TALENT_STATUS,
    value: TF_EXPORT_SECTION_NAME.SUCCESSION_TOP_TALENT_STATUS,
    children: [
      {
        label: TF_EXPORT_SECTION_NAME.SUCCESSOR,
        value: TF_EXPORT_SECTION_NAME.SUCCESSOR,
        children: [
          { label: 'Vice President', value: TF_EXPORT_SECTION_NAME.VICE_PRESIDENT },
          { label: 'Senior General Manager', value: TF_EXPORT_SECTION_NAME.SENIOR_GENERAL_MANAGER },
          { label: 'General Manager', value: TF_EXPORT_SECTION_NAME.GENERAL_MANAGER },
          { label: 'Senior Manager', value: TF_EXPORT_SECTION_NAME.SENIOR_MANAGER },
          { label: 'Manager', value: TF_EXPORT_SECTION_NAME.MANAGER },
          { label: 'Executive', value: TF_EXPORT_SECTION_NAME.EXECUTIVE },
        ],
      },
      { label: TF_EXPORT_SECTION_NAME.TOP_TALENT_STATUS, value: TF_EXPORT_SECTION_NAME.TOP_TALENT_STATUS },
    ],
  },
  {
    label: TF_EXPORT_SECTION_NAME.EMPLOYMENT_INFO,
    value: TF_EXPORT_SECTION_NAME.EMPLOYMENT_INFO,
    children: [
      { label: TF_EXPORT_SECTION_NAME.JOB_GRADE, value: TF_EXPORT_SECTION_NAME.JOB_GRADE },
      { label: TF_EXPORT_SECTION_NAME.SALARY_GRADE, value: TF_EXPORT_SECTION_NAME.SALARY_GRADE },
      { label: TF_EXPORT_SECTION_NAME.YEARS_TO_RETIRE, value: TF_EXPORT_SECTION_NAME.YEARS_TO_RETIRE },
      { label: TF_EXPORT_SECTION_NAME.YEARS_IN_SALARY_GRADE, value: TF_EXPORT_SECTION_NAME.YEARS_IN_SALARY_GRADE },
      { label: TF_EXPORT_SECTION_NAME.YEARS_IN_POSITION, value: TF_EXPORT_SECTION_NAME.YEARS_IN_POSITION },
    ],
  },
  {
    label: TF_EXPORT_SECTION_NAME.EXTERNAL_ASSESSMENT,
    value: TF_EXPORT_SECTION_NAME.EXTERNAL_ASSESSMENT,
    children: [
      {
        label: TF_EXPORT_SECTION_NAME.LEARNING_AGILITY,
        value: TF_EXPORT_SECTION_NAME.LEARNING_AGILITY,
        children: [
          { label: TF_EXPORT_SECTION_NAME.CHANGE_AGILITY, value: TF_EXPORT_SECTION_NAME.CHANGE_AGILITY },
          { label: TF_EXPORT_SECTION_NAME.MENTAL_AGILITY, value: TF_EXPORT_SECTION_NAME.MENTAL_AGILITY },
          { label: TF_EXPORT_SECTION_NAME.PEOPLE_AGILITY, value: TF_EXPORT_SECTION_NAME.PEOPLE_AGILITY },
          { label: TF_EXPORT_SECTION_NAME.RESULTS_AGILITY, value: TF_EXPORT_SECTION_NAME.RESULTS_AGILITY },
          { label: 'Situational Self-Awareness', value: TF_EXPORT_SECTION_NAME.SITUATIONAL_SELF_AWARENESS_EXTERNAL_ASSESSMENT },
        ],
      },

      {
        label: TF_EXPORT_SECTION_NAME.TRAITS,
        value: TF_EXPORT_SECTION_NAME.TRAITS,
        children: [
          { label: TF_EXPORT_SECTION_NAME.ADAPTABILITY, value: TF_EXPORT_SECTION_NAME.ADAPTABILITY },
          { label: TF_EXPORT_SECTION_NAME.AFFILIATION, value: TF_EXPORT_SECTION_NAME.AFFILIATION },
          { label: TF_EXPORT_SECTION_NAME.ASSERTIVENESS, value: TF_EXPORT_SECTION_NAME.ASSERTIVENESS },
          { label: TF_EXPORT_SECTION_NAME.COMPOSURE, value: TF_EXPORT_SECTION_NAME.COMPOSURE },
          { label: TF_EXPORT_SECTION_NAME.CONFIDENCE, value: TF_EXPORT_SECTION_NAME.CONFIDENCE },
          { label: TF_EXPORT_SECTION_NAME.CREDIBILITY, value: TF_EXPORT_SECTION_NAME.CREDIBILITY },
          { label: TF_EXPORT_SECTION_NAME.CURIOSITY, value: TF_EXPORT_SECTION_NAME.CURIOSITY },
          { label: TF_EXPORT_SECTION_NAME.EMPATHY, value: TF_EXPORT_SECTION_NAME.EMPATHY },
          { label: TF_EXPORT_SECTION_NAME.FOCUS, value: TF_EXPORT_SECTION_NAME.FOCUS },
          { label: TF_EXPORT_SECTION_NAME.HUMILITY, value: TF_EXPORT_SECTION_NAME.HUMILITY },
          { label: TF_EXPORT_SECTION_NAME.INFLUENCE, value: TF_EXPORT_SECTION_NAME.INFLUENCE },
          { label: TF_EXPORT_SECTION_NAME.NEED_FOR_ACHIEVEMENT, value: TF_EXPORT_SECTION_NAME.NEED_FOR_ACHIEVEMENT },
          { label: TF_EXPORT_SECTION_NAME.OPENNESS_TO_DIFFERENCES, value: TF_EXPORT_SECTION_NAME.OPENNESS_TO_DIFFERENCES },
          { label: TF_EXPORT_SECTION_NAME.OPTIMISM, value: TF_EXPORT_SECTION_NAME.OPTIMISM },
          { label: TF_EXPORT_SECTION_NAME.PERSISTENCE, value: TF_EXPORT_SECTION_NAME.PERSISTENCE },
          { label: TF_EXPORT_SECTION_NAME.RISK_TAKING, value: TF_EXPORT_SECTION_NAME.RISK_TAKING },
          { label: 'Situational Self-Awareness', value: TF_EXPORT_SECTION_NAME.SITUATIONAL_SELF_AWARENESS_TRAITS },
          { label: TF_EXPORT_SECTION_NAME.SOCIABILITY, value: TF_EXPORT_SECTION_NAME.SOCIABILITY },
          { label: TF_EXPORT_SECTION_NAME.TOLERANCE_OF_AMBIGUITY, value: TF_EXPORT_SECTION_NAME.TOLERANCE_OF_AMBIGUITY },
          { label: TF_EXPORT_SECTION_NAME.TRUST, value: TF_EXPORT_SECTION_NAME.TRUST },
        ],
      },
      {
        label: TF_EXPORT_SECTION_NAME.COMPETENCIES,
        value: TF_EXPORT_SECTION_NAME.COMPETENCIES,
        children: [
          { label: TF_EXPORT_SECTION_NAME.ACTION_ORIENTED, value: TF_EXPORT_SECTION_NAME.ACTION_ORIENTED },
          { label: TF_EXPORT_SECTION_NAME.BALANCES_STAKEHOLDERS, value: TF_EXPORT_SECTION_NAME.BALANCES_STAKEHOLDERS },
          { label: TF_EXPORT_SECTION_NAME.BEING_RESILIENT, value: TF_EXPORT_SECTION_NAME.BEING_RESILIENT },
          { label: TF_EXPORT_SECTION_NAME.BUILDS_EFFECTIVE_TEAMS, value: TF_EXPORT_SECTION_NAME.BUILDS_EFFECTIVE_TEAMS },
          { label: TF_EXPORT_SECTION_NAME.BUILDS_NETWORKS, value: TF_EXPORT_SECTION_NAME.BUILDS_NETWORKS },
          { label: TF_EXPORT_SECTION_NAME.COLLABORATE, value: TF_EXPORT_SECTION_NAME.COLLABORATE },
          { label: TF_EXPORT_SECTION_NAME.COMMUNICATES_EFFECTIVELY, value: TF_EXPORT_SECTION_NAME.COMMUNICATES_EFFECTIVELY },
          { label: TF_EXPORT_SECTION_NAME.COURAGE, value: TF_EXPORT_SECTION_NAME.COURAGE },
          { label: TF_EXPORT_SECTION_NAME.CULTIVATES_INNOVATION, value: TF_EXPORT_SECTION_NAME.CULTIVATES_INNOVATION },
          { label: TF_EXPORT_SECTION_NAME.CUSTOMER_FOCUS, value: TF_EXPORT_SECTION_NAME.CUSTOMER_FOCUS },
          { label: TF_EXPORT_SECTION_NAME.DECISION_QUALITY, value: TF_EXPORT_SECTION_NAME.DECISION_QUALITY },
          { label: TF_EXPORT_SECTION_NAME.DEVELOPS_TALENT, value: TF_EXPORT_SECTION_NAME.DEVELOPS_TALENT },
          { label: TF_EXPORT_SECTION_NAME.DIRECTS_WORK, value: TF_EXPORT_SECTION_NAME.DIRECTS_WORK },
          { label: TF_EXPORT_SECTION_NAME.DRIVES_ENGAGEMENT, value: TF_EXPORT_SECTION_NAME.DRIVES_ENGAGEMENT },
          { label: TF_EXPORT_SECTION_NAME.DRIVES_RESULTS, value: TF_EXPORT_SECTION_NAME.DRIVES_RESULTS },
          { label: TF_EXPORT_SECTION_NAME.ENSURES_ACCOUNTABILITY, value: TF_EXPORT_SECTION_NAME.ENSURES_ACCOUNTABILITY },
          { label: TF_EXPORT_SECTION_NAME.GLOBAL_PERSPECTIVE, value: TF_EXPORT_SECTION_NAME.GLOBAL_PERSPECTIVE },
          { label: TF_EXPORT_SECTION_NAME.INSTILLS_TRUST, value: TF_EXPORT_SECTION_NAME.INSTILLS_TRUST },
          { label: TF_EXPORT_SECTION_NAME.INTERPERSONAL_SAVVY, value: TF_EXPORT_SECTION_NAME.INTERPERSONAL_SAVVY },
          { label: TF_EXPORT_SECTION_NAME.MANAGES_AMBIGUITY, value: TF_EXPORT_SECTION_NAME.MANAGES_AMBIGUITY },
          { label: TF_EXPORT_SECTION_NAME.MANAGES_CONFLICT, value: TF_EXPORT_SECTION_NAME.MANAGES_CONFLICT },
          { label: TF_EXPORT_SECTION_NAME.NIMBLE_LEARNING, value: TF_EXPORT_SECTION_NAME.NIMBLE_LEARNING },
          { label: TF_EXPORT_SECTION_NAME.OPTIMIZES_WORK_PROCESSES, value: TF_EXPORT_SECTION_NAME.OPTIMIZES_WORK_PROCESSES },
          { label: TF_EXPORT_SECTION_NAME.PERSUADES, value: TF_EXPORT_SECTION_NAME.PERSUADES },
          { label: TF_EXPORT_SECTION_NAME.PLANS_AND_ALIGNS, value: TF_EXPORT_SECTION_NAME.PLANS_AND_ALIGNS },
          { label: TF_EXPORT_SECTION_NAME.RESOURCEFULNESS, value: TF_EXPORT_SECTION_NAME.RESOURCEFULNESS },
          { label: TF_EXPORT_SECTION_NAME.SELF_DEVELOPMENT, value: TF_EXPORT_SECTION_NAME.SELF_DEVELOPMENT },
          { label: TF_EXPORT_SECTION_NAME.SITUATIONAL_ADAPTABILITY, value: TF_EXPORT_SECTION_NAME.SITUATIONAL_ADAPTABILITY },
          { label: TF_EXPORT_SECTION_NAME.STRATEGIC_MINDSET, value: TF_EXPORT_SECTION_NAME.STRATEGIC_MINDSET },
          { label: TF_EXPORT_SECTION_NAME.VALUES_DIFFERENCES, value: TF_EXPORT_SECTION_NAME.VALUES_DIFFERENCES },
        ],
      },
      {
        label: TF_EXPORT_SECTION_NAME.DRIVERS,
        value: TF_EXPORT_SECTION_NAME.DRIVERS,
        children: [
          { label: TF_EXPORT_SECTION_NAME.CHALLENGE, value: TF_EXPORT_SECTION_NAME.CHALLENGE },
          { label: TF_EXPORT_SECTION_NAME.COLLABORATION, value: TF_EXPORT_SECTION_NAME.COLLABORATION },
          { label: TF_EXPORT_SECTION_NAME.POWER, value: TF_EXPORT_SECTION_NAME.POWER },
        ],
      },
      {
        label: TF_EXPORT_SECTION_NAME.DERAILMENT_RISKS,
        value: TF_EXPORT_SECTION_NAME.DERAILMENT_RISKS,
        children: [
          { label: TF_EXPORT_SECTION_NAME.AVOIDANT, value: TF_EXPORT_SECTION_NAME.AVOIDANT },
          { label: TF_EXPORT_SECTION_NAME.CLOSED, value: TF_EXPORT_SECTION_NAME.CLOSED },
          { label: TF_EXPORT_SECTION_NAME.DEFENSIVE, value: TF_EXPORT_SECTION_NAME.DEFENSIVE },
          { label: TF_EXPORT_SECTION_NAME.EGOTISTIC, value: TF_EXPORT_SECTION_NAME.EGOTISTIC },
          { label: TF_EXPORT_SECTION_NAME.MICRO_MANAGING, value: TF_EXPORT_SECTION_NAME.MICRO_MANAGING },
          { label: TF_EXPORT_SECTION_NAME.OPPORTUNISTIC, value: TF_EXPORT_SECTION_NAME.OPPORTUNISTIC },
          { label: TF_EXPORT_SECTION_NAME.RESTRAINED, value: TF_EXPORT_SECTION_NAME.RESTRAINED },
          { label: TF_EXPORT_SECTION_NAME.SOCIAL_PLEASER, value: TF_EXPORT_SECTION_NAME.SOCIAL_PLEASER },
          { label: TF_EXPORT_SECTION_NAME.SOLITARY, value: TF_EXPORT_SECTION_NAME.SOLITARY },
          { label: TF_EXPORT_SECTION_NAME.SUSPICIOUS, value: TF_EXPORT_SECTION_NAME.SUSPICIOUS },
          { label: TF_EXPORT_SECTION_NAME.VOLATILE, value: TF_EXPORT_SECTION_NAME.VOLATILE },
        ],
      },
    ],
  },
];

export const TF_EXPORT_DEFAULT_SELECT_OPTIONS = [
  { label: TF_EXPORT_SECTION_NAME.BASIC_INFO, value: TF_EXPORT_SECTION_NAME.BASIC_INFO },
  { label: TF_EXPORT_SECTION_NAME.STAFF_ID, value: TF_EXPORT_SECTION_NAME.STAFF_ID },
  { label: TF_EXPORT_SECTION_NAME.STAFF_NAME, value: TF_EXPORT_SECTION_NAME.STAFF_NAME },
  { label: TF_EXPORT_SECTION_NAME.POSITION_ID, value: TF_EXPORT_SECTION_NAME.POSITION_ID },
  { label: TF_EXPORT_SECTION_NAME.POSITION_NAME, value: TF_EXPORT_SECTION_NAME.POSITION_NAME },
  { label: TF_EXPORT_SECTION_NAME.EMPLOYMENT_INFO, value: TF_EXPORT_SECTION_NAME.EMPLOYMENT_INFO },
  { label: TF_EXPORT_SECTION_NAME.JOB_GRADE, value: TF_EXPORT_SECTION_NAME.JOB_GRADE },
  { label: TF_EXPORT_SECTION_NAME.SALARY_GRADE, value: TF_EXPORT_SECTION_NAME.SALARY_GRADE },
];

export const TF_SORTED_OPTIONS = [
  TF_EXPORT_SECTION_NAME.STAFF_ID,
  TF_EXPORT_SECTION_NAME.STAFF_NAME,
  TF_EXPORT_SECTION_NAME.POSITION_ID,
  TF_EXPORT_SECTION_NAME.POSITION_NAME,
  TF_EXPORT_SECTION_NAME.JOB_GRADE,
  TF_EXPORT_SECTION_NAME.SALARY_GRADE,
  TF_EXPORT_SECTION_NAME.AGE,
  TF_EXPORT_SECTION_NAME.GENDER,
  TF_EXPORT_SECTION_NAME.NATIONALITY,
  TF_EXPORT_SECTION_NAME.STATE_OF_BIRTH,
  TF_EXPORT_SECTION_NAME.CURRENT_BUSINESS_EXPERIENCE,
  TF_EXPORT_SECTION_NAME.CURRENT_FUNCTIONAL_EXPERIENCE,
  TF_EXPORT_SECTION_NAME.BUSINESS_EXPERIENCE,
  TF_EXPORT_SECTION_NAME.FUNCTIONAL_EXPERIENCE,
  TF_EXPORT_SECTION_NAME.COMPLEMENTARY_EXPERIENCE,
  TF_EXPORT_SECTION_NAME.ATTRIBUTES,
  TF_EXPORT_SECTION_NAME.EDGE_SMA,
  TF_EXPORT_SECTION_NAME.EDGE_MODEL,
  TF_EXPORT_SECTION_NAME.EDGE_EXTERNAL_ASSESSMENT,
  TF_EXPORT_SECTION_NAME.PERSONALITY,
  TF_EXPORT_SECTION_NAME.VICE_PRESIDENT,
  TF_EXPORT_SECTION_NAME.SENIOR_GENERAL_MANAGER,
  TF_EXPORT_SECTION_NAME.GENERAL_MANAGER,
  TF_EXPORT_SECTION_NAME.SENIOR_MANAGER,
  TF_EXPORT_SECTION_NAME.MANAGER,
  TF_EXPORT_SECTION_NAME.EXECUTIVE,
  TF_EXPORT_SECTION_NAME.TOP_TALENT_STATUS,
  TF_EXPORT_SECTION_NAME.YEARS_TO_RETIRE,
  TF_EXPORT_SECTION_NAME.YEARS_IN_SALARY_GRADE,
  TF_EXPORT_SECTION_NAME.YEARS_IN_POSITION,
  TF_EXPORT_SECTION_NAME.CHANGE_AGILITY,
  TF_EXPORT_SECTION_NAME.MENTAL_AGILITY,
  TF_EXPORT_SECTION_NAME.PEOPLE_AGILITY,
  TF_EXPORT_SECTION_NAME.RESULTS_AGILITY,
  TF_EXPORT_SECTION_NAME.SITUATIONAL_SELF_AWARENESS_EXTERNAL_ASSESSMENT,
  TF_EXPORT_SECTION_NAME.ADAPTABILITY,
  TF_EXPORT_SECTION_NAME.AFFILIATION,
  TF_EXPORT_SECTION_NAME.ASSERTIVENESS,
  TF_EXPORT_SECTION_NAME.COMPOSURE,
  TF_EXPORT_SECTION_NAME.CONFIDENCE,
  TF_EXPORT_SECTION_NAME.CREDIBILITY,
  TF_EXPORT_SECTION_NAME.CURIOSITY,
  TF_EXPORT_SECTION_NAME.EMPATHY,
  TF_EXPORT_SECTION_NAME.FOCUS,
  TF_EXPORT_SECTION_NAME.HUMILITY,
  TF_EXPORT_SECTION_NAME.INFLUENCE,
  TF_EXPORT_SECTION_NAME.NEED_FOR_ACHIEVEMENT,
  TF_EXPORT_SECTION_NAME.OPENNESS_TO_DIFFERENCES,
  TF_EXPORT_SECTION_NAME.OPTIMISM,
  TF_EXPORT_SECTION_NAME.PERSISTENCE,
  TF_EXPORT_SECTION_NAME.RISK_TAKING,
  TF_EXPORT_SECTION_NAME.SITUATIONAL_SELF_AWARENESS_TRAITS,
  TF_EXPORT_SECTION_NAME.SOCIABILITY,
  TF_EXPORT_SECTION_NAME.TOLERANCE_OF_AMBIGUITY,
  TF_EXPORT_SECTION_NAME.TRUST,
  TF_EXPORT_SECTION_NAME.ACTION_ORIENTED,
  TF_EXPORT_SECTION_NAME.BALANCES_STAKEHOLDERS,
  TF_EXPORT_SECTION_NAME.BEING_RESILIENT,
  TF_EXPORT_SECTION_NAME.BUILDS_EFFECTIVE_TEAMS,
  TF_EXPORT_SECTION_NAME.BUILDS_NETWORKS,
  TF_EXPORT_SECTION_NAME.COLLABORATE,
  TF_EXPORT_SECTION_NAME.COMMUNICATES_EFFECTIVELY,
  TF_EXPORT_SECTION_NAME.COURAGE,
  TF_EXPORT_SECTION_NAME.CULTIVATES_INNOVATION,
  TF_EXPORT_SECTION_NAME.CUSTOMER_FOCUS,
  TF_EXPORT_SECTION_NAME.DECISION_QUALITY,
  TF_EXPORT_SECTION_NAME.DEVELOPS_TALENT,
  TF_EXPORT_SECTION_NAME.DIRECTS_WORK,
  TF_EXPORT_SECTION_NAME.DRIVES_ENGAGEMENT,
  TF_EXPORT_SECTION_NAME.DRIVES_RESULTS,
  TF_EXPORT_SECTION_NAME.ENSURES_ACCOUNTABILITY,
  TF_EXPORT_SECTION_NAME.GLOBAL_PERSPECTIVE,
  TF_EXPORT_SECTION_NAME.INSTILLS_TRUST,
  TF_EXPORT_SECTION_NAME.INTERPERSONAL_SAVVY,
  TF_EXPORT_SECTION_NAME.MANAGES_AMBIGUITY,
  TF_EXPORT_SECTION_NAME.MANAGES_CONFLICT,
  TF_EXPORT_SECTION_NAME.NIMBLE_LEARNING,
  TF_EXPORT_SECTION_NAME.OPTIMIZES_WORK_PROCESSES,
  TF_EXPORT_SECTION_NAME.PERSUADES,
  TF_EXPORT_SECTION_NAME.PLANS_AND_ALIGNS,
  TF_EXPORT_SECTION_NAME.RESOURCEFULNESS,
  TF_EXPORT_SECTION_NAME.SELF_DEVELOPMENT,
  TF_EXPORT_SECTION_NAME.SITUATIONAL_ADAPTABILITY,
  TF_EXPORT_SECTION_NAME.STRATEGIC_MINDSET,
  TF_EXPORT_SECTION_NAME.VALUES_DIFFERENCES,
  TF_EXPORT_SECTION_NAME.CHALLENGE,
  TF_EXPORT_SECTION_NAME.COLLABORATION,
  TF_EXPORT_SECTION_NAME.POWER,
  TF_EXPORT_SECTION_NAME.AVOIDANT,
  TF_EXPORT_SECTION_NAME.CLOSED,
  TF_EXPORT_SECTION_NAME.DEFENSIVE,
  TF_EXPORT_SECTION_NAME.EGOTISTIC,
  TF_EXPORT_SECTION_NAME.MICRO_MANAGING,
  TF_EXPORT_SECTION_NAME.OPPORTUNISTIC,
  TF_EXPORT_SECTION_NAME.RESTRAINED,
  TF_EXPORT_SECTION_NAME.SOCIAL_PLEASER,
  TF_EXPORT_SECTION_NAME.SOLITARY,
  TF_EXPORT_SECTION_NAME.SUSPICIOUS,
  TF_EXPORT_SECTION_NAME.VOLATILE,
];

export const TF_EXPORT_PARENT_OPTIONS = [
  TF_EXPORT_SECTION_NAME.SELECT_ALL,
  TF_EXPORT_SECTION_NAME.BASIC_INFO,
  TF_EXPORT_SECTION_NAME.DEMOGRAPHY,
  TF_EXPORT_SECTION_NAME.EXPERIENCE,
  TF_EXPORT_SECTION_NAME.LEADERSHIP,
  TF_EXPORT_SECTION_NAME.SUCCESSION_TOP_TALENT_STATUS,
  TF_EXPORT_SECTION_NAME.SUCCESSOR,
  TF_EXPORT_SECTION_NAME.EMPLOYMENT_INFO,
  TF_EXPORT_SECTION_NAME.EXTERNAL_ASSESSMENT,
  TF_EXPORT_SECTION_NAME.LEARNING_AGILITY,
  TF_EXPORT_SECTION_NAME.TRAITS,
  TF_EXPORT_SECTION_NAME.COMPETENCIES,
  TF_EXPORT_SECTION_NAME.DRIVERS,
  TF_EXPORT_SECTION_NAME.DERAILMENT_RISKS,
];
