import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { avatar, Close_Icon_2, info_circle } from '../../../../../assets/img';
import { Input, Select, Switch, Tooltip } from 'antd';
import UserCareerTable from '../UserCareerTable/UserCareerTable';
import { AiDatePicker } from '../../../../Meeting/MeetingDetails/Styled';
import _, { isArray, isEmpty } from 'lodash';
import SearchStaffForProposal from '../../../../../components/SearchStaffForProposal/SearchStaffForProposal';
import { calculateContractDuration, findAllKeys, handleShowCTForProposal } from '../../../../../utils/helper';
import moment from 'moment';
import {
  DATE_TIME_FORMAT,
  FIELD_STYLE_DISABLED,
  OPU_NAME_EXCEPTION,
  PROPOSAL_TYPES,
  PROPOSAL_TYPES_JUSTIFICATIONS,
} from '../../../../../utils/constants';
import * as styles from './proposal-template-content.module.scss';
import { useSelector } from 'react-redux';
import RichTextEditor from '../../../../../components/RichTextEditor/RichTextEditor';
import { v4 as uuidv4 } from 'uuid';

// const { TextArea } = Input;

const ProposalTemplateContent = ({ data, setProposalList, isOnlyViewAgenda, prevData }) => {
  const statusLeftMenu = useSelector((state) => state.page.statusLeftMenu);
  const [templateDetail, setTemplateDetail] = useState(null);
  const [allKeys, setAllKeys] = useState([]);
  const detailJustificationRef = useRef(null);
  const detailPerformanceRecordRef = useRef(null);
  const [currentHeightElem, setCurrentHeightElem] = useState(0);

  useEffect(() => {
    setCurrentHeightElem(0);
    setTemplateDetail(data?.proposal_settings);
    setAllKeys(findAllKeys(data?.proposal_settings));
  }, [data]);

  useEffect(() => {
    if (detailJustificationRef.current) {
      const measuredHeight = detailJustificationRef?.current?.offsetHeight - (detailPerformanceRecordRef?.current?.offsetHeight || 0) - 154;
      setCurrentHeightElem(measuredHeight);
    }
  }, [allKeys]);

  const getTemplateColumn = useMemo(() => {
    if (!isEmpty(data?.proposal_settings)) {
      const tempVal = Object.keys(data?.proposal_settings);
      return tempVal.length > 0 && tempVal.length <= 4 ? tempVal.length : null;
    } else {
      return null;
    }
  }, [data, statusLeftMenu]);

  const getContractDuration = useMemo(() => {
    if (templateDetail?.decisionInfo?.contractStartDate && templateDetail?.decisionInfo?.contractEndDate) {
      return calculateContractDuration(templateDetail?.decisionInfo?.contractStartDate, templateDetail?.decisionInfo?.contractEndDate);
    } else {
      return calculateContractDuration(templateDetail?.decisionInfo?.effectiveStartDate, templateDetail?.decisionInfo?.effectiveEndDate);
    }
  }, [templateDetail]);

  const reverseProposalTypes = Object.fromEntries(Object.entries(PROPOSAL_TYPES).map(([key, value]) => [value, key]));

  const getJustificationForType = (proposalTypeValue) => {
    const proposalKey = reverseProposalTypes[proposalTypeValue];
    return PROPOSAL_TYPES_JUSTIFICATIONS[proposalKey] || '';
  };
  const justificationLines = getJustificationForType(data?.proposal_type);

  const getDecisionRemarksDefaultValue = useMemo(() => {
    let tempVal = '';
    if (templateDetail?.decisionInfo?.decision_remarks && data?.proposal_type === prevData?.proposal_type) {
      return templateDetail?.decisionInfo?.decision_remarks;
    }

    if (data?.proposal_type !== PROPOSAL_TYPES.APPOINTMENT_WITH_PROBATION) {
      tempVal = {
        blocks: [
          {
            key: uuidv4(),
            text: 'This appointment will be based on:',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          { key: uuidv4(), text: data?.proposal_type, type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} },
        ],
        entityMap: {},
      };
    } else {
      tempVal = tempVal = {
        blocks: [
          {
            key: uuidv4(),
            text: 'This appointment will be based on:',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: uuidv4(),
            text: `${data?.proposal_type} (between 6 months to 12 months)`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      };
    }
    return JSON.stringify(tempVal);
  }, [templateDetail]);

  return (
    <TemplateWrapper columns={getTemplateColumn}>
      {allKeys.includes('staffInfo') && (
        <div className="staffDetail">
          <div className="topHead">{'Staff Details'}</div>
          <div className="details">
            <div className={'topInfo mb-16'}>
              {allKeys.includes('staffInfo.image') && (
                <div className={'avatar'}>
                  <img src={templateDetail?.staffInfo?.image || avatar} alt="" />
                </div>
              )}
              <div className={'info'}>
                {allKeys.includes(`staffInfo.birthName`) && <div className={'name'}>{templateDetail?.staffInfo?.birthName}</div>}
                {allKeys.includes(`staffInfo.staffId`) && (
                  <div className="fieldItem">
                    <span className={'boldText'}>{'SN : '}</span>
                    <span className={'lightText'}>{templateDetail?.staffInfo?.staffId}</span>
                  </div>
                )}
                {allKeys.includes(`staffInfo.age`) && (
                  <div className="fieldItem">
                    <span className={'boldText'}>{'Age : '}</span>
                    <span className={'lightText'}>{templateDetail?.staffInfo?.age || 'None'}</span>
                  </div>
                )}
                {allKeys.includes(`staffInfo.retirementDate`) && (
                  <div className="fieldItem">
                    <span className={'boldText'}>{'Retirement Date : '}</span>
                    <span className={'lightText'}>{templateDetail?.staffInfo?.retirementDate}</span>
                  </div>
                )}
              </div>
            </div>

            {allKeys.includes(`staffInfo.currentPosition`) && (
              <div className="currentPosition mb-16">
                <div className="fieldItem">
                  <span className={'normalLabel textBlock mb-8'}>{'Current Position'}</span>
                  <span className={'normalValue'}>
                    {templateDetail?.staffInfo?.currentPosition?.position_name && (
                      <span className={'textBlock'}>
                        {templateDetail?.staffInfo?.currentPosition?.position_name
                          ? `${templateDetail?.staffInfo?.currentPosition?.position_name},`
                          : '-,'}{' '}
                      </span>
                    )}
                    {templateDetail?.staffInfo?.currentPosition?.department_name && (
                      <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.department_name}</span>
                    )}
                    {templateDetail?.staffInfo?.currentPosition?.division && (
                      <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.division}</span>
                    )}
                    {templateDetail?.staffInfo?.currentPosition?.sector && (
                      <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.sector}</span>
                    )}
                    {templateDetail?.staffInfo?.currentPosition?.opu_name === OPU_NAME_EXCEPTION ? (
                      <>
                        {templateDetail?.staffInfo?.currentPosition?.business_unit && (
                          <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.business_unit}</span>
                        )}
                        {templateDetail?.staffInfo?.currentPosition?.opu_name && (
                          <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.opu_name}</span>
                        )}
                      </>
                    ) : (
                      <>
                        {templateDetail?.staffInfo?.currentPosition?.opu_name && (
                          <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.opu_name}</span>
                        )}
                        {templateDetail?.staffInfo?.currentPosition?.business_unit && (
                          <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.business_unit}</span>
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
            )}
            {allKeys.includes(`staffInfo.location`) && (
              <div className="location mb-16">
                <div className="fieldItem">
                  <span className={'normalLabel textBlock'}>{'Location'}</span>
                  <span className={'normalValue'}>{templateDetail?.staffInfo?.location}</span>
                </div>
              </div>
            )}
            {allKeys.includes(`staffInfo.reportTo`) && (
              <div className="reportTo mb-16">
                <div className="fieldItem">
                  <span className={'normalLabel textBlock mb-8'}>{'Reports to'}</span>
                  <span className={'normalValue'}>
                    <CustomTooltip
                      placement={'top'}
                      overlayClassName={styles.customTooltip}
                      overlayStyle={{
                        minWidth: '154px',
                        padding: '10px',
                        opacity: 1,
                        backgroundColor: '#000',
                        borderRadius: '4px',
                      }}
                      title={
                        <div className="tooltipContent">
                          <span
                            style={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: '18px',
                              fontWeight: '700',
                              color: '#ffffff',
                              display: 'block',
                              marginBottom: '5px',
                            }}
                          >
                            Current Incumbent
                          </span>
                          <span
                            style={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: '18px',
                              fontWeight: '400',
                              color: '#ffffff',
                              display: 'inline-block',
                            }}
                          >
                            {templateDetail?.staffInfo?.reportTo?.supervisorName}
                          </span>
                        </div>
                      }
                      overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                      <span className={'textBlock'}>
                        {`${templateDetail?.staffInfo?.reportTo?.positionName ? `${templateDetail?.staffInfo?.reportTo?.positionName}` : ''}
                        ${templateDetail?.staffInfo?.reportTo?.positionName && templateDetail?.staffInfo?.reportTo?.opuName ? ` ,` : ''}
                        ${templateDetail?.staffInfo?.reportTo?.opuName ? `${templateDetail?.staffInfo?.reportTo?.opuName}` : ''}`}
                      </span>
                    </CustomTooltip>
                  </span>
                </div>
              </div>
            )}
            <div className="jobGrade mb-16">
              {allKeys.includes(`staffInfo.jg`) && (
                <div className="fieldItem">
                  <span className={'greyLabel'}>{'JG'}</span>
                  <span className={'highlightValue'}>{templateDetail?.staffInfo?.jg}</span>
                </div>
              )}
              {allKeys.includes(`staffInfo.years_in_position`) && (
                <div className="fieldItem">
                  <span className={'greyLabel'}>{'YIP'}</span>
                  <span className={'highlightValue'}>{templateDetail?.staffInfo?.years_in_position}</span>
                </div>
              )}
              {allKeys.includes(`staffInfo.sg`) && (
                <div className="fieldItem">
                  <span className={'greyLabel'}>{'SG'}</span>
                  <span className={'highlightValue'}>{templateDetail?.staffInfo?.sg}</span>
                </div>
              )}
              {allKeys.includes(`staffInfo.yearsInSg`) && (
                <div className="fieldItem">
                  <span className={'greyLabel'}>{'YiSG'}</span>
                  <span className={'highlightValue'}>{templateDetail?.staffInfo?.yearsInSg}</span>
                </div>
              )}
            </div>
            {allKeys.includes(`staffInfo.note`) && (
              <div className="fieldItem mb-20">
                <div className={'normalLabel mb-8'}>{'Notes'}</div>
                <RichTextEditor
                  setFieldVal={(value) => {
                    if (isOnlyViewAgenda) return;
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                staffInfo: {
                                  ...item?.proposal_settings?.staffInfo,
                                  note: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                  fieldVal={templateDetail?.staffInfo?.note || ''}
                  newStyles={{ minHeight: '165px', maxHeight: '165px' }}
                  placeholder="Enter Notes"
                  maxLength={false}
                  readOnly={isOnlyViewAgenda}
                />
              </div>
            )}
            {allKeys.includes(`staffInfo.talentCategory`) && (
              <div className="talentCategory mb-16">
                <div className="fieldItem">
                  <span className={'normalLabel textBlock'}>{'Talent Category'}</span>
                  <span className={'normalValue'}>{templateDetail?.staffInfo?.talentCategory}</span>
                </div>
              </div>
            )}
            {allKeys.includes(`staffInfo.successor_to_1st_line`) && (
              <div className="successor mb-16">
                <div className="fieldItem searchList">
                  <span className={'normalLabel textBlock mb-10'}>{'Successor to (1st Line)'}</span>
                  {!isEmpty(templateDetail?.staffInfo?.successor_to_1st_line) &&
                    templateDetail?.staffInfo?.successor_to_1st_line.map((item, index) => {
                      return (
                        <div className="searchItem" key={index}>
                          <span key={index} className={'normalValue mb-8 inlineTextList'}>
                            <span style={{ minWidth: '15px' }}>{`${index + 1}.`}</span>
                            <span style={{ minWidth: '218px' }}>{`${item?.text}`}</span>
                            {!isOnlyViewAgenda && (
                              <img
                                style={{ cursor: 'pointer' }}
                                src={Close_Icon_2}
                                alt=""
                                onClick={() => {
                                  setProposalList((prev) => {
                                    if (!isEmpty(prev)) {
                                      return prev.map((each) => {
                                        if (each?.staff_id === data?.staff_id) {
                                          return {
                                            ...each,
                                            proposal_settings: {
                                              ...each?.proposal_settings,
                                              staffInfo: {
                                                ...each?.proposal_settings?.staffInfo,
                                                successor_to_1st_line: each?.proposal_settings?.staffInfo?.successor_to_1st_line.filter(
                                                  (existingItem) => existingItem?.position_code !== item.position_code
                                                ),
                                              },
                                            },
                                          };
                                        } else {
                                          return each;
                                        }
                                      });
                                    }
                                  });
                                }}
                              />
                            )}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {allKeys.includes(`staffInfo.replacement`) && (
              <div className="placement">
                <div className="fieldItem">
                  <span className="normalLabel">Replacement</span>
                  <CustomSwitch
                    disabled={isOnlyViewAgenda}
                    defaultChecked={templateDetail?.staffInfo?.replacement?.save}
                    value={templateDetail?.staffInfo?.replacement?.save}
                    onChange={(value) => {
                      setProposalList((prev) => {
                        if (!isEmpty(prev)) {
                          return prev.map((item) => {
                            if (item?.staff_id === data?.staff_id) {
                              return {
                                ...item,
                                proposal_settings: {
                                  ...item?.proposal_settings,
                                  staffInfo: {
                                    ...item?.proposal_settings?.staffInfo,
                                    replacement: {
                                      ...item?.proposal_settings?.staffInfo?.replacement,
                                      save: value,
                                    },
                                  },
                                },
                              };
                            } else {
                              return item;
                            }
                          });
                        }
                      });
                    }}
                  />
                </div>
                {templateDetail?.staffInfo?.replacement?.save ? (
                  <>
                    <div className="fieldItem selectField">
                      <SearchStaffForProposal
                        isDisabled={isOnlyViewAgenda}
                        placeholder="Search By Staff ID, Staff Name"
                        addTalent={(value) => {
                          setProposalList((prev) => {
                            if (!isEmpty(prev)) {
                              return prev.map((item) => {
                                if (item?.staff_id === data?.staff_id) {
                                  const tempList = item?.proposal_settings?.staffInfo?.replacement?.replacement_staff_ids;
                                  const tempData = isArray(tempList)
                                    ? tempList.filter((elem) => (elem?.staffId || elem?.staff_id) === (value?.staffId || value?.item?.staff_id))
                                    : [];
                                  return {
                                    ...item,
                                    proposal_settings: {
                                      ...item?.proposal_settings,
                                      staffInfo: {
                                        ...item?.proposal_settings?.staffInfo,
                                        replacement: {
                                          ...item?.proposal_settings?.staffInfo?.replacement,
                                          replacement_staff_ids: tempData.length <= 0 ? [...(isArray(tempList) ? tempList : []), value] : [value],
                                        },
                                      },
                                    },
                                  };
                                } else {
                                  return item;
                                }
                              });
                            }
                          });
                        }}
                      />
                    </div>
                    {allKeys.includes(`staffInfo.replacement.replacement_staff_ids`) && (
                      <div className="searchList">
                        {isArray(templateDetail?.staffInfo?.replacement?.replacement_staff_ids) &&
                          templateDetail?.staffInfo?.replacement?.replacement_staff_ids.map((option, index) => (
                            <div className="searchItem" key={index}>
                              {option?.text && !option?.top_talent_status ? (
                                <div className="searchItemName">
                                  <span>{index + 1}. </span>
                                  <span>{`${option?.text}`}</span>
                                </div>
                              ) : (
                                <div className="searchItemName">
                                  <span>{index + 1}. </span>
                                  <span>{`${option?.birthName || option?.positionName || 'None'}`}</span>
                                </div>
                              )}
                              {!isOnlyViewAgenda && (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={Close_Icon_2}
                                  alt=""
                                  onClick={() => {
                                    setProposalList((prev) => {
                                      if (!isEmpty(prev)) {
                                        return prev.map((item) => {
                                          if (item?.staff_id === data?.staff_id) {
                                            const tempList = item?.proposal_settings?.staffInfo?.replacement?.replacement_staff_ids;
                                            return {
                                              ...item,
                                              proposal_settings: {
                                                ...item?.proposal_settings,
                                                staffInfo: {
                                                  ...item?.proposal_settings?.staffInfo,
                                                  replacement: {
                                                    ...item?.proposal_settings?.staffInfo?.replacement,
                                                    replacement_staff_ids: tempList.filter((elm) => elm !== option),
                                                  },
                                                },
                                              },
                                            };
                                          } else {
                                            return item;
                                          }
                                        });
                                      }
                                    });
                                  }}
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="fieldItem">
                    <RichTextEditor
                      setFieldVal={(value) => {
                        if (isOnlyViewAgenda) return;
                        setProposalList((prev) => {
                          if (!isEmpty(prev)) {
                            return prev.map((item) => {
                              if (item?.staff_id === data?.staff_id) {
                                return {
                                  ...item,
                                  proposal_settings: {
                                    ...item?.proposal_settings,
                                    staffInfo: {
                                      ...item?.proposal_settings?.staffInfo,
                                      replacement: {
                                        ...item?.proposal_settings?.staffInfo?.replacement,
                                        replacement_input_value: value,
                                      },
                                    },
                                  },
                                };
                              } else {
                                return item;
                              }
                            });
                          }
                        });
                      }}
                      fieldVal={templateDetail?.staffInfo?.replacement?.replacement_input_value || ''}
                      newStyles={{ minHeight: '120px', maxHeight: '165px' }}
                      placeholder="Enter Remarks"
                      maxLength={false}
                      readOnly={isOnlyViewAgenda}
                    />
                  </div>
                )}
              </div>
            )}
            {allKeys.includes(`staffInfo.previous_contract_details`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Previous Contract Details'}</span>
                </div>
                <RichTextEditor
                  setFieldVal={(value) => {
                    if (isOnlyViewAgenda) return;
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                staffInfo: {
                                  ...item?.proposal_settings?.staffInfo,
                                  previous_contract_details: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                  fieldVal={templateDetail?.staffInfo?.previous_contract_details || ''}
                  newStyles={{ minHeight: '120px', maxHeight: '165px' }}
                  placeholder="Enter Contract Details"
                  maxLength={false}
                  readOnly={isOnlyViewAgenda}
                />
              </div>
            )}
            {allKeys.includes(`staffInfo.previous_secondment`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Previous Secondment'}</span>
                </div>
                <RichTextEditor
                  setFieldVal={(value) => {
                    if (isOnlyViewAgenda) return;
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                staffInfo: {
                                  ...item?.proposal_settings?.staffInfo,
                                  previous_secondment: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                  fieldVal={templateDetail?.staffInfo?.previous_secondment || ''}
                  newStyles={{ minHeight: '120px', maxHeight: '165px' }}
                  placeholder="Enter Previous Secondment"
                  maxLength={false}
                  readOnly={isOnlyViewAgenda}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {allKeys.includes(`positionInfo`) && (
        <div className="proposedPosition">
          <div className="topHead">{'Proposed Position'}</div>
          <div className="details">
            {allKeys.includes(`positionInfo.positionDetails.positionName`) && (
              <div className="currentPosition mb-16">
                <div className="fieldItem">
                  <span className={'normalLabel textBlock mb-8'}>{'Position Details'}</span>
                  <span className={'normalValue'}>
                    {templateDetail?.positionInfo?.positionDetails?.positionName && (
                      <span className={'textBlock'}>
                        {templateDetail?.positionInfo?.positionDetails?.positionName &&
                          `${templateDetail?.positionInfo?.positionDetails?.positionName},`}
                      </span>
                    )}
                    {templateDetail?.positionInfo?.positionDetails?.department_name && (
                      <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.department_name}</span>
                    )}
                    {templateDetail?.positionInfo?.positionDetails?.division && (
                      <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.division}</span>
                    )}
                    {templateDetail?.positionInfo?.positionDetails?.sector && (
                      <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.sector}</span>
                    )}
                    {templateDetail?.positionInfo?.positionDetails?.opuName === OPU_NAME_EXCEPTION ? (
                      <>
                        {templateDetail?.positionInfo?.positionDetails?.businessUnit && (
                          <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.businessUnit}</span>
                        )}
                        {templateDetail?.positionInfo?.positionDetails?.opuName && (
                          <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.opuName}</span>
                        )}
                      </>
                    ) : (
                      <>
                        {templateDetail?.positionInfo?.positionDetails?.opuName && (
                          <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.opuName}</span>
                        )}
                        {templateDetail?.positionInfo?.positionDetails?.businessUnit && (
                          <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.businessUnit}</span>
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className="fieldItem">
              {allKeys.includes(`positionInfo.positionCode`) && (
                <span className={'normalValue textBlock'}>
                  <span className={'greyLabel'}>PID: </span>
                  {`${templateDetail?.positionInfo?.positionCode}`}
                </span>
              )}
              {allKeys.includes(`positionInfo.jg`) && (
                <span className={'normalValue textBlock mb-12'}>
                  <span className={'greyLabel'}>JG: </span>
                  {`${templateDetail?.positionInfo?.jg}`}
                </span>
              )}
            </div>
            {allKeys.includes(`positionInfo.location`) && (
              <div className="location mb-16">
                <div className="fieldItem">
                  <span className={'normalLabel textBlock'}>{'Location'}</span>
                  <span className={'normalValue'}>{templateDetail?.positionInfo?.location}</span>
                </div>
              </div>
            )}
            {allKeys.includes(`positionInfo.reportTo`) && (
              <div className="reportTo mb-16">
                <div className="fieldItem">
                  <span className={'normalLabel textBlock mb-8'}>{'Reports to'}</span>
                  <span className={'normalValue'}>
                    <span className={'textBlock'}>{`${templateDetail?.positionInfo?.reportTo?.positionName ?? ''}${
                      templateDetail?.positionInfo?.reportTo?.opuName ? `, ${templateDetail?.positionInfo?.reportTo?.opuName}` : ''
                    }`}</span>
                  </span>
                </div>
              </div>
            )}
            {allKeys.includes(`positionInfo.currentIncumbent`) && (
              <div className="fieldItem mb-20">
                <span className={'normalLabel textBlock mb-8'}>{'Current Incumbent'}</span>
                {allKeys.includes(`positionInfo.currentIncumbent`) && (
                  <span className={'normalValue'}>
                    <span className={'textBlock'}>{templateDetail?.positionInfo?.currentIncumbent}</span>
                  </span>
                )}
                {templateDetail?.positionInfo?.currentIncumbent === 'Vacant' && (
                  <RichTextEditor
                    setFieldVal={(value) => {
                      if (isOnlyViewAgenda) return;
                      setProposalList((prev) => {
                        if (!isEmpty(prev)) {
                          return prev.map((item) => {
                            if (item?.staff_id === data?.staff_id) {
                              return {
                                ...item,
                                proposal_settings: {
                                  ...item?.proposal_settings,
                                  positionInfo: {
                                    ...item?.proposal_settings?.positionInfo,
                                    current_incumbent_input_value: value,
                                  },
                                },
                              };
                            } else {
                              return item;
                            }
                          });
                        }
                      });
                    }}
                    fieldVal={templateDetail?.positionInfo?.current_incumbent_input_value || ''}
                    newStyles={{ minHeight: '62px', maxHeight: '165px', marginTop: '5px' }}
                    placeholder=""
                    maxLength={false}
                    readOnly={isOnlyViewAgenda}
                  />
                )}
              </div>
            )}
            {allKeys.includes(`positionInfo.identifiedSuccessors`) && (
              <>
                <div className="placement">
                  <div className="fieldItem">
                    <span className="normalLabel">Identified Successors</span>
                    <CustomSwitch
                      disabled={isOnlyViewAgenda}
                      defaultChecked={templateDetail?.positionInfo?.identifiedSuccessors?.save}
                      value={templateDetail?.positionInfo?.identifiedSuccessors?.save}
                      onChange={(value) => {
                        setProposalList((prev) => {
                          if (!isEmpty(prev)) {
                            return prev.map((item) => {
                              if (item?.staff_id === data?.staff_id) {
                                return {
                                  ...item,
                                  proposal_settings: {
                                    ...item?.proposal_settings,
                                    positionInfo: {
                                      ...item?.proposal_settings?.positionInfo,
                                      identifiedSuccessors: {
                                        ...item?.proposal_settings?.positionInfo?.identifiedSuccessors,
                                        save: value,
                                      },
                                    },
                                  },
                                };
                              } else {
                                return item;
                              }
                            });
                          }
                        });
                      }}
                    />
                  </div>
                </div>
                {!templateDetail?.positionInfo?.identifiedSuccessors?.save ? (
                  <div className="fieldItem mb-20">
                    <RichTextEditor
                      setFieldVal={(value) => {
                        if (isOnlyViewAgenda) return;
                        setProposalList((prev) => {
                          if (!isEmpty(prev)) {
                            return prev.map((item) => {
                              if (item?.staff_id === data?.staff_id) {
                                return {
                                  ...item,
                                  proposal_settings: {
                                    ...item?.proposal_settings,
                                    positionInfo: {
                                      ...item?.proposal_settings?.positionInfo,
                                      identifiedSuccessors: {
                                        ...item?.proposal_settings?.positionInfo?.identifiedSuccessors,
                                        identified_successor_input_value: value,
                                      },
                                    },
                                  },
                                };
                              } else {
                                return item;
                              }
                            });
                          }
                        });
                      }}
                      fieldVal={templateDetail?.positionInfo?.identifiedSuccessors?.identified_successor_input_value || ''}
                      newStyles={{ minHeight: '120px', maxHeight: '165px' }}
                      placeholder="Enter"
                      maxLength={false}
                      readOnly={isOnlyViewAgenda}
                    />
                  </div>
                ) : (
                  <div className="fieldItem mb-12">
                    <CustomFieldEditable disabled={true}>
                      <span
                        style={{ display: 'block', marginBottom: '5px', minHeight: '56px', fontSize: '12px' }}
                        dangerouslySetInnerHTML={{ __html: templateDetail?.positionInfo?.identifiedSuccessors?.text }}
                      ></span>
                    </CustomFieldEditable>
                  </div>
                )}
                <div className="fieldItem selectField mb-20">
                  <SearchStaffForProposal
                    isDisabled={isOnlyViewAgenda}
                    placeholder="Search By Staff ID, Staff Name"
                    addTalent={(value) => {
                      setProposalList((prev) => {
                        if (!isEmpty(prev)) {
                          return prev.map((item) => {
                            if (item?.staff_id === data?.staff_id) {
                              const tempList = item?.proposal_settings?.positionInfo?.identifiedSuccessors?.successorList;
                              const tempData = !isEmpty(tempList)
                                ? tempList.filter((elem) => (elem?.staffId || elem?.staff_id) === (value?.staffId || value?.item?.staff_id))
                                : [];
                              return {
                                ...item,
                                proposal_settings: {
                                  ...item?.proposal_settings,
                                  positionInfo: {
                                    ...item?.proposal_settings?.positionInfo,
                                    identifiedSuccessors: {
                                      ...item?.proposal_settings?.positionInfo?.identifiedSuccessors,
                                      successorList: tempData.length <= 0 ? [...tempList, value] : tempList,
                                    },
                                  },
                                },
                              };
                            } else {
                              return item;
                            }
                          });
                        }
                      });
                    }}
                  />
                </div>
                {allKeys.includes(`positionInfo.identifiedSuccessors.successorList`) && (
                  <div className="searchList">
                    {!isEmpty(templateDetail?.positionInfo?.identifiedSuccessors?.successorList) &&
                      templateDetail?.positionInfo?.identifiedSuccessors?.successorList.map((option, index) => (
                        <div className="searchItem" key={index}>
                          {option?.text && !option?.top_talent_status ? (
                            <div className="searchItemName">
                              <span>{index + 1}. </span>
                              <span>{`${option?.text}`}</span>
                            </div>
                          ) : (
                            <div className="searchItemName">
                              <span>{index + 1}. </span>
                              <span>{`${option?.birthName || option?.positionName || 'None'} ${`(${handleShowCTForProposal(
                                option?.top_talent_status || option?.ct
                              )}, ${option?.age || '-'}, ${option?.sg || option?.job_grade || '-'})`}`}</span>
                            </div>
                          )}
                          {!isOnlyViewAgenda && (
                            <img
                              style={{ cursor: 'pointer' }}
                              src={Close_Icon_2}
                              alt=""
                              onClick={() => {
                                setProposalList((prev) => {
                                  if (!isEmpty(prev)) {
                                    return prev.map((item) => {
                                      if (item?.staff_id === data?.staff_id) {
                                        const tempList = item?.proposal_settings?.positionInfo?.identifiedSuccessors?.successorList;
                                        return {
                                          ...item,
                                          proposal_settings: {
                                            ...item?.proposal_settings,
                                            positionInfo: {
                                              ...item?.proposal_settings?.positionInfo,
                                              identifiedSuccessors: {
                                                ...item?.proposal_settings?.positionInfo?.identifiedSuccessors,
                                                successorList: tempList.filter((elm) => elm !== option),
                                              },
                                            },
                                          },
                                        };
                                      } else {
                                        return item;
                                      }
                                    });
                                  }
                                });
                              }}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {allKeys.includes(`justificationInfo`) && (
        <div className="justification" ref={detailJustificationRef}>
          <div className="topHead">{'Justification'}</div>
          <div className="details">
            {allKeys.includes(`justificationInfo.rating`) &&
              isArray(templateDetail?.justificationInfo?.rating) &&
              templateDetail?.justificationInfo?.rating[0]?.year !== 'false' && (
                <div className="fieldItem" ref={detailPerformanceRecordRef}>
                  <div className={'normalLabel mb-12'}>{'Performance Record'}</div>
                  <UserCareerTable data={templateDetail?.justificationInfo?.rating || []} type={'PROPOSAL'} />
                </div>
              )}
            {allKeys.includes(`justificationInfo.justification`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'} style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>{'Justification'}</span>
                  <CustomTooltip
                    placement={'top'}
                    overlayClassName={styles.customTooltip}
                    overlayStyle={{
                      minWidth: '468px',
                      paddingBottom: '0px',
                      paddingTop: '0px',
                      opacity: 1,
                      backgroundColor: '#000',
                      borderRadius: '4px',
                    }}
                    title={
                      justificationLines?.length > 0 ? (
                        <div className="tooltipContent">
                          <span
                            style={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              color: '#ffffff',
                            }}
                          >
                            {justificationLines.map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </span>
                        </div>
                      ) : null
                    }
                    overlayInnerStyle={{ borderRadius: '6px' }}
                  >
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: `${70}px`,
                        display: 'block',
                        padding: '5px',
                      }}
                    >
                      <img src={info_circle} alt="" />
                    </div>
                  </CustomTooltip>
                </div>
                <RichTextEditor
                  setFieldVal={(value) => {
                    if (isOnlyViewAgenda) return;
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                justificationInfo: {
                                  ...item?.proposal_settings?.justificationInfo,
                                  justification: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                  fieldVal={templateDetail?.justificationInfo?.justification || ''}
                  newStyles={{ minHeight: currentHeightElem > 0 ? `${currentHeightElem}px` : '536px', maxHeight: '165px' }}
                  placeholder="Enter Justifications"
                  maxLength={false}
                  readOnly={isOnlyViewAgenda}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {allKeys.includes(`decisionInfo`) && (
        <div className="decision">
          <div className="topHead">{'Decision'}</div>
          <div className="details">
            {allKeys.includes(`decisionInfo.decision_remarks`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Decision Remarks'}</span>
                </div>
                <RichTextEditor
                  setFieldVal={(value) => {
                    if (isOnlyViewAgenda) return;
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                decisionInfo: {
                                  ...item?.proposal_settings?.decisionInfo,
                                  decision_remarks: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                  fieldVal={getDecisionRemarksDefaultValue || ''}
                  newStyles={{ minHeight: '120px', maxHeight: '165px' }}
                  placeholder=""
                  maxLength={false}
                  readOnly={isOnlyViewAgenda}
                />
              </div>
            )}
            {allKeys.includes(`decisionInfo.effectiveStartDate`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Effective Start Date'}</span>
                </div>
                <AiDatePicker
                  pageName={'PDC_MOBILITY'}
                  format={DATE_TIME_FORMAT.DATE_SLASH}
                  style={isOnlyViewAgenda ? FIELD_STYLE_DISABLED : null}
                  disabled={isOnlyViewAgenda}
                  placeholder="Select"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  value={templateDetail?.decisionInfo?.effectiveStartDate ? moment(templateDetail?.decisionInfo?.effectiveStartDate) : null}
                  onChange={(value) => {
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                decisionInfo: {
                                  ...item?.proposal_settings?.decisionInfo,
                                  effectiveStartDate: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                />
              </div>
            )}
            {allKeys.includes(`decisionInfo.effectiveEndDate`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Effective End Date'}</span>
                </div>
                <AiDatePicker
                  pageName={'PDC_MOBILITY'}
                  format={DATE_TIME_FORMAT.DATE_SLASH}
                  style={isOnlyViewAgenda ? FIELD_STYLE_DISABLED : null}
                  disabled={isOnlyViewAgenda}
                  placeholder="Select"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  value={templateDetail?.decisionInfo?.effectiveEndDate ? moment(templateDetail?.decisionInfo?.effectiveEndDate) : null}
                  onChange={(value) => {
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                decisionInfo: {
                                  ...item?.proposal_settings?.decisionInfo,
                                  effectiveEndDate: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                />
              </div>
            )}
            {allKeys.includes(`decisionInfo.contractStartDate`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Contract Start Date'}</span>
                </div>
                <AiDatePicker
                  pageName={'PDC_MOBILITY'}
                  format={DATE_TIME_FORMAT.DATE_SLASH}
                  style={isOnlyViewAgenda ? FIELD_STYLE_DISABLED : null}
                  disabled={isOnlyViewAgenda}
                  placeholder="Select"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  value={templateDetail?.decisionInfo?.contractStartDate ? moment(templateDetail?.decisionInfo?.contractStartDate) : null}
                  onChange={(value) => {
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                decisionInfo: {
                                  ...item?.proposal_settings?.decisionInfo,
                                  contractStartDate: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                />
              </div>
            )}
            {allKeys.includes(`decisionInfo.contractEndDate`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Contract End Date'}</span>
                </div>
                <AiDatePicker
                  pageName={'PDC_MOBILITY'}
                  format={DATE_TIME_FORMAT.DATE_SLASH}
                  style={isOnlyViewAgenda ? FIELD_STYLE_DISABLED : null}
                  disabled={isOnlyViewAgenda}
                  placeholder="Select"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  value={templateDetail?.decisionInfo?.contractEndDate ? moment(templateDetail?.decisionInfo?.contractEndDate) : null}
                  onChange={(value) => {
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                decisionInfo: {
                                  ...item?.proposal_settings?.decisionInfo,
                                  contractEndDate: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                />
              </div>
            )}
            {allKeys.includes(`decisionInfo.contractDuration`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Duration'}</span>
                </div>
                <CustomInput style={{ height: '42px' }} disabled={true} placeholder="" value={getContractDuration || ''} />
              </div>
            )}
            {allKeys.includes(`decisionInfo.decision`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Decision'}</span>
                </div>
                <CustomSelect
                  disabled={isOnlyViewAgenda}
                  placeholder="Select"
                  style={{
                    width: 120,
                  }}
                  allowClear
                  options={
                    !isEmpty(templateDetail?.decisionInfo?.decision?.list)
                      ? templateDetail?.decisionInfo?.decision?.list.map((item) => ({
                          value: item,
                          label: item,
                        }))
                      : []
                  }
                  value={templateDetail?.decisionInfo?.decision?.selectedDecision || null}
                  onChange={(value) => {
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                decisionInfo: {
                                  ...item?.proposal_settings?.decisionInfo,
                                  decision: {
                                    ...item?.proposal_settings?.decisionInfo?.decision,
                                    selectedDecision: value,
                                  },
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                />
              </div>
            )}

            {allKeys.includes(`decisionInfo.remarks`) && (
              <div className="fieldItem">
                <div className={'smallText mb-8'}>
                  <span>{'Remarks'}</span>
                  <img src={info_circle} alt="" />
                </div>
                <RichTextEditor
                  setFieldVal={(value) => {
                    if (isOnlyViewAgenda) return;
                    setProposalList((prev) => {
                      if (!isEmpty(prev)) {
                        return prev.map((item) => {
                          if (item?.staff_id === data?.staff_id) {
                            return {
                              ...item,
                              proposal_settings: {
                                ...item?.proposal_settings,
                                decisionInfo: {
                                  ...item?.proposal_settings?.decisionInfo,
                                  remarks: value,
                                },
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                  }}
                  fieldVal={templateDetail?.decisionInfo?.remarks || ''}
                  newStyles={{ minHeight: '204px', maxHeight: '240px' }}
                  placeholder="Enter Remarks"
                  maxLength={false}
                  readOnly={isOnlyViewAgenda}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </TemplateWrapper>
  );
};

export default ProposalTemplateContent;

const TemplateWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  > div {
    flex-basis: ${(props) => {
      return props?.columns ? `${100 / props?.columns}%` : `295px`;
    }};
    min-height: 712px;
    border-right: 1px solid var(--color-border-weak, #cbd6e2);

    &:last-child {
      border: none;
    }
  }

  .justification {
    flex-basis: ${(props) => (props?.columns && props?.columns < 4 ? `${100 / props?.columns}%` : `380px !important`)};
  }

  .topHead {
    background: #d9f2f7;
    color: var(--Other-Colors-Typography, #000);
    text-align: center;
    font-family: var(--font-type-default, Inter);
    font-size: var(--font-size-body-md, 16px);
    font-style: normal;
    font-weight: var(--font-weight-bold, 700);
    line-height: var(--font-line-height-body-md, 24px); /* 150% */
    letter-spacing: var(--font-letter-spacing-body-md, 0.3px);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .details {
    padding: 22px 16px;

    .topInfo {
      display: flex;

      .avatar {
        margin-right: 15px;
        img {
          width: 73px;
          height: 73px;
          border-radius: 50%;
        }
      }

      .name {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
        margin-bottom: 10px;
      }
    }

    .jobGrade {
      .fieldItem {
        display: flex;
      }
    }

    .fieldItem {
      position: relative;
      margin-bottom: 5px;
      .boldText {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .lightText {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .normalLabel {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .normalValue {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--font-type-default, Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .greyLabel {
        color: #15191e;
        font-family: var(--font-type-default, Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 171.429% */
        display: inline-block;
        min-width: 50px;
      }

      .highlightValue {
        color: #15191e;
        font-family: var(--font-type-default, Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
      }

      .textBlock {
        display: block;
      }

      .smallText {
        color: var(--Grays-500, #525252);
        font-family: var(--font-type-default, Inter);
        font-size: 12px;
        font-style: normal;
        font-weight: var(--font-weight-medium, 500);
        line-height: var(--font-line-height-body-sm, 20px); /* 166.667% */

        span {
          margin-right: 10px;
        }
      }
    }

    .placement {
      .fieldItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }

  .decision {
    .fieldItem {
      margin-bottom: 16px;
    }
  }

  .selectField {
    margin-bottom: 16px;
  }

  .inlineTextList {
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }

  .searchList {
    .searchItem {
      color: #000;
      font-family: var(--font-type-default, Inter);
      font-size: var(--font-size-body-sm, 14px);
      font-style: normal;
      font-weight: var(--font-weight-regular, 400);
      line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
      letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s;
      gap: 5px;

      &:last-child {
        margin-bottom: 16px;
      }

      &:hover {
        img {
          visibility: visible;
          opacity: 1;
        }
      }

      .searchItemName {
        display: flex;
        align-items: flex-start;

        span {
          &:first-child {
            min-width: 24px;
            flex-basis: 24px;
          }
        }
      }

      img {
        flex-basis: 20px;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .mb-8 {
    margin-bottom: 8px !important;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }

  .mb-12 {
    margin-bottom: 12px !important;
  }

  .mb-16 {
    margin-bottom: 16px !important;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }
`;

const CustomSelect = styled(Select)`
  width: 100% !important;
  border-radius: 4px;

  &.ant-select-disabled {
    cursor: no-drop;
    .ant-select-selector {
      color: #15191e !important;
      background: var(--color-background-strong, #f0f5fa) !important;
    }
  }
`;

const CustomSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #002a53 !important;
  }

  &.ant-switch-disabled {
    cursor: no-drop;
    background: var(--Light-Info-200, #bee2fe) !important;
  }
`;

const CustomInput = styled(Input)`
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #15191e;
  font-family: var(--font-type-default, Inter);
  font-size: var(--font-size-body-sm, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
  letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);

  &::placeholder {
    color: #8d8d8d !important;
  }

  &:disabled {
    cursor: no-drop;
    color: #15191e !important;
    border: 1px solid var(--color-border-weak, #cbd6e2) !important;
    background: var(--color-background-strong, #f0f5fa) !important;
  }
`;

// const CustomTextarea = styled(TextArea)`
//   resize: none;
//   border-radius: 4px;
//   border: 1px solid #d9d9d9;
//   color: #15191e;
//   font-family: var(--font-type-default, Inter);
//   font-size: var(--font-size-body-sm, 14px);
//   font-style: normal;
//   font-weight: var(--font-weight-regular, 400);
//   line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
//   letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);

//   &::placeholder {
//     color: #8d8d8d !important;
//   }

//   &:disabled {
//     cursor: no-drop;
//     color: #15191e !important;
//     border: 1px solid var(--color-border-weak, #cbd6e2) !important;
//     background: var(--color-background-strong, #f0f5fa) !important;
//   }
// `;

const CustomFieldEditable = styled.div`
  border-radius: 4px;
  background: ${(props) => {
    return props?.disabled ? `#f0f5fa` : `#fff`;
  }};
  border: 1px solid #d9d9d9;
  border-color: ${(props) => {
    return props?.disabled ? `#cbd6e2` : `#cbd6e2`;
  }};
  cursor: ${(props) => {
    return props?.disabled ? `no-drop` : `text`;
  }};
  padding: 10px;
  font-family: var(--font-type-default, Inter);
  font-size: var(--font-size-body-sm, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
  letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);
  overflow: auto;
  width: 100%;

  [data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: #8d8d8d !important;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltipContent {
    display: flex;
    align-items: center;
    font-family: var(--font-type-default, Inter);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 10px;

    span {
      display: inline-block;
      padding-left: 10px;
    }
  }
`;
