import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { TP_PRINT_SECTION_NAME } from '../../../../components/TalentProfilePrintPreview/constants';
import { fetchCheckPermittedHideUnhideToggle } from '../../../../services/talentProfiles';
import { ASSIGNMENT_STATUS_TYPES, PAGE_NAME } from '../../../../utils/constants';
import { decodeBase64ToImage } from '../../../../utils/helper';
import TalentFinderTooltip from '../../../TalentFinderPage/TalentFinderDetail/TalentFinderTooltip/TalentFinderTooltip';
import { avatarSelector, profileSelector } from '../../selector';
import NameAndPosition from './components/NameAndPosition';
import UserCareer from './components/UserCareer';
import UserInfoDetail from './components/UserInfoDetail';
import UserMileStone from './components/UserMileStone';
import styles from './overall-profile-user.module.scss';

const OverAllProfileUser = ({ isPrintPdf, tokenTalentProfile }) => {
  const { roleActive } = useSelector((state) => state.user);
  const avatarImage = useSelector(avatarSelector);
  const { firstSectionProfile, loading } = useSelector(profileSelector);
  const [isActive, setIsActive] = useState(true);
  const [isHasEditPermission, setIsHasEditPermission] = useState(false);
  const { id } = useParams();
  const user = useSelector((state) => state.user);

  const checkPermittedHideUnhideToggle = async () => {
    try {
      const roleUser = { id, roleActive: user.roleActive, permissions: user.permissions };
      let response = await fetchCheckPermittedHideUnhideToggle({ roleUser, id, tokenTalentProfile });
      if (response?.data && response.data.code !== 200) {
        setIsHasEditPermission(false);
        return;
      }
      const rs = response.data.result;
      setIsHasEditPermission(rs);
    } catch (e) {
      console.error(e);
      setIsHasEditPermission(false);
    }
  };

  useEffect(() => {
    if (!roleActive) return;
    setIsActive(true);
  }, [roleActive]);

  const returnBackgroundImage = () => {
    let bgColor = '';
    switch (firstSectionProfile?.assignment_status_type) {
      case ASSIGNMENT_STATUS_TYPES.ACTIVE:
        bgColor = '#14919B';
        break;
      case ASSIGNMENT_STATUS_TYPES.INACTIVE:
        bgColor = '#DA2228';
        break;
      case ASSIGNMENT_STATUS_TYPES.SUSPENDED:
        bgColor = '#FC930D';
        break;
      default:
        bgColor = '#F0F3F7';
        break;
    }
    return bgColor;
  };

  const tpLeft = document.getElementById(isPrintPdf ? 'tp-left-print' : 'tp-left');
  const tpRight = document.getElementById(isPrintPdf ? 'tp-right-print' : 'tp-right');

  useEffect(() => {
    const left = tpLeft?.getBoundingClientRect();
    const right = tpRight?.getBoundingClientRect();
    if (left && right) {
      tpLeft.style.paddingTop = `0px`;
      tpRight.style.paddingTop = `0px`;
    }

    if (Math.round(left?.y) > Math.round(right?.y)) tpRight.style.paddingTop = `${Math.round(left.y) - Math.round(right.y)}px`;
    if (Math.round(left?.y) < Math.round(right?.y)) tpLeft.style.paddingTop = `${Math.round(right.y) - Math.round(left.y)}px`;
  }, [Math.round(tpLeft?.getBoundingClientRect()?.y), Math.round(tpRight?.getBoundingClientRect()?.y), isPrintPdf]);

  useEffect(() => {
    checkPermittedHideUnhideToggle();
  }, [roleActive?.roleId, id]);

  return (
    <div
      className={styles.userInfo}
      style={isActive === false ? { justifyContent: 'flex-end', paddingBottom: '20px' } : { justifyContent: 'space-between' }}
    >
      <div className={styles.leftInfo}>
        <div className={styles.basicInfo}>
          {isActive && (
            <div data-print-section={TP_PRINT_SECTION_NAME.PROFILE_PICTURE} className={styles.avatar_wrapper} style={{ marginBottom: '20px' }}>
              <img
                src={!loading && decodeBase64ToImage(avatarImage)}
                alt=""
                width={155}
                height={155}
                style={{
                  borderRadius: '50%',
                  borderWidth: '3px',
                  borderStyle: 'solid',
                  borderColor: (!loading && returnBackgroundImage()) || '#F0F3F7',
                }}
              />
              {!loading && firstSectionProfile && <TalentFinderTooltip item={firstSectionProfile} pageName={PAGE_NAME.TALENT_PROFILE} />}
            </div>
          )}
          {isActive && <NameAndPosition />}
        </div>
        <UserCareer isPrint={isPrintPdf} />
      </div>
      <div style={isPrintPdf ? { paddingTop: '50px' } : null} className={styles.rightInfo}>
        <UserInfoDetail isActive={isActive} setIsActive={setIsActive} isPrintPdf={isPrintPdf} isHasEditPermission={isHasEditPermission} />
        <UserMileStone isPrintPdf={isPrintPdf} />
      </div>
    </div>
  );
};

OverAllProfileUser.propTypes = {
  isPrintPdf: PropTypes.bool,
};

export default OverAllProfileUser;
