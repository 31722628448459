import React, { useState } from 'react';
import * as styles from './talent-profile-print-selection.module.scss';
import { TP_PRINT_DEFAULT_RADIO_OPTIONS, TP_PRINT_DEFAULT_SELECT_OPTIONS, TP_PRINT_SELECT_OPTIONS } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updatePrintSettingRadio, updatePrintSettingSelection } from '../../../../pages/TalentProfilePage/store/printProfileSlice';
import BasicTreeSelect from '../../../BasicTreeSelect/BasicTreeSelect';
import { tp_print_setting } from '../../../../assets/img';

const TalentProfilePrintSelection = () => {
  const dispatch = useDispatch();
  const { selectedOptions: prevSelectedOption, radioOptions: prevRadioOptions } = useSelector(
    (state) => state.app.talentProfile.printProfile.printSettings
  );
  const [selectedOptions, setSelectedOptions] = useState(TP_PRINT_DEFAULT_SELECT_OPTIONS);
  const [radioOptions, setRadioOptions] = useState(TP_PRINT_DEFAULT_RADIO_OPTIONS);

  const handleChangeRadio = (name, parentName) => {
    setRadioOptions((prev) => ({
      ...prev,
      [parentName]: name,
    }));
  };

  const handleApply = () => {
    dispatch(updatePrintSettingSelection(selectedOptions));
    dispatch(updatePrintSettingRadio(radioOptions));
  };

  return (
    <BasicTreeSelect
      placement="bottomRight"
      treeDefaultExpandAll={true}
      dropdownStyle={{ minWidth: 415, maxWidth: 415 }}
      onApply={handleApply}
      options={TP_PRINT_SELECT_OPTIONS}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      radioOptions={radioOptions}
      setRadioOptions={setRadioOptions}
      onChangeRadio={handleChangeRadio}
      prevSelectedOption={prevSelectedOption}
      prevRadioOptions={prevRadioOptions}
      listHeight={730}
      style={{height: 37}}
    >
      <div className={styles.suffixIcon}>
        <img src={tp_print_setting} alt="print_setting" />
      </div>
    </BasicTreeSelect>
  );
};

export default TalentProfilePrintSelection;
