import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './notes-print.module.scss';
import { TP_PRINT_SECTION_NAME } from '../../constants';
import moment from 'moment';

const NotesPrint = React.memo(({ fetchedData }) => {
  return (
    <div data-print-section={TP_PRINT_SECTION_NAME.NOTES} className={styles.wrapper}>
      <div className={styles.title}>Notes</div>
      <table>
        <thead>
          <tr>
            <th>Platform</th>
            <th>Dates</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {fetchedData ? (
            fetchedData.data.map((item) => (
              <tr key={item.id}>
                <td>{item.platform}</td>
                <td>{moment(item.date).format('DD-MMM-YYYY')}</td>
                <td>{item.notes_content}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
});

export default NotesPrint;
NotesPrint.displayName = 'NotesPrint';

NotesPrint.propTypes = {
  fetchedData: PropTypes.object,
};
