import { useMemo } from 'react';
import { useGetPdcMobilityAgenda } from './useGetPdcMobilityAgenda';
import { useGetRoleInPdcMeeting } from './useGetRoleInPdcMeeting';
import { useGetPDCMeeting } from './useGetPDCMeeting';
import { AGENDA_STATUS, MEETING_DETAIL_STATUS, ROLE } from '../utils/constants';
import { useSelector } from 'react-redux';
import { isArray, isEmpty } from 'lodash';

export const useCanEditPdcMobilityAgenda = (props) => {
  const { idMeeting, mobilityId } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const { data: meetingRole } = useGetRoleInPdcMeeting({ idMeeting });
  const { data: meetingDetail, fetch: fetchPdcMeetingDetail } = useGetPDCMeeting({ idMeeting });
  const { data: mobilityDetails, fetch: fetchMobilityDetails } = useGetPdcMobilityAgenda({ idMeeting, mobilityId });
  const userInfor = useSelector((state) => state.user.data);

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  const isHRPartners = useMemo(() => {
    if (!idMeeting) return false;
    const isValidHrPartner = !isEmpty(mobilityDetails?.hr_partners)
      ? mobilityDetails?.hr_partners.find((m) => m.id === userInfor?.userId)
      : undefined;
    return !meetingRole?.is_secretariat_or_facilitator && !isAdminRole && meetingRole?.is_hr_partners && isValidHrPartner;
  }, [meetingRole, idMeeting, isAdminRole, mobilityDetails, userInfor?.userId]);

  const isChairMainOrCommiteeOrAdvocator = useMemo(() => {
    if (!idMeeting) return false;

    return (
      !meetingRole?.is_secretariat_or_facilitator &&
      !isHRPartners &&
      !isAdminRole &&
      (meetingRole?.is_chairman || meetingRole?.is_advocator || meetingRole?.is_commitee)
    );
  }, [meetingRole, idMeeting, isAdminRole, isHRPartners]);

  const isAdditionalViewAccess = useMemo(() => {
    if (!idMeeting) return false;
    return !meetingRole?.is_secretariat_or_facilitator && !isHRPartners && !isAdminRole && meetingRole?.is_additional_view_access;
  }, [meetingRole, idMeeting, isAdminRole, isHRPartners]);

  const isOnlyViewAgenda = useMemo(() => {
    return (
      ([AGENDA_STATUS.PAPER_READY].includes(mobilityDetails?.status) &&
        [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(meetingDetail?.status)) ||
      ([AGENDA_STATUS.PAPER_FINALIZED].includes(mobilityDetails?.status) && [MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status)) ||
      [MEETING_DETAIL_STATUS.CANCELED, MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status) ||
      isAdditionalViewAccess ||
      isChairMainOrCommiteeOrAdvocator
    );
  }, [mobilityDetails, isAdditionalViewAccess, meetingDetail?.status, isChairMainOrCommiteeOrAdvocator]);

  const isShowTopButtons = useMemo(() => {
    return (
      ([AGENDA_STATUS.PAPER_READY].includes(mobilityDetails?.status) &&
        [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED, MEETING_DETAIL_STATUS.CANCELED].includes(meetingDetail.status)) ||
      ([AGENDA_STATUS.PAPER_FINALIZED, MEETING_DETAIL_STATUS.CANCELED].includes(mobilityDetails?.status) &&
        [MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status)) ||
      ([AGENDA_STATUS.PAPER_FINALIZED].includes(mobilityDetails?.status) && meetingDetail?.status !== MEETING_DETAIL_STATUS.IN_REVIEW) ||
      isAdditionalViewAccess ||
      isChairMainOrCommiteeOrAdvocator
    );
  }, [mobilityDetails, meetingDetail.status, isAdditionalViewAccess, isChairMainOrCommiteeOrAdvocator]);

  const isShowBotButtons = useMemo(() => {
    return (
      [AGENDA_STATUS.PAPER_IN_PROGRESS].includes(mobilityDetails?.status) ||
      ([AGENDA_STATUS.PAPER_READY].includes(mobilityDetails?.status) && [MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail.status)) ||
      [MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingDetail.status)
    );
  }, [mobilityDetails, meetingDetail?.status]);

  const isEnableMobilityTab = useMemo(() => {
    return (
      [AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_FINALIZED].includes(mobilityDetails?.status) &&
      [
        MEETING_DETAIL_STATUS.IN_PLANNING,
        MEETING_DETAIL_STATUS.PUBLISHED,
        MEETING_DETAIL_STATUS.IN_PROGRESS,
        MEETING_DETAIL_STATUS.IN_REVIEW,
        MEETING_DETAIL_STATUS.CONCLUDED,
      ].includes(meetingDetail.status) &&
      (meetingRole?.is_secretariat_or_facilitator ||
        meetingRole?.is_chairman ||
        meetingRole?.is_commitee ||
        meetingRole?.is_advocator ||
        meetingRole?.is_invitee ||
        meetingRole?.is_additional_view_access ||
        isAdminRole)
    );
  }, [mobilityDetails, meetingDetail.status, isAdminRole, meetingRole]);

  const proposalMainStep = useMemo(() => {
    return isArray(mobilityDetails?.proposals) ? mobilityDetails?.proposals.filter((i) => i?.is_main)[0] : { step: 1 };
  }, [mobilityDetails]);

  const isShownMobilityTabBar = useMemo(() => {
    return (
      ([AGENDA_STATUS.PAPER_FINALIZED].includes(mobilityDetails?.status) && meetingDetail?.status !== MEETING_DETAIL_STATUS.IN_REVIEW) ||
      ([AGENDA_STATUS.PAPER_READY].includes(mobilityDetails?.status) && meetingDetail?.status !== MEETING_DETAIL_STATUS.IN_PROGRESS) ||
      isOnlyViewAgenda ||
      isAdditionalViewAccess
    );
  }, [meetingDetail, mobilityDetails, isOnlyViewAgenda, isAdditionalViewAccess]);

  return {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdminRole,
    isAdditionalViewAccess,
    mobilityDetails,
    isEnableMobilityTab,
    meetingDetail,
    proposalMainStep,
    isShownMobilityTabBar,
    fetchMobilityDetails,
    fetchPdcMeetingDetail,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  };
};
