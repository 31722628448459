import { unwrapResult } from '@reduxjs/toolkit';
import { Col, notification, Row, Tooltip } from 'antd';
import { useFormik } from 'formik';
import { cloneDeep, get, isEmpty, isNaN, size } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { AiButton, AiLoading, AiSpin, font } from '../../../assets/common';
import { comment_stack, dashboard_breadcrumb, hr_info } from '../../../assets/img';
import { AlertMessage, AsyncMultipleSelect, BreadCrumb, ModelTC, SingleSelect, TalentComparison } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { onChangeInputData, onChangeSelectData, showError } from '../../../hooks/useFormik';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
import { useGetRoleInMeeting } from '../../../hooks/useGetRoleInMeeting';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { useUpdateStatusAgenda } from '../../../hooks/useUpdateStatusAgenda';
import { meetingApi } from '../../../services/tcMeeting';
import { pushMessage } from '../../../store/alertMessageSlice';
import { saveAgendaDetail } from '../../../store/spTemplateSlice';
import {
  AGENDA_SHORT_TYPES,
  AGENDA_STATUS,
  AGENDA_TYPES,
  DATE_TIME_FORMAT,
  FILL_OUT_REQUIRE_MESSAGE,
  GLOBAL_ALERT_MESSAGE_CONTAINER_ID,
  KEY_CHANGES_TO_CHECK_SP_AGENDA,
  MEETING_DETAIL_STATUS,
  MESSAGE_TYPES,
  NOTIFICATION_TYPE,
  ROLE,
  ROUTE_BACK_HISTORY,
  SAVE_AGENDA_AS_DRAFT_MESSAGE,
  USER_ID,
} from '../../../utils/constants';
import {
  convertAgendaIncumbentData,
  getAsyncOptionAdvocatorsHrPartners,
  getDescForWithoutModal,
  getSPAgendaDetailError,
  staffIdMap,
  validateActionType,
} from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import {
  clearRemarks,
  createNewCreateRemark,
  createNewDeleteRemark,
  getRemarkFromPendingAction,
  getRemarks,
  savePreRemarks,
} from '../../Meeting/redux/remarksSlice';
import {
  getAgendaIncumbent,
  removePosition,
  resetAsMainAgenda,
  resetCardSetting,
  resetTemporaryPrevAgenda,
  setAgendaIncumbent,
  setAllCardSettings,
  setAsMainAgenda,
  updateAgendaIncumbent,
  updateAgendaLine,
  updatePositions,
  updateTemporaryPrevAgenda,
} from '../redux/agendaSlice';
import { addOrUpdateMeeting } from '../redux/meetingDetailSlice';
import AddPositionDialog from './components/AddPositionDialog/AddPositionDialog';
import PopupListConcludedAgenda from './components/PopupListConcludedAgenda/PopupListConcludedAgenda';
import PopupSaveVersion from './components/PopupSaveVersion/PopupSaveVersion';
import Positions from './components/Positions/Positions';
import Recommendations from './components/Recommendations/Recommendations';
import SyndicationDialog from './components/SyndicationDialog/SyndicationDialog';
import VersionHistory from './components/VersionHistoryDialog';
import PrintSucessionPlanPopup from './components/ViewSucessionPlanPopup/PrintSucessionPlanPopup';
import {
  AiAgendaForm,
  AiGrBtnHeader,
  AiGrBtnMiddle,
  AiHeader,
  AiImg,
  AiInput,
  AiLabel,
  AiNoAgenda,
  AiNoAgendaDesc,
  AiNoAgendaTitle,
  AiPositionsInformation,
  AiTimePickerField,
  AiTitle,
} from './Styled';

export const useAgendarControl = (status) => {
  const [controlState, setControlState] = useState({
    isDisabledByAgendaStatus: false,
    isDisplayEditBtn: false,
    isDisplayViewHistoryBtn: true,
    isDisplayRecommendations: true,
  });

  useLayoutEffect(() => {
    setControlState({
      isDisabledByAgendaStatus: [AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_BRING, AGENDA_STATUS.PAPER_FINALIZED].includes(status),
      isDisplayEditBtn: status === AGENDA_STATUS.PAPER_READY || status === AGENDA_STATUS.PAPER_FINALIZED,
      isDisplayViewHistoryBtn: status !== AGENDA_STATUS.PAPER_BRING,
      isDisplayRecommendations: status !== AGENDA_STATUS.PAPER_BRING,
      isDisplayFinalized: status === AGENDA_STATUS.PAPER_FINALIZED,
    });
  }, [status]);

  return controlState;
};

const If = ({ is, children }) => is && children;

const AgendaDetails = ({ isReadOnly }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { agendaId, idMeeting, versionId } = useParams();
  const { search } = useLocation();
  const objSearch = queryString.parse(search);
  const roleActive = useSelector((state) => state.user.roleActive);
  const user = useSelector((state) => state.user);
  const agendaDetail = useSelector((state) => state.spTemplate.agendaDetail);
  const [showCompareTalent, setShowCompareTalent] = useState(false);
  const [versionHistory, setVersionHistory] = useState(false);
  const [versionIdAgenda, setVersionIdAgenda] = useState(versionId || 0);
  const [agendaAsMain, setAgendaAsMain] = useState(null);

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  const onlyViewContent = useMemo(() => {
    return objSearch?.type === 'view';
  }, [objSearch]);

  const { positions, agenda_as_main_positions } = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const { createdAgenda, agendaIncumbent, temporaryPrevAgenda } = useSelector((state) => state.app.meetingModule.agenda);
  const agendaIncumbentStatus = get(agendaIncumbent, 'status');
  const { isDisabledByAgendaStatus, isDisplayRecommendations, isDisplayEditBtn, isDisplayViewHistoryBtn, isDisplayFinalized } =
    useAgendarControl(agendaIncumbentStatus);
  const { date, meeting_id, status } = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const { remarks: remarks_from_pending_action } = useSelector((state) => state.app.meetingModule.remarks);
  const {
    createList,
    editList,
    deleteList,
    remarks: talentRemarks,
    prevRemarks: prevTalentRemarks,
  } = useSelector((state) => state.app.meetingModule.remarks);

  //Save version
  const [isSaveVersion, setIsSaveVersion] = useState(false);
  const [isSaveVersionSuccess, setIsSaveVersionSuccess] = useState(false);
  const [nameVersion, setNameVersion] = useState('');
  const setTriggerSaveVersion = useState(false)[1];
  ///
  const [openPosition, setOpenPosition] = useState(false);
  const [openPrintPopup, setOpenPrintPopup] = useState(false);
  const [openSyndication, setOpenSyndication] = useState(false);
  //Get agenda
  const [loadingGetAgenda, setLoadingGetAgenda] = useState(false);
  const [isDisabledPrintBtn, setIsDisabledPrintBtn] = useState(false);
  const [agendaRatio, setAgendaRatio] = useState(null);
  const viewVersion = '';
  // Change agenda status
  const [visibleChangeStatusModal, setVisibleChangeStatusModal] = useState(false);
  const [isChangedStatusMessage, setIsChangedStatusMessage] = useState(false);
  ///Line
  const initialLineValue = {
    first_line: [],
    second_line: [],
    third_line: [],
  };
  const [lineRe, setLineRe] = useState({
    line1: [],
    line2: [],
    line3: [],
  });
  const [isWarningMessage, setIsWarningMessage] = useState({
    showWarning: false,
    message: '',
  });
  const [loadingSaveVersion, setLoadingSaveVersion] = useState(false);
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });
  const pageHistory = new URLSearchParams(window.location.search).get('history');
  const [prevAgendaData, setPrevAgendaData] = useState({
    ...agendaIncumbent,
    ...initialLineValue,
    remarks: null,
  });
  const [isPositionChanges, setIsPositionChanges] = useState(false);
  const [messageActionType, setMessageActionType] = useState(null); //{ user_role: 'HR COE' , position_category: 'CCP'}

  // Set prev agenda data in the first time
  useEffect(() => {
    if (isPositionChanges) {
      return;
    }

    setPrevAgendaData(
      cloneDeep({
        ...agendaIncumbent,
      })
    );
  }, [agendaIncumbent, isPositionChanges, setPrevAgendaData]);

  const isAdditionalViewAccess = useMemo(() => {
    if (!agendaId) return false;

    return (
      !meetingRole?.is_secretariat_or_facilitator &&
      !meetingRole?.is_chairman &&
      !meetingRole?.is_commitee &&
      !isAdminRole &&
      meetingRole?.is_additional_view_access
    );
  }, [meetingRole, agendaId, isAdminRole]);

  const isViewOnlyAgenda = useMemo(() => {
    return (
      (!meetingRole.is_secretariat_or_facilitator && !isAdminRole && agendaId && status !== MEETING_DETAIL_STATUS.IN_PLANNING) ||
      isAdditionalViewAccess
    );
  }, [meetingRole.is_secretariat_or_facilitator, agendaId, status]);

  const { data: meetingDetail } = useGetMeeting({ idMeeting });
  const backUrl = pageHistory?.includes(ROUTE_BACK_HISTORY.MEETING_DETAIL)
    ? `/meeting/detail/${idMeeting}`
    : `/meeting/${idMeeting || meeting_id}/agendas`;
  const backAgendaUrl = isNaN(Number(agendaId))
    ? `/meeting/${idMeeting || meeting_id}/agenda/${agendaId}`
    : `/meeting/${idMeeting || meeting_id}/agenda/create`;
  const breadCrumbList = [
    { name: 'Dashboard', icon: dashboard_breadcrumb, url: `/dashboard` },
    { name: 'Meeting Details', url: backUrl || '' },
    { name: 'Agenda', url: backAgendaUrl || '' },
    { name: `${agendaDetail?.agenda_name || 'Succession Planning'}` },
  ];

  useEffect(() => {
    if (meetingDetail) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail]);

  const [isShowSendForSyndication, setIsShowSendForSyndication] = useState(false);
  useEffect(() => {
    if (meetingDetail) {
      const isCheck =
        !isNaN(Number(agendaId)) &&
        agendaId !== 0 &&
        [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(meetingDetail?.status);
      setIsShowSendForSyndication(isCheck);
    }
  }, [agendaId, meetingDetail]);

  const [isCreatedAgenda, setIsCreatedAgenda] = useState(false);
  useEffect(() => {
    if (meetingDetail) {
      const isCheck = !isNaN(Number(agendaId)) && agendaId !== 0;
      setIsCreatedAgenda(isCheck);
    }
  }, [agendaId, meetingDetail]);

  const isEditAgendaWithMeetingSecretariatFacilitator = useMemo(() => {
    if (meetingDetail && (meetingDetail.is_secretariat_or_facilitator || isAdminRole) && agendaId) {
      return (
        (meetingDetail?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
          (meetingDetail?.status === MEETING_DETAIL_STATUS.PUBLISHED && agendaDetail?.status === AGENDA_STATUS.PAPER_IN_PROGRESS)) &&
        agendaDetail?.status === AGENDA_STATUS.PAPER_READY
      );
    }
  }, [meetingDetail, agendaId, agendaDetail, isAdminRole]);

  const isEnableButtonToEditPosition = useMemo(() => {
    if (meetingDetail && agendaIncumbent && (meetingDetail.is_secretariat_or_facilitator || isAdminRole)) {
      return [MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status) && [AGENDA_STATUS.PAPER_READY].includes(agendaIncumbent?.status);
    }
  }, [meetingDetail, agendaIncumbent, isAdminRole]);

  const isViewModeAgenda = useMemo(() => {
    if (meetingDetail && agendaIncumbent && (meetingDetail.is_secretariat_or_facilitator || isAdminRole)) {
      return (
        ([MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(meetingDetail?.status) &&
          [AGENDA_STATUS.PAPER_READY].includes(agendaIncumbent?.status)) ||
        ([MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status) && [AGENDA_STATUS.PAPER_FINALIZED].includes(agendaIncumbent?.status)) ||
        [MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status)
      );
    }
    return !meetingDetail?.is_secretariat_or_facilitator;
  }, [meetingDetail, agendaIncumbent, isAdminRole]);

  const isDisplayRemark =
    [MEETING_DETAIL_STATUS.IN_PROGRESS, MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingDetail?.status) &&
    (meetingRole?.is_secretariat_or_facilitator || isAdminRole);

  const getAgendaByVersion = useCallback(
    async ({ agendaId, version }) => {
      if (!agendaId || !version) return;
      setLoadingGetAgenda(true);
      try {
        const agendaDetail = meetingApi.getSPAgendaDetail({ agendaId, roleId: roleActive.roleId, version }).then((res) => res);
        const agendaReadiness = meetingApi.getSPAgendaReadiness({ agendaId, roleId: roleActive.roleId, version }).then((res) => res);
        const agendaIncumbent = meetingApi.getSPAgendaIncumbent({ agendaId, roleId: roleActive.roleId, version }).then((res) => res);
        const results = await Promise.all([agendaDetail, agendaReadiness, agendaIncumbent]);
        const isHasError = results.find((m) => m.status !== 200);
        if (isHasError) throw new Error('Api get detail agenda failed');
        const newData = {
          ...get(results[0], 'data.result'),
          ...get(results[2], 'data.result'),
          ...get(results[1], 'data.result'),
        };
        dispatch(getAgendaIncumbent(newData));
        dispatch(updateTemporaryPrevAgenda(newData));
        setLoadingGetAgenda(false);
      } catch (err) {
        showNotification(`Get Data Of Agenda failed`, NOTIFICATION_TYPE.FAILED);
        setLoadingGetAgenda(false);
      }
    },
    [dispatch, roleActive.roleId]
  );

  const getAgenda = async () => {
    if (isNaN(Number(agendaId))) return;
    setLoadingGetAgenda(true);
    try {
      const agendaDetail = meetingApi.getSPAgendaDetail({ agendaId, roleId: roleActive.roleId, version: 0 }).then((res) => res);
      const agendaReadiness = meetingApi.getSPAgendaReadiness({ agendaId, roleId: roleActive.roleId, version: 0 }).then((res) => res);
      const agendaIncumbent = meetingApi.getSPAgendaIncumbent({ agendaId, roleId: roleActive.roleId, version: 0 }).then((res) => res);
      const results = await Promise.all([agendaDetail, agendaReadiness, agendaIncumbent]);
      const isHasError = results.find((m) => m.status !== 200);
      if (isHasError) throw new Error('Api get detail agenda failed');
      const newData = {
        ...get(results[0], 'data.result'),
        ...get(results[2], 'data.result'),
        ...get(results[1], 'data.result'),
      };
      dispatch(getAgendaIncumbent(newData));
      dispatch(saveAgendaDetail(newData));
      setVersionIdAgenda(newData.version);
      setPrevAgendaData(convertAgendaIncumbentData(newData));
      setLoadingGetAgenda(false);
      dispatch(updateTemporaryPrevAgenda(newData));
      handleSetFormValues(newData);
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          message: `An error occurred while retrieving the agenda details. Please try again.`,
          type: MESSAGE_TYPES.ERROR,
          containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
        })
      );
      setLoadingGetAgenda(false);
    }
  };

  const getAgendaById = async (id) => {
    try {
      const agendaDetail = meetingApi.getSPAgendaDetail({ agendaId: id, roleId: roleActive.roleId, version: 0 }).then((res) => res);
      const agendaReadiness = meetingApi.getSPAgendaReadiness({ agendaId: id, roleId: roleActive.roleId, version: 0 }).then((res) => res);
      const agendaIncumbent = meetingApi.getSPAgendaIncumbent({ agendaId: id, roleId: roleActive.roleId, version: 0 }).then((res) => res);
      const results = await Promise.all([agendaDetail, agendaReadiness, agendaIncumbent]);
      const isHasError = results.find((m) => m.status !== 200);
      if (isHasError) throw new Error('Api get detail agenda failed');
      return {
        ...get(results[0], 'data.result'),
        ...get(results[2], 'data.result'),
        ...get(results[1], 'data.result'),
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    if (isNaN(Number(agendaId))) {
      let idxEle = createdAgenda.findIndex((item) => item.agenda_id === agendaId);
      if (idxEle !== -1) {
        dispatch(setAgendaIncumbent(idxEle));
        dispatch(updateTemporaryPrevAgenda(createdAgenda[idxEle]));
      }
      return () => {
        dispatch(getAgendaIncumbent(getSPAgendaDetailError()));
        dispatch(saveAgendaDetail(getSPAgendaDetailError()));
        dispatch(updateTemporaryPrevAgenda(getSPAgendaDetailError()));
        handleSetFormValues(getSPAgendaDetailError());
        dispatch(resetCardSetting());
      };
    }

    if (!versionId) {
      getAgenda();
      return () => {
        dispatch(getAgendaIncumbent(getSPAgendaDetailError()));
        dispatch(saveAgendaDetail(getSPAgendaDetailError()));
        dispatch(updateTemporaryPrevAgenda(getSPAgendaDetailError()));
        handleSetFormValues(getSPAgendaDetailError());
        dispatch(resetCardSetting());
      };
    }

    getAgendaByVersion({ agendaId, version: versionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agendaIncumbent.agenda_id && !agendaId) {
      setPrevAgendaData({ ...agendaIncumbent, ...formik.values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaId, agendaIncumbent.agenda_id]);

  useEffect(() => {
    if (prevAgendaData.agenda_id && !agendaId) {
      history.push(`/meeting/${idMeeting}/agenda/${prevAgendaData.agenda_id}?history=${pageHistory}`);
    }
  }, [agendaId, prevAgendaData.agenda_id, history, idMeeting, pageHistory]);

  const validationSchema = yup.object().shape(
    {
      succession_planning_name: yup.string().required('Succession planning name is required'),
      end_time: yup.string().required('End Time is required.'),
      start_time: yup
        .string()
        .required('Start Time is required.')
        .test('is-greater', 'Start Time must be less than or equal to End Time', function (value) {
          const startTimeMoment = moment(value, DATE_TIME_FORMAT.TIME);
          const endTimeMoment = moment(formik.values.end_time, DATE_TIME_FORMAT.TIME);
          if (isEmpty(formik.values.end_time) || formik.values.end_time === value) return true;
          return endTimeMoment.isAfter(startTimeMoment);
        }),
      advocators: yup.array().min(1, 'Advocator is required'),
      cc_list: yup.array().min(1, 'HR Partners is required'),
      is_has_position: yup.boolean(),
      action_type: yup.string().when(['is_has_position'], {
        is: (is_has_position) => is_has_position === false,
        then: yup.string().nullable(),
        otherwise: yup.string().required('Action type is required'),
      }),
    },
    ['is_has_position']
  );
  //Formik configure
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      succession_planning_name: agendaIncumbent.succession_planning_name,
      start_time: agendaIncumbent.start_time,
      end_time: agendaIncumbent.end_time,
      advocators: agendaIncumbent.advocators,
      cc_list: agendaIncumbent.cc_list,
      remarks: agendaIncumbent.remarks,
      action_type: agendaIncumbent.action_type || '',
      is_has_position: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      return values;
    },
  });

  useEffect(() => {
    formik.setFieldValue('is_has_position', !isEmpty(agendaIncumbent?.positions));
  }, [agendaIncumbent.positions]);

  const handleSetFormValues = (values) => {
    formik.setValues({
      succession_planning_name: values.agenda_name,
      start_time: values.start_time ? moment(values.start_time).format(DATE_TIME_FORMAT.TIME) : '',
      end_time: values.end_time ? moment(values.end_time).format(DATE_TIME_FORMAT.TIME) : '',
      advocators: values.advocators.map((item) => ({
        value: item.id,
        label: item.name,
      })),
      cc_list: values.ccList.map((item) => ({
        value: item.id,
        label: item.name,
      })),
      remarks: values.remarks,
      action_type: values.action_type || '',
      is_has_position: !isEmpty(values.positions),
    });
  };

  const handleSubmitAgenda = async () => {
    setIsPositionChanges(false);
    const formErrors = await formik.validateForm();
    formik.setSubmitting(true);
    if (!isEmpty(formErrors)) {
      dispatch(
        pushMessage({
          message: FILL_OUT_REQUIRE_MESSAGE,
          type: MESSAGE_TYPES.ERROR,
          containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
        })
      );
      return false;
    }

    dispatch(savePreRemarks('SP'));
    if (isNaN(Number(agendaId))) {
      dispatch(
        pushMessage({
          message: SAVE_AGENDA_AS_DRAFT_MESSAGE,
          type: MESSAGE_TYPES.SUCCESS,
          containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
        })
      );
      dispatch(updateTemporaryPrevAgenda(cloneDeep({ ...agendaIncumbent, agendaId: agendaId || agendaIncumbent?.agenda_id })));
      dispatch(
        updateAgendaIncumbent({
          ...formik.values,
          isEdit: true,
          first_line: lineRe.line1,
          second_line: lineRe.line2,
          third_line: lineRe.line3,
          remarks_from_pending_action,
          cardSettings: agendaIncumbent.cardSettings,
        })
      );
    }

    if (Number(agendaId)) {
      const dateWithFormat = moment(date).format('YYYY-MM-DD');
      const body = {
        succession_planning_name: formik.values.succession_planning_name,
        start_time: moment(`${dateWithFormat} ${formik.values.start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
        end_time: moment(`${dateWithFormat} ${formik.values.end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
        advocator_ids: formik.values.advocators.map((item) => item.value),
        cc_ids: formik.values.cc_list.map((item) => item.value),
        action_type: formik.values.action_type,
        remarks: formik.values.remarks,

        first_line_ids: lineRe.line1.map((item, idx) => ({
          staff_id: item.staff_id,
          ranking: idx + 1,
          is_talent_review: item.is_talent_review,
          agenda_as_main: item.agenda_as_main,
        })),
        second_line_ids: lineRe.line2.map((item, idx) => ({
          staff_id: item.staff_id,
          ranking: idx + 1,
          is_talent_review: item.is_talent_review,
          agenda_as_main: item.agenda_as_main,
        })),
        third_line_ids: lineRe.line3.map((item, idx) => ({
          staff_id: item.staff_id,
          ranking: idx + 1,
          is_talent_review: item.is_talent_review,
          agenda_as_main: item.agenda_as_main,
        })),
        position_codes: agendaIncumbent.positions.map((item) => item.position_code),
        agenda_as_main: agendaIncumbent?.agenda_as_main || null,
        agenda_as_main_positions: agendaIncumbent?.agenda_as_main_positions || [],
        is_from_pending_action: agendaIncumbent?.is_from_pending_action,
      };
      const roles = {
        role: roleActive,
        id: USER_ID,
      };
      try {
        setLoadingGetAgenda(true);
        if (!isNaN(Number(agendaId))) {
          const response = await meetingApi.saveAsDraftTemplate({ body, meetingID: idMeeting, agendaId }, roles);
          if (!isEmpty(deleteList)) {
            const apiList = deleteList.map((item) => {
              return meetingApi.deleteRemarks({
                role: roleActive,
                agenda_id: agendaId,
                remark_id: item.remarkId,
              });
            });
            await Promise.allSettled(apiList);
          }
          if (!isEmpty(createList)) {
            const headers = { role: roleActive };
            const remarkList = createList.map((item) => ({
              staff_id: item?.staffId,
              remark: item?.remarks,
              category: item?.remarksCategory,
              version: item?.version || 0,
              remark_type: item?.remarkType,
            }));
            await meetingApi.addRemarks(headers, agendaId, createList[0].staffId, remarkList);
          }
          if (!isEmpty(editList)) {
            const apiList = editList.map((item) => {
              return meetingApi.updateRemarks({
                role: roleActive,
                remark_id: item.remarkId,
                body: item.body,
              });
            });
            await Promise.allSettled(apiList);
          }

          dispatch(clearRemarks());
          dispatch(
            getRemarks({
              role: roleActive,
              agenda_id: agendaId,
              version: agendaDetail?.version || 0,
              position_codes: !isEmpty(agendaIncumbent?.positions) ? agendaIncumbent.positions.map((item) => item.position_code).toString() : '',
              type: AGENDA_SHORT_TYPES.SUCCESSION_PLANNING,
            })
          );
          if (response.status === 200) {
            dispatch(saveAgendaDetail(response.data.result));
            const ratioRes = await meetingApi.getPositionRatio({
              agendaId,
              version: versionId || 0,
              roles,
            });
            if (ratioRes.status === 200) {
              getPositionRatio();
            }
            dispatch(
              pushMessage({
                message: SAVE_AGENDA_AS_DRAFT_MESSAGE,
                type: MESSAGE_TYPES.SUCCESS,
                containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
              })
            );
          }
        }
      } catch (error) {
        console.error(error);
        const dataError = error?.response?.data;
        if (dataError?.errors) {
          dispatch(
            pushMessage({
              message: dataError?.errors,
              type: MESSAGE_TYPES.ERROR,
              timeShow: 0,
              containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
            })
          );
        } else {
          dispatch(
            pushMessage({
              message: `An unexpected error occurred.`,
              type: MESSAGE_TYPES.ERROR,
              timeShow: 0,
              containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
            })
          );
        }
      } finally {
        setLoadingGetAgenda(false);
      }
    }
    setPrevAgendaData({ ...agendaIncumbent, ...formik.values });
    dispatch(updateTemporaryPrevAgenda({ ...agendaIncumbent, ...formik.values }));
    return true;
  };

  const handleDeletePosition = (position_code) => {
    setMessageActionType(null);
    dispatch(removePosition(position_code));
    const tempPosition = agendaIncumbent.positions.filter((pos) => pos.position_code !== position_code);
    setPrevAgendaData((prev) => ({
      ...prev,
      positions: tempPosition ?? null,
      ...initialLineValue,
    }));

    if (tempPosition.length <= 0) {
      setLineRe({
        line1: [],
        line2: [],
        line3: [],
      });
      dispatch(updateAgendaLine({ first_line: [], second_line: [], third_line: [] }));
      dispatch(clearRemarks());
      formik.setFieldValue('remarks', '');
    }

    if (!agenda_as_main_positions.includes(position_code)) return;
    const deletedAgendaPosition = agenda_as_main_positions.filter((item) => item !== position_code);
    if (isEmpty(deletedAgendaPosition)) {
      setAgendaAsMain(null);
    }
    dispatch(resetAsMainAgenda(deletedAgendaPosition));
  };

  const handleConvertData = (agenda, nameVersion) => {
    const dateWithFormat = moment(date).format('YYYY-MM-DD');
    return {
      succession_planning_name: formik.values.succession_planning_name,
      start_time: moment(`${dateWithFormat} ${formik.values.start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      end_time: moment(`${dateWithFormat} ${formik.values.end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      advocator_ids: formik.values.advocators.map((item) => item.value),
      cc_ids: formik.values.cc_list.map((item) => item.value),
      action_type: formik.values.action_type,
      remarks: !agendaIncumbent.remarks ? agenda.remarks : agendaIncumbent.remarks,
      first_line_ids: lineRe.line1.map((item, idx) => ({
        staff_id: item.staff_id,
        ranking: idx + 1,
        is_talent_review: item.is_talent_review,
      })),
      second_line_ids: lineRe.line2.map((item, idx) => ({
        staff_id: item.staff_id,
        ranking: idx + 1,
        is_talent_review: item.is_talent_review,
      })),
      third_line_ids: lineRe.line3.map((item, idx) => ({
        staff_id: item.staff_id,
        ranking: idx + 1,
        is_talent_review: item.is_talent_review,
      })),
      position_codes: agenda.positions.map((item) => item.position_code),
      meeting_id: idMeeting,
      version_name: nameVersion,
      agenda_id: agenda.agenda_id,
      status: agenda.status,
    };
  };

  const createVersionForAgenda = async (nameVersion) => {
    const formErrors = await formik.validateForm();
    formik.setSubmitting(true);
    if (!isEmpty(formErrors)) {
      dispatch(
        pushMessage({
          message: FILL_OUT_REQUIRE_MESSAGE,
          type: MESSAGE_TYPES.ERROR,
          containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
        })
      );
      setIsSaveVersion(false);
      return false;
    }
    let agendaVersion = handleConvertData(agendaIncumbent, nameVersion);
    setLoadingSaveVersion(true);
    try {
      let res = await meetingApi.saveVersion({ meeting_id: idMeeting, agenda_id: agendaId }, agendaVersion, {
        role: user.roleActive,
      });
      if (res.status === 200) {
        await getPositionRatio(res.data.result.version);
        setLoadingSaveVersion(false);
        setIsSaveVersion(false);
        setIsSaveVersionSuccess(true);
        setNameVersion('');
        getAgenda();
      }
    } catch (error) {
      setLoadingSaveVersion(false);
      setIsSaveVersion(false);
      notification.error({
        message: `Save version fail.`,
        placement: 'topRight',
        duration: 2,
      });
    }
  };

  const getPositionRatio = useCallback(async () => {
    if (!Number(agendaId)) return;

    const roles = {
      role: roleActive,
      id: USER_ID,
    };
    try {
      const ratioRes = await meetingApi.getPositionRatio({
        agendaId,
        version: versionIdAgenda || 0,
        roles,
      });
      if (ratioRes.status === 200) {
        setAgendaRatio(ratioRes.data.result);
      }
    } catch (error) {
      console.error(error);
      setAgendaRatio(null);
    }
  }, [agendaId, roleActive, versionIdAgenda]);

  const [updateStatusVisible, setUpdateStatusVisible] = useState(false);
  const [finalizeSPAgendaVisible, setFinalizeSPAgendaVisible] = useState(false);
  const [unFinalizeSPAgendaVisible, setUnfinalizeSPAgendaVisible] = useState(false);
  const [showMessageFinalize, setShowMessageFinalize] = useState(false);
  const [showMessageUnfinalize, setShowMessageUnfinalize] = useState(false);

  const { isLoading, updateStatusAgenda, finalizeSPAgenda, unfinalizeSPAgenda } = useUpdateStatusAgenda({
    onSuccess: () => {
      setUpdateStatusVisible(false);
      getAgenda();
    },
  });

  const handleRevertFormValues = useCallback(() => {
    formik.setValues({
      succession_planning_name: agendaIncumbent.succession_planning_name,
      start_time: agendaIncumbent.start_time,
      end_time: agendaIncumbent.end_time,
      advocators: agendaIncumbent.advocators,
      cc_list: agendaIncumbent.cc_list,
      remarks: agendaIncumbent.remarks,
      action_type: agendaIncumbent.action_type || '',
      is_has_position: formik.values.is_has_position,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaIncumbent]);

  const handleUpdateStatusPaper = useCallback(() => {
    updateStatusAgenda({ agendaId, status: AGENDA_STATUS.PAPER_IN_PROGRESS });
    handleRevertFormValues();
  }, [agendaId, updateStatusAgenda, handleRevertFormValues]);

  const handleFinalizeSPAgenda = useCallback(async () => {
    const response = await finalizeSPAgenda(agendaId);
    if (response.code === 200) {
      setFinalizeSPAgendaVisible(false);
      setShowMessageFinalize(true);
      handleRevertFormValues();
    }
  }, [agendaId, finalizeSPAgenda, handleRevertFormValues]);

  const handleUnfinalizeSPAgenda = useCallback(async () => {
    const response = await unfinalizeSPAgenda(agendaId);
    if (response.code === 200) {
      setUnfinalizeSPAgendaVisible(false);
      setShowMessageUnfinalize(true);
      handleRevertFormValues();
      getPositionRatio();
    }
  }, [agendaId, unfinalizeSPAgenda, getPositionRatio, handleRevertFormValues]);

  const handleChangeAgendaStatus = async () => {
    try {
      let response = await meetingApi.changeAgendaStatusById(agendaId);
      if (response.status === 200) {
        getAgenda();
        setIsChangedStatusMessage(true);
        handleRevertFormValues();
        return response;
      }
    } catch (error) {
      return console.error(error);
    }
  };

  const handleGoBack = () => {
    if (isEmpty(getFieldChanges)) {
      dispatch(clearRemarks());
    }
    switch (pageHistory) {
      case ROUTE_BACK_HISTORY.MEETING_DETAIL:
        history.push(`/meeting/detail/${idMeeting || meeting_id}`);
        break;
      case ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2:
        history.push(`/meeting/${idMeeting || meeting_id}/agendas`);
        break;
      default:
        history.goBack();
        break;
    }
  };

  useEffect(() => {
    if (agendaId && positions.length) {
      getPositionRatio();
    }
  }, [agendaId, getPositionRatio, positions]);

  useEffect(() => {
    if (!agendaId || (!agendaDetail?.version && agendaDetail?.version !== 0)) {
      if (!isEmpty(agendaIncumbent?.remarks_from_pending_action)) {
        dispatch(getRemarkFromPendingAction(agendaIncumbent?.remarks_from_pending_action));
      } else {
        dispatch(clearRemarks());
      }
      return;
    }
    if (!isEmpty(agendaIncumbent.positions) && !isNaN(Number(agendaId))) {
      dispatch(
        getRemarks({
          role: roleActive,
          agenda_id: agendaId,
          version: agendaDetail?.version || 0,
          position_codes: agendaIncumbent.positions
            .map((item) => {
              return item.position_code;
            })
            .toString(),
          type: AGENDA_SHORT_TYPES.SUCCESSION_PLANNING,
        })
      );
    }
  }, [agendaDetail.version, agendaId, dispatch, roleActive, agendaIncumbent?.remarks_from_pending_action, agendaIncumbent?.positions]);

  function showMessageToDragFromRecommendation(line) {
    setIsWarningMessage({
      showWarning: true,
      message: `Talent already exist in "${line}" table`,
    });
  }

  const handleSetAsMain = async (record, isGetRemark) => {
    try {
      setLoadingGetAgenda(true);
      const mainAgenda = await getAgendaById(record.agenda_id);
      dispatch(
        setAsMainAgenda({
          ...mainAgenda,
          agenda_as_main: record,
          isGetRemark: isGetRemark ? true : false,
          formik: formik.values,
        })
      );
      formik.setFieldValue(`action_type`, '');
      formik.setFieldValue(`is_has_position`, !isEmpty(mainAgenda?.positions));
      if (isGetRemark) {
        formik.setFieldValue(`remarks`, mainAgenda?.remarks);
        if (!isNaN(Number(agendaId))) {
          dispatch(createNewDeleteRemark(talentRemarks));
        }
        const resultAction = await dispatch(
          getRemarks({
            role: roleActive,
            agenda_id: record.agenda_id,
            version: 0,
            position_codes: !isEmpty(mainAgenda?.positions) ? mainAgenda.positions.map((item) => item.position_code).toString() : '',
            type: AGENDA_SHORT_TYPES.SUCCESSION_PLANNING,
          })
        );
        const result = unwrapResult(resultAction);
        if (!isNaN(Number(agendaId))) {
          dispatch(
            createNewCreateRemark(
              !isEmpty(result)
                ? result.map((item) => ({
                    ...item,
                    meetingId: idMeeting,
                    agendaId: agendaId,
                    remarkId: uuidv4(),
                  }))
                : []
            )
          );
        }
      }
      dispatch(
        pushMessage({
          message: `${record.agenda_name} has been set as main agenda.`,
          type: MESSAGE_TYPES.SUCCESS,
          containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetAgenda(false);
    }
  };

  const isDisableActionType = useMemo(() => {
    if (
      agendaDetail?.agenda_id &&
      [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(meetingDetail?.status) &&
      agendaDetail?.status !== AGENDA_STATUS.PAPER_IN_PROGRESS
    ) {
      return true;
    }
    if (
      agendaDetail?.agenda_id &&
      MEETING_DETAIL_STATUS.IN_PROGRESS === meetingDetail?.status &&
      ![AGENDA_STATUS.PAPER_IN_PROGRESS, AGENDA_STATUS.PAPER_READY].includes(agendaDetail?.status)
    ) {
      return true;
    }
    if (
      agendaDetail?.agenda_id &&
      MEETING_DETAIL_STATUS.IN_REVIEW === meetingDetail?.status &&
      agendaDetail?.status !== AGENDA_STATUS.PAPER_FINALIZED
    ) {
      return true;
    }
    if (agendaDetail?.agenda_id && !meetingDetail?.is_secretariat_or_facilitator) {
      return true;
    }
    return (
      agendaDetail?.agenda_id &&
      [
        MEETING_DETAIL_STATUS.PENDING_APPROVAL,
        MEETING_DETAIL_STATUS.MOM_REJECTED,
        MEETING_DETAIL_STATUS.CANCELED,
        MEETING_DETAIL_STATUS.MEETING_COMPLETED,
        MEETING_DETAIL_STATUS.CONCLUDED,
      ].includes(meetingDetail?.status)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaDetail?.status, agendaDetail?.agenda_id, meetingDetail?.status]);

  // Handle show confirm modal without changes
  const isCheckFormValues = (obj1, obj2) => {
    let changes = [];
    for (const [key, val] of Object.entries(obj1)) {
      if (key in obj2) {
        const newVal = obj2[key];
        if (JSON.stringify(val) !== JSON.stringify(newVal) && KEY_CHANGES_TO_CHECK_SP_AGENDA.includes(key)) {
          changes.push(key);
        }
      }
    }
    return changes;
  };

  const [fieldValueChanges, setFieldValueChanges] = useState([]);
  const getFieldChanges = useMemo(() => {
    const tempObj1 = cloneDeep({
      ...prevAgendaData,
      positions: updateField(prevAgendaData?.positions, temporaryPrevAgenda?.positions),
      first_line: updateField(staffIdMap(prevAgendaData?.first_line), staffIdMap(temporaryPrevAgenda?.first_line)),
      second_line: updateField(staffIdMap(prevAgendaData?.second_line), staffIdMap(temporaryPrevAgenda?.second_line)),
      third_line: updateField(staffIdMap(prevAgendaData?.third_line), staffIdMap(temporaryPrevAgenda?.third_line)),
      talent_remarks: prevTalentRemarks,
      remarks: prevAgendaData?.remarks || agendaDetail?.remarks || '',
    });
    const tempObj2 = cloneDeep({
      ...agendaIncumbent,
      ...formik.values,
      first_line: staffIdMap(agendaIncumbent?.first_line),
      second_line: staffIdMap(agendaIncumbent?.second_line),
      third_line: staffIdMap(agendaIncumbent?.third_line),
      talent_remarks: talentRemarks,
    });
    const tempValues = isCheckFormValues(tempObj1, tempObj2);
    return tempValues;
  }, [agendaIncumbent, formik.values, prevAgendaData, talentRemarks, prevTalentRemarks, temporaryPrevAgenda]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(!isEmpty(getFieldChanges));

  useEffect(() => {
    if (!isEmpty(getFieldChanges)) {
      setFieldValueChanges(getFieldChanges);
    }
  }, [agendaIncumbent, formik.values, getFieldChanges, setIsShowPopupSaveAgenda]);

  const isEnablePrintButton = useMemo(() => {
    return (
      !isEmpty(agendaIncumbent?.positions) &&
      ([AGENDA_STATUS.PAPER_IN_PROGRESS, AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_FINALIZED, AGENDA_STATUS.PAPER_BRING].includes(
        agendaDetail?.status
      ) ||
        !agendaIncumbent?.status ||
        agendaIncumbent?.status === AGENDA_STATUS.PAPER_IN_PROGRESS)
    );
  }, [agendaDetail?.status, meetingDetail?.status, agendaIncumbent]);

  function handleChangeActionType({ action_type }) {
    if (isEmpty(agendaIncumbent.positions)) return setMessageActionType(null);
    const postions = cloneDeep(agendaIncumbent.positions);
    const position_role = postions.filter((item) => item.role)[0]?.role;
    if (!position_role) return setMessageActionType(null);
    const positionSelected = postions.find((item) => item.role === position_role);
    const position_category = positionSelected?.position_label;
    if (!position_category) return setMessageActionType(null);
    setMessageActionType(
      validateActionType({
        user_role: roleActive.roleName,
        action_type: action_type || formik.values?.action_type || '',
        position_category,
        position_role,
      })
    );
  }

  //get setting option in edit sp agenda with number id
  useEffect(() => {
    if (agendaId && !isNaN(Number(agendaId))) {
      (async function getOptionsInLine() {
        try {
          const res = await meetingApi.getCardSettings({ agenda_id: agendaId }, roleActive.roleId);
          const data = get(res, 'data.result');
          dispatch(setAllCardSettings({ data }));
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [agendaId, roleActive.roleId]);

  return (
    <>
      {agendaDetail?.agenda_id && <BreadCrumb level={4} breadCrumbList={breadCrumbList} style={{ marginBottom: '35px' }} />}
      <GlobalAlertMessage
        style={{ marginBottom: '20px' }}
        id={GLOBAL_ALERT_MESSAGE_CONTAINER_ID.SP_AGENDA_DETAIL}
        isShowFirstTime={isNaN(Number(agendaId))}
      />
      {messageActionType?.user_role && (
        <div style={{ marginBottom: '20px' }}>
          <AlertMessage
            onClose={() => setMessageActionType(null)}
            message={`Your current role {${messageActionType.user_role}} does not match with the recommended approval authority for this position criticality {${messageActionType.position_category}}. Confirm the LOA before proceeding.`}
            type="warning"
          />
        </div>
      )}
      <form onSubmit={!isReadOnly ? formik.handleSubmit : undefined}>
        {isChangedStatusMessage && (
          <div style={{ marginBottom: '20px' }}>
            <AlertMessage message="Succession Planning is ready for the meeting." type="success" />
          </div>
        )}
        {isSaveVersionSuccess && (
          <div style={{ marginBottom: '20px' }}>
            <AlertMessage
              type="success"
              message="Succession Planning Version has been saved successfully"
              onClose={() => {
                setTimeout(() => {
                  setIsSaveVersionSuccess(false);
                }, 300);
              }}
            />
          </div>
        )}
        {isWarningMessage.showWarning && (
          <div style={{ marginBottom: '20px' }}>
            <AlertMessage
              type="warning"
              message={isWarningMessage.message}
              onClose={() => {
                setIsWarningMessage({
                  showWarning: false,
                  message: '',
                });
              }}
              timeShow={5000}
            />
          </div>
        )}

        {showMessageFinalize && (
          <div style={{ marginBottom: '20px' }}>
            <AlertMessage
              type="success"
              message={`${agendaDetail?.agenda_id ? agendaDetail?.agenda_name : 'Succession Planning'} has been successfully finalized.`}
              onClose={() => {
                setTimeout(() => {
                  setShowMessageFinalize(false);
                }, 300);
              }}
            />
          </div>
        )}
        {showMessageUnfinalize && (
          <div style={{ marginBottom: '20px' }}>
            <AlertMessage
              type="success"
              message={`${agendaDetail?.agenda_id ? agendaDetail?.agenda_name : 'Succession Planning'} has been successfully unfinalized.`}
              onClose={() => {
                setTimeout(() => {
                  setShowMessageUnfinalize(false);
                }, 300);
              }}
            />
          </div>
        )}
        {loadingGetAgenda && (
          <AiLoading>
            <AiSpin size="large"></AiSpin>
          </AiLoading>
        )}
        <AiHeader>{agendaDetail?.agenda_id ? agendaDetail?.agenda_name : 'Succession Planning'}</AiHeader>
        {isAdditionalViewAccess && (
          <AiGrBtnHeader>
            <AiButton data-testid="back-btn" className="ml-2" onKeyDown={() => {}} onClick={handleGoBack}>
              Back
            </AiButton>
          </AiGrBtnHeader>
        )}
        {!onlyViewContent && !isAdditionalViewAccess && (
          <If is={!isReadOnly}>
            <AiGrBtnHeader>
              <AiButton data-testid="back-btn" className="ml-2" onKeyDown={() => {}} onClick={handleGoBack}>
                Back
              </AiButton>
              {((!isDisabledByAgendaStatus && !isViewOnlyAgenda && !isDisplayFinalized) ||
                (meetingDetail?.is_secretariat_or_facilitator && meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW) ||
                (isEditAgendaWithMeetingSecretariatFacilitator && !isDisplayFinalized)) && (
                <AiButton data-testid="save-as-draft" onKeyDown={() => {}} onClick={handleSubmitAgenda} disabled={isLoading}>
                  Save as Draft
                </AiButton>
              )}
              {!isDisabledByAgendaStatus && !isViewOnlyAgenda && agendaId && !JSON.stringify(agendaId).includes('-') && !isDisplayFinalized && (
                <AiButton mode="teal" className="ml-2" onKeyDown={() => {}} onClick={() => setVisibleChangeStatusModal(true)}>
                  Paper Ready
                </AiButton>
              )}
              {isDisplayEditBtn &&
                !isViewOnlyAgenda &&
                meetingDetail?.status &&
                (meetingDetail?.status === MEETING_DETAIL_STATUS.PUBLISHED || meetingDetail?.status === MEETING_DETAIL_STATUS.IN_PLANNING) &&
                !isEditAgendaWithMeetingSecretariatFacilitator && (
                  <AiButton onKeyDown={() => {}} onClick={() => setUpdateStatusVisible(true)} className="ml-2">
                    Edit Paper
                  </AiButton>
                )}

              {isDisplayEditBtn &&
                meetingDetail?.status &&
                meetingDetail?.status === MEETING_DETAIL_STATUS.IN_PROGRESS &&
                meetingDetail.is_secretariat_or_facilitator &&
                [AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_FINALIZED].includes(agendaIncumbent?.status) && (
                  <AiButton
                    mode="teal"
                    className="ml-2"
                    onKeyDown={() => {}}
                    onClick={() =>
                      agendaIncumbent.status === AGENDA_STATUS.PAPER_READY ? setFinalizeSPAgendaVisible(true) : setUnfinalizeSPAgendaVisible(true)
                    }
                  >
                    {agendaIncumbent?.status === AGENDA_STATUS.PAPER_READY ? `Finalize` : `Unfinalized`}
                  </AiButton>
                )}
            </AiGrBtnHeader>
          </If>
        )}
        <AiAgendaForm>
          <AiTitle>Succession Planning Session Details</AiTitle>
          <Row gutter={16} className="mb-3">
            <Col span={16} className="gutter-row">
              <AiLabel>
                Succession Planning Name <span style={{ color: '#FF787F' }}>*</span>
              </AiLabel>
              <AiInput
                placeholder="Enter Succession Planning Name"
                name="succession_planning_name"
                value={formik.values.succession_planning_name}
                onChange={formik.handleChange}
                status={formik.errors?.succession_planning_name && formik.isSubmitting ? 'error' : ''}
                disabled={isReadOnly || isDisabledByAgendaStatus || isViewOnlyAgenda}
              />
              {showError(formik, 'succession_planning_name')}
            </Col>

            <Col span={4} className="gutter-row">
              <AiLabel>
                Start time <span style={{ color: '#FF787F' }}>*</span>
              </AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={formik.values.start_time ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local() : null}
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(formik, value, 'start_time')}
                status={formik.errors?.start_time && formik.isSubmitting ? 'error' : ''}
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
                disabled={isReadOnly || isDisabledByAgendaStatus || isViewOnlyAgenda}
              />
              {showError(formik, 'start_time')}
            </Col>
            <Col span={4} className="gutter-row">
              <AiLabel>
                End time <span style={{ color: '#FF787F' }}>*</span>
              </AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={formik.values.end_time ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local() : null}
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(formik, value, 'end_time')}
                status={formik.errors?.end_time && formik.isSubmitting ? 'error' : ''}
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
                disabled={isReadOnly || isDisabledByAgendaStatus || isViewOnlyAgenda}
              />
              {showError(formik, 'end_time')}
            </Col>
          </Row>
          <If is={!isReadOnly}>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>
                  Advocators <span style={{ color: '#FF787F' }}>*</span>
                </AiLabel>
                <div data-testid="select">
                  <AsyncMultipleSelect
                    value={formik.values.advocators}
                    placeholder="Enter Advocators Name"
                    loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                    onChange={(e) => onChangeSelectData(formik, e, 'advocators')}
                    status={formik.errors?.advocators && formik.isSubmitting ? 'error' : ''}
                    isDisabled={isDisabledByAgendaStatus || isViewOnlyAgenda}
                  />
                  {showError(formik, 'advocators')}
                </div>
              </Col>
            </Row>
          </If>
          <Row>
            <Col span={24}>
              <AiLabel>
                HR Partners
                <Tooltip
                  placement="top"
                  title="Please include HR personnel involved during review, endorsement and approval"
                  overlayClassName="custom-tooltip"
                  {...{
                    overlayInnerStyle: {
                      fontFamily: font.inter,
                      width: '260px',
                      fontSize: '12px',
                      lineHeight: '18px',
                      gap: '24px',
                      padding: '12px',
                      borderRadius: '4px',
                      backgroundColor: '#000',
                    },
                  }}
                >
                  <span style={{ marginLeft: '5px' }}>
                    <img src={hr_info} alt="hr_info" />
                  </span>
                </Tooltip>
              </AiLabel>
              <AsyncMultipleSelect
                value={formik.values.cc_list}
                placeholder="Enter HR Partners Name"
                loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                onChange={(e) => onChangeSelectData(formik, e, 'cc_list')}
                status={formik.errors?.cc_list && formik.isSubmitting ? 'error' : ''}
                isDisabled={isReadOnly || isDisabledByAgendaStatus || isViewOnlyAgenda}
              />
              {showError(formik, 'cc_list')}
            </Col>
          </Row>
          <Row gutter={16} className="mb-3 mt-3">
            <Col span={6} className="gutter-row">
              <AiLabel>
                Action Type
                <Tooltip
                  placement="top"
                  title={
                    <div
                      style={{
                        display: 'grid',
                        rowGap: '10px',
                        fontWeight: '400',
                        fontSize: '12px',
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: '600' }}>Approval</span> - Final talent council decision according to LOA. Will be reflected
                        accordingly in myTalentX.
                      </div>
                      <div>
                        <span style={{ fontWeight: '600' }}>Endorsed</span> - Endorsed talent council decision prior to final level talent council
                        (n-1). Will be saved in SP pending action in Meeting Agenda.
                      </div>
                      <div>
                        <span style={{ fontWeight: '600' }}>Reviewed</span> - Reviewed talent council decision prior to final level talent council
                        (n-2 or n-3). Will be saved in SP pending action in Meeting Agenda.
                      </div>
                    </div>
                  }
                  {...{
                    overlayInnerStyle: {
                      fontFamily: font.inter,
                      width: '340px',
                      backgroundColor: '#000',
                      fontSize: '12px',
                      lineHeight: '18px',
                      gap: '24px',
                      padding: '12px',
                      borderRadius: '4px',
                    },
                  }}
                >
                  <span style={{ marginLeft: '5px' }}>
                    <img src={hr_info} alt="" />
                  </span>
                </Tooltip>
                {formik.values.is_has_position && <span style={{ color: '#FF787F' }}>*</span>}
              </AiLabel>
              <div data-testid="select">
                <SingleSelect
                  value={
                    [
                      { label: 'To Review', value: 'Reviewed' },
                      { label: 'To Endorse', value: 'Endorsed' },
                      { label: 'To Approve', value: 'Approved' },
                    ].find((item) => item.value === formik.values.action_type) || ''
                  }
                  placeholder="Select Action Type"
                  options={[
                    { label: 'To Review', value: 'Reviewed' },
                    { label: 'To Endorse', value: 'Endorsed' },
                    { label: 'To Approve', value: 'Approved' },
                  ]}
                  onChange={(e) => {
                    onChangeSelectData(formik, e?.value, 'action_type');
                    handleChangeActionType({ action_type: e?.value });
                  }}
                  status={(formik.errors?.action_type && formik.isSubmitting) || messageActionType ? 'error' : ''}
                  isDisabled={isDisableActionType || isAdditionalViewAccess}
                />
                {showError(formik, 'action_type')}
              </div>
            </Col>
          </Row>
        </AiAgendaForm>
        {!onlyViewContent && (
          <If is={!isReadOnly}>
            <AiGrBtnMiddle>
              {isEnablePrintButton && (
                <AiButton
                  className="ml-2"
                  onKeyDown={() => {}}
                  onClick={() => setOpenPrintPopup(true)}
                  disabled={loadingGetAgenda || isDisabledPrintBtn}
                  style={{ minWidth: `100px` }}
                >
                  View / Print
                </AiButton>
              )}
              {((!isDisabledByAgendaStatus && !isViewOnlyAgenda && !isDisplayFinalized) ||
                (meetingDetail?.is_secretariat_or_facilitator && meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW) ||
                (isEditAgendaWithMeetingSecretariatFacilitator && !isDisplayFinalized)) && (
                <>
                  <AiButton className="ml-2" onKeyDown={() => {}} onClick={() => setOpenPosition(true)}>
                    Add Position
                  </AiButton>
                  <PopupListConcludedAgenda
                    type={AGENDA_TYPES.SP_AGENDA}
                    agendaDetail={agendaIncumbent}
                    handleSetAsMain={handleSetAsMain}
                    setAgendaAsMain={setAgendaAsMain}
                    agendaAsMain={agendaAsMain}
                    addedPositionCode={!isEmpty(agendaIncumbent?.positions) ? agendaIncumbent.positions.map((item) => item.position_code) : []}
                    meetingId={idMeeting}
                    setIsPositionChanges={setIsPositionChanges}
                    setPrevAgendaData={setPrevAgendaData}
                  />
                  {!isDisplayEditBtn && isShowSendForSyndication && (
                    <AiButton className="ml-2" onKeyDown={() => {}} onClick={() => setOpenSyndication(true)}>
                      Send for Syndication
                    </AiButton>
                  )}
                </>
              )}
              {((isDisplayViewHistoryBtn && !isViewOnlyAgenda && meetingDetail?.status) || isEditAgendaWithMeetingSecretariatFacilitator) &&
                meetingDetail?.status !== MEETING_DETAIL_STATUS.IN_REVIEW &&
                meetingDetail?.status !== MEETING_DETAIL_STATUS.CONCLUDED && (
                  <AiButton className="ml-2" onKeyDown={() => {}} onClick={() => setVersionHistory(true)}>
                    Version History
                  </AiButton>
                )}
              {(meetingDetail?.status !== MEETING_DETAIL_STATUS.IN_REVIEW ||
                (meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW && !meetingDetail?.is_secretariat_or_facilitator)) && (
                <AiButton className="ml-2" onKeyDown={() => {}} onClick={() => setShowCompareTalent(true)}>
                  Compare Talent
                </AiButton>
              )}
              {((!isDisabledByAgendaStatus && !isViewOnlyAgenda && !isDisplayFinalized && isCreatedAgenda && size(agendaDetail?.positions) > 0) ||
                (isEditAgendaWithMeetingSecretariatFacilitator && !isDisplayFinalized && size(agendaDetail?.positions) > 0)) && (
                <AiButton data-testid="save-version-btn" mode="teal" className="ml-2" onKeyDown={() => {}} onClick={() => setIsSaveVersion(true)}>
                  Save Version
                </AiButton>
              )}
            </AiGrBtnMiddle>
          </If>
        )}
      </form>

      {size(agendaIncumbent.positions) > 0 && (
        <AiPositionsInformation>
          <Positions
            positions={positions}
            viewVersion={viewVersion}
            agendaRatio={agendaRatio}
            handleDeletePosition={handleDeletePosition}
            isDisabledByAgendaStatus={isReadOnly || isDisabledByAgendaStatus || isAdditionalViewAccess}
            isEnableButtonToEditPosition={isEnableButtonToEditPosition}
            setIsPositionChanges={setIsPositionChanges}
            setPrevAgendaData={setPrevAgendaData}
            formik={formik}
            onChangeSelectData={onChangeSelectData}
          />
          {isDisplayRecommendations && (
            <Recommendations
              positions={positions}
              isReadOnly={isReadOnly}
              lineRe={lineRe}
              setLineRe={setLineRe}
              isViewModeAgenda={isViewModeAgenda || isAdditionalViewAccess}
              isNotViewAllRemark={!isDisplayRemark}
              showMessageToDragFromRecommendation={showMessageToDragFromRecommendation}
              setIsPositionChanges={setIsPositionChanges}
              setPrevAgendaData={setPrevAgendaData}
              setIsDisabledPrintBtn={setIsDisabledPrintBtn}
              meetingDetail={meetingDetail}
            />
          )}
        </AiPositionsInformation>
      )}

      {openPrintPopup && (
        <PrintSucessionPlanPopup
          setIsShowSuccessionPlanPopup={setOpenPrintPopup}
          isShowSuccessionPlanPopup={openPrintPopup}
          positions={positions}
          isDisabledPrintBtn={loadingGetAgenda || isDisabledPrintBtn}
        />
      )}
      {openPosition && (
        <AddPositionDialog
          setIsVisible={setOpenPosition}
          setPrevAgendaData={setPrevAgendaData}
          resetActionType={() => onChangeSelectData(formik, '', 'action_type')}
        />
      )}
      {openSyndication && <SyndicationDialog show={openSyndication} setShow={setOpenSyndication} />}
      {showCompareTalent && (
        <TalentComparison talentProfileDetails={{}} isPopupTalentComparison={showCompareTalent} setIsPopupTalentComparison={setShowCompareTalent} />
      )}
      {versionHistory && (
        <VersionHistory
          meetingRoles={meetingRole}
          setIsVisible={setVersionHistory}
          isVisible={versionHistory}
          isPaperFinalized={agendaIncumbent?.status === AGENDA_STATUS.PAPER_FINALIZED}
        />
      )}
      {isSaveVersion && (
        <PopupSaveVersion
          show={isSaveVersion}
          setShow={setIsSaveVersion}
          onSaveVersion={createVersionForAgenda}
          setVersionHistory={setVersionHistory}
          name={nameVersion}
          setName={setNameVersion}
          setTriggerSaveVersion={setTriggerSaveVersion}
          loadingSaveVersion={loadingSaveVersion}
        />
      )}
      {size(agendaIncumbent.positions) === 0 && (
        <AiNoAgenda>
          <AiImg src={comment_stack} alt="" />
          <AiNoAgendaTitle>No position added, yet.</AiNoAgendaTitle>
          <AiNoAgendaDesc>No position added in this section. Click “Add Position” to add the position.</AiNoAgendaDesc>
        </AiNoAgenda>
      )}
      <ModelTC
        info={{
          type: 'editPaper',
          visible: updateStatusVisible,
          setVisible: setUpdateStatusVisible,
          handleSubmit: handleUpdateStatusPaper,
          loading: isLoading,
          onClose: () => setUpdateStatusVisible(false),
        }}
      />
      <ModelTC
        info={{
          type: 'finalizeSPAgenda',
          visible: finalizeSPAgendaVisible,
          setVisible: setFinalizeSPAgendaVisible,
          handleSubmit: handleFinalizeSPAgenda,
          loading: isLoading,
          onClose: () => setFinalizeSPAgendaVisible(false),
        }}
      />
      <ModelTC
        info={{
          type: 'unFinalizeSPAgenda',
          visible: unFinalizeSPAgendaVisible,
          setVisible: setUnfinalizeSPAgendaVisible,
          handleSubmit: handleUnfinalizeSPAgenda,
          loading: isLoading,
          onClose: () => setUnfinalizeSPAgendaVisible(false),
        }}
      />
      <ModelTC
        info={{
          type: 'changeToPaperReadyStatus',
          visible: visibleChangeStatusModal,
          setVisible: setVisibleChangeStatusModal,
          handleSubmit: async () => {
            handleChangeAgendaStatus();
            setVisibleChangeStatusModal(false);
          },
          onClose: () => setVisibleChangeStatusModal(false),
        }}
      />

      <ModelTC
        info={{
          type: agendaId ? 'withoutSavingAgenda' : 'leaveWhileAddAgenda',
          title: `You are about to leave this page without saving.`,
          desc: getDescForWithoutModal(fieldValueChanges, agendaId, AGENDA_SHORT_TYPES.SUCCESSION_PLANNING),
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          handleSubmit: async () => {
            const canNavigate = await handleSubmitAgenda();
            if (canNavigate) {
              handleKeepNavigate();
              dispatch(resetTemporaryPrevAgenda());
            }
          },
          onClose: () => {
            setIsShowPopupSaveAgenda(false);
            if (getFieldChanges.includes(`positions`) && agendaId) {
              dispatch(updatePositions(temporaryPrevAgenda?.positions || agendaIncumbent.positions));
            }
            if (
              (getFieldChanges.includes(`first_line`) ||
                getFieldChanges.includes(`second_line`) ||
                getFieldChanges.includes(`third_line`) ||
                getFieldChanges.includes(`action_type`)) &&
              agendaId
            ) {
              dispatch(
                updateAgendaIncumbent({
                  ...agendaIncumbent,
                  action_type: getFieldChanges.includes(`action_type`) ? temporaryPrevAgenda?.action_type : agendaIncumbent.action_type,
                  positions: getFieldChanges.includes(`positions`) ? temporaryPrevAgenda?.positions : agendaIncumbent.positions,
                  first_line: getFieldChanges.includes(`first_line`) ? temporaryPrevAgenda?.first_line : agendaIncumbent.first_line,
                  second_line: getFieldChanges.includes(`second_line`) ? temporaryPrevAgenda?.second_line : agendaIncumbent.second_line,
                  third_line: getFieldChanges.includes(`third_line`) ? temporaryPrevAgenda?.third_line : agendaIncumbent.third_line,
                })
              );
            }
            setPrevAgendaData((prev) => ({
              ...prev,
              first_line: [],
              second_line: [],
              third_line: [],
            }));
            handleKeepNavigate();
            dispatch(clearRemarks());
            dispatch(resetTemporaryPrevAgenda());
          },
          loading: loadingGetAgenda,
        }}
      />
    </>
  );
};
export default AgendaDetails;

const updateField = (prevField, tempField) => (JSON.stringify(prevField) !== JSON.stringify(tempField) ? tempField ?? [] : prevField);
