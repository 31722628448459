import { TreeSelect } from 'antd';
import styled, { css } from 'styled-components';

import { color } from '../../assets/common';

export const AiTreeSelect = styled(TreeSelect)`
  ${({ styles = { height: 44 } }) => {
    const { height, hasChild } = styles;
    return css`
      width: 100%;

      .ant-select-tree .ant-select-tree-treenode:hover {
        background-color: #f5f5f5;
      }

      .ant-select-selector {
        height: ${height}px;
      }

      .ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
        padding: 12px 0;
      }

      .ant-select-tree-switcher {
        width: ${hasChild ? 24 : 12}px;

        .ant-select-tree-switcher-icon {
          vertical-align: middle;
        }
      }

      .ant-select-selection-item {
        background: transparent;
        border: none;
        color: #bfbfbf;
        margin-bottom: 6px;
        margin-left: -1px;
      }

      .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
        background-color: ${color.teal};
        border-color: ${color.teal};
      }

      .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
        border-color: ${color.teal};
      }

      .ant-select-tree-checkbox-checked::after {
        border: 1px solid ${color.teal};
      }

      .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after{
        background-color: ${color.teal};
      }
    `;
  }}
`;
