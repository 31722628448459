import { Spin, Tooltip as TooltipAntd } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { info_circle } from '../../../../assets/img';
import { returnBgdColorByScoreVal } from '../../../../utils/helper';
import {
  FieldItemWrap,
  FieldWrap,
  LearningAgilitiesTable,
  NormalText,
  StatusBgd,
  TooltipDesc,
  TooltipTitle,
} from '../ExternalAssessments/ExternalAssessmentStyled';
import styles from '../GeneralExternalAssessments/general-external-assessments.module.scss';

const LearningAgilitiesAssessments = (props) => {
  const { assessmentData, loading, isPrint } = props;

  const convertData = useMemo(() => {
    return !isEmpty(assessmentData?.data?.learningAgilities)
      ? assessmentData?.data?.learningAgilities.map((item) => ({
          ...item,
          numberOfLAMeet: assessmentData?.data?.numberOfLAMeet,
        }))
      : [];
  }, [assessmentData]);

  const columns = [
    {
      title: 'Learning Agilities',
      dataIndex: 'learningAgilities',
      key: 'learningAgilities',
      width: '35%',
      className: `border-right-none`,
      render: (_, record) => {
        return (
          <FieldWrap>
            <FieldItemWrap>
              <NormalText>{record.learningAgilities}</NormalText>
              {!isPrint ? (
                <TooltipAntd
                  title={
                    record && (
                      <div>
                        {!isEmpty(record?.popupContent) ? (
                          record.popupContent.map((item) => {
                            return (
                              <>
                                <TooltipTitle>{item.header}</TooltipTitle>
                                <TooltipDesc>{item.content}</TooltipDesc>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  }
                  {...{
                    overlayInnerStyle: {
                      minWidth: '298px',
                      fontSize: '12px',
                      lineHeight: '18px',
                      fontWeight: 600,
                      fontFamily: `'Inter', sans-serif`,
                      padding: '12px',
                      borderRadius: '4px',
                      backgroundColor: '#000',
                    },
                  }}
                >
                  <img src={info_circle} alt="" />
                </TooltipAntd>
              ) : (
                <img src={info_circle} alt="" />
              )}
            </FieldItemWrap>
          </FieldWrap>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: `10%`,
      align: 'center',
      render: (_, record) => {
        return (
          <FieldItemWrap>
            <NormalText>{record.score && record.score !== '-' ? record.score : '-'}</NormalText>
            {record.score && record.score !== '-' && <StatusBgd bgdColor={returnBgdColorByScoreVal(record)}></StatusBgd>}
          </FieldItemWrap>
        );
      },
    },
    {
      title: 'Potential',
      dataIndex: 'fitPotential',
      key: 'fitPotential',
      width: `25%`,
      render: (_, record) => {
        return <span>{record.fitPotential && record.fitPotential !== '-' ? record.fitPotential : '-'}</span>;
      },
    },
    {
      title: 'No. of Potential met',
      dataIndex: 'numberOfLAMeet',
      key: 'numberOfLAMeet',
      width: `30%`,
      align: 'center',
      onCell: (_, _rowIndex) => {
        return {
          rowSpan: _rowIndex % convertData.length === 0 ? convertData.length : 0,
        };
      },
      render: (_, record) => <span>{record.numberOfLAMeet && record.numberOfLAMeet !== '-' ? record.numberOfLAMeet : '-'}</span>,
    },
  ];

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.tableContent}>
        <Spin spinning={loading} size="small">
          <LearningAgilitiesTable dataSource={convertData} columns={columns} pagination={false} />
        </Spin>
      </div>
    </div>
  );
};

export default LearningAgilitiesAssessments;
