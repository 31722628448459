import React from 'react';
import styles from '../overall-profile-user.module.scss';
import { formatDate, handleShowYIPAndYISG } from '../../../../../utils/helper';
import Skeleton from 'react-loading-skeleton';
import { LABEL_MILES_STONES } from '../constants';
import { profileSelector } from '../../../selector';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TP_PRINT_SECTION_NAME } from '../../../../../components/TalentProfilePrintPreview/constants';

const UserMileStone = ({ isPrintPdf }) => {
  const { thirdSectionProfile, loading } = useSelector(profileSelector);

  const displayInfoEmployee = (text) => {
    if (loading) {
      return (
        <p>
          <Skeleton count={1} height={17}></Skeleton>
        </p>
      );
    } else {
      return React.isValidElement(text) ? text : <p>{text}</p>;
    }
  };

  return (
    <div className={styles.userCareerList} id={isPrintPdf ? 'tp-right-print' : 'tp-right'}>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.JOB_GRADE}>
        <p>{LABEL_MILES_STONES.JOB_GRADE}</p>
        {displayInfoEmployee && thirdSectionProfile?.jg ? (
          <>
            {isPrintPdf ? (
              <p>
                <span className={styles.jg}>{thirdSectionProfile && thirdSectionProfile?.job_role_level}</span>
              </p>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip className="jobGradeTooltip">JG: {thirdSectionProfile && thirdSectionProfile?.jg}</Tooltip>}
              >
                <p>
                  <span className={styles.jg}>{thirdSectionProfile && thirdSectionProfile?.job_role_level}</span>
                </p>
              </OverlayTrigger>
            )}
          </>
        ) : (
          <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip className="jobGradeTooltip">JG:</Tooltip>}>
            <p>
              <span className={styles.jg}>N/A</span>
            </p>
          </OverlayTrigger>
        )}
      </div>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.SALARY_GRADE}>
        <p>{LABEL_MILES_STONES.SALARY_GRADE}</p>
        {displayInfoEmployee(thirdSectionProfile && thirdSectionProfile?.sg)}
      </div>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.SG_SINCE}>
        <p>
          SG Since
          <br />
          (Years in SG)
        </p>
        {displayInfoEmployee(thirdSectionProfile && formatDate(thirdSectionProfile?.dateSg))}
        {<p>{displayInfoEmployee(<>({handleShowYIPAndYISG(thirdSectionProfile?.yisg, thirdSectionProfile?.dateSg)})</>)}</p>}
      </div>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.SUPERIOR}>
        <p>{LABEL_MILES_STONES.SUPERRIOR}</p>
        <span
          style={{ cursor: `${thirdSectionProfile?.supervisorId ? 'pointer' : 'default'}` }}
          onKeyDown={() => {}}
          onClick={() => (thirdSectionProfile?.supervisorId ? window.open('/profile/' + thirdSectionProfile?.supervisorId, '_blank') : null)}
        >
          {displayInfoEmployee(thirdSectionProfile && thirdSectionProfile?.supervisorName)}
        </span>
      </div>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.DATE_IN_POSITION}>
        <p>{LABEL_MILES_STONES.DATE_IN_POSITION}</p>
        {displayInfoEmployee(thirdSectionProfile && formatDate(thirdSectionProfile?.datePosition))}
        {<p>{displayInfoEmployee(<>({handleShowYIPAndYISG(thirdSectionProfile?.yip, thirdSectionProfile?.datePosition)})</>)}</p>}
      </div>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.DATE_IN_COMPANY}>
        <p>{LABEL_MILES_STONES.DATE_IN_COMPANY}</p>
        {displayInfoEmployee(thirdSectionProfile && formatDate(thirdSectionProfile.dateCompany))}
      </div>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.DATE_IN_PETRONAS}>
        <p>{LABEL_MILES_STONES.DATE_IN_PETRONAS}</p>
        {displayInfoEmployee(thirdSectionProfile && formatDate(thirdSectionProfile.datePet))}
      </div>
      <div className={styles.userCareerItem} data-print-section={TP_PRINT_SECTION_NAME.POSITION_ID}>
        <p>{LABEL_MILES_STONES.POSITION_ID}</p>
        {displayInfoEmployee(thirdSectionProfile && thirdSectionProfile.positionId)}
      </div>
    </div>
  );
};
export default UserMileStone;
