import { combineReducers } from '@reduxjs/toolkit';

import profilesReducer from './profileSlice';
import printProfilesReducer from './printProfileSlice';

const reducer = combineReducers({
  profile: profilesReducer,
  printProfile: printProfilesReducer,
});
export default reducer;
