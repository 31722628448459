import React, { memo, useCallback, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ApprovedSP,
  BestFitRecommendation,
  BoardMembership,
  CareerAspiration,
  CareerJourney,
  ContractDetails,
  EDGEView,
  Experiences,
  OtherContribution,
  Qualification,
  SpokenLanguage,
} from '..';
import PrintApprovedSp from '../../pages/Agenda/components/PrintApprovedSP/PrintApprovedSp';
import OverAllProfileUser from '../../pages/TalentProfilePage/components/OverAllProfileUser/OverAllProfileUser';
import { profileSelector } from '../../pages/TalentProfilePage/selector';
import AssessmentPrint from './components/AssessmentPrint/AssessmentPrint';
import DevelopmentPlanPrint from './components/DevelopmentPlanPrint/DevelopmentPlanPrint';
import HistoricalTalentStatusPrint from './components/HistoricalTalentStatusPrint/HistoricalTalentStatusPrint';
import MobilityPlanPrint from './components/MobilityPlanPrint/MobilityPlanPrint';
import OtherExperiencePrint from './components/OtherExperiencePrint/OtherExperiencePrint';
import PersonalityPrint from './components/PersonalityPrint/PersonalityPrint';
import styles from './talent-profile-print-preview.module.scss';
import TalentProfilePrintSelection from './components/TalentProfilePrintSelection/TalentProfilePrintSelection';
import { TP_PRINT_SECTION_NAME } from './constants';
import WorkPreference from '../WorkPreference/WorkPreference';
import NotesPrint from './components/NotesPrint/NotesPrint';
import TalentReviewPrint from './components/TalentReviewPrint/TalentReviewPrint';
import KeyStrengthDevelopmentAreaPrint from './components/KeyStrengthDevelopmentAreaPrint/KeyStrengthDevelopmentAreaPrint';
import ExternalAssessments from '../../pages/TalentProfilePage/components/ExternalAssessments/ExternalAssessments';
import ExternalExperience from '../ExternalExperience/ExternalExperience';

const TalentProfilePrintPreview = (props) => {
  const { tokenTalentProfile } = props;
  const { id } = useParams();
  const { firstSectionProfile } = useSelector(profileSelector);
  const customFileName = `${firstSectionProfile.staffId ?? id}_${
    firstSectionProfile.birthName ? firstSectionProfile.birthName.split(' ').join('_') : ''
  }.pdf`;
  const { selectedValues } = useSelector((state) => state.app.talentProfile.printProfile.printSettings);
  const printRef = useRef();
  const isRefReady = useCallback((node) => {
    if (node !== null) {
      return node;
    }
  }, []);
  const { fetchedData } = useSelector((state) => state.app.talentProfile.printProfile);

  useEffect(() => {
    if (!printRef.current) return;
    const printSectionsDOM = printRef.current.querySelectorAll('[data-print-section]');
    printSectionsDOM.forEach((element) => {
      const printSection = element.attributes['data-print-section'].value;
      if (!selectedValues.includes(printSection)) {
        element.style.display = 'none';
      } else {
        element.style.removeProperty('display');
      }
    });
  }, [selectedValues]);

  return (
    <Modal
      destroy
      onHide={() => props?.closeModal()}
      dialogClassName={styles.containerModal}
      show={props?.isPrinting}
      style={{ overflowX: 'auto' }}
      centered
    >
      <Modal.Body>
        <div className="relative">
          <span className={styles.settingIcon}>
            <TalentProfilePrintSelection />
          </span>
        </div>
        <div ref={printRef} className={styles.printContainer}>
          <div className={styles.distance}>
            <OverAllProfileUser isPrintPdf={true} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <CareerJourney
              isPrintTP
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.CAREER_JOURNEY]}
              id={id}
              roleUser={props?.roleUser}
              setCareerJourneyData={() => {}}
              noDetail={false}
              customStyle={{}}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <CareerAspiration isPrinting carreerAspiration={props?.carreerAspiration} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <KeyStrengthDevelopmentAreaPrint fetchedData={fetchedData[TP_PRINT_SECTION_NAME.KEY_STRENGTHS]} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <PersonalityPrint personality={props?.personality} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <BoardMembership langAndBoard={props?.langAndBoard} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <Qualification qualification={props?.qualification} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <ApprovedSP isPrintTP id={id} roleUser={props?.roleUser} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <SpokenLanguage langAndBoard={props?.lang} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <EDGEView isPrintTP id={id} roleUser={props?.roleUser} tokenTalentProfile={tokenTalentProfile} />
          </div>

          <div className={styles.distance}>
            <Experiences
              profileId={id}
              roleUser={props?.roleUser}
              isPrinting={true}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.EXPERIENCE]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <ExternalExperience
              staffId={id}
              role={props?.roleUser}
              isPrinting={true}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.EXTERNAL_EXPERIENCE]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <OtherExperiencePrint
              profileId={id}
              roleUser={props?.roleUser}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.OTHER_EXPERIENCE]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <OtherContribution
              profileId={id}
              isPrintPdf={true}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION]}
              fetchedDataProjectInvolvement={fetchedData[TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <ExternalAssessments isPrintTP staffId={id} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <DevelopmentPlanPrint
              profileId={id}
              roleUser={props?.roleUser}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.DEVELOPMENT_PLAN]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <MobilityPlanPrint
              profileId={id}
              roleUser={props?.roleUser}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.MOBILITY_PLAN]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <ContractDetails
              profileId={id}
              roleUser={props?.roleUser}
              isPrintPdf={true}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.CONTRACT_DETAIL]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <AssessmentPrint
              profileId={id}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.ASSESSMENT_DATA]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={`${styles.container}  mt-3`}>
            <HistoricalTalentStatusPrint
              profileId={id}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.HISTORICAL_TALENT_STATUS]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <WorkPreference
              isPrintTP
              profileId={id}
              roleUser={props?.roleUser}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.WORK_PREFERENCE]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <BestFitRecommendation
              isPrintTP
              profileId={id}
              roleUser={props?.roleUser}
              fetchedData={fetchedData[TP_PRINT_SECTION_NAME.RIGHT_FIT_RECOMMENDATION]}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
          <div className={styles.distance}>
            <NotesPrint fetchedData={fetchedData[TP_PRINT_SECTION_NAME.NOTES]} tokenTalentProfile={tokenTalentProfile} />
          </div>
          <div className={styles.distance}>
            <TalentReviewPrint fetchedData={fetchedData[TP_PRINT_SECTION_NAME.TALENT_REVIEW]} tokenTalentProfile={tokenTalentProfile} />
          </div>
        </div>
        <div className={styles.btn_wrapper} ref={isRefReady}>
          <PrintApprovedSp
            componentRef={printRef}
            positionName={'test'}
            dateApproved={''}
            customFileName={customFileName}
            style={{ backgroundColor: ' #00a19c', color: 'white' }}
            tokenTalentProfile={tokenTalentProfile}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(TalentProfilePrintPreview);
