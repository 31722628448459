import { createSlice } from '@reduxjs/toolkit';
import { TP_PRINT_DEFAULT_RADIO_OPTIONS, TP_PRINT_DEFAULT_SELECT_OPTIONS } from '../../../components/TalentProfilePrintPreview/constants';

const printProfileSlice = createSlice({
  name: 'printProfile',
  initialState: {
    printSettings: {
      selectedOptions: TP_PRINT_DEFAULT_SELECT_OPTIONS,
      selectedValues: TP_PRINT_DEFAULT_SELECT_OPTIONS.map((i) => i.value),
      radioOptions: TP_PRINT_DEFAULT_RADIO_OPTIONS,
    },
    fetchedData: {},
  },
  reducers: {
    updatePrintData: (state, action) => {
      const { sectionName, data } = action.payload;
      if (data) {
        state.fetchedData[sectionName] = data;
        return;
      }
      if (state.fetchedData[sectionName]) delete state.fetchedData[sectionName];
    },
    clearPrintData: (state) => {
      state.fetchedData = {};
    },
    clearPrintSetting: (state) => {
      state.printSettings.selectedOptions = TP_PRINT_DEFAULT_SELECT_OPTIONS;
      state.printSettings.radioOptions = TP_PRINT_DEFAULT_RADIO_OPTIONS;
      state.printSettings.selectedValues = TP_PRINT_DEFAULT_SELECT_OPTIONS.map((i) => i.value);
    },
    updatePrintSettingSelection: (state, action) => {
      const selectedOptions = action.payload;
      state.printSettings.selectedOptions = selectedOptions;
      state.printSettings.selectedValues = selectedOptions.map((i) => i.value);
    },
    updatePrintSettingRadio: (state, action) => {
      const radioOptions = action.payload;
      state.printSettings.radioOptions = radioOptions;
    },
  },
});

export const { reducer: printProfilesReducer, actions } = printProfileSlice;
export const { updatePrintData, clearPrintData, clearPrintSetting, updatePrintSettingSelection, updatePrintSettingRadio } = actions;
export default printProfilesReducer;
