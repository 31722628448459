import React, { memo } from 'react';

import styles from './qualification.module.scss';
import CommonLoading from '../CommonLoading/CommonLoading';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';

const Qualification = ({ qualification, loading }) => {
  const handleRenderListQualification = () => {
    return qualification.map((item, index) => {
      if (item.school) {
        return (
          <p className={styles.CareerAspiration__item} key={index}>
            {item.major}, {item.school}
          </p>
        );
      }
      return null;
    });
  };
  return (
    <div className={styles.CareerAspiration} data-print-section={TP_PRINT_SECTION_NAME.QUALIFICATION}>
      <h3 className={styles.CareerAspiration__h3}>Qualification</h3>
      {!loading ? (
        <div className={styles.CareerAspiration__list}>{handleRenderListQualification()}</div>
      ) : (
        <div style={{ width: '785.04px' }} className="d-flex justify-content-center">
          <CommonLoading />
        </div>
      )}
    </div>
  );
};
export default memo(Qualification);
