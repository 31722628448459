import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Spin } from 'antd';

import { default_carousel } from '../../assets/img';
import styles from './SportlightCarousel.module.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import { adminApi } from '../../services/admin';
import { getGifDuration } from '../../utils/helper';

const DEFAULT_AUTO_PLAY_SPEED = 5000;

const CustomSlide = (props) => {
  const { item, ...otherProps } = props;

  return (
    <div {...otherProps}>
      <img
        src={item?.thumbnail_url ? item?.thumbnail_url : default_carousel}
        alt={item?.title}
        style={{ maxHeight: '400px', width: '100%' }}
        loading="lazy"
        onError={(e) => {
          e.target.src = default_carousel;
        }}
      />
    </div>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        right: '10px',
        backgroundColor: '#FFFFFF',
        paddingTop: '16px',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        color: '#181818',
        fontSize: '16px',
        textAlign: 'center',
      }}
      onClick={onClick}
    >
      {'>'}
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        left: '10px',
        backgroundColor: '#FFFFFF',
        paddingTop: '16px',
        borderRadius: '50%',
        zIndex: 1,
        width: '32px',
        height: '32px',
        color: '#181818',
        fontSize: '16px',
        textAlign: 'center',
      }}
      onClick={onClick}
    >
      {'<'}
    </div>
  );
};

const SportlightCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sportlight, setSportLight] = useState([]);
  const [loading, setLoading] = useState(false);

  let sliderRef = useRef(null);

  const getViewHomeSpotlight = async () => {
    setLoading(true);
    try {
      const res = await adminApi.getViewHomeSpotlight();
      if (res.status === 200) {
        const result = res.data.result;
        const updatedSlides = await Promise.all(
          result.map(async (slide) => {
            const gifDuration = await getGifDuration(slide.thumbnail_url);
            return { ...slide, autoPlaySpeed: gifDuration + DEFAULT_AUTO_PLAY_SPEED };
          })
        );
        setSportLight(updatedSlides);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getViewHomeSpotlight();
  }, []);

  const settings = {
    customPaging: function (i) {
      return (
        <button
          onClick={() => sliderRef.slickGoTo(i)}
          className={styles.customPagingButton}
          style={{
            backgroundColor: currentSlide === i ? '#00908C' : '#9FDEDB',
            height: '8px',
            width: '8px',
            borderRadius: currentSlide === i ? '25%' : '50%',
            border: 'none',
            margin: '5px',
            cursor: 'pointer',
            transition: 'border-radius 0.2s ease, transform 0.3s ease, background-color 0.3s ease',
            transformOrigin: 'center',
            transform: currentSlide === i ? 'scaleX(2.5)' : 'scaleX(1)',
          }}
        ></button>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_, newIndex) => setCurrentSlide(newIndex),
    appendDots: (dots) => (
      <div
        style={{
          display: 'flex',
          bottom: '12px',
          justifyContent: 'center',
        }}
      >
        <ul
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            padding: '4px 6px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {' '}
          {dots}{' '}
        </ul>
      </div>
    ),
  };

  return (
    <div style={{ height: '400px', width: '1290px' }} className="slider-container">
      <Spin spinning={loading} size="large">
        {!loading && (
          <>
            {sportlight.length > 0 ? (
              <Slider
                {...settings}
                autoplaySpeed={sportlight[currentSlide]?.autoPlaySpeed}
                ref={(slider) => {
                  sliderRef = slider;
                }}
              >
                {sportlight.map((item, index) => (
                  <CustomSlide key={index} index={index} item={item} />
                ))}
              </Slider>
            ) : (
              <div className={styles.noDataContainer}>
                <img
                  src={default_carousel}
                  alt="Default"
                  style={{ maxHeight: '400px', width: '100%' }}
                />
              </div>
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

export default SportlightCarousel;
