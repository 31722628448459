import { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicButton } from '../../../../../../assets/common';
import { deleteIcon } from '../../../../../../assets/img';
import { AlertMessage, ModelTC } from '../../../../../../components';
import { adminApi } from '../../../../../../services/admin';
import { COLUMNS_MAP_CRITERIA, CRITERIA_TYPE, FORM_TYPES, MESSAGE_TYPES } from '../../../../../../utils/constants';
import { getNewColumnCriteria, handleReturnValueOfSetMatching } from '../../../../../../utils/helper';
import {
  addOrUpdateMatchingCriteria,
  removeMatchingCriteria,
  updateIsDeleteCriteria,
  updateIsEditCriteria,
  updateIsValidForm
} from '../../../redux/manageCriteriaSetMatchingSlice';
import CriteriaColumns from './CriteriaColumns';
import * as styles from './selected-criteria-form.module.scss';
import { CriteriaCustomTable } from './styled';

const SelectedMatchingCriteriaForm = (props) => {
  const { data, mode, currentSetMatching, currentSetMatchingFromStore, duplicateSetMatching } = props;
  const dispatch = useDispatch();
  const { prevSetMatching, set_index, isFirstInitSetMatching, isSavedSetMatching, isValidForm } = useSelector(
    (state) => state.app.adminModule.manageCriteriaSetMatching
  );
  const [functionContents, setFunctionContents] = useState({ index: -1, options: [[]] });
  const [inputValue, setInputValue] = useState({
    isUpdate: false,
    value: currentSetMatchingFromStore.entered_criteria.find((item) => item.criteria === data.criteria)?.value || [],
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isFetchedEdit, setIsFetchedEdit] = useState(false);

  useEffect(() => {
    if (
      mode === FORM_TYPES.EDIT &&
      !isFirstInitSetMatching &&
      !isSavedSetMatching &&
      !currentSetMatchingFromStore.isEditCriteria &&
      currentSetMatching?.entered_criteria.length
    ) {
      setInputValue({
        isUpdate: true,
        value: currentSetMatching.entered_criteria.find((item) => item.criteria === data.criteria)?.value || handleReturnValueOfSetMatching(data),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, data, set_index, currentSetMatching, isFirstInitSetMatching, setInputValue]);

  const isShowAddNew = useMemo(
    () =>
      [
        CRITERIA_TYPE.ATTRIBUTES,
        CRITERIA_TYPE.FUNCTIONAL,
        CRITERIA_TYPE.FUNCTIONAL_OPTIONAL,
        CRITERIA_TYPE.FUNCTIONAL_SPIKE,
        // CRITERIA_TYPE.TOP_TALENT_EXPERIENCE,
        // CRITERIA_TYPE.TOP_TALENT_EXPERIENCE_OPTIONAL,
        CRITERIA_TYPE.BUSINESS_EXPOSURE,
        CRITERIA_TYPE.BUSINESS_EXPOSURE_OPTIONAL,        
        CRITERIA_TYPE.NATIANALITY_LOCALITY_REQUIREMENT,
      ].includes(data?.criteria),
    [data.criteria]
  );

  const handleAddNew = () => {
    const isRequireCriteriaHasAddNew = [CRITERIA_TYPE.NATIANALITY_LOCALITY_REQUIREMENT].includes(data?.criteria);
    if (isRequireCriteriaHasAddNew) {
      dispatch(updateIsValidForm(isValidForm.filter((f) => f.criteria !== data.criteria)));
    }
    let newRecord = getNewColumnCriteria(data.criteria);
    setInputValue((prevState) => {
      const cloneState = cloneDeep(prevState);
      cloneState.value.push(newRecord);
      cloneState.isUpdate = true;
      return cloneState;
    });
    if (!duplicateSetMatching) {
      dispatch(updateIsEditCriteria({ set_index, value: true }));
    }
  };

  const fetchFunctionContents = async (functionCriteria, index) => {
    try {
      const res = await adminApi.getFunctionCriteriaContent(functionCriteria);
      if (res.status === 200) {
        setFunctionContents((prevState) => {
          const cloneState = cloneDeep(prevState);
          cloneState.options[index] = res.data.result;
          cloneState.index = -1;
          return cloneState;
        });
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // get content options when select function field of Functional
  useEffect(() => {
    if (![CRITERIA_TYPE.FUNCTIONAL, CRITERIA_TYPE.FUNCTIONAL_OPTIONAL, CRITERIA_TYPE.FUNCTIONAL_SPIKE].includes(data.criteria)) return;
    const currentIndex = functionContents.index;
    if (currentIndex < 0) return;
    fetchFunctionContents(inputValue.value[currentIndex].function, functionContents.index);
  }, [data.criteria, mode, functionContents.index, inputValue]);

  // set init data when user edit criteria
  useEffect(() => {
    if (!isEmpty(prevSetMatching)) {
      const editData = prevSetMatching.set_matching_criteria[set_index]?.list_edit.find((item) => item.criteria === data.criteria);
      if (!editData) return;
      setInputValue({ isUpdate: true, value: editData.value });
      if (
        [CRITERIA_TYPE.FUNCTIONAL, CRITERIA_TYPE.FUNCTIONAL_OPTIONAL, CRITERIA_TYPE.FUNCTIONAL_SPIKE].includes(data.criteria) &&
        mode === FORM_TYPES.EDIT &&
        !isFetchedEdit
      ) {
        editData.value.forEach(async (element, index) => {
          await fetchFunctionContents(element.function, index);
        });
        setIsFetchedEdit(true);
      }
    }
  }, [data.criteria, isFetchedEdit, set_index, mode, prevSetMatching]);

  // update redux when input value
  useEffect(() => {
    if (inputValue.isUpdate) {
      dispatch(addOrUpdateMatchingCriteria({ criteria: data.criteria, value: inputValue.value }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.criteria, inputValue]);

  const confirmDeleteCriteria = () => {
    dispatch(updateIsDeleteCriteria({ set_index, value: true }));
    dispatch(removeMatchingCriteria(data.criteria));
    setShowConfirmDelete(false);
  };

  const renderColumns = (data) => {
    return CriteriaColumns(dispatch)[`${COLUMNS_MAP_CRITERIA[data.criteria]}`](
      data,
      inputValue,
      setInputValue,
      functionContents,
      setFunctionContents
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className="d-flex justify-content-between">
        <h4 className={styles.title}>{data?.criteria}</h4>
        <img
          style={!currentSetMatchingFromStore?.isEditSetMatching ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
          alt="delete_criteria"
          src={deleteIcon}
          className={styles.deleteCriteriaBtn}
          onClick={() => {
            setShowConfirmDelete(true);
          }}
          onKeyDown={() => {
            setShowConfirmDelete(true);
          }}
        />
      </div>

      {isShowAddNew && (
        <div className="d-flex justify-content-end mt-3">
          <BasicButton
            disabled={!currentSetMatchingFromStore?.isEditSetMatching}
            mode="teal"
            onClick={handleAddNew}
            data-testid={`add-${data?.criteria}`}
          >
            {data?.criteria === CRITERIA_TYPE.ATTRIBUTES ? 'Add Attributes' : 'Add New'}
          </BasicButton>
        </div>
      )}

      <div className="mt-2">
        {data?.criteria === CRITERIA_TYPE.NATIANALITY_LOCALITY_REQUIREMENT && (
          <AlertMessage
            type={MESSAGE_TYPES.NORMAL}
            message={<span className={styles.disclaimer_title}>Disclaimer</span>}
            description="Disclaimer: Only to be used when legal / local requirements arise. Do consider Diversity & Inclusion (D&I) when selecting the criteria(s)."
            closable={false}
          />
        )}
      </div>
      <CriteriaCustomTable columns={renderColumns(data)} dataSource={[data?.criteria]} pagination={false} rowKey={1} />
      <ModelTC
        info={{
          type: 'deleteKeyPositionFromListOfCriticalPosition',
          visible: showConfirmDelete,
          setVisible: setShowConfirmDelete,
          handleSubmit: () => confirmDeleteCriteria(),
          onClose: () => setShowConfirmDelete(false),
        }}
      />
    </div>
  );
};

export default SelectedMatchingCriteriaForm;
