import React, { useMemo } from 'react';

import GeneralExternalAssessments from '../GeneralExternalAssessments/GeneralExternalAssessments';
import styles from './all-external-assessments.module.scss';
import LearningAgilitiesAssessments from '../LearningAgilitiesAssessments/LearningAgilitiesAssessments';
import TopTalentPotential from '../TopTalentPotential/TopTalentPotential';
import ExternalAssessmentTraits from '../../../../components/ExternalAssessmentEdge/ExternalAssessmentTraits';
import DerailmentRisksAssessments from '../DerailmentRisksAssessments/DerailmentRisksAssessments';
import PersonalityArchetypesAssessments from '../PersonalityArchetypesAssessments/PersonalityArchetypesAssessments';
import ExternalAssessmentCompetencies from '../../../../components/ExternalAssessmentEdge/ExternalAssessmentCompetencies';
import { ASSESSMENT_TYPES, ASSESSMENT_TYPE_VALUES } from '../ExternalAssessments/constant_assessments';
import ExternalAssessmentEdge from '../../../../components/ExternalAssessmentEdge/ExternalAssessmentEdge';
import DriversAssessments from '../DriversAssessments/DriversAssessments';

const AllExternalAssessments = (props) => {
  const { assessmentData, loading, isPrintTP } = props;

  const useConvertData = (assessmentData, type) => {
    return useMemo(() => {
      if (Array.isArray(assessmentData)) {
        const data = assessmentData.find((item) => item.type === type);
        return data ? data : [];
      }
      return [];
    }, [assessmentData, type]);
  };

  const convertGeneralData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.GENERAL);
  const convertEdgeData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.EDGE);
  const convertPersonalityArchetypesData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.PERSONALITY_ARCHETYPES);
  const convertTopTalentPotentialData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.TOP_TALENT_POTENTIAL);
  const convertExternalAssessmentTraitsData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.TRAITS);
  const convertExternalAssessmentDriversData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.DRIVERS);
  const convertLearningAgilitsData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.LEARNING_AGILITIES);
  const convertDerailmentRisksData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.DERAILMENT_RISKS);
  const convertCompetenciesData = useConvertData(assessmentData, ASSESSMENT_TYPE_VALUES.COMPETENCIES);

  const getLabelByValue = (value) => {
    const assessment = ASSESSMENT_TYPES.find((type) => type.value === value);
    return assessment ? assessment.label : '';
  };

  return (
    <div className={styles.listContent}>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.GENERAL)}</h3>
        <GeneralExternalAssessments assessmentData={convertGeneralData} loading={loading} />
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.EDGE)}</h3>
        <ExternalAssessmentEdge assessmentData={convertEdgeData} loading={loading} />
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.PERSONALITY_ARCHETYPES)}</h3>
        <PersonalityArchetypesAssessments assessmentData={convertPersonalityArchetypesData} loading={loading} />
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.TOP_TALENT_POTENTIAL)}</h3>
        <TopTalentPotential
          assessmentData={convertTopTalentPotentialData}
          assessmentType={ASSESSMENT_TYPE_VALUES.TOP_TALENT_POTENTIAL}
          loading={loading}
        />
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.LEARNING_AGILITIES)}</h3>
        <LearningAgilitiesAssessments assessmentData={convertLearningAgilitsData} loading={loading} isPrint={isPrintTP}/>
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.TRAITS)}</h3>
        <ExternalAssessmentTraits assessmentData={convertExternalAssessmentTraitsData} loading={loading} isPrint={isPrintTP}/>
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.DRIVERS)}</h3>
        <DriversAssessments assessmentData={convertExternalAssessmentDriversData} loading={loading} isPrint={isPrintTP} />
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.DERAILMENT_RISKS)}</h3>
        <DerailmentRisksAssessments assessmentData={convertDerailmentRisksData} loading={loading} isPrint={isPrintTP}/>
      </div>
      <div>
        <h3 className={styles.mainTitle}>{getLabelByValue(ASSESSMENT_TYPE_VALUES.COMPETENCIES)}</h3>
        <ExternalAssessmentCompetencies assessmentData={convertCompetenciesData} loading={loading} isPrint={isPrintTP}/>
      </div>
    </div>
  );
};

export default AllExternalAssessments;
