import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { BasicButton, BasicLabel, BasicSwitch, BasicTable } from '../../assets/common';
import { DATA_FROM, PERMISSION } from '../../utils/constants';
import styles from './viewnote.module.scss';
import { Spin } from 'antd';
import { paginationConfig } from '../../utils/helper';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import More3DotDropdown from '../More3DotDropdown/More3DotDropdown';

const modalStyle = {
  borderRadius: 8,
  closePosition: [32, 26],
  containerPaddingLeftRight: 32,
  containerPaddingTopBottom: 26,
  headerPaddingBottom: 12,
  headerBorderBottom: 'none',
  titleFontSize: 20,
  titleFontWeight: 700,
  titleColor: '#3F3C4C',
};

const tableStyle = {
  borderRadius: 8,
  headerWeight: 500,
  headerColor: '#3F3C4C',
};

const ViewNote = ({
  isOpen,
  setIsOpen,
  setIsOpenNote,
  allNotes,
  setIsPopupDelete,
  onChooseItem,
  setIsEdit,
  isViewToggle,
  setIsViewToggle,
  isLoading,
}) => {
  const { permissions } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);

  const isAddNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return (
      permissions.includes(PERMISSION.TP_EDIT_NON_PRIVATE_NOTE) ||
      permissions.includes(PERMISSION.VIEW_EDIT_PRIVACY_NOTES)
    );
  }, [permissions]);

  const isShowActionNonPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_NON_PRIVATE_NOTE);
  }, [permissions]);

  const isShowActionPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.VIEW_EDIT_PRIVACY_NOTES);
  }, [permissions]);

  const handleClickEdit = (note) => {
    onChooseItem(note);
    setIsOpenNote(true);
    setIsEdit(true);
    setIsOpen(false);
  };

  const handleClickDelete = (note) => {
    onChooseItem(note);
    setIsPopupDelete(true);
    setIsOpen(false);
  };

  const columns = [
    {
      title: 'Platform',
      width: '18%',
      dataIndex: 'platform',
      render: (text) => <span className={styles.text}>{text}</span>,
    },
    {
      title: 'Date',
      width: '18%',
      dataIndex: 'date',
      render: (text) => <span className={styles.text}>{moment(text).format('DD MMM YYYY')}</span>,
    },
    {
      title: 'Notes',
      width: '42%',
      dataIndex: 'notes_content',
      render: (text) => <span className={styles.noteText}>{text}</span>,
    },
    {
      title: 'Paper Status',
      width: '18%',
      dataIndex: 'paper_status',
      render: (text) => <span className={styles.text}>{text || '-'}</span>,
    },
    {
      width: '4%',
      render: (_, note) => (
        <>
          {note.from === DATA_FROM.MANUAL &&
            note.is_editable !== 0 &&
            ((isShowActionNonPrivateNote && !note.is_private) ||
              (isShowActionPrivateNote && note.is_private)) && (
              <More3DotDropdown
                item={note}
                handleClickEdit={handleClickEdit}
                handleClickDelete={handleClickDelete}
              />
            )}
        </>
      ),
    },
  ];

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setPage(pageNum);
    };
    return paginationConfig(allNotes.totalNotes, page, onChange, 3);
  }, [allNotes.totalNotes, page]);

  return (
    <CustomAntModal
      hideDefaultBtn
      styles={modalStyle}
      open={isOpen}
      setOpen={setIsOpen}
      width={1130}
      title={
        <div className={styles.title}>
          <h1 data-testid="view-note-title">Notes</h1>
          <div className={styles.view_other}>
            <BasicSwitch
              id="view_other"
              checked={isViewToggle}
              onChange={(checked) => {
                setIsViewToggle(checked);
              }}
            />
            <BasicLabel hmtlFor="view_other">View other notes</BasicLabel>
          </div>
        </div>
      }
    >
      {isAddNote && (
        <div className={styles.addBtn}>
          <BasicButton
            mode="teal"
            onKeyDown={() => {}}
            onClick={() => {
              setIsOpen(false);
              setIsOpenNote(true);
            }}
            data-testid="click-add-note"
          >
            Add Note
          </BasicButton>
        </div>
      )}
      <Spin spinning={isLoading}>
        <BasicTable
          rowKey="id"
          styles={tableStyle}
          dataSource={allNotes.data}
          columns={columns}
          pagination={pagination}
        />
      </Spin>
    </CustomAntModal>
  );
};

export default ViewNote;
