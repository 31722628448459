import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { BasicButton } from '../../../assets/common';
import styles from './pdc-print-page.module.scss';
import { useReactToPrint } from 'react-to-print';
import PrintMeetingDetail from './components/PrintMeetingDetail/PrintMeetingDetail';
import PrintCover from './components/PrintCover/PrintCover';
import { useGetPDCMeeting } from '../../../hooks/useGetPDCMeeting';
import PrintSummary from './components/PrintSummary/PrintSummary';
import PrintMobility from './components/PrintMobility/PrintMobility';
import CandidatePrint from './components/CandidatePrint/CandidatePrint';
import { isEmpty } from 'lodash';
import { checked_print_pdc } from '../../../assets/img';
import PrintSkeleton from './components/PrintSkeleton/PrintSkeleton';
import { InView } from 'react-intersection-observer';

const PDCPrintPage = () => {
  const printRef = useRef();
  let { idMeeting } = useParams();
  const renderedCount = useRef([]);
  const [currentRenderIndex, setCurrentRenderIndex] = useState(0);
  const [isLoadingDone, setIsLoadingDone] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(0);
  const [selectedEndorsed, setSelectedEndorsed] = useState(null);
  const [isErrorEndorsed, setIsErrorEndorsed] = useState(false);
  const [isShowFloatBtn, setIsShowFloatBtn] = useState(false);

  const { data: meetingDetails, loading: meetingDetailsLoading } = useGetPDCMeeting({ idMeeting });

  const { common, mobilities, mobilitySectionConfigs, totalCheckedArr } = useMemo(() => {
    const storedData = localStorage.getItem('printPdcFilter');
    if (!storedData) return {};
    const data = JSON.parse(storedData);
    const { common, mobilities, ...mobilitySectionConfigs } = data;

    const totalCheckedArr = [];
    for (const id in mobilitySectionConfigs) {
      let total = 0;
      const { mobility, ...otherConfig } = mobilitySectionConfigs[id];
      const printSectionConfig = mobilitySectionConfigs[id];
      printSectionConfig.candidate = {
        checked:
          printSectionConfig.proposal.checked ||
          printSectionConfig.compareSuccessor.checked ||
          printSectionConfig.assessment.checked ||
          printSectionConfig.compareAssessment.checked ||
          printSectionConfig.aspirationAndRisks.checked ||
          printSectionConfig.compareAspirationAndRisks.checked,
      };
      if (mobility.checked) {
        total += 1;
      }
      for (const section in otherConfig) {
        const config = otherConfig[section];
        if (config.checked) {
          total += 1;
          break;
        }
      }
      if (total !== 0) totalCheckedArr.push(total);
    }

    if (isEmpty(totalCheckedArr)) setIsLoadingDone(true);

    return {
      common,
      mobilities: mobilities.filter(({ id }) => !Object.values(data[id]).every((item) => !item.checked)),
      mobilitySectionConfigs,
      totalCheckedArr,
    };
  }, []);

  const handleFetchComplete = (_logComponent) => {
    // console.log(`Fetched data for ${_logComponent}`); //debug logging
    if (renderedCount.current[currentRenderIndex] === undefined) {
      renderedCount.current[currentRenderIndex] = 0;
    }

    renderedCount.current[currentRenderIndex]++;
    const totalRendered = renderedCount.current.reduce((prev, current) => prev + current, 0);
    const total = totalCheckedArr.reduce((prev, current) => prev + current, 0);
    setLoadingProcess(Math.ceil((totalRendered * 100) / total));

    if (currentRenderIndex === totalCheckedArr.length - 1 && totalCheckedArr[currentRenderIndex] === renderedCount.current[currentRenderIndex]) {
      setIsLoadingDone(true);
    }
    if (renderedCount.current[currentRenderIndex] === totalCheckedArr[currentRenderIndex]) {
      setCurrentRenderIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: meetingDetails?.meeting_name,
  });

  const handleClickPrint = () => {
    if (selectedEndorsed) return handlePrint();
    setIsErrorEndorsed(true);
  };

  return (
    <div className={styles.wrapper} style={!isLoadingDone ? { overflow: 'hidden', height: '100vh' } : {}}>
      {isLoadingDone ? (
        <div className={styles.btnRow}>
          <div className={styles.loadingText}>
            <img src={checked_print_pdc} alt="checked" />
            All set! You can now view, print, or save your document as needed.
          </div>
          <InView
            as="div"
            onChange={(inView) => {
              setIsShowFloatBtn(!inView);
            }}
          >
            <BasicButton onClick={handleClickPrint} mode="teal">
              Print details
            </BasicButton>
          </InView>
          {isShowFloatBtn && (
            <BasicButton onClick={handleClickPrint} mode="teal" className={styles.floatBtn}>
              Print details
            </BasicButton>
          )}
        </div>
      ) : (
        <div className={styles.loadingBar}>
          <div>This might take a few moments to load due to file size. Hang tight!</div>
          <div className={styles.loadingProcess}>
            <progress min={0} max={100} value={loadingProcess} />
            {loadingProcess}%
          </div>
        </div>
      )}

      {!isLoadingDone && (
        <div className={styles.printSection}>
          <PrintSkeleton />
        </div>
      )}
      <div ref={printRef} className={styles.printSection} style={!isLoadingDone ? { visibility: 'hidden' } : {}}>
        <section>
          <PrintCover
            data={meetingDetails}
            selectedEndorsed={selectedEndorsed}
            setSelectedEndorsed={setSelectedEndorsed}
            isErrorEndorsed={isErrorEndorsed}
            setIsErrorEndorsed={setIsErrorEndorsed}
          />
        </section>
        {common?.details?.checked && (
          <section>
            <PrintMeetingDetail data={meetingDetails} loading={meetingDetailsLoading} preRead={common?.details.preRead} />
          </section>
        )}
        {common?.summary?.checked && (
          <section>
            <PrintSummary idMeeting={idMeeting} preRead={common?.summary?.preRead} />
          </section>
        )}
        {mobilities?.map((mobility, index) => {
          if (currentRenderIndex < index) return <React.Fragment key={mobility.id}></React.Fragment>;
          return (
            <React.Fragment key={mobility.id}>
              <section>
                <div className={styles.mobilityTitle}>
                  {index + 1}. {mobility.title}
                </div>
                <div className={styles.divider} />
                {mobilitySectionConfigs[mobility.id].mobility.checked && (
                  <PrintMobility
                    mobilityInfo={mobility}
                    preRead={mobilitySectionConfigs[mobility.id]?.mobility.preRead}
                    idMeeting={idMeeting}
                    handleFetchComplete={handleFetchComplete}
                  />
                )}
                <div className={styles.divider} />
                <CandidatePrint
                  index={index}
                  mobilityInfo={mobility}
                  printSectionConfig={mobilitySectionConfigs[mobility.id]}
                  idMeeting={idMeeting}
                  handleFetchComplete={handleFetchComplete}
                  isDisplayCandidate={mobilitySectionConfigs[mobility.id].candidate.checked}
                />
              </section>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PDCPrintPage;
