import React, { memo } from 'react';
import styles from './board-membership.module.scss';
import CommonLoading from '../CommonLoading/CommonLoading';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';

const BoardMembership = ({ langAndBoard, loading }) => {
  const handleRenderListBoard = () => {
    return langAndBoard.map((item, index) => {
      if (item.role_contribution && item.description_contribution) {
        return (
          <p className={styles.CareerAspiration__item} key={index}>
            {item.role_contribution}, {item.description_contribution}
          </p>
        );
      }
      return null;
    });
  };

  return (
    <div className={styles.CareerAspiration} data-print-section={TP_PRINT_SECTION_NAME.BOARD_MEMBERSHIP}>
      <h3 className={styles.CareerAspiration__h3}>Board Membership </h3>
      {!loading ? (
        <div className={styles.CareerAspiration__list}>{handleRenderListBoard()}</div>
      ) : (
        <div style={{ width: '785.04px' }} className="d-flex justify-content-center">
          <CommonLoading />
        </div>
      )}
    </div>
  );
};
export default memo(BoardMembership);
