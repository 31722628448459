import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './project-involvement.module.scss';
import { BasicTable } from '../../assets/common';
import { Spin } from 'antd';
import talentProfileApi from '../../services/talentProfiles';
import { paginationConfig } from '../../utils/helper';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updatePrintData } from '../../pages/TalentProfilePage/store/printProfileSlice';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';

const NOT_AVAILABLE = 'Not Available';
const COLUMNS = [
  {
    title: 'Name',
    width: 121,
    dataIndex: 'project_name',
    render: (text) => <span className={styles.text1}>{text}</span>,
  },
  {
    title: 'Description',
    dataIndex: 'project_description',
    className: 'column-w-200',
    render: (text) => <span className={styles.textHtml} dangerouslySetInnerHTML={{ __html: text }} />,
  },
  {
    title: 'Roles',
    width: 120,
    dataIndex: 'project_role',
    render: (text) => <span className={styles.text2}>{text}</span>,
  },
  {
    title: 'Level',
    width: 120,
    dataIndex: 'project_level',
    render: (text) => <span className={styles.text2}>{text}</span>,
  },
  {
    title: 'Start Date',
    width: 120,
    dataIndex: 'project_start_date',
    render: (text) => <span className={styles.text2}>{text === NOT_AVAILABLE ? text : moment(text).format('DD MMM YYYY')}</span>,
  },
  {
    title: 'Completion Date',
    width: 146,
    dataIndex: 'project_end_date',
    render: (text) => <span className={styles.text2}>{text === NOT_AVAILABLE ? text : moment(text).format('DD MMM YYYY')}</span>,
  },
];
const LIMIT_ROWS = 5;
const PRINT_LIMIT_ROWS = 1000;
const TABLE_STYLE = {
  headerColor: '#181818',
  headerWeight: 500,
  borderRadius: 6,
  roundPagination: true,
};
const INIT_DATA = { data: [], total: 0 };

const ProjectInvolvement = ({ staffId, roleId, permissions, isPrintPdf, fetchedData, tokenTalentProfile }) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState(INIT_DATA);
  const dispatch = useDispatch();

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setPage(pageNum);
    };
    return paginationConfig(projects.total, page, onChange, LIMIT_ROWS);
  }, [projects.total, page]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const limit = isPrintPdf ? PRINT_LIMIT_ROWS : LIMIT_ROWS;
      const res = await talentProfileApi.getProjectInvolvement({ staffId, page, roleId, permissions, limit, tokenTalentProfile });
      if (res.status === 200) {
        if (isPrintPdf) {
          dispatch(updatePrintData({ sectionName: TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT, data: res.data.result }));
        } else {
          setProjects(res.data.result);
        }
      }
    } catch (error) {
      console.error(error);
      setProjects(INIT_DATA);
    } finally {
      setLoading(false);
    }
  }, [page, isPrintPdf, permissions, roleId, staffId, dispatch]);

  useEffect(() => {
    if (isPrintPdf && fetchedData) {
      setProjects(fetchedData);
      return;
    }
    getData();
  }, [getData, fetchedData, isPrintPdf]);

  return (
    <div>
      <div className={styles.title}>Project Involvement</div>
      <Spin spinning={loading}>
        <BasicTable
          rowKey="id"
          styles={TABLE_STYLE}
          dataSource={projects.data}
          columns={COLUMNS}
          pagination={!isPrintPdf ? pagination : false}
          rowClassName={styles.customTable}
        />
      </Spin>
    </div>
  );
};

ProjectInvolvement.propTypes = {
  staffId: PropTypes.string,
  roleId: PropTypes.number,
  permissions: PropTypes.array,
  isPrintPdf: PropTypes.bool,
  fetchedData: PropTypes.object,
};

export default ProjectInvolvement;
