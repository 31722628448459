import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './basic-tree-select.module.scss';
import { Radio } from 'antd';
import TreeSelect, { TreeNode } from 'antd/lib/tree-select';
import { arrow_expanded } from '../../assets/img';
import { BasicButton, BasicErrorText, BasicSelect } from '../../assets/common';
import { PoposalTypeWrapper, PoposalWrapper, ProposalListWrapper, ProposalTitle } from '../ProposalSetting/ProposalSettingStyle';
import { isEmpty } from 'lodash';

const BasicTreeSelect = memo((props) => {
  const {
    options,
    onApply,
    customApplyText,
    valueSelectAll,
    onChangeRadio,
    selectedOptions,
    setSelectedOptions,
    radioOptions,
    setRadioOptions,
    prevSelectedOption,
    prevRadioOptions,
    children,
    style,
    type,
    initProposalOptions,
    handleChangeProposalType,
    proposalType,
    handleOpenSettingProposal,
    currentItemProposal,
    ...args
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [hasError, setHasError] = useState(false);
  const treeSelectRef = useRef(null);

  const mappingAllNodes = useMemo(() => {
    return !isEmpty(options) ? buildAllNodes(options) : [];
  }, [options]);

  const mappingParentNodes = useMemo(() => {
    return buildParentNodes(options);
  }, [options]);

  const mappingChildNodes = useMemo(() => {
    return buildChildNodes(options);
  }, [options]);

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (onApply && !dropdownOpen) {
      setIsApplied(false);
    }
    if (handleOpenSettingProposal) {
      handleOpenSettingProposal(currentItemProposal);
    }
  };

  const handleClickParentNode = (checked, triggerValue, currentValuesSet) => {
    const childValuesSet = mappingParentNodes[triggerValue].children;
    const higherParentValue = mappingParentNodes[triggerValue].parent;
    let result = [];
    if (checked) {
      const newValuesSet = currentValuesSet.union(childValuesSet);
      newValuesSet.forEach((value) => {
        result.push({ label: value, value });
      });
      if (higherParentValue && !newValuesSet.has(higherParentValue)) {
        result.push({ label: higherParentValue, value: higherParentValue });
      }
    } else {
      const higherChildValuesSet = mappingParentNodes[higherParentValue]?.children;
      let isUncheckParent = true;
      currentValuesSet.forEach((value) => {
        if (!childValuesSet.has(value)) {
          result.push({ label: value, value });
        }
        if (higherChildValuesSet && higherChildValuesSet.has(value) && !childValuesSet.has(value)) {
          isUncheckParent = false;
        }
      });
      if (higherChildValuesSet && isUncheckParent) {
        result = result.filter((i) => i.value !== higherParentValue);
      }
    }
    return result;
  };

  const handleClickChildNode = (checked, triggerValue, currentValuesSet) => {
    if (valueSelectAll && valueSelectAll === triggerValue) {
      return checked ? mappingAllNodes : [];
    }

    const parentValuesSet = mappingChildNodes[triggerValue];
    let result = [];
    if (checked) {
      const newValuesSet = currentValuesSet.union(parentValuesSet);
      newValuesSet.forEach((value) => {
        result.push({ label: value, value });
      });
    } else {
      const higherChildValuesSet = new Set();
      const addedParentsSet = new Set();
      parentValuesSet.forEach((value) => {
        higherChildValuesSet.add({ value, children: mappingParentNodes[value]?.children });
      });

      for (let value of currentValuesSet) {
        if (parentValuesSet.has(value)) continue;

        result.push({ label: value, value });
        higherChildValuesSet.forEach((item) => {
          if (item.children && !addedParentsSet.has(item.value) && item.children.has(value)) {
            result.push({ label: item.value, value: item.value });
            addedParentsSet.add(item.value);
          }
        });
      }
    }
    return result;
  };

  const handleChangeSelect = (currentSelectedOptions, _, { checked, triggerValue }) => {
    const currentValuesSet = new Set(currentSelectedOptions.map((i) => i.value));
    const parentKeys = Object.keys(mappingParentNodes);
    const isParentNode = parentKeys.includes(triggerValue);
    let newValues = [];

    if (isParentNode) {
      newValues = handleClickParentNode(checked, triggerValue, currentValuesSet);
    } else {
      newValues = handleClickChildNode(checked, triggerValue, currentValuesSet);
    }
    if (!valueSelectAll || newValues.length === mappingAllNodes.length) {
      setSelectedOptions(newValues);
    }

    if (newValues.length === mappingAllNodes.length - 1 && !currentValuesSet.has(valueSelectAll)) {
      newValues = [{ value: valueSelectAll, label: valueSelectAll }, ...newValues];
    } else if (newValues.length < mappingAllNodes.length) {
      newValues = newValues.filter((i) => i.value !== valueSelectAll);
    }

    setSelectedOptions(newValues);
  };

  const handleClickOutside = (event) => {
    if (treeSelectRef.current && !treeSelectRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleClickApply = () => {
    setIsApplied(true);
    setDropdownOpen(false);
    onApply();
    setHasError(false);
  };

  useEffect(() => {
    if (onApply && !dropdownOpen && !isApplied) {
      setTimeout(() => {
        setSelectedOptions(prevSelectedOption);
        setRadioOptions?.(prevRadioOptions);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpen, prevSelectedOption]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dropDownRender = (menu) => (
    <>
      {type === 'proposal' ? (
        <PoposalWrapper>
          <PoposalTypeWrapper hasError={hasError}>
            <ProposalTitle style={{ paddingBottom: '15px' }}>Proposal Type</ProposalTitle>
            <BasicSelect
              id="proposalType"
              data-testid="proposalType"
              size="middle"
              options={initProposalOptions}
              getPopupContainer={(trigger) => trigger.parentElement}
              name="proposalType"
              placeholder="Select"
              onChange={(value) => {
                handleChangeProposalType(value);
                setHasError(false);
              }}
              value={proposalType}
            />
            <BasicErrorText show={hasError}>{`Please select proposal type`}</BasicErrorText>
          </PoposalTypeWrapper>
          <ProposalListWrapper>
            <ProposalTitle>Proposal Item List</ProposalTitle>
            <>
              {menu}
              {onApply && (
                <div className={styles.applyBtn}>
                  <BasicButton mode="teal" onClick={type === 'proposal' && !proposalType ? () => setHasError(true) : handleClickApply} width="100px">
                    {customApplyText || 'Apply'}
                  </BasicButton>
                </div>
              )}
            </>
          </ProposalListWrapper>
        </PoposalWrapper>
      ) : (
        <>
          {menu}
          {onApply && (
            <div className={styles.applyBtn}>
              <BasicButton mode="teal" onClick={handleClickApply} width="100px">
                {customApplyText || 'Apply'}
              </BasicButton>
            </div>
          )}
        </>
      )}
    </>
  );

  const renderTreeNodes = (data) => {
    return data.map((item) => {
      if (!item.children) {
        return <TreeNode key={item.value} value={item.value} title={item.label} checked={item.checked} disableCheckbox={item.disabled} />;
      }
      if (item.isRadio) {
        return (
          <TreeNode key={item.value} value={item.value} title={item.label} checkable={false}>
            {item.children.map((child) => (
              <TreeNode
                checkable={false}
                selectable={false}
                key={child.value}
                value={child.value}
                title={
                  <Radio
                    name={item.value}
                    value={child.value}
                    checked={radioOptions[item.value] === child.value}
                    onChange={() => {
                      onChangeRadio(child.value, item.value);
                    }}
                  >
                    {child.label}
                  </Radio>
                }
              />
            ))}
          </TreeNode>
        );
      }
      return (
        <TreeNode key={item.value} value={item.value} title={item.label} checkable={item.checkable} disableCheckbox={item.disabled}>
          {renderTreeNodes(item.children)}
        </TreeNode>
      );
    });
  };

  const treeOptionsRendered = useMemo(() => {
    return renderTreeNodes(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioOptions]);

  const switcherIcon = ({ expanded }) => {
    return expanded ? <img src={arrow_expanded} alt="expanded" /> : <img className={styles.collapsed} src={arrow_expanded} alt="collapsed" />;
  };

  return (
    <div className={styles.wrapper} ref={treeSelectRef} style={style}>
      <button className={styles.suffixIcon} onClick={handleToggleDropdown}>
        {children}
      </button>
      <TreeSelect
        open={onApply || children ? dropdownOpen : undefined}
        switcherIcon={switcherIcon}
        getPopupContainer={(trigger) => trigger.parentElement.parentElement}
        treeCheckable={true}
        dropdownRender={dropDownRender}
        value={selectedOptions}
        onChange={handleChangeSelect}
        maxTagCount={0}
        treeCheckStrictly={true}
        {...args}
      >
        {treeOptionsRendered}
      </TreeSelect>
    </div>
  );
});

BasicTreeSelect.displayName = 'BasicTreeSelect';
BasicTreeSelect.propTypes = {
  options: PropTypes.array,
  onApply: PropTypes.func,
  customApplyText: PropTypes.string,
  valueSelectAll: PropTypes.string,
  onChangeRadio: PropTypes.func,
  selectedOptions: PropTypes.array,
  setSelectedOptions: PropTypes.func,
  radioOptions: PropTypes.object,
  setRadioOptions: PropTypes.func,
  prevSelectedOption: PropTypes.array,
  prevRadioOptions: PropTypes.object,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default BasicTreeSelect;

function buildAllNodes(options) {
  let result = [];

  function traverse(optionList) {
    optionList.forEach((option) => {
      const { label, value, children, isRadio, checkable } = option;

      if (!isRadio && checkable !== false) {
        result.push({ label, value });
      }

      if (children && !isRadio) {
        traverse(children);
      }
    });
  }

  traverse(options);
  return result;
}

function buildParentNodes(options) {
  if (isEmpty(options)) return {};
  let parentNodes = {};

  function addChildren(node, parentValue) {
    const { value, children, checkable, isRadio, skipParent } = node;

    if (isRadio || checkable === false || !children || skipParent) return;

    if (!parentNodes[value]) {
      parentNodes[value] = {
        parent: parentValue,
        children: new Set(),
      };
    }

    for (const child of children) {
      addChildren(child, value);
      parentNodes[value].children.add(child.value);

      if (parentNodes[child.value]) {
        parentNodes[child.value].children.forEach((descendant) => {
          parentNodes[value].children.add(descendant);
        });
      }
    }
  }

  for (const option of options) {
    addChildren(option, null);
  }

  return parentNodes;
}

function buildChildNodes(options) {
  const childNodes = {};

  function addParents(node, higherParentValues) {
    const { value, children } = node;

    if (!children) {
      childNodes[value] = new Set([]);
      return;
    }
    for (const child of children) {
      if (child.children && !child.skipParent) {
        addParents(child, [...higherParentValues, value]);
        continue;
      }
      childNodes[child.value] = new Set([value, ...higherParentValues]);
    }
  }

  for (const option of options) {
    addParents(option, []);
  }

  return childNodes;
}
