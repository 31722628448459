import { combineReducers } from '@reduxjs/toolkit';
import rolesReducer from './RoleManagement/RoleList/rolesSlice';
import manageCriteriaSetMatchingReducer from './SPManagement/redux/manageCriteriaSetMatchingSlice';

const reducer = combineReducers({
  roleManagement: rolesReducer,
  manageCriteriaSetMatching: manageCriteriaSetMatchingReducer,
});

export default reducer;
