import React, { useCallback, useEffect, useState } from 'react';

import { fetchOtherExperiences } from '../../../../services/talentProfiles';
import styles from './other-experience-print.module.scss';
import { TP_PRINT_SECTION_NAME } from '../../constants';
import { useDispatch } from 'react-redux';
import { updatePrintData } from '../../../../pages/TalentProfilePage/store/printProfileSlice';

const OtherExperiencePrint = (props) => {
  const { profileId, roleUser, fetchedData, tokenTalentProfile } = props;
  const {
    roleActive: { roleId },
    permissions,
  } = roleUser;
  const [otherExperience, setOtherExperience] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchApi = useCallback(async () => {
    if (!(roleId && profileId)) return;
    const params = {
      page: 1,
      limit: 1000,
      isCheck: true,
      is_print_pdf: true,
    };
    setLoading(true);
    const response = await fetchOtherExperiences(params, roleId, permissions, profileId, tokenTalentProfile);
    if (response.status === 200) {
      dispatch(updatePrintData({ sectionName: TP_PRINT_SECTION_NAME.OTHER_EXPERIENCE, data: response?.data?.result?.experiences }));
    }
    setLoading(false);
  }, [roleId, permissions, profileId, dispatch]);

  useEffect(() => {
    if (fetchedData) {
      setOtherExperience(fetchedData);
      return;
    }
    fetchApi();
  }, [fetchApi, fetchedData]);

  return (
    <div className={styles.OtherExperience} data-testid="other-experiences" data-print-section={TP_PRINT_SECTION_NAME.OTHER_EXPERIENCE}>
      <div className={styles.OtherExperience__header}>
        <h3 className={styles.OtherExperience__h3}>Other Experience</h3>
      </div>
      <div className={styles.OtherExperience__table}>
        <table>
          <thead>
            <tr>
              <th>
                <p>Experience</p>
              </th>
              <th>
                <p>Remarks</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td align="center" colSpan="2">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              (otherExperience && otherExperience.length > 0 ? (
                otherExperience.map((item, idx) => {
                  return (
                    <tr key={item.id || idx}>
                      <td>
                        <p>{item.experience || '-'}</p>
                      </td>
                      <td>
                        <p>{item.remarks || '-'}</p>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr key="0">
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default OtherExperiencePrint;
