/**
 * @author Vishal Kumar Verma
 * @create date 2019-09-16 13:28:24
 * @modify date 2019-09-16 13:28:24
 * @desc Constants to be used across application
 */

import { mobility_discussion_type, other_type, succession_planning_type, talent_review_type } from '../assets/img';

export const BRAND_NAME = 'Talent Dashboard';
export const BRAND_WELCOME_TEXT = 'Welcome to the Talent Dashboard';
export const BRAND_DESCRIPTION =
  'The Talent Dashboard paves the way for PETRONAS to nurture and grow our employee’s talent by making informed talent decisions, with data analytics at the core.\n\nBy leveraging on talent profiles and job fitment algorithm, the dashboard enables PETRONAS to match the right employee with the right role, helping them do what they love to do while enabling them to unleash their full potential.';
export const GET_STARTED = 'Get Started';
export const EMP_SUMM_TAB = 'Employee Insights';
export const ORG_TAB = 'Organisation Themes View';
export const EMP_ORG_VIEW_TAB = 'Employee / Organisation View';
export const POS_MATCH_TAB = 'Position Matching';
export const POPUP_TITLE = 'Employee Insights';
export const POPUP_CAPTION = 'Search by Staff ID, Name or Email Address.';
export const HOMEPAGE_CAPTION = 'Select an option to access your desired page.';
export const ORG_DIV_STRUCT = 'Division Organisation Structure';
export const ORG_DEPT_STRUCT = 'Department Organisation Structure';
export const ORG_POSITION = 'Position Details';
export const ORG_CURRENT_STATE = 'CURRENT STATE';
export const ORG_CALIBRATION_STATE = 'CALIBRATION STATE';
export const IT_EMAIL = 'lim.boonchin@petronas.com.my';
export const IT_PHONE_NUMBER = '';

export const BU_ACCESS_PETRONAS_AND_OWN_BU = 'BU';
export const BU_ACCESS_PETRONAS_AND_FAMILY = 'Job Family';
export const BU_ACCESS_OPU_DIV = 'OPU/Div';
export const BU_ACCESS_ALL = 'All';
export const PESTRONAS = 'PETRONAS';
export const BUSINESS = 'Business';
export const OPU = 'OPU';
export const DIVISION = 'Division';
export const TYPE_EKP = 'CCP';
export const TYPE_BKP = 'BCP';
export const USER_ID = 27;
export const OTHER_EXPERIENCE = 'OTHER EXPERIENCE';
export const FEED_BACK = 'FEED BACK';
export const MEETING_ID = '1';
export const MEETING_NAME = 'Sample Meeting Editted';
export const NAME = 'Razak Abdul Kareem';
export const VERSION = '1.0';
export const AGENDA_ID = '1';
export const ERROR_MESSAGE = 'user_not_found';
export const USER_NOT_EXIST = 'User not in access list';
export const LABEL_EKP = 'Corporate Critical Positions [CCP]';
export const LABEL_BKP = 'Business Critical Positions [BCP]';
export const TR_AGENDA_FILE_NAME_SUBFIX = '_talent_review.pdf';
export const SIZE_DROPDOWN = {
  SM: 'SM',
  SSM: 'SSM',
  NOR: 'NORMAL',
  LG: 'LG',
};
export const EDGE_OPTION_VALUE = {
  SMA: 'SMA',
  EXTERNAL: 'external',
  MODEL: 'MODEL',
  ALL: 'all',
};
export const EDGE_OPTION_LABEL = {
  SMA: 'SMA',
  EXTERNAL: 'External',
  MODEL: 'Model',
  ALL: 'All Chart',
};

export const LEADERSHIP_ROW_NAME = [
  {
    name: 'ENERGISE',
    color: '#6AC2BE',
    childColor: '#BDE7E5',
    childBackground: '#F4FBFB',
    childNames: ['Interpersonal Effectiveness', 'Foster Collaboration & Teamwork'],
  },
  {
    name: 'DECIDE',
    color: '#D4C0E4',
    childColor: '#D4C0E4',
    childBackground: '#F4F0F8',
    childNames: ['Analysis & Problem Solving', 'Set Goals & Drive Directions'],
  },
  {
    name: 'GROW',
    color: '#3685D3',
    childColor: '#BEE2FE',
    childBackground: '#F0F9FF',
    childNames: ['Lead Change & Innovation', 'Commitment to Learning & Development'],
  },
  {
    name: 'EXECUTE',
    color: '#68B172',
    childColor: '#C1DCC4',
    childBackground: '#F0F8F0',
    childNames: ['Deliver Performance', 'Professionalism & Expertise'],
  },
];

export const LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR = {
  CURRENT: '#FBE2E2',
  NEXT: '#FEF2DC',
};

export const LEADERSHIP_DESIRE_ORDER = [
  'Energise: Interpersonal Effectiveness',
  'Energise: Foster Collaboration & Teamwork',
  'Decide: Analysis & Problem Solving',
  'Decide: Set Goals & Drive Directions',
  'Grow: Lead Change & Innovation',
  'Grow: Commitment to Learning & Development',
  'Execute: Deliver Performance',
  'Execute: Professionalism & Expertise',
];

export const LEADERSHIP_DOT_COLOR = {
  sma: '#6AC2BE',
  model: '#FFA958',
  external: '#BFA4D6',
};

export const BEPCB = {
  EFFECTIVE: 'Effective',
  DEVELOPING: 'Developing',
  NEEDS_DEVELOPMENT: 'Needs Development',
  DISTINCTIVE: 'Distinctive',
  EXCEPTIONAL: 'Exceptional',
  PROFICIENT: 'Proficient',
  ROLE_MODEL: 'Role Model',
};

export const MAX_INTEGER = 2147483647;

export const END_GROUP_ROW_CLASS_NAME = 'groupRow';

export const PERMISSION = {
  NO_SPECIFIC: 0,
  VIEW_TALENT_PROFILE: 6,
  EDIT_TALENT_PROFILE: 3,
  VIEW_TALENT_DASHBOARD: 5,
  SEARCH_TALENT_PROFILE: 2,
  SEARCH_USER_BY_EMAIL: 4,
  ADD_TALENT_COMPARISION: 9,
  LINK_TO_TALENT_COMPARISION: 11,
  SEARCH_VIEW_SP_DASHBOARD: 12,
  CREATE_MEETING: 13,
  TALENT_FINDER: 14,
  TALENT_EXPORT: 31,
  // MOBILITY_SIMULATION: 32,
  GENERATE_MOM_FILE: 5,
  VIEW_EDIT_PRIVACY_NOTES: 33,
  MANAGING_CRITICAL_POSITION: 37,
  MANAGING_CRITERIA_FOR_CRITICAL_POSITION: 36,
  COPY_SUCCESSION_PLANNING_TO_OTHER_POSITION: 38,
  AD_USER_ACCESS_MANAGEMENT: 39,
  AD_PROFILE_MANAGEMENT: 40,
  AD_MASTER_DATA_MANAGEMENT: 41,
  AD_ROLE_MANAGEMENT: 42,
  AD_AUDIT_LOGS: 43,
  TP_EDIT_NON_PRIVATE_NOTE: 44,
  TP_EDIT_OTHER_EXPERIENCE_TAGGING: 46,
  TP_EDIT_VERBATIM_FEEDBACK_TAGGING: 47,
  TP_EDIT_EXPERIENCE: 48,
  TP_HIDE_UNHIDE_TALENT_PROFILE: 49,
  TP_EDIT_TALENT_REVIEW: 50,
  TP_EDIT_DEVELOPMENT_PLAN: 51,
  TP_EDIT_MOBILITY_PLAN: 52,
  TP_EDIT_ASSESSMENT_DATA: 53,
  TP_EDIT_HISTORICAL_TALENT_STATUS: 54,
  TP_ADD_DOCUMENT: 55,
  TP_DELETE_DOCUMENT: 56,
  ADD_INITIAL_POSITION: 57,
  VIEW_RECOMMENDED_REPLACEMENT: 58,
  ADD_TALENT_MANUALLY: 59,
  EDIT_APPROVED_SUCCESSION_PLANNING: 35,
  SP_READINESS_RULE: 60,
  AUDIT_LOG_AI_PIPELINE: 43,
  REPORT_BUILDER: 61,
  EDIT_ASPIRATION_MATCHING: 62,
  ADD_COMPLEMENTARY_EXPERIENCE: 65,
  EDIT_COMPLEMENTARY_EXPERIENCE: 65,
  VIEW_EXTERNAL_ASSESSMENT: 64,
  PDC_CREATE_MEETING: 66,
};

export const ACCESS_LEVEL = [
  {
    id: 1,
    name: 'PETRONAS',
    category: ['PETRONAS'],
    isDelete: true,
  },
  {
    id: 2,
    name: 'Business',
    category: ['Business', 'OPU', 'Division', 'Sector'],
    isDelete: true,
  },
  {
    id: 3,
    name: 'OPU',
    category: ['Business', 'OPU', 'Division', 'Sector'],
    isDelete: true,
  },
  {
    id: 4,
    name: 'Division',
    category: ['Business', 'OPU', 'Division', 'Sector'],
    isDelete: true,
  },
  {
    id: 5,
    name: 'Sector',
    category: ['Business', 'OPU', 'Division', 'Sector'],
    isDelete: true,
  },
  {
    id: 6,
    name: 'SKG',
    category: ['SKG'],
    isDelete: true,
  },
  {
    id: 7,
    name: 'Job Family',
    category: ['Job Family'],
    isDelete: true,
  },
];
export const ROLE = {
  HR_BP: 'HR BP',
  HR_BP_SECRETARIAT: 'HR BP - Secretariat',
  HR_COE: 'HR COE',
  HR_BP_LEADERS: 'HR BP Leaders',
  ADMIN: 'Admin',
};

export const ROLE_ON_PRODUCT = {
  HR_COE: 7,
  HR_BUSINESS: 6,
};
export const BUSINESS_NAME = [
  { name: ['UPSTREAM', 'Upstream'], symbol: 'US', color: '#308196' },
  { name: ['DOWNSTREAM', 'Downstream'], symbol: 'DS', color: '#FCAA1B' },
  { name: ['CORPORATE', 'Corporate'], symbol: 'COR', color: '#625E70' },
  { name: 'GNE', symbol: 'GNE', color: '#183288' },
  { name: 'PETRONAS Gas', symbol: 'PET', color: '#00A19C' },
  { name: 'Project Delivery & Technology', symbol: 'PD&T', color: '#B1CD24' },
  { name: ['Gas & New Energy', 'GAS'], symbol: 'GNE', color: '#183288' },
  { name: 'Others', symbol: 'OT', color: '#504689' },
];

export const BUSINESS_NAME_TALENT_REVIEW = [
  { name: 'Upstream', symbol: 'US', color: '#308196' },
  { name: 'Downstream', symbol: 'DS', color: '#FCAA1B' },
  { name: 'Corporate', symbol: 'CORP', color: '#625E70' },
  { name: 'Legal', symbol: 'LGL', color: '#8BC5F5' },
  { name: 'Gas & New Energy', symbol: 'GNE', color: '#183288' },
  { name: 'Project Delivery & Technology', symbol: 'PD&T', color: '#B1CD24' },
  { name: 'PD&T', symbol: 'PD&T', color: '#B1CD24' },
  { name: 'PETRONAS', symbol: 'PET', color: '#00A19C' },
  { name: 'Others', symbol: 'OTH', color: '#566879' },
  { name: 'Finance', symbol: 'FIN', color: '#90A0CD' },
  { name: 'Group HRM', symbol: 'HR', color: '#504689' },
  { name: 'GAS', symbol: 'GAS', color: '#72CB96' },
  { name: 'New Energy', symbol: 'NE', color: '#BB9FCC' },
  { name: 'Holding Company', symbol: 'CORP', color: '#625E70' },
  { name: 'PETRONAS Gas', symbol: 'GAS', color: '#72CB96' },
  { name: 'Upstream Business', symbol: 'US', color: '#308196' },
  { name: 'Downstream Business', symbol: 'DS', color: '#FCAA1B' },
  { name: 'HR', symbol: 'HR', color: '#504689' },
  { name: 'HC', symbol: 'CORP', color: '#625E70' },
  { name: 'HCU', symbol: 'CORP', color: '#625E70' },
];

export const EDGE_TYPE = {
  INDIVIDUAL: 'Individual',
  ALL: 'All',
};

export const BUSINESS_NAME_TIME_LINE = {
  UPSTREAM: 'Upstream',
  DOWNSTREAM: 'Downstream',
  CORPORATE: 'Corporate',
  GAS_NEW_ENERGY: 'Gas & New Energy',
  PDT: 'Project Delivery & Technology',
  NEW_Energy: 'New Energy',
  HR: 'Group HRM',
  PETRONAS_GAS: 'PETRONAS Gas',
  HOLDING_COMPANY: 'Holding Company',
  OTHERS: 'Others',
  GAS: 'GAS',
  HC: 'HC',
  HCU: 'HCU',
  FIN: 'Finance',
};
export const BUSINESS_SHORT_NAME = {
  UPSTREAM: 'US',
  DOWNSTREAM: 'DS',
  CORPORATE: 'Corp',
  GAS_NEW_ENERGY: 'GNE',
  PDT: 'PD&T',
  NEW_Energy: 'NE',
  HR: 'HR',
  PETRONAS_GAS: 'GAS',
  HOLDING_COMPANY: 'HCU',
  GAS: 'GAS',
};

export const ROLE_FULLNAME = [
  { roleName: 'EVP/VP', roleFullName: 'PETRONAS Leaders' },
  { roleName: 'SGM', roleFullName: 'Business Leaders (SGM)' },
  { roleName: 'GM', roleFullName: 'Business Leaders (GM)' },
  { roleName: 'SM', roleFullName: 'Business Leaders (SM)' },
  { roleName: 'HR BP', roleFullName: 'HR Business' },
  { roleName: 'HR BP Leaders', roleFullName: 'HR Business Leaders' },
  { roleName: 'HR BP - Secretariat', roleFullName: 'SKG Secretariat' },
  { roleName: 'HR COE', roleFullName: 'HR COE' },
];
export const ROLE_SG_MAPPING = {
  EXECUTIVE: 'Executive',
  GENERAL_MANAGER: 'General Manager',
  MANAGER: 'Manager',
  SENIOR_GENERAL_MANAGER: 'Senior General Manager',
  SENIOR_MANAGER: 'Senior Manager',
  VICE_PRESIDENT: 'Vice President',
};
export const ROLE_SHORT_NAME = {
  EXECUTIVE: 'Exec',
  ROLE_EXECUTIVE: 'Executive',
  GENERAL_MANAGER: 'GM',
  MANAGER: 'Mgr',
  ROLE_MANAGER: 'M',
  SENIOR_GENERAL_MANAGER: 'SGM',
  SENIOR_MANAGER: 'SM',
  VICE_PRESIDENT: 'VP',
};
export const ROLE_SP = {
  VP: ['VP', 'Vice President'],
  SGM: ['SGM', 'Senior General Manager'],
  GM: ['GM', 'General Manager'],
};
export const NOTIFICATION_TYPE = {
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
};

export const USER_ACCESS_LEVEL = {
  OTHER_OPU_OR_DIVISION: 'Other OPU/Div',
  OWN_OPU_OR_DIVISION: 'Own OPU/Div',
  OWN_BU: 'Own Business',
  OTHER_BU: 'Other Business',
  OTHER_JOB_FAMILY: 'Other Job Family',
  OWN_JOB_FAMILY: 'Own Job Family',
};

export const USER_ACCESS_MESSAGE = {
  USER_EXIST_SYSTEM: 'User has already been registered in myTalentX.',
  AN_OCCUR_ERROR: 'An occur error',
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
};

export const BUSINESS_ACCESS = {
  JOB_FAMILY: 'job family',
};
export const ROLE_PERMISSIONS = {
  VIEW_TALENT_PROFILE: 'VIEW_TALENT_PROFILE',
  EDIT_TALENT_PROFILE: 'EDIT_TALENT_PROFILE',
  ADD_TALENT_COMPARISION: 'ADD_TALENT_COMPARISION',
  TP_HIDE_UNHIDE_TALENT_PROFILE: 'TP_HIDE_UNHIDE_TALENT_PROFILE',
  LINK_TO_TALENT_COMPARISION: 'LINK_TO_TALENT_COMPARISION',
  SEARCH_VIEW_SP_DASHBOARD: 'SEARCH_VIEW_SP_DASHBOARD',
};

export const MEETING_DETAIL_STATUS = {
  IN_PLANNING: 'In-Planning',
  IN_PROGRESS: 'In Progress',
  IN_REVIEW: 'In-Review',
  PENDING_APPROVAL: 'Pending Approval',
  MOM_REJECTED: 'MOM Rejected',
  CANCELED: 'Cancelled',
  MEETING_COMPLETED: 'Meeting Completed',
  PUBLISHED: 'Published',
  CONCLUDED: 'Meeting Concluded',
};

export const MEETING_MESSAGE = {
  WARNING_EXISTS_PAPER_READY: 'Please ensure all paper is finalized to proceed to the next step',
  WARNING_MODE_CURRENTLY_IS_EDITTING: `Please complete your edit by clicking 'Done Edit Meeting' button before you can proceed to complete the meeting`,
};
export const MEETING_STATUS = [
  { status: 'In-Planning', color: '#F09900' },
  { status: 'In Progress', color: '#F09900' },
  { status: 'In-Review', color: '#F09900' },
  { status: 'Pending Approval', color: '#F09900' },
  { status: 'MOM Rejected', color: '#DA2228' },
  { status: 'Cancelled', color: '#DA2228' },
  { status: 'Published', color: '#0B882E' },
  { status: 'Meeting Completed', color: '#0B882E' },
  { status: 'Meeting Concluded', color: '#0B882E' },
];

export const MEETING_ROLE_TYPES = {
  FACILITATOR: 'facilitator',
  SECRETARIAT: 'secretariat',
};

export const SHOW_REMARK_ICON_STATUS = ['In Progress', 'In-Review', 'MOM Rejected'];

export const CALENDAR = {
  DATE_FILTER_TYPE: {
    RANGE: 'range',
    DATE: 'date',
  },

  MEETING_TYPE: [
    { display: 'All', param: 'all' },
    { display: 'Scheduled Meeting', param: 'scheduled' },
    { display: 'On-Going Meeting', param: 'on going' },
    { display: 'Pending Approval', param: 'pending approval' },
    { display: 'Approved Meeting', param: 'approved' },
  ],

  PDC_MEETING_TYPE: [
    { display: 'All', param: 'all' },
    { display: 'In-Planning Meeting', param: 'In-Planning' },
    { display: 'In-Progress Meeting', param: 'In Progress' },
    { display: 'In-Review Meeting', param: 'In-Review' },
    { display: 'Meeting Concluded', param: 'Meeting Concluded' },
  ],

  MEETING_CATEGORY: ['PETRONAS', 'Business', 'OPU', 'Division', 'Sector', 'SKG', 'Job Family'],

  BU_TYPE: {
    OPU: 'OPU',
    DIVISION: 'Division',
    BUSINESS: 'Business',
    JOB_FAMILY: 'Job Family',
  },

  MEETING_STATUS: {
    IN_PLANNING: 'In-Planning',
    CANCELLED: 'Cancelled',
    PUBLISHED: 'Published',
    IN_PROGRESS: 'In Progress',
    IN_REVIEW: 'In-Review',
    PENDING_APPROVAL: 'Pending Approval',
    MOM_REJECTED: 'MOM Rejected',
    COMPLETED: 'Completed',
    CONCLUDED: 'Meeting Concluded',
  },
};

export const NOTIFICATION_MEETING_TYPE = {
  SUCCESSION_PLANNING_SYNDICATION: 'Succession Planning Syndication',
  MEETING_RE_INVITATION: 'Meeting Re-Invitation',
  PDC_MEETING_RE_INVITATION: 'PDC Meeting Re-Invitation',
  MEETING_CANCELLED: 'Meeting Cancelled',
  PDC_MEETING_CANCELLED: 'PDC Meeting Cancelled',
  MEETING_INVITATION: 'Meeting Invitation',
  PDC_MEETING_INVITATION: 'PDC Meeting Invitation',
  TALENT_FINDER_EXPORT: 'Talent Finder Export',
};

export const AGENDA_STATUS = {
  PAPER_BRING: 'Paper bring to forward',
  PAPER_READY: 'Paper Ready',
  PAPER_IN_PROGRESS: 'Paper in Progress',
  PAPER_FINALIZED: 'Paper Finalized',
};

export const AGENDA_TYPES = {
  SP_AGENDA: 'succession_planning_agenda',
  TR_AGENDA: 'talent_review_agenda',
  OTHERS_AGENDA: 'others_agenda',
};

export const AGENDA_SHORT_TYPES = {
  SUCCESSION_PLANNING: 'SP',
  MOBILITY_SIMULATION: 'MS',
  TALENT_REVIEW: 'TR',
  OTHERS: 'OT',
};

export const ROUTE_BACK_HISTORY = {
  MEETING_AGENDA_STEP2: 'meeting-step2',
  MEETING_DETAIL: 'meeting-detail',
  SP_DASHBOARD: 'sp-dashboard',
};
export const DASHBOARD_TAB = ['Succession Planning Overview', 'Talent Overview'];

export const DASHBOARD_FILTER_ACTIONS = {
  DASHBOARD_FILTERED: 'dashboardFiltered',
};

export const LOCAL_STORAGE = {
  IS_LOGIN: 'isAfterLogin',
};

export const DASHBOARD_GET_TYPE = ['Procurement', 'Human Resource Management', 'Finance'];

const DUE_TO_ATTRITION =
  'Succession planning of the position which incumbent of the position will retire/end of contract within end of the next calendar year AND Succession planning of the position has not been reviewed for more than 1 year';
const NOT_REVIEWED_UP_3_YEARS = 'Succession planning of the position that has not been reviewed for more than 3 years';
const NEW_POSITION = 'Succession planning of the position that has not been conducted';
const POSITION_RATIO_DOWN_3 = 'Succession planning in of the position that has less than 3 first line successor';

export const EKP_TOTAL_ROLE = [
  { role: 'Vice President', number: 0, type: 'VP' },
  { role: 'Senior General Manager', number: 0, type: 'SGM' },
  { role: 'General Manager', number: 0, type: 'GM' },
];

export const BKP_TOTAL_ROLE = [
  { role: 'Senior General Manager', number: 0, type: 'SGM' },
  { role: 'General Manager', number: 0, type: 'GM' },
  { role: 'Senior Manager', number: 0, type: 'SM' },
];

export const SG_ROLE_LEVEL = {
  'General Manager': 'GM',
  Executive: 'Exec',
  Manager: 'Mgr',
  'Senior General Manager': 'SGM',
  'Senior Manager': 'SM',
  'Vice President': 'VP',
};

export const DASHBOARD_TOBE_REVIEW = [
  {
    id: 1,
    label: 'Due to attrition',
    total: 0,
    totalRoles: [],
    toolTip: DUE_TO_ATTRITION,
    keyApi: 'attrition',
    query: 'attrition',
  },
  {
    id: 2,
    label: 'Not reviewed > 3 years',
    total: 0,
    totalRoles: [],
    toolTip: NOT_REVIEWED_UP_3_YEARS,
    keyApi: 'not_review',
    query: 'not-review',
  },
  {
    id: 3,
    label: 'New position',
    total: 0,
    totalRoles: [],
    toolTip: NEW_POSITION,
    keyApi: 'new_position',
    query: 'conducted',
  },
  {
    id: 4,
    label: 'Position ratio < 3.0',
    total: 0,
    totalRoles: [],
    toolTip: POSITION_RATIO_DOWN_3,
    keyApi: 'position_ratio',
    query: 'successor',
  },
];

const CCP_VP_RATIO = 'Ratio = Total of  Unique Successors ÷ Vice President positions';
const CCP_SGM_RATIO = 'Ratio = Total of Unique Successors  ÷ Senior General Manager positions';
const CCP_GM_RATIO = 'Ratio = Total of Unique Successors  ÷ General Manager positions';
const BCP_SGM_RATIO = 'Ratio = Total of Unique Successors  ÷ Senior General Manager positions';
const BCP_GM_RATIO = 'Ratio = Total of Unique Successors  ÷ General Manager positions';
const BCP_SM_RATIO = 'Ratio = Total of Unique Successors  ÷ Senior  Manager positions';

export const EKP_SPS = [
  {
    roleName: 'Overall',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'CCP',
  },
  {
    roleName: 'Vice President',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'VP',
    tooltipRatio: CCP_VP_RATIO,
  },
  {
    roleName: 'Senior General Manager',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'SGM',
    tooltipRatio: CCP_SGM_RATIO,
  },
  {
    roleName: 'General Manager',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'GM',
    tooltipRatio: CCP_GM_RATIO,
  },
];

export const BKP_SPS = [
  {
    roleName: 'Overall',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'BCP',
  },
  {
    roleName: 'Senior General Manager',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'SGM',
    tooltipRatio: BCP_SGM_RATIO,
  },
  {
    roleName: 'General Manager',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'GM',
    tooltipRatio: BCP_GM_RATIO,
  },
  {
    roleName: 'Senior Manager',
    ratio: 0,
    positionDeliberated: 0,
    keyPosition: 0,
    type: 'SM',
    tooltipRatio: BCP_SM_RATIO,
  },
];

export const TYPE_TALENT = {
  NON_SUCCESSORS: 1,
  NOT_REVIEW_MORE_THAN_FIVE_YEARS: 2,
  NEW_TOP_TALENT: 3,
  ALL: 'all',
};

export const MEETING_CATEGORY = {
  BUSINESS: 'Business',
  PETRONAS: 'PETRONAS',
};

export const POSITION_LABEL = {
  CCP: 'EKP',
  BCP: 'BKP',
};

export const FILTER_TITLE = {
  DEMOGRAPHIC: 'Demography',
  EXPERIENCE: 'Experience',
  LEADERSHIP: 'Leadership',
  EMPLOYMENT_INFO: 'Employment Info',
  SUCCESSION_AND_TOP_TALENT_STATUS: 'Succession & Top Talent Status',
  EXTERNAL_ASSESSMENT: 'External Assessment',
};
export const DEMOGRAPHIC_FILTERS = [
  { value: 'age', label: 'Age' },
  { value: 'gender', label: 'Gender' },
  { value: 'nationality', label: 'Nationality' },
  { value: 'state_of_birth', label: 'State of Birth' },
  { value: 'business_experience', label: 'Current Business' },
  { value: 'functional_experience', label: 'Current Functional' },
];
export const EXPERIENCE_FILTERS = [
  {
    value: 'previous_business_experience',
    label: 'Business Experience',
  },
  {
    value: 'previous_functional_experience',
    label: 'Functional Experience',
  },
  { value: 'complementary_experience', label: 'Complementary Experience' },
];

export const LEADERSHIP_FILTERS = [
  { value: 'attr27', label: 'Attributes' },
  { value: 'personality', label: 'Personality' },
  { value: 'edge_model', label: 'EDGE (Model)' },
  { value: 'edge', label: 'EDGE (SMA)' },
  { value: 'ext_edge', label: 'EDGE (External Assessment)' },
];

export const EMPLOYMENT_INFO_FILTERS = [
  { value: 'salary_grade', label: 'Salary Grade' },
  { value: 'years_to_retire', label: 'Years to Retire' },
  { value: 'years_in_position', label: 'Years in Position' },
];

export const SUCCESSION_AND_TOP_TALENT_STATUS_FILTERS = [
  { value: 'successor', label: 'Successor' },
  { value: 'top_talent_status', label: 'Top Talent Status' },
];

export const EXTERNAL_ASSESSMENT_FILTERS = [
  { value: 'learning_agility', label: 'Learning Agility' },
  { value: 'traits', label: 'Traits' },
  { value: 'competencies', label: 'Competencies' },
  { value: 'drivers', label: 'Drivers' },
  { value: 'derailment_risks', label: 'Derailment Risks' },
];

export const TALENT_FINDER_FILTER_OPTIONS = {
  TOP_TALENT_STATUS: 'top_talent_status',
  BUSINESS: 'business',
  SALARY_GRADE: 'salary_grade',
  SUCCESSOR: 'successor',
  PERSONALITY: 'personality',
  AGE: 'age',
  BUSINESS_EXPERIENCE: 'business_experience',
  PREVIOUS_BUSINESS_EXPERIENCE: 'previous_business_experience',
  YEARS_IN_POSITION: 'years_in_position',
  NATIONALITY: 'nationality',
  STATE_OF_BIRTH: 'state_of_birth',
  YEARS_TO_RETIRE: 'years_to_retire',
  GENDER: 'gender',
  FUNCTIONAL_EXPERIENCE: 'functional_experience',
  PREVIOUS_FUNCTIONAL_EXPERIENCE: 'previous_functional_experience',
  EDGE: 'edge',
  EDGE_MODEL: 'edge_model',
  EXT_EDGE: 'ext_edge',
  TRAITS: 'traits',
  DERAILMENT_RISKS: 'derailment_risks',
  LEARNING_AGILITY: 'learning_agility',
  DRIVERS: 'drivers',
  COMPLEMENTARY_EXPERIENCE: 'complementary_experience',
  OTHER_EXPERIENCE: 'other_experience',
  ATTRIBUTES: 'attr27',
  COMPETENCIES: 'competencies',
};

export const TALENT_FILTERS = [
  { value: 'top_talent_status', label: 'Top Talent Status' },
  { value: 'salary_grade', label: 'Salary Grade' },
  { value: 'successor', label: 'Successor' },
  { value: 'personality', label: 'Personality' },
  { value: 'age', label: 'Age' },
  { value: 'attr27', label: 'Attributes' },
  { value: 'years_in_position', label: 'Years in Position' },
  { value: 'nationality', label: 'Nationality' },
  { value: 'state_of_birth', label: 'State of Birth' },
  { value: 'years_to_retire', label: 'Years to Retire' },
  { value: 'gender', label: 'Gender' },
  { value: 'edge', label: 'EDGE' },
  {
    value: 'previous_business_experience',
    label: 'Previous Business Experience',
  },
  { value: 'business_experience', label: 'Current Business Experience' },
  { value: 'other_experience', label: 'Other Experience' },
  {
    value: 'previous_functional_experience',
    label: 'Previous Functional Experience',
  },
  { value: 'functional_experience', label: 'Current Functional Experience' },
];

export const TALENT_FILTER_OPTIONS = {
  TOP_TALENT_STATUS: 'top_talent_status',
  BUSINESS: 'business',
  SALARY_GRADE: 'salary_grade',
  SUCCESSOR: 'successor',
  PERSONALITY: 'personality',
  AGE: 'age',
  BUSINESS_EXPERIENCE: 'business_experience',
  PREVIOUS_BUSINESS_EXPERIENCE: 'previous_business_experience',
  YEARS_IN_POSITION: 'years_in_position',
  NATIONALITY: 'nationality',
  STATE_OF_BIRTH: 'state_of_birth',
  YEARS_TO_RETIRE: 'years_to_retire',
  GENDER: 'gender',
  FUNCTIONAL_EXPERIENCE: 'functional_experience',
  PREVIOUS_FUNCTIONAL_EXPERIENCE: 'previous_functional_experience',
  EDGE: 'edge',
  OTHER_EXPERIENCE: 'other_experience',
  ATTRIBUTES: 'attr27',
};

export const JOB_GRADE_VALUES = {
  TM: 'TM',
  VP: 'VP',
};

export const SELECT_ALL_LABEL = '(Select All)';

export const CURRENT_YEAR = '4712';

export const ACTION_LOG = {
  ACCESS: 'ACCESS',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  TIMEOUT: 'TIMEOUT',
};

export const REMARK_TYPES = {
  GENERAL: 'General',
  TALENT: 'Talent',
};

export const FORM_TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const AUDIT_LOG_FILTERS = [
  {
    value: 'sp_management_filter',
    label: 'Module',
    placeholder: 'Select Module',
  },
  { value: 'action_filter', label: 'Action', placeholder: 'Select Action' },
  { value: 'user_filter_type', label: 'Type', placeholder: 'Filter Type' },
];

export const AUDIT_LOG_FILTER_OPTIONS = {
  SP_MANAGEMENT_FILTER: 'sp_management_filter',
  ACTION: 'action_filter',
  USER_FILTER_TYPE: 'user_filter_type',
};

export const AUDIT_LOG_MODULE = {
  SP_MANAGEMENT: {
    MANAGING_CRITICAL_POSITION: 'Managing Critical Position',
    EDIT_APPROVED_SUCCESSION_PLANNING: ' Edit Approved Succession Planning',
    SP_READINESS_RULE: 'SP Readiness Rule',
  },
  UNSEARCHABLE_POSITION: 'Unsearchable Position List',

  UNSEARCHABLE_TALENT: 'Unsearchable Talent List',
  USER_ACCESS_MANAGEMENT: 'User Access Management',
};

export const AUDIT_LOG_VALUES = {
  position_code: 'Position Code',
  position_name: 'Position Name',
  plan_name: 'Plan Name',
  business_unit: 'Business Unit',
  position_label: 'Position Label',
  group_code: 'Group Code',
  platform: 'Platform',
  approved_date: 'Approved Date',
  included_in_ratio: 'Included in Ratio',
  candidate_id: 'Candidate ID',
  candidate_name: 'Candidate Name',
  readiness: 'Readiness',
  ranking: 'Ranking',
  active_approved_successor: 'Active Approved Successor',
  talent_review_date: 'Talent Review Date',
  role: 'Role',
  business: 'Business',
  // Role Level List
  role_level: 'Role Level',
  role_level_sp: 'Role Level for Succession Planning',
  // SG/JG Mapping
  sg_in_oracle: 'SG in Oracle',
  jg_in_oracle: 'JG in Oracle',
  jg_in_p_series: 'JG in P Series',
  // Mapping for Bu/Opu/Division
  field_in_oracle: 'Data Field in Oracle',
  data_in_oracle: 'Data Content in Oracle',
  // Top talent status
  staff_id: 'Staff ID',
  function: 'Function',
  birth_name: 'Staff Name',
  from_date: 'From Date',
  to_date: 'To Date',
  assessment_type: 'Assessment Type',
  assessment_date: 'Assessment Date',
  approval_platform: 'Approval Platform',
  assessment_result: 'Assessment Result',
  cluster: 'Cluster',
  file_name: 'Document',
  // List of Profiles
  staff_name: 'Staff Name',
  // Managing Criteria
  criteria: 'Criteria',
  spike: 'Spike',
  content: 'Content',
  spike_value: 'Spike Value',
  spike_ranking: 'Spike Ranking',
  // Managing Meeting
  name: 'Meeting Name',
  start_at: 'Meeting Date',
  meeting_id: 'Meeting ID',
  // SP Readiness Rule
  job_family: 'Job Family',
  sub_job_family: 'Sub Job Family',
  role_code1: 'Role',
  position_jg_min1: 'Position JG Min',
  position_jg_max1: 'Position JG Max',
  talent_sg_min1: 'Talent SG Min',
  talent_sg_max1: 'Talent SG Max',
  matching_percentage_cut_off1: 'Matching Percentage Cut off',
  min_years_to_retirement1: 'Minimum years to Retirement',
  remove_contract1: 'Remove Contract',
  position_jg_min2: 'Position JG Min',
  position_jg_max2: 'Position JG Max',
  talent_sg_min2: 'Talent SG Min',
  talent_sg_max2: 'Talent SG Max',
  matching_percentage_cut_off2: 'Matching Percentage Cut off',
  min_years_to_retirement2: 'Minimum years to Retirement',
  remove_contract2: 'Remove Contract',
  position_jg_min3: 'Position JG Min',
  position_jg_max3: 'Position JG Max',
  talent_sg_min3: 'Talent SG Min',
  talent_sg_max3: 'Talent SG Max',
  matching_percentage_cut_off3: 'Matching Percentage Cut off',
  min_years_to_retirement3: 'Minimum years to Retirement',
  remove_contract3: 'Remove Contract',
  readiness1: 'Readiness',
  readiness2: 'Readiness',
  readiness3: 'Readiness',
  unsearchable_position_name: 'Position Name',
  // Note of Talent Review
  date: 'Date',
  remarks: 'Remarks',
  notes: 'Notes',
  document: 'Document',
  //Other Experience
  current_tag: 'Current Tag',
  sentences: 'Sentences',
  // Historical Talent Status in Talent Profile
  status: 'Status',
  start_date: 'Start Date',
  end_date: 'End Date',
  //Verbatim Feedback
  edge: 'Edge',
  attr27: 'Attribute',
  hide_flag: 'Hide',
  //Development Plan
  development_plan: 'Development Plan',
  //Mobility Plan
  mobility_plan_type: 'Mobility Plan',
  platform_date: 'Date',
  mobility_plan_detail: 'Detail',
  // Assessment data in Talent Profile
  recommendeation_result: 'Recommendation Result',
  // Role management
  role_name: 'Role Name',
  permissions: 'Permissions',
  role_access_level: 'Role Access Level',
  access_level: 'Access Level',
  //User Access Management
  email: 'Email',
  fullname: 'Full Name',
  business_access: 'Business Access',
  // Top talent category
  category: 'Category',
};

export const AUDIT_LOG_KEY_REPLACE = {
  VERBATIM_FEEDBACK_HIDE: 'hide_flag',
};

export const AUDIT_LOG_REPLACE_VALUE = {
  VERBATIM_FEEDBACK_HIDE: 'Yes',
  VERBATIM_FEEDBACK_UNHIDE: 'No',
};
export const AUDIT_LOG_DIFFERENCE_VALUES = {
  CONTENT: 'content',
  SPIKE_VALUE: 'spike_value',
  ROLE_ACCESS_LEVEL: 'role_access_level',
};

export const AUDIT_LOG_USER_ACCESS_VALUES = {
  ACCESS_LEVELS: 'access_levels',
};

export const CRITERIA_TYPE = {
  EDGE: 'EDGE (Model)',
  SMA_RESULT: 'EDGE (SMA)',
  ATTRIBUTES: 'Attributes',
  PERFORMANCE_RATING: 'Performance Rating',
  SUCCESSOR_TO_VP_POSITION: 'Successor to VP Position',
  SUCCESSOR_TO_SGM_POSITION: 'Successor to SGM Position',
  PERSONALITY_ARCHETYPE: 'Personality Archetype (Mandatory)',
  PERSONALITY_ARCHETYPE_OPTIONAL: 'Personality Archetype (Optional)',

  FUNCTIONAL: 'Functional (Mandatory)',
  FUNCTIONAL_OPTIONAL: 'Functional (Optional)',
  FUNCTIONAL_SPIKE: 'Functional (Spike)',
  // TOP_TALENT_EXPERIENCE: 'Top Talent Experience (Mandatory)',
  // TOP_TALENT_EXPERIENCE_OPTIONAL: 'Top Talent Experience (Optional)',
  BUSINESS_EXPOSURE: 'Business Exposure (Mandatory)',
  BUSINESS_EXPOSURE_OPTIONAL: 'Business Exposure (Optional)',
  EXTERNAL_RESULT: 'External Result (Mandatory)',
  EXTERNAL_RESULT_OPTIONAL: 'External Result (Optional)',
  NATIANALITY_LOCALITY_REQUIREMENT: 'Nationality / Locality Requirement',
};

export const CRITERIA_SMA_EDGE_TYPE = `SMA EDGE`;

export const CRITERIA_SPIKE_TYPE = {
  EDGE_SPIKE: 'EDGE (Spike)',
  ATTRIBUTES_SPIKE: 'Attributes (Spike)',
  // FUNCTIONAL_SPIKE: 'Functional (Spike)',
  BUSINESS_EXPOSURE_SPIKE: 'Business Exposure (Spike)',
};

export const CRITERIA_COMPLEMENTARY_TYPE = {
  FUNCTIONAL_MANDATORY: 'Functional Mandatory (Complementary)',
  FUNCTIONAL_OPTIONAL: 'Functional Optional (Complementary)',
  FUNCTIONAL_SPIKE: 'Functional Spike (Complementary)',
};

export const CRITERIA_NO_RANKING = [
  CRITERIA_TYPE.FUNCTIONAL,
  CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_MANDATORY,
  CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_OPTIONAL,
  CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_SPIKE,
  CRITERIA_TYPE.NATIANALITY_LOCALITY_REQUIREMENT,
];

export const CRITERIA_MANDATORY_TYPE = [
  CRITERIA_TYPE.PERSONALITY_ARCHETYPE,
  CRITERIA_TYPE.FUNCTIONAL,
  // CRITERIA_TYPE.FUNCTIONAL_SPIKE,
  // CRITERIA_TYPE.TOP_TALENT_EXPERIENCE,
  CRITERIA_TYPE.BUSINESS_EXPOSURE,
  CRITERIA_TYPE.EXTERNAL_RESULT,
  CRITERIA_TYPE.NATIANALITY_LOCALITY_REQUIREMENT,
];

export const CRITERIA_BOOLEAN_TYPE = [
  CRITERIA_TYPE.SUCCESSOR_TO_VP_POSITION,
  CRITERIA_TYPE.SUCCESSOR_TO_SGM_POSITION,
  CRITERIA_TYPE.PERFORMANCE_RATING,
];

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const NUMBER_OPTIONS = [1, 2, 3, 4, 5];

export const DEVELOPMENT_PLAN_OPTIONS = [
  { label: 'Job Assignment', value: 'Job Assignment' },
  { label: 'Learning', value: 'Learning' },
];

export const FORM_MODE_DEVELOPMENT_PLAN = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const DEVELOPMENT_PLAN_MODE = {
  CREATE: 'CREATE',
  EDIT_DRAFT: 'EDIT_DRAFT',
  EDIT: 'EDIT',
};
export const FILL_OUT_REQUIRE_MESSAGE = 'There are items that require your attention. Please fill out this field.';

export const AN_UNEXPECTED_ERROR = 'An unexpected error occurred.';

export const SAVE_AGENDA_AS_DRAFT_MESSAGE = 'Agenda has been saved as draft.';

export const ALERT_MESSAGE_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  NORMAL: 'normal',
  WARNING: 'warning',
};

export const USER_ACTIVITY_TYPE = {
  user_filter_type: [
    {
      label: 'Access',
      value: 'ACCESS',
      checked: false,
      disabled: false,
    },
    {
      label: 'Login',
      value: 'LOGIN',
      checked: false,
      disabled: false,
    },
    {
      label: 'Logout',
      value: 'LOGOUT',
      checked: false,
      disabled: false,
    },
  ],
};

export const AUDIT_LOG_TABS = ['Audit Log', 'User Activity Log', 'Daily AI Run'];

export const TALENT_REVIEW_BUSINESS_COLOR = {
  Upstream: '#308196',
  Downstream: '#fcaa1b',
  Corporate: '#625e70',
  'Gas & New Energy': '#183288',
  'Project Delivery & Technology': '#b1cd24',
  'PETRONAS Gas': '#72cb96',
  Finance: '#90a0cd',
  'Group HRM': '#504689',
  'New Energy': '#bb9fcc',
  Legal: '#8bc5f5',
  'Holding Company': '#625e70',
  HC: '#625e70',
  HCU: '#625e70',
};

export const ITEM_EDIT_TAGGING = {
  EDGE: 'EDGE',
  ATTRIBUTE: 'ATTRIBUTE',
  HIDE: 'HIDE',
};

export const AGENDA_STATUS_IMG = {
  READY: 'ready',
  PROGRESS: 'progress',
  FORWARD: 'forward',
  FINALIZED: 'finalized',
};

export const MULTIPLE_FILTER_TYPE = {
  AUDIT_LOG: 'Audit Log',
  USER_ACTIVITY_LOG: 'User Activity Log',
  SG_JG_MAPPING: 'SG/JG Mapping',
  FUNCTION_LIST: 'Function List',
};

export const SG_JG_FILTERS = [
  { value: 'role_level', label: 'Role Level', placeholder: 'Role Level' },
  {
    value: 'jg_in_p_series',
    label: 'JG in P Series',
    placeholder: 'JG in P Series',
  },
];

export const SG_JG_FILTER_OPTIONS = {
  ROLE_LEVEL: 'role_level',
  JG_IN_P_SERIES: 'jg_in_p_series',
};

export const FUNCTION_LIST_FILTERS = [
  {
    value: 'function_list',
    label: 'Job Family / Sub Job Family / Function',
    placeholder: 'Job Family / Sub Job Family / Function',
  },
];

export const FUNCTION_LIST_FILTER_OPTIONS = {
  FUNCTION_LIST: 'function_list',
};

export const SG_JG_MAPPING_MESSAGE = {
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  SG_JG_UPPERCASE: 'Character must be in Uppercase',
  FILL_ALL_FIELD_REQUIRED: 'Please fill in the required field(s).',
  JG_IN_P_SERIES_SAME_ROLE: 'Please ensure JG in P Series selected is of the same role as existing record.',
  JG_IN_ORACLE_MAPPED_ONLY_ONE_JG_IN_P_SERIES: 'Please ensure one JG in Oracle is mapped to only one JG in P Series and vice versa',
  SG_JG_MAPPING_ALREADY_EXIST: 'The SG/JG Mapping (To Role) already exist',
  SG_JG_MAPPING_ADD_SUCCESS: 'New SG/JG Mapping (To Role) has been added successfully.',
  SG_JG_MAPPING_UPDATE_SUCCESS: 'Records successfully edited',
  RECORD_DELETE_SUCCESS: 'Records successfully deleted',
  NO_RESULT_FOUND: 'No result found for this SG/JG Mapping(To Role)',
  FIRST_CHARACTER_MUST_BE_UPPERCASE: 'First character must be UPPERCASE',
  FIRST_CHARACTER_MUST_BE_UPPERCASE_TOOLTIP: 'First character must be UPPERCASE. Example: P12',
  CHARACTER_MUST_BE_UPPERCASE_TOOLTIP: 'Character must be in UPPERCASE. Example: P12',
};

export const MASTER_DATA_MANAGEMENT = {
  ADD_BUSINESS_EXIST: 'Business Unit already exist in Business Unit List',
  ADD_BUSINESS_SUCCESS: 'Business Unit successfully added',
  BUSINESS_SEARCH_NOT_FOUND: 'No result found for this Business Unit',
  EDIT_BUSINESS_SUCCESS: 'Business Unit has been edited successfully',
  DELETE_BUSINESS_SUCCESS: 'Business Unit successfully deleted',
};

export const ROLE_LEVEL_LIST = {
  ROLE_LEVEL: 'role_level',
  EXIST_ROLE_LEVEL: 'role_level_existing',
  EXIST_ROLE_LEVEL_SP: 'role_level_sp_existing',
  EXIST_ROLE_LEVEL_MESSAGE: 'Role Level already exist in Role Level List',
  EXIST_ROLE_LEVEL_SP_MESSAGE: 'Role Level for Succession Planning already exist in Role Level List',
  REQUIRED_FIELDS: 'Please fill in the required fields',
  UPDATE_SUCCESS_MESSAGE: 'Role Level successfully edited',
  UPDATE_FAILED_MESSAGE: 'There are items that require your attention. Please fill out this field.',
  ROLE_LEVEL_USE: 'Role Level is in use.',
  ROLE_LEVEL_USE_CHILD: 'Please ensure Role Level is not relate with other module in order to proceed with the business unit deletion',
  DELETE_SUCCESS_MESSAGE: 'Role Level successfully deleted',
};

export const TOP_TALENT_STATUS_CATEGORY = {
  REQUIRED_FIELDS: 'Please fill in the required fields',
  ADD_TOP_TALENT_CATEGORY_SUCCESS: 'Top Talent Status Category successfully added',
  TOP_TALENT_CATEGORY_ALREADY_EXIST: 'This top talent Category already exist in Top Talent Category list',
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
};

export const MANAGING_POSITION_CATEGORY = {
  INPUT_REQUIRED_FIELD: 'Please fill in this field.',
  REQUIRED_FIELDS: 'Please fill in the required field(s).',
  CATEGORY_AND_ABBREVIATIONS_EXIST: 'This position category and abbreviations already exist in Managing Position Category list.',
  CATEGORY_EXIST: 'This position category already exist in Managing Position Category list.',
  ABBREVIATIONS_EXIST: 'This abbreviations already exist in Managing Position Category list.',
  ADD_MANAGING_POSITION_CATEGORY_SUCCESS: 'Managing Position category successfully added.',
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  DELETE_SUCCESS: 'Managing Position Category successfully deleted',
  EDIT_SUCCESS: 'Managing Position Category successfully edited',
  MAX_SHOW_IN_DASHBOARD:
    'Maximum 2 position category can be reflected in dashboard. To display the other category on the dashboard, you must hide one of the position categories.',
  MUST_SELECTED_ROLE_LEVEL: '3 role level must be selected in the role level field.',
};

export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  NORMAL: 'normal',
  WARNING: 'warning',
};

export const BU_MAPPING_MESSAGE = {
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  FILL_ALL_FIELD_REQUIRED: 'Please fill in the required fields',
  MAPPING_ALREADY_EXIST: 'The Mapping already exist. Please enter new mapping records',
  BU_ADD_SUCCESS: 'Mapping for Business/OPU/Division successfully added',
  BU_UPDATE_SUCCESS: 'Mapping for Business/OPU/Division successfully edited',
  RECORD_DELETE_SUCCESS: 'Mapping for Business/OPU/Division successfully deleted',
  FINANCE_BUSINESS_UNIT_IN_USE: {
    TITLE: 'Finance Business Unit is in use.',
    CONTENT: 'Please ensure record is not relate with other module in order to proceed with the mapping deletion.',
  },
  SEARCH_NOT_FOUND: 'No result found Mapping for Business/OPU/Division',
  DATA_ORACLE_EXISTED: 'The data content in Oracle already has an existing mapping.',
};

export const SUCCESSION_PLANNING_MANAGEMENT = {
  ADD_EDIT_APPROVED_EXIST:
    'The add action cannot be proceeded due to there is already existing record for the Position Code of the same Approved Date',
};

export const ASSESSMENT_TYPE = [
  {
    value: 'DW',
    label: 'DW',
  },
  {
    value: 'DC',
    label: 'DC',
  },
  {
    value: 'OA',
    label: 'OA',
  },
];

export const MOBILITY_PLAN_TYPE_OPTION = [
  {
    value: 'Next Mobility',
    label: 'Next Mobility',
  },
  {
    value: 'Next 2 Mobility',
    label: 'Next 2 Mobility',
  },
  {
    value: 'End State',
    label: 'End State',
  },
];

export const DEVELOPMENT_PLAN_STATUS = [
  {
    value: 'IN_PROGRESS',
    label: 'IN_PROGRESS',
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED',
  },
  {
    value: 'NOT_STARTED',
    label: 'NOT_STARTED',
  },
];

export const DATA_FIELD_IN_ORACLE = {
  BUSINESS_UNIT: 'Business Unit',
  COMPANY: 'Company',
  DIVISION: 'Division',
  JOB_FAMILY: 'Job Family',
};

export const CONTENT_SEARCH_NOT_FOUND = {
  MAPPING_BU_OPU_DIVISION:
    'Mapping for Business/OPU/Division is not found. Make sure that all words are spelled correctly or try different keywords.',
  FUNCTION_LIST: 'Function is not found. Make sure that all words are spelled correctly or try different keywords.',
  LIST_OF_PROFILES: 'No matching staff found.',
};

export const MAPPING_DATA_TYPES = {
  SG_JG_MAPPING: 'SG/JG Mapping',
  BU_MAPPING: 'Mapping for Business/OPU/Division',
  FUNCTION_LIST: 'Function List',
};

export const FUNCTION_LIST_MESSAGE = {
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  FILL_ALL_FIELD_REQUIRED: 'Please fill in the required field(s).',
  FUNCITON_ALREADY_EXIST: 'Function already exist in Function List',
  FUNCITON_INVALID: 'Function is exist in other Job Family/Sub-job Family. Please rename the Function',
  FUNCTION_ADD_SUCCESS: 'Function successfully added',
  FUNCTION_EDIT_SUCCESS: 'Function successfully edited',
  FUNCTION_DELETE_SUCCESS: 'Function successfully deleted',
  FUNCTION_IN_USE: {
    TITLE: 'Function list is in use.',
    CONTENT: 'Please ensure Function is not relate with other module in order to proceed with the function list deletion.',
  },
  SEARCH_NOT_FOUND: 'No result found for this Function List',
  REQUIRED_FIELDS: 'There are items that require your attention. Please fill out this field.',
};

export const SP_NOTES_PERFORMANCE_RATING = 'Performance Rating is in order of recent year, for the past 5 years';

export const SP_AGENDA_DISCLAIMER = [
  {
    id: 1,
    label:
      'AI recommendation accuracy is subject to data completeness & richness from talent profile, talent assessment, feedback on PCB, personality assessment and other data sources.',
  },
  {
    id: 2,
    label: 'Performance Rating is in order of recent year, for the past 5 years.',
  },
];

export const TR_AGENDA_DISCLAIMER = [
  {
    id: 1,
    label:
      'AI recommendation accuracy is subject to data completeness & richness from talent profile, talent assessment, feedback on PCB, personality assessment and other data sources.',
  },
];

export const MS_AGENDA_DISCLAIMER = [
  {
    id: 1,
    label:
      'DISLAIMER: AI recommendation accuracy is subject to data completeness & richness from talent profile, talent assessment, feedback on PCB, personality assessment and other data sources',
  },
];

export const TALENT_PROFILE_DISCLAIMER = [
  {
    id: 1,
    label:
      'AI recommendation accuracy is subject to data completeness & richness from talent profile, talent assessment, feedback on PCB, personality assessment and other data sources.',
  },
];

export const MOBILITY_PLAN_MESSAGE = {
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  MOBILITY_PLAN: 'Mobility Plan successfully',
};

export const CURRENT_TAG_OPTION = [
  'Knowledge in managing P & L for specific unit / function',
  'Experience managing government (local or foreign)',
  'Knowledge of business value chain',
  'Experience working in multi country',
  'Experience in managing project',
  'Experience in Business or Strategic Negotiations',
  'Lead Medium/Large - sized function/ business',
  'Experience in business transformation/ growth',
  'Experience in Core Operational & Execution Management',
  'Experience in technical or technology development',
  'Developed Strategy for Business or Function',
  'Multi Business Exposure',
];

export const UNSEARCHABLE_POSITION_MESSAGE = {
  SEARCH_EXIST_POSITION_ADD: 'Position is already in the Unsearchable Position List',
  EXIST_UNSEARCHABLE_POSITION: 'Position is already in the Unsearchable Position List.',
  ADD_SUCCESS: 'Successfully added to Unsearchable Position list',
  DELETE_SUCCESS: 'Successfully Deleted from Unsearchable list',
  LIST_OF_POSITION: 'No matching position found.',
};

export const MIN_RECORD_BEFORE_DELETE = 1;

export const MOBILITY_PLAN_LATEST_KEYS = {
  END_STATE: 'endState',
  NEXT_MOBILITY: 'nextMobility',
  NEXT_2_MOBILITY: 'next2Mobility',
};

export const PERSONALITY_SORT_ORDER = ['strongest', 'second_strongest', 'third_strongest'];

export const ASSIGNMENT_STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
};

export const MODE_TYPES = {
  GIRD: 'Gird',
  LIST: 'List',
};

export const FILE_EXTENSION = {
  PDF: 'pdf',
  TXT: 'txt',
  XLS: 'xls',
  XLSX: 'xlsx',
  CSV: 'csv',
  DOC: 'doc',
  MP4: 'mp4',
  DOCX: 'docx',
  PPTX: 'pptx',
  PPT: 'ppt',
};

export const OPU_DIVISION_FROM_TABLE = {
  POSITION: 'position',
  EMPLOYMENT_INFO: 'empl',
};

export const OPU_NAME_EXCEPTION = `Petroliam Nasional Berhad (PETRONAS)`;
export const HELP_DOCUMENT = {
  GUIDE_DOCUMENT_ADMIN: 'eGuide for Admin Module.pdf',
  GUIDE_DOCUMENT_USER: 'eGuide for User.pdf',
  TRAINERS_DOCUMENT_ADMIN: 'myTalentX_TTT__v1.0 - For Admin.pdf',
  TRAINERS_DOCUMENT_USER: 'myTalentX TTT_v1.0_For User.pdf',
};

export const AUDIT_TRAIL_ACTION = {
  CONCLUDE_MEETING: 'Conclude Meeting',
  ADD: 'Add',
  DELETE: 'Delete',
  EDIT: 'Edit',
};

export const EMPLOYEE_INSIGHTS_OPTIONS = {
  TALENT_SUMMARY: 'Talent Summary',
  LEADERSHIP_COMPETENCIES: 'Leadership Competencies',
  EXPERIENCES: 'Experiences',
  TALENT_DEVELOPMENT_PLAN: 'Talent Development',
  RIGHT_FIT_RECOMMENDATION: 'Right Fit Recommendation',
  EXTERNAL_ASSESSMENT: 'External Assessment',
};

export const DATA_FROM = {
  ORACLE: 'oracle',
  MANUAL: 'manual',
  MEETING: 'tc_meeting',
};

export const PAGE_NAME = {
  TALENT_PROFILE: 'Talent Profile',
  PDC_MEETING: 'PDC meeting',
};

export const CONSTANT_HISTORICAL_SUCCESSION_PLANNING = {
  overall: 'overall',
  'General Manager': 'gm',
  'Senior Manager': 'sm',
  'Senior General Manager': 'sgm',
  'Vice President': 'vp',
};

export const TALENT_REMARK_CATEGORY = {
  NOTES: 'Notes',
  KEY_STRENGTH: 'Key Strength',
  DEVELOPMENT_AREA: 'Development Areas',
};

export const SQL_INTERGER_LIMIT = {
  MIN_VALUE: -2147483647,
  MAX_VALUE: 2147483648,
};

export const SET_MATCHING_CRITERIA_STATUS = {
  NOT_AVAILABLE: 'Not Available',
  RUNNING: 'Running',
  FAILED: 'Failed',
  COMPLETED: 'Completed',
  QUEUE: 'Queue',
};

export const HISTORICAL_DATA_TALENT_TYPE = {
  NO_OF_TOP_TALENT: 'noOfTopTalent',
  NO_OF_CORPORATE_TALENT: 'noOfCorporateTalent',
  NO_OF_BUSINESS_TALENT: 'noOfBusinessTalent',
  NO_OF_REVIEW_CONDUCTED: 'noOfReviewConducted',
};

export const NAME_FOLDER_BLOB = {
  EXTERNAL: 'External Talent Photos',
  UAT: 'UAT',
};

export const ERROR_MESSAGE_API = ['invalid_server_key', 'no_group_id', 'no_server_key'];

export const ADVANCED_FILTER_OPTION = {
  SHOW_ONLY_MATCHING_RESULT: 'matching_result',
  SHOW_ALL_RELATED_RESULT: 'related_result',
};

export const TALENT_SEARCH_RESULT_OPTIONS = [
  {
    value: 'matching_result',
    label: 'Show only Matching Result',
  },
  {
    value: 'related_result',
    label: 'Show all Related Result',
  },
];

export const ACTION_TYPE_AGENDA = {
  // {roleName: "HR Sector", roleId: 49}
  // {roleName: "HR BP", roleId: 6}
  // {roleName: "HR BP - Secretariat", roleId: 8}
  // {roleName: "HR COE", roleId: 7}
  // "Vice President", "VP", "Senior General Manager", "SGM", "General Manager", "GM", "Senior Manager", "SM", "Manager", "Mgr", "Executive", "Exec"
  APPROVE: [
    {
      group: 'HR Sector',
      type: ['BCP', 'CCP', 'OCP'],
      role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP',
      type: ['CCP'],
      role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP',
      type: ['BCP', 'OCP'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP - Secretariat',
      type: ['CCP'],
      role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP - Secretariat',
      type: ['BCP', 'OCP'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR COE',
      type: ['BCP', 'CCP', 'OCP'],
      role: [
        'Vice President',
        'VP',
        'Senior General Manager',
        'SGM',
        'General Manager',
        'GM',
        'Senior Manager',
        'SM',
        'Manager',
        'Mgr',
        'Executive',
        'Exec',
      ],
    },
  ],
  REVIEW: [
    // {
    //   group: 'HR Sector',
    //   type: ['CCP'],
    //   role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    // },
  ],
  ENDORSE: [
    {
      group: 'HR Sector',
      type: ['BCP', 'CCP'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    },
    {
      group: 'HR BP',
      type: ['CCP'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    },
    {
      group: 'HR BP - Secretariat',
      type: ['CCP'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    },
  ],
};

export const ACTION_TYPE_TALENT_REVIEW_AGENDA = {
  APPROVE: [
    {
      group: 'HR Sector',
      type: ['Corporate Talent', 'Business Talent', 'Other Talent'],
      role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },

    {
      group: 'HR BP',
      type: ['Corporate Talent 1st Line', 'Other Talent'],
      role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP',
      type: ['Corporate Talent not 1st Line', 'Other Talent'],
      role: ['General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP',
      type: ['Business Talent', 'Other Talent'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP - Secretariat',
      type: ['Corporate Talent', 'Other Talent'],
      role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR BP - Secretariat',
      type: ['Business Talent', 'Other Talent'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
    },
    {
      group: 'HR COE',
      type: ['Corporate Talent 1st Line', 'Corporate Talent not 1st Line', 'Business Talent', 'Other Talent'],
      role: [
        'Vice President',
        'VP',
        'Senior General Manager',
        'SGM',
        'General Manager',
        'GM',
        'Senior Manager',
        'SM',
        'Manager',
        'Mgr',
        'Executive',
        'Exec',
      ],
    },
  ],
  REVIEW: [
    {
      group: 'HR Sector',
      type: ['Corporate Talent'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    },
  ],
  ENDORSE: [
    {
      group: 'HR Sector',
      type: ['Business Talent', 'Other Talent'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    },
    {
      group: 'HR BP',
      type: ['Corporate Talent 1st Line'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    },
    {
      group: 'HR BP',
      type: ['Corporate Talent not 1st Line'],
      role: ['Senior General Manager', 'SGM'],
    },
    {
      group: 'HR BP - Secretariat',
      type: ['Corporate Talent'],
      role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
    },
  ],
};

export const COLUMNS_MAP_CRITERIA = {
  [CRITERIA_TYPE.EDGE]: 'EdgeColumns',
  [CRITERIA_TYPE.ATTRIBUTES]: 'AttributesColumns',
  [CRITERIA_TYPE.PERSONALITY_ARCHETYPE]: 'PersonalityArchetypeColumns',
  [CRITERIA_TYPE.PERSONALITY_ARCHETYPE_OPTIONAL]: 'PersonalityArchetypeColumns',
  [CRITERIA_TYPE.SMA_RESULT]: 'SMAEdgeColumns',
  [CRITERIA_TYPE.EXTERNAL_RESULT]: 'PersonalityArchetypeColumns',
  [CRITERIA_TYPE.EXTERNAL_RESULT_OPTIONAL]: 'PersonalityArchetypeColumns',
  [CRITERIA_TYPE.SUCCESSOR_TO_VP_POSITION]: 'SuccessorVPPositionColumns',
  [CRITERIA_TYPE.SUCCESSOR_TO_SGM_POSITION]: 'SuccessorVPPositionColumns',
  [CRITERIA_TYPE.FUNCTIONAL]: 'FunctionalColumns',
  [CRITERIA_TYPE.FUNCTIONAL_OPTIONAL]: 'FunctionalOptionalColumns',
  [CRITERIA_TYPE.FUNCTIONAL_SPIKE]: 'FunctionalOptionalColumns',
  [CRITERIA_TYPE.BUSINESS_EXPOSURE]: 'BusinessExposureColumns',
  [CRITERIA_TYPE.BUSINESS_EXPOSURE_OPTIONAL]: 'AttributesColumns',
  // [CRITERIA_TYPE.TOP_TALENT_EXPERIENCE]: 'TopTalentExperienceColumns',
  // [CRITERIA_TYPE.TOP_TALENT_EXPERIENCE_OPTIONAL]: 'TopTalentExperienceColumns',
  [CRITERIA_TYPE.PERFORMANCE_RATING]: 'SuccessorVPPositionColumns',
  [CRITERIA_TYPE.NATIANALITY_LOCALITY_REQUIREMENT]: 'NationalityColumns',
};

export const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

export const INIT_PARAMS = {
  search: '',
  page: 1,
  fetching: false,
  searching: true,
  isAll: false,
  limit: 15,
};

export const CONTENT_FUNCTION_TYPE = {
  FUNCTION: 'function',
  EXCEPTION_FUNCTION: 'exception function',
  JOB_FAMILY: 'job family',
  SUB_JOB_FAMILY: 'sub job family',
  EXCEPTION_SUB_JOB_FAMILY: 'exception sub job family',
};

export const DATE_TIME_FORMAT = {
  ONLY_DAY: 'DD',
  ONLY_MONTH: 'MM',
  ONLY_YEAR: 'YYYY',
  ONLY_MONTH_YEAR: 'MM YYYY',
  DATE_SPACE: 'DD MMM YYYY',
  DATE_DASH: 'YYYY-MM-DD',
  DATE_SLASH: 'DD/MM/YYYY',
  SHORT_DAY: 'D MMMM YYYY',
  DATE_STANDARD: 'YYYY-MM-DD',
  TIME: 'hh:mm A',
  DATE_TIME: 'YYYY-MM-DD hh:mm A',
};

export const CAREER_JOURNEY_EXPERIENCE_TYPE = {
  FUNCTIONAL: `Functional Experience`,
  COMPLEMENTARY: `Complementary Experience`,
};

export const MOBILITY_PLAN_DROP_CARD_INFO = {
  NEXT_MOBILITY: {
    title: 'Next Mobility',
    desc: 'Drag the card from Recommendation table for Plan 1 Next Mobility and Next 2 Mobility to Add Position',
    tableName: 'Recommendations for Plan 1 : Next Mobility and Next 2 Mobility',
    type: 'next_mobility',
  },
  NEXT_2_MOBILITY: {
    title: 'Next 2 Mobility',
    desc: 'Drag the card from Recommendation table for Plan 1 Next Mobility and Next 2 Mobility to Add Position',
    tableName: 'Recommendations for Plan 1 : Next Mobility and Next 2 Mobility',
    type: 'next_2_mobility',
  },
  END_STATE: {
    title: 'End State',
    desc: 'Drag the card from Recommendation table for Plan 1 End State to Add Position',
    tableName: 'Recommendations for Plan 1 : End State',
    type: 'end_state',
  },
};

export const MOBILITY_PLAN_CATEGORY_TYPE = [
  {
    name: 'Strength-based',
    value: 'STRENGTH',
    desc: `Based on the talent's strength and recommend positions until reaching
    the end state.`,
  },
  {
    name: 'Gap-based',
    value: 'GAP',
    desc: `Based on the talent's current state and recommend positions until
    reaching the end state.`,
  },
];

export const MOBILITY_PLAN_MESSAGES = {
  TALENT_EXIST_IN_NEXT_MOBILITY: `Talent already exist in “Next Mobility“ section`,
  TALENT_EXIST_IN_NEXT_2_MOBILITY: `Talent already exist in “Next 2 Mobility“ section`,
  TALENT_EXIST_IN_END_STATE: `Talent already exist in “End State“ section`,
};

export const MOBILITY_PLAN_MOVEMENT_TYPE = {
  LATERAL: 'Lateral',
  ONE_LEVEL_PROMOTION: '1-Level Promotion',
  TWO_LEVEL_PROMOTION: '2-Level Promotion',
};

export const SHORT_FORM_RECOMMENDATION_MOBILITY = {
  Upstream: 'US',
  Downstream: 'DS',
  Corporate: 'HC',
  'Project Delivery & Technology': 'PD&T',
  GAS: 'GAS',
  'Group HRM': 'GHRM',
  HR: 'GHRM',
  Others: 'OT',
  Finance: 'FIN',
};

export const CATEGORY_OPTIONS = [
  { value: 'Strength', label: 'Strength' },
  { value: 'Development Areas', label: 'Development Areas' },
  { value: 'Notes', label: 'Notes' },
  { value: 'Development Plan', label: 'Development Plan' },
  { value: 'Next Mobility', label: 'Next Mobility' },
  { value: 'Next 2 Mobility', label: 'Next 2 Mobility' },
  { value: 'End State', label: 'End State' },
];

export const CATEGORY_OPTIONS_PDC = [
  { value: 'Development Plan', label: 'Development Plan' },
  { value: 'Next Mobility', label: 'Next Mobility' },
  { value: 'Dual Hatting', label: 'Dual Hatting' },
  { value: 'Others', label: 'Others' },
];

export const FILTER_MODEL_OUTPUT = {
  JOB_FUNCTION: 'job_function',
  SHOW_HIDE: 'show_hide',
};

export const MODULE_FILTER_TABLE = {
  COMPLEMENTARY_EXPERIENCE: 'Complementary Experience',
  ASPIRATION_MATCHING: 'Aspiration Matching',
};

export const GLOBAL_ALERT_MESSAGE_CONTAINER_ID = {
  TP_COMPLEMENTARY: 'TP_COMPLEMENTARY',
  TP_ASPIRATION: 'TP_ASPIRATION',
  TC_IN_REVIEW_MEETING: 'TC_IN_REVIEW_MEETING',
  SP_AGENDA_DETAIL: 'SP_AGENDA_DETAIL',
  TP_VIDEO: 'TP_VIDEO',
  TP_VIDEO_ADD: 'TP_VIDEO_ADD',
};

export const STATUS_UPLOAD = {
  UPLOADING: 'uploading',
  CANCEL: 'cancel',
};

export const AGENDA_FIELD_CHANGES = {
  succession_planning_name: `Succession Planning Name`,
  start_time: `Start time`,
  end_time: `End time`,
  advocators: `Advocators`,
  cc_list: `HR Partners`,
  action_type: `Action Type`,
  positions: `Postions`,
  remarks: `Description of role(s)/ requirements​`,
  first_line: `1st Line`,
  second_line: `2nd Line`,
  third_line: `3rd Line`,

  //TR SPECIFIC FIELD
  title: 'Talent Review Name',
  hr_partners: 'HR Partners',
  details: 'Details',
  agreed_to_drop: 'Agreed to Drop',
  mobility_plan: 'Plan',
  recommendation_category: 'Recommendation Category',
  development_plan: 'Development Plan List',
  key_strength: 'Key Strength and Development Areas',
  talent: 'Talent',
  top_talent_category: 'Top Talent Category',
};

export const KEY_CHANGES_TO_CHECK_SP_AGENDA = [
  `succession_planning_name`,
  `start_time`,
  `end_time`,
  `advocators`,
  `cc_list`,
  `action_type`,
  `positions`,
  `remarks`,
  `first_line`,
  `second_line`,
  `third_line`,
  `talent_remarks`,
];

export const ALLOW_URL_NAVIGATE = [
  '/agenda/',
  '/agenda',
  '/talent-review-agenda/create',
  '/agenda/create',
  '/mobility-agenda/create',
  '/others-agenda/create',
  '/others-agenda/',
  '/talent-review-agenda/',
  '/mobility-agenda/',
  '/meeting-conclude',
];

export const MS_FIELD = {
  title: 'Mobility Discussion Session Details - Title',
  start_time: 'Mobility Discussion Session Details - Start Time',
  end_time: 'Mobility Discussion Session Details - End Time',
  advocators: 'Mobility Discussion Session Details - Advocators',
  hr_partners: 'Mobility Discussion Session Details - HR Partners',
  remarks: 'Mobility Discussion Session Details - Remarks',
  ms_ids: 'Mobility Simulation',
  all_remarks: 'Mobility Discussion Session Details - Show All Remarks',
};

export const OT_FIELD = {
  title: 'Title',
  start_time: 'Start Time',
  end_time: 'End Time',
  advocators: 'Advocators',
  hr_partners: 'HR Partners',
  fileUpload: 'Attachment',
  remarks: 'All Remark',
};

export const TALENT_PROFILE_EXPERIENCES = {
  function: 'Function Edit History',
  business: 'Business Edit History',
};

export const SP_AGENDA_FIELDS = {
  succession_planning_name: `Succession Planning Session Details - Succession Planning Name`,
  start_time: `Succession Planning Session Details - Start time`,
  end_time: `Succession Planning Session Details - End time`,
  advocators: `Succession Planning Session Details - Advocators`,
  cc_list: `Succession Planning Session Details - HR Partners`,
  action_type: `Succession Planning Session Details - Action Type`,
  positions: `Succession Planning - Position`,
  remarks: `Succession Planning - Description of role(s)/ requirements​`,
  first_line: `Succession Planning - 1st`,
  second_line: `Succession Planning - 2nd`,
  third_line: `Succession Planning - 3rd`,
  talent_remarks: `Succession Planning - Remarks`,
};

export const MEETING_DETAILS_FIELDS = {
  file_url: 'Upload approved Minutes of Meeting (MoM) - MoM',
  meeting_name: 'Meeting Details - Meeting Name',
  date: 'Meeting Details - Meeting Date',
  category: 'Meeting Details - Meeting Category',
  start_time: 'Meeting Details - Meeting Start time',
  end_time: 'Meeting Details - Meeting End time',
  location: 'Meeting Details - Meeting Location',
  chairman: 'Meeting Details - Meeting Chairman',
  meeting_committee: 'Meeting Details - Meeting Committee Members',
  meeting_facilitator: 'Meeting Details - Meeting Facilitators',
  meeting_secretariats: 'Meeting Details - Meeting Secretariat',
  remarks: 'Add Remark - All Remark',
  remark_remarkType: `Add Remark - Remarks type`,
  remark_category: `Add Remark - Category`,
  remark_agenda: `Add Remark - Agenda`,
  remark_staff: `Add Remark - Talent Name`,
  remark_remark: `Add Remark - Remarks`,
  remark_fields: {
    remarkType: null,
    title: '',
    staff: '',
    remark: '',
    category: null,
    agenda: null,
  },
};
export const AGENDA_TYPES_LABEL = [
  {
    type_agenda: 'SP',
    label: 'Succession Planning',
    icon: succession_planning_type,
  },
  {
    type_agenda: 'MS',
    label: 'Mobility Discussion',
    icon: mobility_discussion_type,
  },
  { type_agenda: 'TR', label: 'Talent Review', icon: talent_review_type },
  { type_agenda: 'OT', label: 'Others', icon: other_type },
];

export const INIT_POSITION_VALUES = {
  name: [],
  age: [],
  retirementOrContractEndDate: [],
  proposedSuccessionPlanning: [],
  roleAndJG: [],
  yearsInPositionAndSince: [],
  incumbents: [],
};

export const COMPETENCIES_CATEGORY = {
  ACTION_ORIENTED: 'Action Oriented',
  BALANCES_STAKEHOLDERS: 'Balances Stakeholders',
  BEING_RESILIENT: 'Being Resilient',
  BUILDS_EFFECTIVE_TEAMS: 'Builds Effective Teams',
  BUILDS_NETWORKS: 'Builds Networks',
  COLLABORATES: 'Collaborates',
  COMMUNICATES_EFFECTIVELY: 'Communicates Effectively',
  COURAGE: 'Courage',
  CULTIVATES_INNOVATION: 'Cultivates Innovation',
  CUSTOMER_FOCUS: 'Customer Focus',
  DECISION_QUALITY: 'Decision Quality',
  DEVELOPS_TALENT: 'Develops Talent',
  DIRECTS_WORK: 'Directs Work',
  DRIVES_ENGAGEMENT: 'Drives Engagement',
  DRIVES_RESULTS: 'Drives Results',
  ENSURES_ACCOUNTABILITY: 'Ensures Accountability',
  GLOBAL_PERSPECTIVE: 'Global Perspective',
  INSTILLS_TRUST: 'Instills Trust',
  INTERPERSONAL_SAVVY: 'Interpersonal Savvy',
  MANAGES_AMBIGUITY: 'Manages Ambiguity',
  MANAGES_CONFLICT: 'Manages Conflict',
  NIMBLE_LEARNING: 'Nimble Learning',
  OPTIMIZES_WORK_PROCESSES: 'Optimizes Work Processes',
  PERSUADES: 'Persuades',
  PLANS_AND_ALIGNS: 'Plans And Aligns',
  RESOURCEFULNESS: 'Resourcefulness',
  SELF_DEVELOPMENT: 'Self Development',
  SITUATIONAL_ADAPTABILITY: 'Situational Adaptability',
  STRATEGIC_MINDSET: 'Strategic Mindset',
  VALUES_DIFFERENCES: 'Values Differences',
};

export const SP_CARD_DEFAULT = {
  SELECT_ALL: 'select_all',
  PROFILE_PICTURE: 'profile_picture',
  STAFF_NAME: 'staff_name',
  STAFF_ID: 'staff_id',
  TOP_TALENT_STATUS: 'top_talent_status',
  MATCHING_PERCENTAGE: 'matching_percentage',
  POSITION: 'position',
  BUSINESS: 'business',
  SECTOR: 'sector',
  OPU: 'opu',
  DIVISION: 'division',
  DEPARTMENT: 'department',
  SP_COUNTER: 'sp_counter',
  FIRST_LINE: '1st Line',
  SECOND_LINE: '2nd Line',
  THIRD_LINE: '3rd Line',
  APPROVAL_STATUS: 'approval_status',
  YEAR_IN_POSITION: 'year_in_position',
  YEAR_IN_SG: 'year_in_sg',
  AGE: 'age',
  RETIREMENT_DATE: 'retirement_date',
  PERFORMANCE_RATING: 'performance_rating',
  ADDITIONAL_INPUT: 'additional_input',
};

export const SP_CARD_DEFAULT_SELECT_OPTIONS = [
  { label: 'Age', value: SP_CARD_DEFAULT.AGE },
  { label: 'Approval Status', value: SP_CARD_DEFAULT.APPROVAL_STATUS },
  { label: 'Matching Percentage', value: SP_CARD_DEFAULT.MATCHING_PERCENTAGE },
  { label: 'Performance Rating', value: SP_CARD_DEFAULT.PERFORMANCE_RATING },
  { label: 'Position', value: SP_CARD_DEFAULT.POSITION },
  { label: 'Profile Picture', value: SP_CARD_DEFAULT.PROFILE_PICTURE },
  { label: 'Staff Name ', value: SP_CARD_DEFAULT.STAFF_NAME },
  { label: 'Staff ID', value: SP_CARD_DEFAULT.STAFF_ID },
  {
    label: 'Retirement / Contract End Date',
    value: SP_CARD_DEFAULT.RETIREMENT_DATE,
  },
  { label: 'SP Counter', value: SP_CARD_DEFAULT.SP_COUNTER },
  { label: '1st Line', value: SP_CARD_DEFAULT.FIRST_LINE },
  { label: 'Top Talent Status', value: SP_CARD_DEFAULT.TOP_TALENT_STATUS },
  { label: 'Year in Position', value: SP_CARD_DEFAULT.YEAR_IN_POSITION },
  { label: 'Year in Salary Grade', value: SP_CARD_DEFAULT.YEAR_IN_SG },
];

export const SP_CARD_HAS_BORDER = [
  SP_CARD_DEFAULT.YEAR_IN_POSITION,
  SP_CARD_DEFAULT.YEAR_IN_SG,
  SP_CARD_DEFAULT.AGE,
  SP_CARD_DEFAULT.RETIREMENT_DATE,
  SP_CARD_DEFAULT.PERFORMANCE_RATING,
];

export const SP_CARD_SELECT_OPTIONS = [
  { label: 'Select All', value: SP_CARD_DEFAULT.SELECT_ALL },
  { label: 'Age', value: SP_CARD_DEFAULT.AGE },
  { label: 'Additional Input', value: SP_CARD_DEFAULT.ADDITIONAL_INPUT },
  { label: 'Approval Status', value: SP_CARD_DEFAULT.APPROVAL_STATUS },
  { label: 'Matching Percentage', value: SP_CARD_DEFAULT.MATCHING_PERCENTAGE },
  { label: 'Performance Rating', value: SP_CARD_DEFAULT.PERFORMANCE_RATING },
  { label: 'Position', value: SP_CARD_DEFAULT.POSITION },
  { label: 'Business', value: SP_CARD_DEFAULT.BUSINESS },
  { label: 'Sector', value: SP_CARD_DEFAULT.SECTOR },
  { label: 'OPU', value: SP_CARD_DEFAULT.OPU },
  { label: 'Division', value: SP_CARD_DEFAULT.DIVISION },
  { label: 'Department', value: SP_CARD_DEFAULT.DEPARTMENT },
  { label: 'Profile Picture', value: SP_CARD_DEFAULT.PROFILE_PICTURE },
  {
    label: 'Retirement / Contract End Date',
    value: SP_CARD_DEFAULT.RETIREMENT_DATE,
  },
  {
    label: 'SP Counter',
    value: SP_CARD_DEFAULT.SP_COUNTER,
    children: [
      { label: '1st Line', value: SP_CARD_DEFAULT.FIRST_LINE },
      { label: '2nd Line', value: SP_CARD_DEFAULT.SECOND_LINE },
      { label: '3rd Line', value: SP_CARD_DEFAULT.THIRD_LINE },
    ],
  },
  { label: 'Staff Name ', value: SP_CARD_DEFAULT.STAFF_NAME },
  { label: 'Staff ID', value: SP_CARD_DEFAULT.STAFF_ID },
  { label: 'Top Talent Status', value: SP_CARD_DEFAULT.TOP_TALENT_STATUS },
  { label: 'Year in Position', value: SP_CARD_DEFAULT.YEAR_IN_POSITION },
  { label: 'Year in Salary Grade', value: SP_CARD_DEFAULT.YEAR_IN_SG },
];

export const MATRIX_SP_AGENDA = [
  // {roleName: "HR Sector", roleId: 49}
  // {roleName: "HR BP", roleId: 6} (HR Business)
  // {roleName: "HR BP - Secretariat", roleId: 8} (SKG Secretariat)
  // {roleName: "HR COE", roleId: 7}
  // "Vice President", "VP", "Senior General Manager", "SGM", "General Manager", "GM", "Senior Manager", "SM", "Manager", "Mgr", "Executive", "Exec"

  {
    user_role: 'HR Sector',
    position_category: ['BCP', 'CCP', 'OCP'],
    action_type: 'Approved',
    position_role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR Sector',
    position_category: 'CCP',
    action_type: 'Reviewed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },
  {
    user_role: 'HR Sector',
    position_category: 'BCP',
    action_type: 'Endorsed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },
  {
    user_role: 'HR BP',
    position_category: ['BCP', 'OCP'],
    action_type: 'Approved',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP',
    position_category: 'CCP',
    action_type: 'Approved',
    position_role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP',
    position_category: 'CCP',
    action_type: 'Endorsed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },

  {
    user_role: 'HR BP - Secretariat',
    position_category: ['BCP', 'OCP'],
    action_type: 'Approved',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP - Secretariat',
    position_category: 'CCP',
    action_type: 'Approved',
    position_role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP - Secretariat',
    position_category: 'CCP',
    action_type: 'Endorsed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },
  {
    user_role: ROLE.HR_COE,
    position_category: ['CCP', 'BCP', 'OCP'],
    action_type: 'Approved',
    position_role: [
      'Vice President',
      'VP',
      'Senior General Manager',
      'SGM',
      'General Manager',
      'GM',
      'Senior Manager',
      'SM',
      'Manager',
      'Mgr',
      'Executive',
      'Exec',
    ],
  },
  {
    user_role: ROLE.ADMIN,
    position_category: ['CCP', 'BCP', 'OCP'],
    action_type: 'Approved',
    position_role: [
      'Vice President',
      'VP',
      'Senior General Manager',
      'SGM',
      'General Manager',
      'GM',
      'Senior Manager',
      'SM',
      'Manager',
      'Mgr',
      'Executive',
      'Exec',
    ],
  },
];

export const MATRIX_TR_AGENDA = [
  // {roleName: "HR Sector", roleId: 49}
  // {roleName: "HR BP", roleId: 6} (HR Business)
  // {roleName: "HR BP - Secretariat", roleId: 8} (SKG Secretariat)
  // {roleName: "HR COE", roleId: 7}
  // "Vice President", "VP", "Senior General Manager", "SGM", "General Manager", "GM", "Senior Manager", "SM", "Manager", "Mgr", "Executive", "Exec"

  {
    user_role: 'HR Sector',
    talent_type: ['Business Talent', 'Corporate Talent', 'Other Talent'],
    action_type: 'Approved',
    position_role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR Sector',
    talent_type: 'Corporate Talent',
    action_type: 'Reviewed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },
  {
    user_role: 'HR Sector',
    talent_type: ['Business Talent', 'Other Talent'],
    action_type: 'Endorsed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },
  {
    user_role: 'HR BP',
    talent_type: ['Business Talent', 'Other Talent'],
    action_type: 'Approved',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP',
    talent_type: 'Corporate Talent',
    action_type: 'Approved',
    position_role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP',
    talent_type: 'Corporate Talent',
    action_type: 'Endorsed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },

  {
    user_role: 'HR BP - Secretariat',
    talent_type: ['Business Talent', 'Other Talent'],
    action_type: 'Approved',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM', 'Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP - Secretariat',
    talent_type: 'Corporate Talent',
    action_type: 'Approved',
    position_role: ['Senior Manager', 'SM', 'Manager', 'Mgr', 'Executive', 'Exec'],
  },
  {
    user_role: 'HR BP - Secretariat',
    talent_type: 'Corporate Talent',
    action_type: 'Endorsed',
    position_role: ['Senior General Manager', 'SGM', 'General Manager', 'GM'],
  },
  {
    user_role: ROLE.HR_COE,
    talent_type: ['Corporate Talent', 'Business Talent', 'Other Talent'],
    action_type: 'Approved',
    position_role: [
      'Vice President',
      'VP',
      'Senior General Manager',
      'SGM',
      'General Manager',
      'GM',
      'Senior Manager',
      'SM',
      'Manager',
      'Mgr',
      'Executive',
      'Exec',
    ],
  },
  {
    user_role: ROLE.ADMIN,
    talent_type: ['Corporate Talent', 'Business Talent', 'Other Talent'],
    action_type: 'Approved',
    position_role: [
      'Vice President',
      'VP',
      'Senior General Manager',
      'SGM',
      'General Manager',
      'GM',
      'Senior Manager',
      'SM',
      'Manager',
      'Mgr',
      'Executive',
      'Exec',
    ],
  },
];

export const POSITION_FILTER_TITLE = {
  POSITION_DETAILS: 'Position Details',
  SPUR: 'SPUR',
  CRITICAL_POSITION_STATUS: 'Critical Position Status',
};

export const POSITION_DETAIL_FILTERS = [
  { value: 'business', label: 'Business' },
  { value: 'sector', label: 'Sector' },
  { value: 'opu', label: 'OPU' },
  { value: 'division', label: 'Division' },
  { value: 'department', label: 'Department' },
  { value: 'job_grade', label: 'Job Grade / Equi Job Grade / Est Job Grade' },
  { value: 'skg', label: 'SKG' },
];

export const SPUR_DETAIL_FILTERS = [
  { value: 'job_family', label: 'Job Family' },
  { value: 'sub_job_family', label: 'Sub Job Family' },
  { value: 'function', label: 'Job Function' },
  { value: 'spur_name', label: 'SPUR Name' },
];

export const CRITICAL_POSITION_FILTERS = [{ value: 'position_label', label: 'Critical Position Category' }];

export const POSITION_FINDER_FILTER_OPTIONS = {
  BUSINESS: 'business',
  SECTOR: 'sector',
  OPU: 'opu',
  DIVISION: 'division',
  DEPARTMENT: 'department',
  JOB_GRADE: 'job_grade',
  SKG: 'skg',
  JOB_FAMILY: 'job_family',
  SUB_JOB_FAMILY: 'sub_job_family',
  JOB_FUNCTION: 'function',
  SPUR_NAME: 'spur_name',
  CRITICAL_POSITION_CATEGORY: 'position_label',
};

export const POSITION_FINDER_FILTERS = [
  { value: 'business', label: 'Business' },
  { value: 'sector', label: 'Sector' },
  { value: 'opu', label: 'OPU' },
  { value: 'division', label: 'Division' },
  { value: 'department', label: 'Department' },
  { value: 'job_grade', label: 'Job Grade / Equi Job Grade / Est Job Grade' },
  { value: 'skg', label: 'SKG' },
  { value: 'job_family', label: 'Job Family' },
  { value: 'sub_job_family', label: 'Sub Job Family' },
  { value: 'function', label: 'Job function' },
  { value: 'spur_name', label: 'SPUR Name' },
  { value: 'position_label', label: 'Critical Position Category' },
];

export const NATIONALITY_OPTIONS = ['Nationality', 'State of Birth'];
export const NATIONALITY_FIELD_MAP = {
  Nationality: 'nationality',
  'State of Birth': 'state_of_birth',
};

export const HOMEPAGE_MAMANGEMENT = 'Homepage Management';

export const PROPOSAL_LABEL = {
  SELECT_ALL: 'select_all',
  // STAFF_DETAILS
  STAFF_DETAILS: 'staff_details',
  PROFILE_PICTURE: 'profile_picture',
  STAFF_NAME: 'staff_name',
  STAFF_ID: 'staff_id',
  AGE: 'age',
  RETIREMENT_DATE: 'retirement_date',
  CURRENT_POSITION: 'current_position',
  WORK_LOCATION: 'work_location',
  STAFF_REPORT_TO: 'report_to',
  STAFF_JG: 'jg',
  YIP: 'yip',
  SG: 'sg',
  YISG: 'yisg',
  NOTE: 'note',
  TALENT_CATEGORY: 'talent_category',
  SUCCESSOR_TO_1ST_LINE: 'successor_to_1st_line',
  REPLACEMENT: 'replacement',
  PREVIOUS_CONTRACT_DETAILS: 'previous_contract_details',
  PREVIOUS_SECONDMENT: 'previous_secondment',
  // POSITION_DETAILS
  PROPOSED_POSITION: 'proposed_position',
  POSITION_DETAILS: 'position_details',
  POSITION_CODE: 'position_code',
  POSITION_JG: 'position_jg',
  LOCATION: 'location',
  POSITION_REPORTS_TO: 'position_reports_to',
  CURRENT_INCUMBENT: 'current_incumbent',
  IDENTIFIED_SUCCESSORS: 'identified_successors',
  // JUSTIFICATION
  JUSTIFICATION: 'justification',
  PERFORMANCE_RATING: 'performance_rating',
  BEPCB: 'bepcb',
  JUSTIFICATION_NESTED: 'justification_nested',
  // Decision
  DECISION: 'decision',
  APPOINTMENT_AS: 'appointment_as',
  DECISION_REMARKS: 'decision_remarks',
  EFFECTIVE_START_DATE: 'effective_start_date',
  EFFECTIVE_END_DATE: 'effective_end_date',
  CONTRACT_START_DATE: 'contract_start_date',
  CONTRACT_END_DATE: 'contract_end_date',
  CONTRACT_DURATION: 'contract_duration',
  DECISION_NESTED: 'decision_nested',
  REMARKS: 'remarks',
};

export const PROPOSAL_SELECT_OPTIONS = [
  { label: 'Select All', value: PROPOSAL_LABEL.SELECT_ALL },
  {
    label: 'Staff Details',
    value: PROPOSAL_LABEL.STAFF_DETAILS,
    children: [
      { label: 'Profile Picture', value: PROPOSAL_LABEL.PROFILE_PICTURE },
      { label: 'Name', value: PROPOSAL_LABEL.STAFF_NAME },
      { label: 'SN', value: PROPOSAL_LABEL.STAFF_ID },
      { label: 'Age', value: PROPOSAL_LABEL.AGE },
      { label: 'Retirement Date', value: PROPOSAL_LABEL.RETIREMENT_DATE },
      { label: 'Current Position', value: PROPOSAL_LABEL.CURRENT_POSITION },
      { label: 'Location', value: PROPOSAL_LABEL.WORK_LOCATION },
      { label: 'Reports to', value: PROPOSAL_LABEL.STAFF_REPORT_TO },
      { label: 'JG', value: PROPOSAL_LABEL.STAFF_JG },
      { label: 'YIP', value: PROPOSAL_LABEL.YIP },
      { label: 'SG', value: PROPOSAL_LABEL.SG },
      { label: 'YiSG', value: PROPOSAL_LABEL.YISG },
      { label: 'Notes', value: PROPOSAL_LABEL.NOTE },
      { label: 'Talent Category', value: PROPOSAL_LABEL.TALENT_CATEGORY },
      {
        label: 'Successor to (1st Line)',
        value: PROPOSAL_LABEL.SUCCESSOR_TO_1ST_LINE,
      },
      { label: 'Replacement', value: PROPOSAL_LABEL.REPLACEMENT },
      {
        label: 'Previous Contract Details',
        value: PROPOSAL_LABEL.PREVIOUS_CONTRACT_DETAILS,
      },
      {
        label: 'Previous Secondment',
        value: PROPOSAL_LABEL.PREVIOUS_SECONDMENT,
      },
    ],
  },
  {
    label: 'Proposed Position',
    value: PROPOSAL_LABEL.PROPOSED_POSITION,
    children: [
      { label: 'Position Details', value: PROPOSAL_LABEL.POSITION_DETAILS },
      { label: 'PID', value: PROPOSAL_LABEL.POSITION_CODE },
      { label: 'JG', value: PROPOSAL_LABEL.POSITION_JG },
      { label: 'Location', value: PROPOSAL_LABEL.LOCATION },
      { label: 'Reports to', value: PROPOSAL_LABEL.POSITION_REPORTS_TO },
      { label: 'Current Incumbent', value: PROPOSAL_LABEL.CURRENT_INCUMBENT },
      {
        label: 'Identified Successors',
        value: PROPOSAL_LABEL.IDENTIFIED_SUCCESSORS,
      },
    ],
  },
  {
    label: 'Justification',
    value: PROPOSAL_LABEL.JUSTIFICATION,
    children: [
      {
        label: 'Performance Rating/Rating',
        value: PROPOSAL_LABEL.PERFORMANCE_RATING,
      },
      { label: 'BePCB', value: PROPOSAL_LABEL.BEPCB },
      { label: 'Justification', value: PROPOSAL_LABEL.JUSTIFICATION_NESTED },
    ],
  },
  {
    label: 'Decision',
    value: PROPOSAL_LABEL.DECISION,
    children: [
      { label: 'Decision Remarks', value: PROPOSAL_LABEL.DECISION_REMARKS },
      {
        label: 'Effective Start Date',
        value: PROPOSAL_LABEL.EFFECTIVE_START_DATE,
      },
      { label: 'Effective End Date', value: PROPOSAL_LABEL.EFFECTIVE_END_DATE },
      {
        label: 'Contract Start Date',
        value: PROPOSAL_LABEL.CONTRACT_START_DATE,
      },
      { label: 'Contract End Date', value: PROPOSAL_LABEL.CONTRACT_END_DATE },
      { label: 'Duration', value: PROPOSAL_LABEL.CONTRACT_DURATION },
      { label: 'Decision', value: PROPOSAL_LABEL.DECISION_NESTED },
      { label: 'Remarks', value: PROPOSAL_LABEL.REMARKS },
    ],
  },
];

export const PROPOSAL_TYPE_OPTIONS = [
  'Appointment with Promotion',
  'Appointment with Probation',
  'Appointment of Country Chair',
  'Secondment to Other Entities',
  'Extension of Secondment',
  'Appointment as Contract Retiree',
  'Change of Position for Contract Retiree',
  'Renewal of Contract Retiree',
  'Confirmation of Probation',
  'Mutual Separation Scheme',
  'Other Proposal',
];

export const PROPOSAL_TYPES = {
  APPOINTMENT_WITH_PROMOTION: 'Appointment with Promotion',
  APPOINTMENT_WITH_PROBATION: 'Appointment with Probation',
  APPOINTMENT_OF_COUNTRY_CHAIR: 'Appointment of Country Chair',
  SECONDMENT_TO_OTHER_ENTITIES: 'Secondment to Other Entities',
  EXTENSION_OFSECONDMENT: 'Extension of Secondment',
  APPOINTMENT_AS_CONTRACT_RETRIEE: 'Appointment as Contract Retiree',
  CHANGE_OF_POSITION_FOR_CONTRACT_RETRIEE: 'Change of Position for Contract Retiree',
  RENEWAL_OF_CONTRACT_RETRIEE: 'Renewal of Contract Retiree',
  CONFIRMATION_OF_PROBATION: 'Confirmation of Probation',
  MUTUAL_SEPARATION_SCHEMA: 'Mutual Separation Scheme',
  OTHER_PROPOSAL: 'Other Proposal',
};

export const PERMISSION_ORDER = [
  'Talent Profile',
  'Talent DashBoard',
  'Talent Comparison',
  'Succession Planning',
  'TC Meeting',
  'PDC Meeting',
  'Talent Finder',
  'Admin',
  'Mobility Simulation',
  'Report Builder',
];

export const PROPOSAL_TYPES_JUSTIFICATIONS = {
  APPOINTMENT_WITH_PROMOTION: [
    'Writing Tips :',

    '1. Include back story - when the incumbent was first appointed to SG P11. For example. If the person is sitting on P10 first then include that and when he was appointed as P11.',

    '2. Include the incumbent work achievement and the impact it brings.',

    '3. Include his leadership styles/the incumbent strengths.',

    '4. Include summary on EDGE.',
  ],
  APPOINTMENT_WITH_PROBATION: [
    'Writing Tips :',

    '1. Include back story - when the incumbent was first appointed to SG P11. For example. If the person is sitting on P10 first then include that and when he was appointed as P11.',

    '2. Include the incumbent work achievement and the impact it brings.',

    '3. Include his leadership styles/the incumbent strengths.',

    '4. Include summary on EDGE.',
  ],
  APPOINTMENT_OF_COUNTRY_CHAIR: [
    'Writing Tips',

    '1. Elaborate on his achievements in the current role/country.',

    `For example: PETRONAS' aspiration in <country> cuts across <which businesses> in all growth strategy areas. As the country chair, XX key role is expanding PETRONAS footprint in <country> by linking together all existing and future businesses and ensure achievement of MFT 50.30.0.`,

    `2. Elaborate on the talent's strengths, leadership skill.`,

    '3. Explain how this appointment as Country Chair will shape the talent.',

    `For example: With this appointment he is responsible to strategise, direct and control overall Indonesia operations with respect to the PETRONAS business aspirations whilst safeguarding the long-term interest of key stakeholders to maximise return on investment and secure best market positioning for PETRONAS in the region.`,
  ],
  SECONDMENT_TO_OTHER_ENTITIES: [
    'Writing Tips : ',

    '1. Background of the requirement of secondment.',
    '2. What is the scope of the role?',
    '3. Include why the talent is the best fit talent for the role. Please include achievements in current and previous roles that can bring value to this new role.',
  ],
  EXTENSION_OFSECONDMENT: [
    'Writing Tips : ',

    '1. Has it been deliberated in Business PDC or any meeting platforms? If yes, indicate when and what was the decision.',

    '2. Please include the achievements in the current role.',

    '3. Include past achievements (which is relevant) and current work achievement that warrant the incumbent to be appointed to the current position.{The WHY parts}.',

    '4. Then, include the reason to extend the talent on the role.',
  ],
  APPOINTMENT_AS_CONTRACT_RETRIEE: [
    'Writing Tips :',

    '1. Please include why the incumbent is the best candidate to be appointed to the role.',

    '2. Include past experience & achievements (which is relevant) and current work achievement that warrant the incumbent to be appointed to the current position.{The WHY parts}.',

    '3. Then, include what is the expected deliverables for the talent during this contract duration.',

    '4. Include summary on EDGE.',
  ],
  CHANGE_OF_POSITION_FOR_CONTRACT_RETRIEE: [
    'Writing Tips : ',

    '1. Please include why the incumbent is the best candidate to be appointed to the role.',

    '2. Include past achievements (which is relevant) and current work achievement that warrant the incumbent to be appointed to the current position.{The WHY parts}.',

    `3. Then, include on the leadership behaviours/incumbent's strengths.`,

    '4. Include summary on EDGE.',
  ],
  RENEWAL_OF_CONTRACT_RETRIEE: [
    'Writing Tips : ',

    '1. Please include why the incumbent is the best candidate to be appointed to the role.',

    '2. Include past experience & achievements (which is relevant) and current work achievement that warrant the incumbent to be appointed to the current position.{The WHY parts}.',

    '3. Then, include what is the expected deliverables for the talent during this contract duration.',
  ],
  CONFIRMATION_OF_PROBATION: [
    'Writing Tips :',

    '1. Include backstory. When was the incumbent first appointed to the position (refer example as follows).',

    '2. Include what was the achievement during the probation.',

    '3. Refer to the previous appointment and check MOM what was the gap and items to be monitored during probation. Please update what the incumbent has covered in order to close the gaps.',

    `4. Include the incumbents' strengths and leadership behaviour.`,

    '5. Include summary on EDGE.',
  ],
  MUTUAL_SEPARATION_SCHEMA: [
    'Writing Tips',

    '1. Background when the talent applied for ELSO <Some chronology>.',

    '2. Include the WHY the incumbent applied.',

    '3. Has it been deliberated in Business PDC or any meeting platforms? If yes indicate when and what was the decision.',
  ],
  OTHER_PROPOSAL: [],
};

export const MEETING_CATEGORY_VALUES = {
  PETRONAS: 'PETRONAS',
  BUSINESS: 'Business',
  OPU: 'OPU',
  DIVISION: 'Division',
  SECTOR: 'Sector',
  SKG: 'SKG',
  JOB_FAMILY: 'Job Family',
};

export const MEETING_CATEGORY_OPTIONS = [
  {
    id: '1',
    name: MEETING_CATEGORY_VALUES.PETRONAS,
  },
  {
    id: '2',
    name: MEETING_CATEGORY_VALUES.BUSINESS,
  },
  {
    id: '3',
    name: MEETING_CATEGORY_VALUES.OPU,
  },
  {
    id: '4',
    name: MEETING_CATEGORY_VALUES.DIVISION,
  },
  {
    id: '5',
    name: MEETING_CATEGORY_VALUES.SECTOR,
  },
  {
    id: '6',
    name: MEETING_CATEGORY_VALUES.SKG,
  },
  {
    id: '7',
    name: MEETING_CATEGORY_VALUES.JOB_FAMILY,
  },
];

export const PDC_MEETING_FIELDS = {
  meeting_name: 'Meeting Name',
  date: 'Meeting Date',
  category: 'Meeting Category',
  start_time: 'Meeting Start time',
  end_time: 'Meeting End time',
  location: 'Meeting Location',
  chairman: 'Meeting Chairman',
  meeting_committee: 'Meeting Committee Members',
  meeting_facilitator: 'Meeting Facilitators',
  meeting_secretariats: 'Meeting Secretariat',
  meeting_additional_view_accesses: 'Meeting Additional View Access',
};

export const PDC_PROPOSAL_TYPES = {
  SINGLE: 'single',
  MULTIPLE_TALENT: 'multiple_talent',
  MULTIPLE_POSITION: 'multiple_position',
};

export const NEW_ACCESS_LEVEL_OPTIONS = [
  {
    name: 'PETRONAS',
    id: 1,
  },
  {
    name: 'Business',
    id: 2,
  },
  {
    name: 'OPU',
    id: 3,
  },
  {
    name: 'Division',
    id: 4,
  },
  {
    name: 'Sector',
    id: 5,
  },
  {
    name: 'SKG',
    id: 6,
  },
  {
    name: 'Job Family',
    id: 7,
  },
];

export const FIELD_STYLE_DISABLED = {
  backgroundColor: '#f0f5fa',
  borderColor: '#cbd6e2',
  cursor: 'no-drop',
  color: '#15191e',
};

export const REMARK_MODE = {
  PDC: 'PDC',
};

export const REMARK_TYPE_OPTIONS_PDC = [
  { label: 'All', value: 'select-all' },
  { label: 'Development Plan', value: 'Development Plan' },
  { label: 'Next Mobility', value: 'Next Mobility' },
  { label: 'Dual Hatting', value: 'Dual Hatting' },
  { label: 'Others', value: 'Others' },
];

export const ADD_REMARK_SUCCESS_MESSAGE = 'Talent Remark successfully added.';
export const EDIT_REMARK_SUCCESS_MESSAGE = 'Talent Remark successfully edited.';
export const ASPIRATION_RISKS_SUCCESS_MESSAGE = 'Aspirations & Risks has been successfully saved.';
export const DELETE_REMARK_SUCCESS_MESSAGE = 'Talent Remark successfully deleted.';
export const FOOTER_COPYRIGHT = 'Copyright ©2024 PETRONAS. All rights reserved.';
export const UPLOAD_DOC_STATEMENT = [
  `myCareerX Talent Profile`,
  `BePCB Report for past 5 years`,
  `Organisation Structure where the proposed position resides`,
  `Endorsement from Business PDC`,
  `Disciplinary Record / IR Status`,
];
