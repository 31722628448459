import { cloneDeep, isEmpty, map, upperFirst } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteIcon } from '../../../../../../assets/img';
import SearchFunctionModal from '../../../../../../components/SearchFunctionModal/SearchFunctionModal';
import { fetchFunctionExperiences } from '../../../../../../services/talentProfiles';
import {
  CRITERIA_TYPE,
  INIT_PARAMS,
  MAX_INTEGER,
  NUMBER_OPTIONS,
  YES_NO_OPTIONS,
} from '../../../../../../utils/constants';
import {
  updateIsDeleteCriteria,
  updateIsEditCriteria,
  updateIsFirstInitSetMatching,
} from '../../../redux/manageCriteriaSetMatchingSlice';
import { CriteriaCheckbox } from '../AddCriteriaPopOut/styled';
import * as styles from './selected-criteria-form.module.scss';
import { CustomSelect } from './styled';

const NATIONALITY_OPTIONS = ['Nationality', 'State of Birth'];
const NATIONALITY_FIELD_MAP = { Nationality: 'nationality', 'State of Birth': 'state_of_birth' };

const CriteriaColumns = () => {
  const dispatch = useDispatch();
  const { set_matching_criteria, set_index, isValidForm } = useSelector(
    (state) => state.app.adminModule.manageCriteriaSetMatching
  );
  const isValid = useMemo(() => {
    return isValidForm;
  }, [isValidForm]);

  const isEdit = useMemo(() => {
    return set_matching_criteria[set_index]?.isEditSetMatching;
  }, [set_matching_criteria, set_index]);

  const checkNullString = (value) => {
    if (value === 'null') return null;
    return value;
  };

  const checkNullFunctionValue = (value) => {
    if (value === 'null') return null;
    return value ? map(value.split(' '), upperFirst).join(' ') : null;
  };

  const onBlurInput = (e) => {
    let value = e.target.value;
    if (!value || value === '') {
      e.target.value = '';
      return;
    }
    value = Math.round(value);
    const comparedMinValue = value < 0 ? 0 : value;
    value = value > MAX_INTEGER ? MAX_INTEGER : comparedMinValue;
    e.target.value = value;
    return value;
  };

  const handleDeleteItem = (setInputValue, inputValue, initValue, index) => {
    dispatch(updateIsDeleteCriteria({ set_index, value: true }));
    dispatch(updateIsEditCriteria({ set_index, value: true }));
    setInputValue((prevState) => {
      const cloneState = cloneDeep(prevState);
      if (inputValue.value.length > 1) {
        cloneState.value.splice(index, 1);
        return cloneState;
      }
      return { isUpdate: true, value: [initValue] };
    });
  };

  const inputData = (index, type, value, setInputValue) => {
    dispatch(updateIsFirstInitSetMatching(false));
    dispatch(updateIsEditCriteria({ set_index, value: true }));
    setInputValue((preState) => {
      const cloneState = cloneDeep(preState);
      cloneState.value[index][type] = value;
      cloneState.isUpdate = true;
      if (type === 'function') {
        cloneState.value[index].content = null;
      }
      return cloneState;
    });
  };

  const user = useSelector((state) => state.user);
  const roleUser = { roleActive: user.roleActive, permissions: user.permissions };
  //Edit state
  const [functionParams, setFunctionParams] = useState(INIT_PARAMS);
  const [functions, setFunctions] = useState([]);
  const [searchIndex, setSearchIndex] = useState(null);

  const fetchFunctionList = useCallback(async () => {
    const { search, page, fetching, searching } = functionParams;
    if (!roleUser?.roleActive?.roleId || !roleUser?.permissions || (!fetching && !searching))
      return;
    try {
      let res = await fetchFunctionExperiences({
        roleId: roleUser.roleActive.roleId,
        permissions: roleUser.permissions,
        search,
        page,
        limit: INIT_PARAMS.limit,
      });
      if (res.status !== 200) throw Error();
      const result = res.data.result;
      const isAll = result.length < INIT_PARAMS.limit;

      if (fetching) {
        setFunctions((prev) => [...prev, ...res.data.result]);
        setFunctionParams((prev) => ({ ...prev, fetching: false, isAll }));
      }

      if (searching) {
        setFunctions(result);
        setFunctionParams((prev) => ({ ...prev, searching: false, isAll }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [functionParams, roleUser.roleActive.roleId, roleUser.permissions]);

  useEffect(() => {
    fetchFunctionList();
  }, [fetchFunctionList]);

  return {
    EdgeColumns: (data, inputValue, setInputValue) => {
      const { criteria, content } = data;
      if (!inputValue.isUpdate) {
        dispatch(updateIsEditCriteria({ set_index, value: true }));
        setInputValue(() => {
          return {
            value: content.map((item) => ({
              content: item,
              proficiency: null,
              spike: false,
            })),
            isUpdate: true,
          };
        });
      }
      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
          width: '30%',
        },
        {
          title: 'Content',
          render: () => (
            <>
              {!isEmpty(content) &&
                content.map((item, index) => (
                  <div key={index}>
                    <div>{item}</div>
                  </div>
                ))}
            </>
          ),
          width: '40%',
        },
        {
          align: 'center',
          title: 'Proficiency',
          render: () => (
            <>
              {!isEmpty(content) &&
                content.map((item, index) => (
                  <div key={index}>
                    <CustomSelect
                      value={inputValue.value.find((i) => i.content === item)?.proficiency || null}
                      disabled={!isEdit}
                      placeholder="Select"
                      size="middle"
                      style={{ width: 86 }}
                      onChange={(value) => {
                        inputData(
                          inputValue.value.findIndex((e) => e.content === item),
                          'proficiency',
                          value,
                          setInputValue
                        );
                      }}
                      className={
                        !inputValue.value.find((i) => i.content === item)?.proficiency &&
                        !isValid &&
                        'isInvalid'
                      }
                      options={NUMBER_OPTIONS.map((item) => ({ label: item, value: item }))}
                    />
                  </div>
                ))}
            </>
          ),
        },
        {
          align: 'center',
          title: 'Spike',
          render: () => (
            <>
              {!isEmpty(content) &&
                content.map((item, index) => (
                  <div key={index}>
                    <CriteriaCheckbox
                      className="justify-content-center"
                      disabled={!isEdit}
                      onChange={(e) => {
                        inputData(
                          inputValue.value.findIndex((e) => e.content === item),
                          'spike',
                          e.target.checked,
                          setInputValue
                        );
                      }}
                      checked={inputValue.value.find((i) => i.content === item)?.spike}
                    />
                  </div>
                ))}
            </>
          ),
        },
      ];
    },

    AttributesColumns: (data, inputValue, setInputValue) => {
      const { criteria, content } = data;
      const initValue = {
        content: null,
        spike: false,
      };

      if (!inputValue.isUpdate) {
        setInputValue({ isUpdate: true, value: [initValue] });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '30%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          width: '40%',
          title: 'Content',
          render: () => (
            <>
              {inputValue.value.map((item, index) => (
                <div key={index}>
                  <CustomSelect
                    value={checkNullString(item?.content)}
                    disabled={!isEdit}
                    size="middle"
                    style={{ width: 220, zIndex: 1 }}
                    placeholder="Select"
                    onChange={(value) => {
                      inputData(index, 'content', value, setInputValue);
                    }}
                    options={content?.map((item) => ({ label: item, value: item }))}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: 'Spike',
          render: () => (
            <>
              {inputValue.value.map((item, index) => (
                <div key={index}>
                  <CriteriaCheckbox
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    className="justify-content-center"
                    checked={item?.spike}
                    disabled={!isEdit}
                    onClick={(e) => {
                      inputData(index, 'spike', e.target.checked, setInputValue);
                    }}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <button
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    disabled={inputValue.value.length <= 1 || !isEdit}
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteItem(setInputValue, inputValue, initValue, index)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              ))}
            </>
          ),
        },
      ];
    },

    TopTalentExperienceColumns: (data, inputValue, setInputValue) => {
      const { criteria, content } = data;
      const initValue = {
        content: null,
        spike: false,
      };

      if (!inputValue.isUpdate) {
        setInputValue({ isUpdate: true, value: [initValue] });
      }

      return [
        {
          title: 'Criteria',
          width: '40%',
          dataIndex: 'criteria',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          width: '40%',
          title: 'Content',
          render: () => (
            <>
              {inputValue.value.map((item, index) => (
                <div key={index}>
                  <CustomSelect
                    value={checkNullString(item?.content)}
                    disabled={!isEdit}
                    size="middle"
                    style={{ width: 220, zIndex: 1 }}
                    placeholder="Select"
                    onChange={(value) => {
                      inputData(index, 'content', value, setInputValue);
                    }}
                    options={content?.map((item) => ({ label: item, value: item }))}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <button
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    disabled={inputValue.value.length <= 1 || !isEdit}
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteItem(setInputValue, inputValue, initValue, index)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              ))}
            </>
          ),
        },
      ];
    },

    PersonalityArchetypeColumns: (data, inputValue, setInputValue) => {
      const { criteria, content } = data;
      if (!inputValue.isUpdate) {
        dispatch(updateIsEditCriteria({ set_index, value: true }));
        setInputValue(() => {
          return {
            isUpdate: true,
            value: content.map((item) => ({
              content: item,
              spike: null,
            })),
          };
        });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '30%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          width: '40%',
          title: 'Content',
          render: () => (
            <>
              {!isEmpty(content) &&
                content.map((item, index) => (
                  <div key={index}>
                    <div>{item}</div>
                  </div>
                ))}
            </>
          ),
        },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {!isEmpty(content) &&
                content.map((item, index) => (
                  <div key={index}>
                    <CustomSelect
                      style={{ width: 220, zIndex: 1 }}
                      disabled={!isEdit}
                      value={checkNullString(
                        inputValue.value.find((i) => i.content === item)?.spike
                      )}
                      placeholder="Select"
                      onChange={(value) => {
                        inputData(
                          inputValue.value.findIndex((e) => e.content === item),
                          'spike',
                          value,
                          setInputValue
                        );
                      }}
                      size="middle"
                      options={YES_NO_OPTIONS}
                    />
                  </div>
                ))}
            </>
          ),
        },
      ];
    },

    SMAEdgeColumns: (data, inputValue, setInputValue) => {
      const { criteria, content } = data;
      if (!inputValue.isUpdate) {
        dispatch(updateIsEditCriteria({ set_index, value: true }));
        setInputValue(() => {
          return {
            isUpdate: true,
            value:
              !isEmpty(content) &&
              content.map((item) => ({
                content: item,
                proficiency: null,
              })),
          };
        });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '30%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          width: '40%',
          title: 'Content',
          render: () => (
            <>
              {!isEmpty(content) &&
                content.map((item, index) => (
                  <div key={index}>
                    <div>{item}</div>
                  </div>
                ))}
            </>
          ),
        },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {!isEmpty(content) &&
                content.map((item, index) => (
                  <div key={index}>
                    <CustomSelect
                      value={inputValue.value.find((i) => i.content === item)?.proficiency || null}
                      disabled={!isEdit}
                      placeholder="Select"
                      size="middle"
                      style={{ width: 86 }}
                      onChange={(value) => {
                        inputData(
                          inputValue.value.findIndex((e) => e.content === item),
                          'proficiency',
                          value,
                          setInputValue
                        );
                      }}
                      options={NUMBER_OPTIONS.map((item) => ({ label: item, value: item }))}
                    />
                  </div>
                ))}
            </>
          ),
        },
      ];
    },

    SuccessorVPPositionColumns: (data, inputValue, setInputValue) => {
      const { criteria } = data;

      if (!inputValue.isUpdate) {
        setInputValue({ isUpdate: true, value: [{ content: null }] });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '30%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          width: '40%',
          title: 'Content',
          render: () => (
            <div>
              <CustomSelect
                value={checkNullString(inputValue?.value[0]?.content)}
                disabled={!isEdit}
                placeholder="Select"
                size="middle"
                onChange={(value) => {
                  inputData(0, 'content', value, setInputValue);
                }}
                style={{ width: 220, zIndex: 1 }}
                options={YES_NO_OPTIONS}
              />
            </div>
          ),
        },
      ];
    },

    FunctionalColumns: (data, inputValue, setInputValue, functionContents, setFunctionContents) => {
      const { criteria } = data;
      const initValue = {
        function: null,
        content: null,
        year: '',
      };

      if (!inputValue.isUpdate) {
        setInputValue({ isUpdate: true, value: [initValue] });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '30%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          align: 'center',
          title: 'Function',
          render: () => (
            <>
              {inputValue.value.map((record, index) => (
                <div key={index}>
                  <CustomSelect
                    showSearch
                    placeholder={`Search by Function, Job Family & Sub-Job Family`}
                    value={checkNullFunctionValue(inputValue.value[index]?.function)}
                    disabled={!isEdit}
                    size="middle"
                    style={{ width: 220, zIndex: 1 }}
                    onChange={async (value) => {
                      inputData(index, 'function', value, setInputValue);
                      setFunctionContents({ ...functionContents, index });
                      setFunctionParams(INIT_PARAMS);
                    }}
                    options={data?.function?.map((item) => ({
                      label: checkNullFunctionValue(item),
                      value: item,
                    }))}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: 'Content',
          width: '30%',
          render: () => (
            <>
              {inputValue.value.map((record, index) => (
                <div key={index} className={styles.fieldWrapper}>
                  <SearchFunctionModal
                    searchIndex={searchIndex}
                    setSearchIndex={setSearchIndex}
                    record={record}
                    rowIndex={0}
                    searchParams={functionParams}
                    setSearchParams={setFunctionParams}
                    functions={functions}
                    inputValue={inputValue.value[index]?.content}
                    setInputValue={setInputValue}
                    inputData={inputData}
                    index={index}
                    disabled={!isEdit || isEmpty(record?.function) || record?.function === 'null'}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: 'Year (Duration)',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <input
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    disabled={!isEdit}
                    className={styles.magicInput}
                    value={inputValue.value[index]?.year}
                    type="number"
                    onChange={(e) => {
                      const value = onBlurInput(e);
                      inputData(index, 'year', value, setInputValue);
                    }}
                  />
                </div>
              ))}
            </>
          ),
        },
        // {
        //   align: 'center',
        //   title: 'Complementary',
        //   render: () => (
        //     <>
        //       {inputValue.value.map((item, index) => (
        //         <div key={index}>
        //           <CriteriaCheckbox
        //             style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
        //             checked={item.complementary}
        //             disabled={!isEdit}
        //             className="justify-content-center"
        //             onClick={(e) => {
        //               inputData(index, 'complementary', e.target.checked, setInputValue);
        //             }}
        //           />
        //         </div>
        //       ))}
        //     </>
        //   ),
        // },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <button
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    disabled={inputValue.value.length <= 1 || !isEdit}
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteItem(setInputValue, inputValue, initValue, index)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              ))}
            </>
          ),
        },
      ];
    },

    FunctionalOptionalColumns: (
      data,
      inputValue,
      setInputValue,
      functionContents,
      setFunctionContents
    ) => {
      const { criteria } = data;
      const initValue = {
        function: null,
        content: null,
        spike: false,
      };

      if (!inputValue.isUpdate) {
        setInputValue({ isUpdate: true, value: [initValue] });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '30%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          align: 'center',
          title: 'Function',
          render: () => (
            <>
              {inputValue.value.map((item, index) => (
                <div key={index}>
                  <CustomSelect
                    showSearch
                    placeholder={`Search by Function, Job Family & Sub-Job Family`}
                    disabled={!isEdit}
                    size="middle"
                    value={checkNullFunctionValue(item?.function)}
                    style={{ width: 220, zIndex: 1 }}
                    onChange={(value) => {
                      inputData(index, 'function', value, setInputValue);
                      setFunctionContents({ ...functionContents, index });
                      setFunctionParams(INIT_PARAMS);
                    }}
                    options={data?.function?.map((item) => ({
                      label: checkNullFunctionValue(item),
                      value: item,
                    }))}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: 'Content',
          width: '30%',
          render: () => (
            <>
              {inputValue.value.map((record, index) => (
                <div key={index} className={styles.fieldWrapper}>
                  <SearchFunctionModal
                    searchIndex={searchIndex}
                    setSearchIndex={setSearchIndex}
                    record={record}
                    rowIndex={0}
                    searchParams={functionParams}
                    setSearchParams={setFunctionParams}
                    functions={functions}
                    inputValue={inputValue.value[index]?.content}
                    setInputValue={setInputValue}
                    inputData={inputData}
                    index={index}
                    disabled={!isEdit || isEmpty(record?.function) || record?.function === 'null'}
                  />
                </div>
              ))}
            </>
          ),
        },
        // {
        //   align: 'center',
        //   title: 'Complementary',
        //   render: () => (
        //     <>
        //       {inputValue.value.map((item, index) => (
        //         <div key={index}>
        //           <CriteriaCheckbox
        //             style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
        //         checked={item.complementary}
        //             disabled={!isEdit}
        //             className="justify-content-center"
        //             onClick={(e) => {
        //               inputData(index, 'complementary', e.target.checked, setInputValue);
        //             }}
        //           />
        //         </div>
        //       ))}
        //     </>
        //   ),
        // },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <button
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    disabled={inputValue.value.length <= 1 || !isEdit}
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteItem(setInputValue, inputValue, initValue, index)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              ))}
            </>
          ),
        },
      ];
    },

    BusinessExposureColumns: (data, inputValue, setInputValue) => {
      const { criteria, content } = data;
      const initValue = {
        content: null,
        year: '',
      };

      if (!inputValue.isUpdate) {
        setInputValue({ isUpdate: true, value: [initValue] });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '30%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          width: '40%',
          title: 'Content',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <CustomSelect
                    disabled={!isEdit}
                    style={{ width: 220, zIndex: 1 }}
                    value={checkNullString(inputValue.value[index]?.content)}
                    placeholder="Select"
                    size="middle"
                    onChange={(value) => {
                      inputData(index, 'content', value, setInputValue);
                    }}
                    options={content.map((item) => ({ label: item, value: item }))}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: 'Year (Duration)',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <input
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    className={styles.magicInput}
                    disabled={!isEdit}
                    value={inputValue.value[index]?.year}
                    type="number"
                    onChange={(e) => {
                      const value = onBlurInput(e);
                      inputData(index, 'year', value, setInputValue);
                    }}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <button
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    disabled={inputValue.value.length <= 1 || !isEdit}
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteItem(setInputValue, inputValue, initValue, index)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              ))}
            </>
          ),
        },
      ];
    },

    NationalityColumns: (data, inputValue, setInputValue) => {
      const { criteria } = data;
      const initValue = {
        function: null,
        content: null,
      };

      const isValidate = isValid?.find((f) => f.criteria === CRITERIA_TYPE.NATIANALITY_LOCALITY_REQUIREMENT);

      if (!inputValue.isUpdate) {
        setInputValue({ isUpdate: true, value: [initValue] });
      }

      return [
        {
          dataIndex: 'criteria',
          title: 'Criteria',
          width: '35%',
          render: () => (
            <div>
              <div>{criteria}</div>
            </div>
          ),
        },
        {
          title: 'Function',
          render: () => (
            <>
              {inputValue.value.map((record, index) => (
                <div key={index}>
                  <CustomSelect
                    showSearch
                    placeholder="Select"
                    value={record?.function || null}
                    disabled={!isEdit}
                    size="middle"
                    style={{ width: 300, zIndex: 1 }}
                    onChange={async (value) => {
                      inputData(index, 'function', value, setInputValue);
                      setFunctionParams(INIT_PARAMS);
                    }}
                    className={!record?.function && isValidate && 'isInvalid'}
                    options={NATIONALITY_OPTIONS.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          title: 'Content',
          width: '35%',
          render: () => (
            <>
              {inputValue.value.map((record, index) => (
                <div key={index} className={styles.fieldWrapper}>
                  <CustomSelect
                    showSearch
                    placeholder="Select"
                    value={record?.content || null}
                    disabled={!isEdit || !record?.function}
                    size="middle"
                    style={{ width: 300, zIndex: 1 }}
                    onChange={async (value) => {
                      inputData(index, 'content', value, setInputValue);
                    }}
                    className={!record?.content && isValidate && 'isInvalid'}
                    options={data?.[NATIONALITY_FIELD_MAP[record?.function]]?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </div>
              ))}
            </>
          ),
        },
        {
          align: 'center',
          title: '',
          render: () => (
            <>
              {inputValue.value.map((_, index) => (
                <div key={index}>
                  <button
                    style={!isEdit ? { cursor: 'no-drop', pointerEvents: 'none' } : {}}
                    disabled={inputValue.value.length <= 1 || !isEdit}
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteItem(setInputValue, inputValue, initValue, index)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              ))}
            </>
          ),
        },
      ];
    },
  };
};

export default CriteriaColumns;
