import { Dropdown, Pagination } from 'antd';
import __, {
  cloneDeep,
  concat,
  filter,
  find,
  get,
  isEmpty,
  isNaN,
  orderBy,
  size,
  unionBy,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AiAlert, AiButton, AiSpin } from '../../../assets/common';
import { tc_meeting_breadcrumb } from '../../../assets/img';
import { BreadCrumb, ModalAddExistingAgenda, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
import { useGetRoleInMeeting } from '../../../hooks/useGetRoleInMeeting';
import { useSecretarialOptions } from '../../../hooks/useSecretarialOptions';
import { talentReviewApi } from '../../../services/talentReviewApi';
import { meetingApi } from '../../../services/tcMeeting';
import { pushMessage } from '../../../store/alertMessageSlice';
import { resetAgendaDetail } from '../../../store/spTemplateSlice';
import {
  AGENDA_STATUS,
  DATE_TIME_FORMAT,
  MESSAGE_TYPES,
  NOTIFICATION_TYPE,
  PERMISSION,
  ROLE,
  ROUTE_BACK_HISTORY,
} from '../../../utils/constants';
import {
  convertMobilitySimulationAgenda,
  convertTalentReviewAgendaToPost,
  getDevelopmentPlanningData,
  isExistDataNotSave,
} from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import AgendaItem from '../components/AgendaItem/AgendaItem';
import MeetingStatus from '../components/MeetingStatus/MeetingStatus';
import StepBar from '../components/StepBar/StepBar';
import {
  clearAgendaState,
  removeAgenda,
  removeCreatedOrExistedAgenda,
  setInitialStateAgenda,
  updateChangeStatusList,
  updateRemoveAgendas,
} from '../redux/agendaSlice';
import { addOrUpdateMeeting, clearMeeting } from '../redux/meetingDetailSlice';
import { addOrUpdateStepper } from '../redux/stepperSlice';
import { AiContainerAgendas, AiContainerGrBtn, AiH3, AiTitle, AiTitleFlex } from './Styled';
import { useDectectNavigate } from '../../../hooks/useDetectNavigate';

const numEachPage = 6;

const Agendas = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { idMeeting } = useParams();
  const { createdAgenda, agendas, removeList, existedAgenda, changeStatusList } = useSelector(
    (state) => state.app.meetingModule.agenda
  );
  const [loadingGetAgendas, setLoadingGetAgendas] = useState(false);
  const [loadingDeleteMeeting, setLoadingDeleteMeeting] = useState(false);
  const { date } = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const meeting = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const [visibleExistingAgenda, setVisibleExistingAgenda] = useState(false);
  const [existingAgendas, setExistingAgendas] = useState([]);
  const [listAgendas, setListAgendas] = useState([]);
  const [visibleWithOutSave, setVisibleWithOutSave] = useState(false);
  const [currentAgendaId, setCurrentAgendaId] = useState('');
  const setIsDeletedAgenda = useState(false)[1];
  const [breadCrumbList] = useState([
    { name: 'TC Meeting', icon: tc_meeting_breadcrumb },
    { name: 'Meeting Details' },
    { name: 'Agenda' },
  ]);
  const [visibleRemoveMeeting, setVisibleRemoveMeeting] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  ///Paper forward
  const [agendaIdPaperForward, setAgendaIdPaperForward] = useState({});
  const [visibleAddAgendaPaperForward, setVisibleAddAgendaPaperForward] = useState(false);
  const [loadingAddAgendaPaperForward, setLoadingAddAgendaPaperForward] = useState(false);
  const [visibleRemoveAgenda, setVisibleRemoveAgenda] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);

  const createAgenda = () => {
    dispatch(setInitialStateAgenda());
    dispatch(resetAgendaDetail());
    history.push(
      `/meeting/${idMeeting}/agenda/create?history=${ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2}`
    );
  };

  const createMobilityAgenda = () => {
    history.push(
      `/meeting/${idMeeting}/mobility-agenda/create?history=${ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2}`
    );
  };

  const createTalentReviewAgenda = () => {
    history.push(
      `/meeting/${idMeeting}/talent-review-agenda/create?history=${ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2}`
    );
  };

  const handleConvertData = (agendas) => {
    const newArr = [];
    agendas.forEach((item) => {
      if (item.filter_type === 'SP') {
        const dateWithFormat = moment(date).format('YYYY-MM-DD');
        let itemConvert = {
          succession_planning_name: item.succession_planning_name,
          advocator_ids: item.advocators.map((advocator) => advocator.value),
          cc_ids: item.cc_list.map((cc) => cc.value),
          first_line_ids: item.first_line.map((item, idx) => ({
            staff_id: item.staff_id,
            ranking: idx + 1,
            is_talent_review: item.is_talent_review,
            agenda_as_main: item.agenda_as_main,
          })),
          second_line_ids: item.second_line.map((item, idx) => ({
            staff_id: item.staff_id,
            ranking: idx + 1,
            is_talent_review: item.is_talent_review,
            agenda_as_main: item.agenda_as_main,
          })),
          third_line_ids: item.third_line.map((item, idx) => ({
            staff_id: item.staff_id,
            ranking: idx + 1,
            is_talent_review: item.is_talent_review,
            agenda_as_main: item.agenda_as_main,
          })),
          position_codes: item.positions.map((item) => item.position_code),
          meeting_id: idMeeting,
          version: '0',
          end_time: moment(
            `${dateWithFormat} ${item.end_time}`,
            DATE_TIME_FORMAT.DATE_TIME
          ).toDate(),
          start_time: moment(
            `${dateWithFormat} ${item.start_time}`,
            DATE_TIME_FORMAT.DATE_TIME
          ).toDate(),
          remarks: item.remarks,
          action_type: item.action_type,
          agenda_as_main: item.agenda_as_main,
          agenda_as_main_positions: item?.agenda_as_main_positions || [],
          saved_card_view: [
            {
              line: 'first_line',
              view: item.cardSettings.first_line.map((m) => ({ ...m, checked: true })),
            },
            {
              line: 'second_line',
              view: item.cardSettings.second_line.map((m) => ({ ...m, checked: true })),
            },
            {
              line: 'third_line',
              view: item.cardSettings.third_line.map((m) => ({ ...m, checked: true })),
            },
          ],
        };
        if (isNaN(Number(item.agenda_id))) {
          newArr.push({
            agenda_id: null,
            ...itemConvert,
          });
        } else {
          newArr.push({
            agenda_id: item.agenda_id,
            ...itemConvert,
          });
        }
      }
    });
    return newArr;
  };

  const createOrUpdateAgendas = async () => {
    try {
      setLoadingGetAgendas(true);
      if (size(createdAgenda) > 0) {
        const sucessionList = handleConvertData(createdAgenda);
        const mobilityList = convertMobilitySimulationAgenda(createdAgenda, date, idMeeting);
        let talentReviewList = convertTalentReviewAgendaToPost(createdAgenda, date, idMeeting);
        if (!isEmpty(talentReviewList)) {
          talentReviewList.forEach((item) => {
            if (!isEmpty(item?.succession_planning)) {
              item.succession_planning = getDevelopmentPlanningData(item.succession_planning);
            }
          });
        }
        const remarksTalent = createdAgenda
          .filter((item) => !isEmpty(item.remarks_from_pending_action))
          .map((item) => ({
            ...item,
            remarks_from_pending_action: item.remarks_from_pending_action.map((v) => ({
              ...v,
              flag:
                item.advocators.map((cc) => cc.value).join() +
                (item.cc_list ? item.cc_list.map((cc) => cc.value).join() : '') +
                (item.hr_partners ? item.hr_partners.map((cc) => cc.value).join() : '') +
                (item.succession_planning_name || item.title),
            })),
          }))
          .map((item) => item.remarks_from_pending_action)
          .flat();
        if (size(sucessionList) > 0) {
          const rs = await meetingApi.createOrUpdateAgenda({
            body: {
              business: 'Business',
              agendas: sucessionList,
            },
            roleUser: user,
            meetingID: idMeeting,
          });
          const agendaCreated = get(rs, 'data.results') || [];
          if (!isEmpty(agendaCreated)) {
            agendaCreated.forEach(async (ac) => {
              const remarkBelongAgenda = remarksTalent.filter(
                (c) =>
                  ac.advocators.map((ad) => ad.user_id).join() +
                    ac.cc_list.map((ad) => ad.user_id).join() +
                    ac.agenda_name ===
                  c.flag
              );
              if (!isEmpty(remarkBelongAgenda)) {
                const headers = { role: roleActive };
                const remarkList = remarkBelongAgenda.map((item) => ({
                  staff_id: item?.staffId,
                  remark: item?.remarks,
                  category: item?.remarksCategory,
                  version: item?.version || 0,
                  remark_type: item?.remarkType,
                }));
                await meetingApi.addRemarks(
                  headers,
                  ac.agenda_id,
                  remarkList[0].staff_id,
                  remarkList
                );
              }
            });
          }
        }
        if (size(mobilityList) > 0) {
          await meetingApi.createAgendaMS({
            body: mobilityList,
            roleUser: user,
          });
        }
        if (size(talentReviewList) > 0) {
          const rs = await talentReviewApi.createTalentReviewAgenda({
            body: talentReviewList,
            roleUser: user,
          });
          const agendaCreated = get(rs, 'data.result') || [];
          if (!isEmpty(agendaCreated)) {
            agendaCreated.forEach(async (ac) => {
              const remarkBelongAgenda = remarksTalent.filter(
                (c) =>
                  ac.advocators.map((ad) => ad.user_id).join() +
                    ac.hr_partners.map((ad) => ad.user_id).join() +
                    ac.agenda_name ===
                  c.flag
              );
              if (!isEmpty(remarkBelongAgenda)) {
                const headers = { role: roleActive };
                const remarkList = remarkBelongAgenda.map((item) => ({
                  staff_id: item?.staffId,
                  remark: item?.remarks,
                  category: item?.remarksCategory,
                  version: item?.version || 0,
                  remark_type: item?.remarkType,
                }));
                await meetingApi.addRemarks(
                  headers,
                  ac.agenda_id,
                  remarkList[0].staff_id,
                  remarkList
                );
              }
            });
          }
        }
      }
      if (size(removeList) > 0) {
        await meetingApi.deleteAgendas({
          roleUser: user,
          agenda_ids: removeList.join(','),
        });
      }
      if (size(existedAgenda) > 0) {
        await meetingApi.addExistedAgendas({
          roleUser: user,
          body: {
            agenda_ids: existedAgenda.map((item) => item.agenda_id),
          },
          idMeeting,
        });
      }
      if (size(changeStatusList) > 0) {
        setLoadingAddAgendaPaperForward(true);
        await Promise.all(
          changeStatusList.map(async (item) => {
            try {
              await meetingApi.updateAgendaExisted({
                roleUser: user,
                agendaId: item.agendaId,
                type: item.type,
              });
            } catch (error) {
              showNotification(`Add Agenda failed`, NOTIFICATION_TYPE.FAILED);
            }
          })
        );
        setLoadingAddAgendaPaperForward(false);
      }
    } catch (error) {
      dispatch(
        pushMessage({ message: `Agenda hasn't been saved as draft.`, type: MESSAGE_TYPES.ERROR })
      );
    }

    await getAgendasWithoutInvitee();
    dispatch(clearAgendaState());
    dispatch(
      pushMessage({ message: `Agenda has been saved as draft.`, type: MESSAGE_TYPES.SUCCESS })
    );
  };
  const { data: meetingDetail } = useGetMeeting({ idMeeting });
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });

  const isAdditionalViewAccess = useMemo(() => {
    if (!idMeeting || isNaN(Number(idMeeting))) return false;

    return (
      !meetingRole?.is_secretariat_or_facilitator &&
      !meetingRole?.is_chairman &&
      !meetingRole?.is_commitee &&
      !isAdminRole &&
      meetingRole?.is_additional_view_access
    );
  }, [meetingRole, idMeeting, isAdminRole]);

  const handleDeleteAgenda = (agenda_id) => {
    setVisibleRemoveAgenda(true);
    setCurrentAgendaId(agenda_id);
  };

  const getAgendasWithoutInvitee = useCallback(async () => {
    if (!idMeeting) return;
    try {
      setLoadingGetAgendas(true);
      const response = await meetingApi.getAgendaList(
        { meetingID: idMeeting, business: '' },
        user?.roleActive.roleId
      );
      if (response.status === 200) {
        const count = __(response.data.result)
          .sortBy((item) => moment(item.start_time, true).format(DATE_TIME_FORMAT.TIME))
          .uniqBy('agenda_id')
          .value();
        setListAgendas(count);
        setLoadingGetAgendas(false);
      }
      setLoadingGetAgendas(false);
    } catch (error) {
      setLoadingGetAgendas(false);
      console.log(error);
    }
  }, [idMeeting, user.roleActive.roleId]);

  useEffect(() => {
    getAgendasWithoutInvitee();
  }, [getAgendasWithoutInvitee]);

  const { secretarialOptions } = useSecretarialOptions();
  const meeting_category_option = [
    {
      id: '1',
      name: 'PETRONAS',
    },
    {
      id: '2',
      name: 'Business',
    },
    {
      id: '3',
      name: 'OPU/Division',
    },
  ];
  useEffect(() => {
    if (meetingDetail) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
          category: find(meeting_category_option, {
            name: meetingDetail.category,
          }),
          meeting_secretariats: filter(secretarialOptions, ({ value }) => {
            return find(meetingDetail.meeting_secretariats, {
              bu_unit_id: value.bu_unit_id,
              business_type: value.business_type,
              role_id: value.role_id,
            });
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail, secretarialOptions, dispatch]);

  ///Implement Update Exised Agenda
  const handeUpdateAgendaExisted = async (agendaId, type) => {
    setAgendaIdPaperForward({ agendaId, type });
    setVisibleAddAgendaPaperForward(true);
  };
  ///Implement Show List Agenda
  const handleShowListAngeda = () => {
    let clonelistAgendas = unionBy(cloneDeep(listAgendas), 'agenda_id');
    ///Case remove agenda get from db
    if (size(removeList) > 0) {
      removeList.forEach((ele) => {
        clonelistAgendas = listAgendas.filter((item) => item.agenda_id !== ele);
      });
    }
    ///Case edit agenda that get from db
    if (size(createdAgenda) > 0) {
      createdAgenda.forEach((ele) => {
        let idx = clonelistAgendas.findIndex((item) => ele.agenda_id === item.agenda_id);
        if (idx !== -1) {
          clonelistAgendas[idx] = ele;
        }
      });
      if (size(agendas) > 0) {
        agendas.forEach((ele) => {
          clonelistAgendas = clonelistAgendas.filter((item) => ele.agenda_id !== item.agenda_id);
        });
      }
    }
    if (loadingGetAgendas) {
      return {
        header: '',
        content: (
          <AiSpin tip="Loading...">
            <AiAlert />
          </AiSpin>
        ),
      };
    }
    if (size(concat(agendas, clonelistAgendas)) <= 0) {
      return {
        header: 'Add Agenda in Meeting',
        content: '',
      };
    }
    let tempAgendaList = concat(agendas, clonelistAgendas).map((item) => {
      if (moment(item.start_time).isValid() && moment(item.end_time).isValid()) {
        return {
          ...item,
          start_time: moment(item.start_time).format(DATE_TIME_FORMAT.TIME),
          end_time: moment(item.end_time).format(DATE_TIME_FORMAT.TIME),
        };
      }
      return item;
    });

    tempAgendaList = orderBy(
      tempAgendaList,
      [(item) => moment(item.start_time, 'h:mm A').format('HH:mm')],
      ['esc']
    );
    changeStatusList.forEach((e) => {
      tempAgendaList.forEach((item, idx) => {
        if (item.agenda_id === e.agendaId) tempAgendaList[idx].status = e.status;
      });
    });
    tempAgendaList = uniqBy(tempAgendaList, 'agenda_id');
    return {
      totalAgendaList: tempAgendaList.length,
      header: 'Agenda',
      idsAgenda: tempAgendaList.map((item) => item.agenda_id),
      content: tempAgendaList.slice(minValue, maxValue).map((item) => {
        return (
          <div key={item.agenda_id}>
            <AgendaItem
              item={item}
              key={item.agenda_id}
              idMeeting={idMeeting}
              avatar={false}
              handleDeleteAgenda={handleDeleteAgenda}
              handeUpdateAgendaExisted={handeUpdateAgendaExisted}
              prevPageName={ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2}
              isAdditionalViewAccess={isAdditionalViewAccess}
            />
          </div>
        );
      }),
    };
  };
  let { header, content: agendaList, totalAgendaList, idsAgenda } = handleShowListAngeda();
  const exitAgenda = () => {
    dispatch(clearMeeting());
    dispatch(clearAgendaState());
    return setTimeout(() => {
      history.push('/calendar');
    }, 1000);
  };
  const nextAgenda = async () => {
    await createOrUpdateAgendas();
    dispatch(clearAgendaState());
    dispatch(
      addOrUpdateStepper({ currentTabActive: 3, currentTabFinished: [1, 2], tabsActive: [1, 2, 3] })
    );
    await handleUpdateStepNumber(3);
    history.push(`/meeting/overall/${idMeeting}`);
  };
  const handleUpdateStepNumber = async (stepNumber) => {
    await meetingApi.updateStepMeeting({
      body: {
        on_step: stepNumber,
      },
      idMeeting,
    });
  };
  const handleGoBack = async () => {
    await createOrUpdateAgendas();
    history.push(`/meeting/${idMeeting}`);
  };
  //IMPLEMENT CHECK PERMISSION ADD AGENDA
  const isAddAgenda = useMemo(() => {
    if (
      (meetingRole?.is_secretariat_or_facilitator &&
        user?.permissions.includes(PERMISSION.CREATE_MEETING) &&
        !isAdditionalViewAccess) ||
      isAdminRole
    ) {
      return true;
    }
  }, [meetingRole, user, isAdditionalViewAccess, isAdminRole]);

  const handleExit = () => {
    const addNewAgenda = agendas.find((x) => x.isEdit === true);
    if (
      !addNewAgenda &&
      existingAgendas.length <= 0 &&
      existedAgenda.length <= 0 &&
      changeStatusList.length === 0
    )
      return history.push('/calendar');
    setVisibleWithOutSave(true);
  };

  const handleChange = (value) => {
    setMinValue((value - 1) * numEachPage);
    setMaxValue(value * numEachPage);
  };

  const { showPopup, setShowPopup, handleKeepNavigate } = useDectectNavigate({
    blockNavigateByData: isExistDataNotSave({
      existedAgenda,
      createdAgenda,
      removeList,
      changeStatusList,
      editAgenda: agendas.find((x) => x.isEdit === true),
    }),
  });
  return (
    <>
      <BreadCrumb level={3} breadCrumbList={breadCrumbList} />
      <StepBar />
      <div style={{ margin: '10px 0' }}>
        <GlobalAlertMessage />
      </div>
      {visibleExistingAgenda && (
        <ModalAddExistingAgenda
          show={visibleExistingAgenda}
          setShow={setVisibleExistingAgenda}
          setExistingAgendas={setExistingAgendas}
          idsAgenda={idsAgenda}
        />
      )}

      <AiContainerAgendas>
        <AiTitle>
          <AiTitleFlex>
            <AiH3>{header}</AiH3>
            <MeetingStatus status={meeting.status} />
          </AiTitleFlex>
          {isAddAgenda && (
            <AiTitleFlex>
              <AiButton
                border="true"
                onKeyDown={() => {}}
                onClick={() => setVisibleExistingAgenda(true)}
              >
                Add Existing Agenda
              </AiButton>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <div onKeyDown={() => {}} onClick={() => createTalentReviewAgenda()}>
                          Talent Review
                        </div>
                      ),
                    },
                    {
                      key: '2',
                      label: (
                        <div onKeyDown={() => {}} onClick={() => createAgenda()}>
                          Succession Planning
                        </div>
                      ),
                    },
                    {
                      key: '3',
                      label: (
                        <div onKeyDown={() => {}} onClick={() => createMobilityAgenda()}>
                          Mobility Discussion
                        </div>
                      ),
                    },
                    {
                      key: '4',
                      label: <div>Others</div>,
                      disabled: true,
                    },
                  ],
                }}
                trigger={['click']}
                placement="bottomLeft"
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <AiButton mode="teal" className="ml-3">
                  Add New Agenda
                </AiButton>
              </Dropdown>
            </AiTitleFlex>
          )}
        </AiTitle>
        {agendaList}

        {totalAgendaList > 0 && (
          <Pagination
            total={totalAgendaList}
            showTotal={(total, range) => `Showing ${range[0]} to ${range[1]} of ${total} results`}
            defaultCurrent={1}
            defaultPageSize={numEachPage}
            showSizeChanger={false}
            onChange={handleChange}
          />
        )}
      </AiContainerAgendas>
      {!loadingGetAgendas && (
        <AiContainerGrBtn className="mb-4">
          <div>
            {!isAdditionalViewAccess && (
              <AiButton
                mode="danger"
                onKeyDown={() => {}}
                onClick={() => setVisibleRemoveMeeting(true)}
              >
                Delete Meeting
              </AiButton>
            )}
          </div>
          <div>
            <AiButton onKeyDown={() => {}} onClick={handleExit}>
              Exit
            </AiButton>
            {!isAdditionalViewAccess && (
              <AiButton className="ml-2" onKeyDown={() => {}} onClick={createOrUpdateAgendas}>
                Save as Draft
              </AiButton>
            )}
            <AiButton className="ml-2" onKeyDown={() => {}} onClick={handleGoBack}>
              Back
            </AiButton>
            <AiButton mode="teal" className="ml-2" onKeyDown={() => {}} onClick={nextAgenda}>
              Next
            </AiButton>
          </div>
        </AiContainerGrBtn>
      )}
      <ModelTC
        info={{
          type: 'deleteMeeting',
          visible: visibleRemoveMeeting,
          setVisible: setVisibleRemoveMeeting,
          handleSubmit: async () => {
            setLoadingDeleteMeeting(true);
            try {
              let res = await meetingApi.deleteMeeting({ idMeeting });
              if (res.status === 200) {
                setVisibleRemoveMeeting(false);
                setLoadingDeleteMeeting(false);
                history.push('/calendar');
              }
            } catch (error) {
              showNotification(`Delete Meeting failed`, NOTIFICATION_TYPE.FAILED);
              setVisibleRemoveMeeting(false);
              setLoadingDeleteMeeting(false);
            }
          },
          onClose: () => {},
          loading: loadingDeleteMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'paperForward',
          visible: visibleAddAgendaPaperForward,
          setVisible: setVisibleAddAgendaPaperForward,
          handleSubmit: async () => {
            let changeStatusListCopy = cloneDeep(changeStatusList);
            changeStatusListCopy.push({
              ...agendaIdPaperForward,
              status: AGENDA_STATUS.PAPER_BRING,
            });
            dispatch(updateChangeStatusList(changeStatusListCopy));
            setVisibleAddAgendaPaperForward(false);
            setAgendaIdPaperForward(false);
          },
          onClose: () => {},
          loading: loadingAddAgendaPaperForward,
        }}
      />
      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: visibleWithOutSave,
          setVisible: setVisibleWithOutSave,
          onClose: () => {
            exitAgenda();
          },
          handleSubmit: async () => {
            await createOrUpdateAgendas();
            setVisibleWithOutSave(false);
            return setTimeout(() => {
              history.push('/calendar');
            }, 2000);
          },
        }}
      />
      <ModelTC
        info={{
          type: 'deleteAgenda',
          visible: visibleRemoveAgenda,
          setVisible: setVisibleRemoveAgenda,
          handleSubmit: async () => {
            let idxEleAgendaHasCreated = listAgendas.findIndex(
              (item) => item.agenda_id === currentAgendaId
            );
            if (idxEleAgendaHasCreated !== -1) {
              setListAgendas((preState) => {
                return preState.filter((item) => item.agenda_id !== currentAgendaId);
              });
              dispatch(updateRemoveAgendas(currentAgendaId));
            } else {
              let idxEle = agendas.findIndex((item) => item.agenda_id === currentAgendaId);
              if (idxEle !== -1) {
                dispatch(removeAgenda(currentAgendaId));
                dispatch(removeCreatedOrExistedAgenda(currentAgendaId));
              }
            }
            setIsDeletedAgenda(true);
            setVisibleRemoveAgenda(false);
          },
          onClose: () => {},
        }}
      />
      <ModelTC
        info={{
          type: 'leaveWhileAddAgenda',
          visible: showPopup,
          setVisible: setShowPopup,
          handleSubmit: async () => {
            await createOrUpdateAgendas();
            handleKeepNavigate();
            setShowPopup(false);
          },
          onClose: () => {
            dispatch(clearMeeting());
            dispatch(clearAgendaState());
            setShowPopup(false);
            handleKeepNavigate();
          },
        }}
      />
    </>
  );
};
export default Agendas;
