import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './key-strength-development-area-print.module.scss';
import { TEXT_LABEL } from '../../../TalentSketch/constants';
import { TP_PRINT_SECTION_NAME } from '../../constants';
import { Divider } from 'antd';

const KeyStrengthDevelopmentAreaPrint = ({ fetchedData }) => {
  const [printData, setPrintData] = useState({
    [TEXT_LABEL.KEY_STRENGTH]: {
      pillData: [],
      tableData: [],
    },
    [TEXT_LABEL.DEVELOPMENT_AREA]: {
      pillData: [],
      tableData: [],
    },
  });

  const handleSetDataPrint = (resData) => {
    setPrintData({
      [TEXT_LABEL.KEY_STRENGTH]: {
        pillData: resData?.keyStrengths || [],
        tableData: resData?.keyStrengthsAll || [],
      },
      [TEXT_LABEL.DEVELOPMENT_AREA]: {
        pillData: resData?.DevArea || [],
        tableData: resData?.keyDevelopmentAll || [],
      },
    });
  };

  useEffect(() => {
    if (!fetchedData) return;
    handleSetDataPrint(fetchedData);
  }, [fetchedData]);
  return (
    <div className={styles.printWrapContent}>
      <div className={styles.wrapper} data-print-section={TP_PRINT_SECTION_NAME.KEY_STRENGTHS}>
        <div className={styles.title}>{TEXT_LABEL.KEY_STRENGTH}</div>
        <div className={styles.list}>
          {printData[TEXT_LABEL.KEY_STRENGTH].pillData.map((item, index) => (
            <div className={styles.item} key={index}>
              {item.edge || item.attr27 || item.extracted_keyword}
            </div>
          ))}
        </div>
        <Divider />
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Key Strength Remarks</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              {printData[TEXT_LABEL.KEY_STRENGTH].tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className={styles.content}>
                        <p className="mb-2">{item?.feedback_display || ''}</p>
                        <p className={styles.source}>
                          {item?.feedback_display && `${item.document_source ? `${item.document_source}, ` : ''} ${item.doc_year} (${item.source})`}
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.wrapper} data-print-section={TP_PRINT_SECTION_NAME.DEVELOPMENT_AREAS}>
        <div className={styles.title}>{TEXT_LABEL.DEVELOPMENT_AREA}</div>
        <div className={styles.list}>
          {printData[TEXT_LABEL.DEVELOPMENT_AREA].pillData.map((item, index) => (
            <div className={styles.item} key={index}>
              {item.edge || item.attr27 || item.extracted_keyword}
            </div>
          ))}
        </div>
        <Divider />
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Development Area Remarks</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              {printData[TEXT_LABEL.DEVELOPMENT_AREA].tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className={styles.content}>
                        <p className="mb-2">{item?.feedback_display || ''}</p>
                        <p className={styles.source}>
                          {item?.feedback_display && `${item.document_source ? `${item.document_source}, ` : ''} ${item.doc_year} (${item.source})`}
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default KeyStrengthDevelopmentAreaPrint;

KeyStrengthDevelopmentAreaPrint.propTypes = {
  fetchedData: PropTypes.object,
};
