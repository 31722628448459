export const ASSESSMENT_TYPES = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'GENERAL',
    label: 'General',
  },
  {
    value: 'EDGE',
    label: 'EDGE',
  },
  {
    value: 'PERSONALITY_ARCHETYPES',
    label: 'Personality Archetypes',
  },
  {
    value: 'TOP_TALENT_POTENTIAL',
    label: 'Top Talent Potential (KF)',
  },
  {
    value: 'LEARNING_AGILITIES',
    label: 'Learning Agilities',
  },
  {
    value: 'TRAITS',
    label: 'Traits',
  },
  {
    value: 'DRIVERS',
    label: 'Drivers',
  },
  {
    value: 'DERAILMENT_RISKS',
    label: 'Derailment Risks',
  },
  {
    value: 'COMPETENCIES',
    label: 'Competencies',
  },
];

export const ASSESSMENT_FILTER_TYPES = {
  DATE: 'Assessment Date',
  TYPE: 'Components',
};

export const ASSESSMENT_TYPE_VALUES = {
  All: 'ALL',
  GENERAL: 'GENERAL',
  EDGE: 'EDGE',
  PERSONALITY_ARCHETYPES: 'PERSONALITY_ARCHETYPES',
  TOP_TALENT_POTENTIAL: 'TOP_TALENT_POTENTIAL',
  TRAITS: 'TRAITS',
  DRIVERS: 'DRIVERS',
  LEARNING_AGILITIES: 'LEARNING_AGILITIES',
  DERAILMENT_RISKS: 'DERAILMENT_RISKS',
  COMPETENCIES: 'COMPETENCIES',
};

export const ASSESSMENT_STATUS_TYPES = {
  NO_ASSESSMENT: 'No Assessment',
  IN_PROGRESS: 'In-Progress',
  IN_PROGRESS_1: 'In Progress',
  INCOMPLETE: 'Incomplete',
  COMPLETED: 'Completed',
  COMPLETE: 'Complete',
};

export const PRINT_STATUS_TYPES = [
  ASSESSMENT_STATUS_TYPES.COMPLETED,
  ASSESSMENT_STATUS_TYPES.COMPLETE,
];

export const FETCH_ASSESSMENT_DATE_DATA = {
  code: 200,
  message: 'OK',
  result: [
    {
      assessmentDate: '2023-11-20T00:00:00.000Z',
      statusOaCompletion: 'Completed',
    },
    {
      assessmentDate: '2023-06-02T00:00:00.000Z',
      statusOaCompletion: 'Completed',
    },
    {
      assessmentDate: null,
      statusOaCompletion: 'Incomplete',
    },
    {
      assessmentDate: null,
      statusOaCompletion: 'In Progress',
    },
  ],
};
