import { Spin, Tooltip as TooltipAntd } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { info_circle } from '../../../../assets/img';
import { returnBgdColorByScoreVal } from '../../../../utils/helper';
import {
  DriversTable,
  FieldItemWrap,
  FieldWrap,
  NormalText,
  StatusBgd,
  TooltipDesc,
  TooltipTitle,
} from '../ExternalAssessments/ExternalAssessmentStyled';
import styles from '../GeneralExternalAssessments/general-external-assessments.module.scss';

const DriversAssessments = (props) => {
  const { assessmentData, loading, isPrint } = props;

  const convertData = useMemo(() => {
    return !isEmpty(assessmentData?.data?.drivers)
      ? assessmentData?.data?.drivers.map((item) => ({
          ...item,
          numberOfDriversMeet: assessmentData?.data?.numberOfDriversMet,
        }))
      : [];
  }, [assessmentData]);

  const getTooltipContent = (popupContent) => {
    let tempHTML = [];
    if (popupContent?.length > 0) {
      popupContent.forEach((item) => {
        tempHTML.push(<TooltipTitle key={item.header}>{item.header}</TooltipTitle>);
        tempHTML.push(<TooltipDesc key={item.header}>{item.content}</TooltipDesc>);
      });
    }
    return tempHTML;
  };

  const columns = [
    {
      title: 'Drivers',
      dataIndex: 'drivers',
      key: 'drivers',
      className: `border-right-none`,
      width: '35%',
      render: (_, record) => {
        return (
          <FieldWrap>
            <FieldItemWrap>
              <NormalText>{record.category}</NormalText>
              {!isPrint ? (
                <TooltipAntd
                  title={record && <div>{getTooltipContent(record.popupContent)}</div>}
                  {...{
                    overlayInnerStyle: {
                      minWidth: '298px',
                      fontSize: '12px',
                      lineHeight: '18px',
                      fontWeight: 600,
                      fontFamily: `'Inter', sans-serif`,
                      padding: '12px',
                      borderRadius: '4px',
                      backgroundColor: '#000',
                    },
                  }}
                >
                  <img src={info_circle} alt="" />
                </TooltipAntd>
              ) : (
                <img src={info_circle} alt="" />
              )}
            </FieldItemWrap>
          </FieldWrap>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: `10%`,
      align: 'center',
      render: (_, record) => {
        return (
          <FieldItemWrap>
            <NormalText>{record.score && record.score !== '-' ? record.score : '-'}</NormalText>
            {record.score && record.score !== '-' && (
              <StatusBgd bgdColor={returnBgdColorByScoreVal(record)}></StatusBgd>
            )}
          </FieldItemWrap>
        );
      },
    },
    {
      title: 'Potential',
      dataIndex: 'fitPotential',
      key: 'fitPotential',
      width: `25%`,
      render: (_, record) => {
        return <span>{record?.fitPotential || '-'}</span>;
      },
    },
    {
      title: 'No. of Potential met',
      dataIndex: 'numberOfDriversRMeet',
      key: 'numberOfDriversMeet',
      width: `30%`,
      align: 'center',
      onCell: (_, _rowIndex) => {
        const length = convertData?.length || 6;
        return {
          rowSpan: _rowIndex % length === 0 ? length : 0,
        };
      },
      render: (_, record) => <span>{record?.numberOfDriversMeet || '-'}</span>,
    },
  ];

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.tableContent}>
        <Spin spinning={loading} size="small">
          <DriversTable
            rowKey={(convertData) => convertData.category}
            dataSource={convertData}
            columns={columns}
            pagination={false}
          />
        </Spin>
      </div>
    </div>
  );
};

export default DriversAssessments;
