import React, { memo } from 'react';
import styles from './spoken-language.module.scss';
import CommonLoading from '../CommonLoading/CommonLoading';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';

const SpokenLanguage = ({ langAndBoard, loading }) => {
  const handleRenderListSpokenLanguage = () => {
    return langAndBoard.map((item, index) => {
      if (item.language) {
        return (
          <li className={styles.talentSketch__item} key={index}>
            {item.language}
          </li>
        );
      }
      return null;
    });
  };
  return (
    <div className={styles.wrapper} data-print-section={TP_PRINT_SECTION_NAME.SPOKEN_LANGUAGE}>
      <div className={styles.talentSketch} data-testid="spoken-language">
        <div className={styles.talentSketch__filter}>
          <div className={styles.talentSketch__left}>
            <h3 className={styles.talentSketch__h3}>Spoken Language</h3>
            {!loading ? (
              <ul className={styles.talentSketch__list}>{handleRenderListSpokenLanguage()}</ul>
            ) : (
              <div style={{ width: '785.04px' }} className="d-flex justify-content-center">
                <CommonLoading />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(SpokenLanguage);
