import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-proposal.module.scss';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { isArray, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Input, Select, Spin, Switch, Tooltip } from 'antd';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import { PDC_MOBILITY_PROPOSAL_TYPE } from '../../../constants';
import { avatar, info_circle, proposal_no_setting } from '../../../../../assets/img';
import styled from 'styled-components';
import { calculateContractDuration, findAllKeys, handleShowCTForProposal } from '../../../../../utils/helper';
import { DATE_TIME_FORMAT, OPU_NAME_EXCEPTION, PROPOSAL_TYPES, PROPOSAL_TYPES_JUSTIFICATIONS } from '../../../../../utils/constants';
import { AiDatePicker } from '../../../../Meeting/MeetingDetails/Styled';
import moment from 'moment';
import SearchStaffForProposal from '../../../../../components/SearchStaffForProposal/SearchStaffForProposal';
import UserCareerTable from '../../../PdcProposalList/components/UserCareerTable/UserCareerTable';
import AutoResizeTextarea from '../../../../../components/AutoResizeTextArea/AutoResizeTextArea';

const PrintProposal = ({ preRead, proposal, handleFetchComplete, mobilityInfo }) => {
  const { id: mobility_id, mobility_proposal_id, proposal_main_type } = mobilityInfo;
  const { roleId } = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(false);
  const [templateDetail, setTemplateDetail] = useState({});
  const [allKeys, setAllKeys] = useState([]);
  const detailJustificationRef = useRef(null);
  const detailPerformanceRecordRef = useRef(null);

  const getContractDuration = useMemo(() => {
    return calculateContractDuration(templateDetail?.decisionInfo?.contractStartDate, templateDetail?.decisionInfo?.contractEndDate);
  }, [templateDetail]);

  const reverseProposalTypes = Object.fromEntries(Object.entries(PROPOSAL_TYPES).map(([key, value]) => [value, key]));

  const getJustificationForType = (proposalTypeValue) => {
    const proposalKey = reverseProposalTypes[proposalTypeValue];
    return PROPOSAL_TYPES_JUSTIFICATIONS[proposalKey] || '';
  };

  const justificationLines = getJustificationForType(proposal?.proposal_type);

  const getDecisionRemarksDefaultValue = useMemo(() => {
    let tempVal = '';
    if (templateDetail?.decisionInfo?.decision_remarks) {
      return templateDetail?.decisionInfo?.decision_remarks;
    }

    if (proposal?.proposal_type !== PROPOSAL_TYPES.APPOINTMENT_WITH_PROBATION) {
      tempVal = `This appointment will be based on: <br />
          ${proposal?.proposal_type}`;
    } else {
      tempVal = `This appointment will be based on: <br />
          ${proposal?.proposal_type} (between 6 months to 12 months)`;
    }
    return tempVal;
  }, [templateDetail]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const payload = {
          staff_id: proposal.staff_id,
          position_code: proposal.position_code,
          mobility_id,
          data: proposal.proposal_settings,
          roleId,
          mobility_proposal_id,
        };
        const res = await pdcMeetingApi.getTemplateContent(payload);
        if (res.status === 200) {
          setTemplateDetail(res.data.result);
          setAllKeys(findAllKeys(res.data.result));
        }
      } catch (error) {
        console.error(error);
      } finally {
        handleFetchComplete('Proposal');
        setLoading(false);
      }
    }

    if (isEmpty(proposal)) return;
    if (proposal_main_type !== PDC_MOBILITY_PROPOSAL_TYPE.SINGLE || !proposal.proposal_type) return handleFetchComplete('Proposal');
    fetchData();
  }, [proposal, roleId]);

  return (
    <Spin spinning={loading}>
      {proposal_main_type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE && (
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Proposal
            {preRead && <PreReadLabel />}
          </div>
          <div className={styles.content}>
            {!isEmpty(templateDetail) ? (
              <TemplateWrapper>
                <div className={styles.noColumn}>
                  <div className="topHead">{'No.'}</div>
                  <div className={styles.noText}>1.</div>
                </div>
                {allKeys.includes('staffInfo') && (
                  <div className="staffDetail">
                    <div className="topHead">{'Staff Details'}</div>
                    <div className="details">
                      <div className={'topInfo mb-16'}>
                        {allKeys.includes('staffInfo.image') && (
                          <div className={'avatar'}>
                            <img src={templateDetail?.staffInfo?.image || avatar} alt="" />
                          </div>
                        )}
                        <div className={'info'}>
                          {allKeys.includes(`staffInfo.birthName`) && <div className={'name'}>{templateDetail?.staffInfo?.birthName}</div>}
                          {allKeys.includes(`staffInfo.staffId`) && (
                            <div className="fieldItem">
                              <span className={'boldText'}>{'SN : '}</span>
                              <span className={'lightText'}>{templateDetail?.staffInfo?.staffId}</span>
                            </div>
                          )}
                          {allKeys.includes(`staffInfo.age`) && (
                            <div className="fieldItem">
                              <span className={'boldText'}>{'Age : '}</span>
                              <span className={'lightText'}>{templateDetail?.staffInfo?.age || 'None'}</span>
                            </div>
                          )}
                          {allKeys.includes(`staffInfo.retirementDate`) && (
                            <div className="fieldItem">
                              <span className={'boldText'}>{'Retirement : '}</span>
                              <span className={'lightText'}>{templateDetail?.staffInfo?.retirementDate}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {allKeys.includes(`staffInfo.currentPosition`) && (
                        <div className="currentPosition mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock mb-8'}>{'Current Position'}</span>
                            <span className={'normalValue'}>
                              <CustomTooltip
                                placement={'top'}
                                overlayClassName={styles.customTooltip}
                                overlayStyle={{
                                  minWidth: '154px',
                                  padding: '10px',
                                  opacity: 1,
                                  backgroundColor: '#000',
                                  borderRadius: '4px',
                                }}
                                title={
                                  <div className="tooltipContent">
                                    <span
                                      style={{
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        lineHeight: '18px',
                                        fontWeight: '700',
                                        color: '#ffffff',
                                        display: 'block',
                                        marginBottom: '5px',
                                      }}
                                    >
                                      Current Incumbent
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        lineHeight: '18px',
                                        fontWeight: '400',
                                        color: '#ffffff',
                                        display: 'inline-block',
                                      }}
                                    >
                                      {templateDetail?.staffInfo?.reportTo?.supervisorName}
                                    </span>
                                  </div>
                                }
                                overlayInnerStyle={{ borderRadius: '6px' }}
                              >
                                {templateDetail?.staffInfo?.currentPosition?.position_name && (
                                  <span className={'textBlock'}>
                                    {templateDetail?.staffInfo?.currentPosition?.position_name
                                      ? `${templateDetail?.staffInfo?.currentPosition?.position_name},`
                                      : '-,'}{' '}
                                  </span>
                                )}
                              </CustomTooltip>
                              {templateDetail?.staffInfo?.currentPosition?.department_name && (
                                <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.department_name}</span>
                              )}
                              {templateDetail?.staffInfo?.currentPosition?.division && (
                                <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.division}</span>
                              )}
                              {templateDetail?.staffInfo?.currentPosition?.sector && (
                                <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.sector}</span>
                              )}
                              {templateDetail?.staffInfo?.currentPosition?.opu_name === OPU_NAME_EXCEPTION ? (
                                <>
                                  {templateDetail?.staffInfo?.currentPosition?.business_unit && (
                                    <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.business_unit}</span>
                                  )}
                                  {templateDetail?.staffInfo?.currentPosition?.opu_name && (
                                    <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.opu_name}</span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {templateDetail?.staffInfo?.currentPosition?.opu_name && (
                                    <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.opu_name}</span>
                                  )}
                                  {templateDetail?.staffInfo?.currentPosition?.business_unit && (
                                    <span className={'textBlock'}>{templateDetail?.staffInfo?.currentPosition?.business_unit}</span>
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {allKeys.includes(`staffInfo.location`) && (
                        <div className="location mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock'}>{'Location'}</span>
                            <span className={'normalValue'}>{templateDetail?.staffInfo?.location}</span>
                          </div>
                        </div>
                      )}
                      {allKeys.includes(`staffInfo.reportTo`) && (
                        <div className="reportTo mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock mb-8'}>{'Reports to'}</span>
                            <span className={'normalValue'}>
                              <span className={'textBlock'}>
                                {`${templateDetail?.staffInfo?.reportTo?.supervisorName ?? ''}${
                                  templateDetail?.staffInfo?.reportTo?.positionName ? `, ${templateDetail?.staffInfo?.reportTo?.positionName}` : ''
                                }${templateDetail?.staffInfo?.reportTo?.opuName ? `, ${templateDetail?.staffInfo?.reportTo?.opuName}` : ''}`}
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="jobGrade mb-16">
                        {allKeys.includes(`staffInfo.jg`) && (
                          <div className="fieldItem">
                            <span className={'greyLabel'}>{'JG'}</span>
                            <span className={'highlightValue'}>{templateDetail?.staffInfo?.jg}</span>
                          </div>
                        )}
                        {allKeys.includes(`staffInfo.years_in_position`) && (
                          <div className="fieldItem">
                            <span className={'greyLabel'}>{'YIP'}</span>
                            <span className={'highlightValue'}>{templateDetail?.staffInfo?.years_in_position}</span>
                          </div>
                        )}
                        {allKeys.includes(`staffInfo.sg`) && (
                          <div className="fieldItem">
                            <span className={'greyLabel'}>{'SG'}</span>
                            <span className={'highlightValue'}>{templateDetail?.staffInfo?.sg}</span>
                          </div>
                        )}
                        {allKeys.includes(`staffInfo.yearsInSg`) && (
                          <div className="fieldItem">
                            <span className={'greyLabel'}>{'YiSG'}</span>
                            <span className={'highlightValue'}>{templateDetail?.staffInfo?.yearsInSg}</span>
                          </div>
                        )}
                      </div>
                      {allKeys.includes(`staffInfo.note`) && (
                        <div className="fieldItem mb-20">
                          <div className={'normalLabel mb-8'}>{'Notes'}</div>
                          <CustomFieldEditable
                            data-placeholder="Enter Notes"
                            style={{ minHeight: '145px' }}
                            value={templateDetail?.staffInfo?.note || ''}
                          />
                        </div>
                      )}
                      {allKeys.includes(`staffInfo.talentCategory`) && (
                        <div className="talentCategory mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock'}>{'Talent Category'}</span>
                            <span className={'normalValue'}>{templateDetail?.staffInfo?.talentCategory}</span>
                          </div>
                        </div>
                      )}
                      {allKeys.includes(`staffInfo.successor_to_1st_line`) && (
                        <div className="successor mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock mb-10'}>{'Successor to (1st Line)'}</span>
                            {!isEmpty(templateDetail?.staffInfo?.successor_to_1st_line) &&
                              templateDetail?.staffInfo?.successor_to_1st_line.map((item, index) => {
                                return (
                                  <span key={index} className={'normalValue mb-8 inlineTextList'}>
                                    <span>{`${index + 1}.`}</span>
                                    <span>{`${item?.text}`}</span>
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                      )}
                      {allKeys.includes(`staffInfo.replacement`) && (
                        <div className="placement">
                          <div className="fieldItem">
                            <span className="normalLabel">Replacement</span>
                            <CustomSwitch
                              defaultChecked={templateDetail?.staffInfo?.replacement?.save}
                              checked={templateDetail?.staffInfo?.replacement?.save}
                            />
                          </div>
                          {templateDetail?.staffInfo?.replacement?.save ? (
                            <>
                              <div className="fieldItem selectField">
                                <SearchStaffForProposal isDisabled={true} placeholder="Search By Staff ID, Staff Name" addTalent={() => {}} />
                              </div>
                              {allKeys.includes(`staffInfo.replacement.replacement_staff_ids`) && (
                                <div className="searchList">
                                  {isArray(templateDetail?.staffInfo?.replacement?.replacement_staff_ids) &&
                                    templateDetail?.staffInfo?.replacement?.replacement_staff_ids.map((option, index) => (
                                      <div className="searchItem" key={index}>
                                        {option?.text && !option?.top_talent_status ? (
                                          <div className="searchItemName">
                                            <span>{index + 1}. </span>
                                            <span>{`${option?.text}`}</span>
                                          </div>
                                        ) : (
                                          <div className="searchItemName">
                                            <span>{index + 1}. </span>
                                            <span>{`${option?.birthName || option?.positionName || 'None'}`}</span>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="fieldItem">
                              <CustomFieldEditable
                                data-placeholder="Enter Remarks"
                                style={{ minHeight: '145px', width: '100%' }}
                                value={templateDetail?.staffInfo?.replacement?.replacement_input_value || ''}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {allKeys.includes(`staffInfo.previous_contract_details`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Previous Contract Details'}</span>
                          </div>
                          <CustomFieldEditable
                            data-placeholder="Enter Contract Details"
                            style={{ minHeight: '145px' }}
                            value={templateDetail?.staffInfo?.previous_contract_details || ''}
                          />
                        </div>
                      )}
                      {allKeys.includes(`staffInfo.previous_secondment`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Previous Secondment'}</span>
                          </div>
                          <CustomFieldEditable
                            data-placeholder="Enter Previous Secondment"
                            style={{ minHeight: '145px' }}
                            value={templateDetail?.staffInfo?.previous_secondment}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {allKeys.includes(`positionInfo`) && (
                  <div className="proposedPosition">
                    <div className="topHead">{'Proposed Position'}</div>
                    <div className="details">
                      {allKeys.includes(`positionInfo.positionDetails.positionName`) && (
                        <div className="currentPosition mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock mb-8'}>{'Position Details'}</span>
                            <span className={'normalValue'}>
                              {templateDetail?.positionInfo?.positionDetails?.positionName && (
                                <span className={'textBlock'}>
                                  {templateDetail?.positionInfo?.positionDetails?.positionName &&
                                    `${templateDetail?.positionInfo?.positionDetails?.positionName},`}
                                </span>
                              )}
                              {templateDetail?.positionInfo?.positionDetails?.department_name && (
                                <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.department_name}</span>
                              )}
                              {templateDetail?.positionInfo?.positionDetails?.division && (
                                <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.division}</span>
                              )}
                              {templateDetail?.positionInfo?.positionDetails?.sector && (
                                <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.sector}</span>
                              )}
                              {templateDetail?.positionInfo?.positionDetails?.opuName === OPU_NAME_EXCEPTION ? (
                                <>
                                  {templateDetail?.positionInfo?.positionDetails?.businessUnit && (
                                    <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.businessUnit}</span>
                                  )}
                                  {templateDetail?.positionInfo?.positionDetails?.opuName && (
                                    <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.opuName}</span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {templateDetail?.positionInfo?.positionDetails?.opuName && (
                                    <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.opuName}</span>
                                  )}
                                  {templateDetail?.positionInfo?.positionDetails?.businessUnit && (
                                    <span className={'textBlock'}>{templateDetail?.positionInfo?.positionDetails?.businessUnit}</span>
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="fieldItem">
                        {allKeys.includes(`positionInfo.positionCode`) && (
                          <span className={'normalValue textBlock'}>
                            <span className={'greyLabel'}>PID: </span>
                            {`${templateDetail?.positionInfo?.positionCode}`}
                          </span>
                        )}
                        {allKeys.includes(`positionInfo.jg`) && (
                          <span className={'normalValue textBlock mb-12'}>
                            <span className={'greyLabel'}>JG: </span>
                            {`${templateDetail?.positionInfo?.jg}`}
                          </span>
                        )}
                      </div>
                      {allKeys.includes(`positionInfo.location`) && (
                        <div className="location mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock'}>{'Location'}</span>
                            <span className={'normalValue'}>{templateDetail?.positionInfo?.location}</span>
                          </div>
                        </div>
                      )}
                      {allKeys.includes(`positionInfo.reportTo`) && (
                        <div className="reportTo mb-16">
                          <div className="fieldItem">
                            <span className={'normalLabel textBlock mb-8'}>{'Reports to'}</span>
                            <span className={'normalValue'}>
                              <span className={'textBlock'}>{`${templateDetail?.positionInfo?.reportTo?.positionName ?? ''}${
                                templateDetail?.positionInfo?.reportTo?.opuName ? `, ${templateDetail?.positionInfo?.reportTo?.opuName}` : ''
                              }`}</span>
                            </span>
                          </div>
                        </div>
                      )}
                      {allKeys.includes(`positionInfo.currentIncumbent`) && (
                        <div className="fieldItem mb-20">
                          <span className={'normalLabel textBlock mb-8'}>{'Current Incumbent'}</span>
                          {allKeys.includes(`positionInfo.currentIncumbent`) && (
                            <span className={'normalValue'}>
                              <span className={'textBlock'}>{templateDetail?.positionInfo?.currentIncumbent}</span>
                            </span>
                          )}
                          {templateDetail?.positionInfo?.currentIncumbent === 'Vacant' && (
                            <CustomFieldEditable
                              style={{ minHeight: '62px', marginTop: '5px' }}
                              value={templateDetail?.positionInfo?.current_incumbent_input_value}
                            />
                          )}
                        </div>
                      )}
                      {allKeys.includes(`positionInfo.identifiedSuccessors`) && (
                        <>
                          <div className="placement">
                            <div className="fieldItem">
                              <span className="normalLabel">Identified Successors</span>
                              <CustomSwitch
                                defaultChecked={templateDetail?.positionInfo?.identifiedSuccessors?.save}
                                checked={templateDetail?.positionInfo?.identifiedSuccessors?.save}
                              />
                            </div>
                          </div>
                          {!templateDetail?.positionInfo?.identifiedSuccessors?.save ? (
                            <div className="fieldItem mb-20">
                              <CustomFieldEditable
                                data-placeholder="Enter"
                                style={{ minHeight: '145px' }}
                                value={templateDetail?.positionInfo?.identifiedSuccessors?.identified_successor_input_value || ''}
                              />
                            </div>
                          ) : (
                            <div className="fieldItem mb-12">
                              <CustomFieldEditable
                                style={{ background: '#f5f5f5', display: 'block', marginBottom: '5px', minHeight: '56px', fontSize: '12px' }}
                                value={templateDetail?.positionInfo?.identifiedSuccessors?.text}
                              ></CustomFieldEditable>
                            </div>
                          )}
                          <div className="fieldItem selectField mb-20">
                            <SearchStaffForProposal isDisabled={true} placeholder="Search By Staff ID, Staff Name" addTalent={() => {}} />
                          </div>
                          {allKeys.includes(`positionInfo.identifiedSuccessors.successorList`) && (
                            <div className="searchList">
                              {!isEmpty(templateDetail?.positionInfo?.identifiedSuccessors?.successorList) &&
                                templateDetail?.positionInfo?.identifiedSuccessors?.successorList.map((option, index) => (
                                  <div className="searchItem" key={index}>
                                    {option?.text && !option?.top_talent_status ? (
                                      <div className="searchItemName">
                                        <span>{index + 1}. </span>
                                        <span>{`${option?.text}`}</span>
                                      </div>
                                    ) : (
                                      <div className="searchItemName">
                                        <span>{index + 1}. </span>
                                        <span>{`${option?.birthName || option?.positionName || 'None'} ${`(${handleShowCTForProposal(
                                          option?.top_talent_status || option?.ct
                                        )}, ${option?.job_grade || ''}, ${option?.age || ''})`}`}</span>
                                      </div>
                                    )}
                                    {/* <img style={{ cursor: 'pointer' }} src={Close_Icon_2} alt="" /> */}
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}

                {allKeys.includes(`justificationInfo`) && (
                  <div className="justification" ref={detailJustificationRef}>
                    <div className="topHead">{'Justification'}</div>
                    <div className="details">
                      {allKeys.includes(`justificationInfo.rating`) &&
                        isArray(templateDetail?.justificationInfo?.rating) &&
                        templateDetail?.justificationInfo?.rating[0]?.year !== 'false' && (
                          <div className="fieldItem" ref={detailPerformanceRecordRef}>
                            <div className={'normalLabel mb-12'}>{'Performance Record'}</div>
                            <UserCareerTable data={templateDetail?.justificationInfo?.rating || []} type={'Rating'} />
                          </div>
                        )}
                      {allKeys.includes(`justificationInfo.justification`) && (
                        <div className="fieldItem justificationField">
                          <div className={'smallText mb-8'} style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>{'Justification'}</span>
                            <CustomTooltip
                              placement={'top'}
                              overlayClassName={styles.customTooltip}
                              overlayStyle={{
                                minWidth: '468px',
                                paddingBottom: '0px',
                                paddingTop: '0px',
                                opacity: 1,
                                backgroundColor: '#000',
                                borderRadius: '4px',
                              }}
                              title={
                                justificationLines?.length > 0 ? (
                                  <div className="tooltipContent">
                                    <span
                                      style={{
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        color: '#ffffff',
                                      }}
                                    >
                                      {justificationLines.map((line, index) => (
                                        <React.Fragment key={index}>
                                          {line}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                    </span>
                                  </div>
                                ) : null
                              }
                              overlayInnerStyle={{ borderRadius: '6px' }}
                            >
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  maxWidth: `${70}px`,
                                  display: 'block',
                                  padding: '5px',
                                }}
                              >
                                <img src={info_circle} alt="" />
                              </div>
                            </CustomTooltip>
                          </div>
                          <CustomFieldEditable
                            data-placeholder="Enter Justifications"
                            style={{ flexGrow: 1 }}
                            value={templateDetail?.justificationInfo?.justification || ''}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {allKeys.includes(`decisionInfo`) && (
                  <div className="decision">
                    <div className="topHead">{'Decision'}</div>
                    <div className="details">
                      {allKeys.includes(`decisionInfo.decision_remarks`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Decision Remarks'}</span>
                          </div>
                          <CustomFieldEditable
                            style={{ display: 'block', marginBottom: '5px', minHeight: '145px' }}
                            value={getDecisionRemarksDefaultValue}
                          ></CustomFieldEditable>
                        </div>
                      )}
                      {allKeys.includes(`decisionInfo.effectiveStartDate`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Effective Start Date'}</span>
                          </div>
                          <AiDatePicker
                            format={DATE_TIME_FORMAT.DATE_SLASH}
                            disabled
                            placeholder="Select"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            value={templateDetail?.decisionInfo?.effectiveStartDate ? moment(templateDetail?.decisionInfo?.effectiveStartDate) : null}
                          />
                        </div>
                      )}
                      {allKeys.includes(`decisionInfo.effectiveEndDate`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Effective End Date'}</span>
                          </div>
                          <AiDatePicker
                            format={DATE_TIME_FORMAT.DATE_SLASH}
                            disabled
                            placeholder="Select"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            value={templateDetail?.decisionInfo?.effectiveEndDate ? moment(templateDetail?.decisionInfo?.effectiveEndDate) : null}
                          />
                        </div>
                      )}
                      {allKeys.includes(`decisionInfo.contractStartDate`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Contract Start Date'}</span>
                          </div>
                          <AiDatePicker
                            format={DATE_TIME_FORMAT.DATE_SLASH}
                            disabled
                            placeholder="Select"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            value={templateDetail?.decisionInfo?.contractStartDate ? moment(templateDetail?.decisionInfo?.contractStartDate) : null}
                          />
                        </div>
                      )}
                      {allKeys.includes(`decisionInfo.contractEndDate`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Contract End Date'}</span>
                          </div>
                          <AiDatePicker
                            format={DATE_TIME_FORMAT.DATE_SLASH}
                            disabled
                            placeholder="Select"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            value={templateDetail?.decisionInfo?.contractEndDate ? moment(templateDetail?.decisionInfo?.contractEndDate) : null}
                          />
                        </div>
                      )}
                      {allKeys.includes(`decisionInfo.contractDuration`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Contract Duration'}</span>
                          </div>
                          <CustomInput
                            disabled={true}
                            placeholder=""
                            value={getContractDuration || templateDetail?.decisionInfo?.contractDuration || ''}
                          />
                        </div>
                      )}
                      {allKeys.includes(`decisionInfo.decision`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Decision'}</span>
                          </div>
                          <CustomSelect
                            disabled
                            placeholder="Select"
                            style={{
                              width: 120,
                            }}
                            allowClear
                            options={
                              !isEmpty(templateDetail?.decisionInfo?.decision?.list)
                                ? templateDetail?.decisionInfo?.decision?.list.map((item) => ({
                                    value: item,
                                    label: item,
                                  }))
                                : []
                            }
                            value={templateDetail?.decisionInfo?.decision?.selectedDecision || null}
                          />
                        </div>
                      )}

                      {allKeys.includes(`decisionInfo.remarks`) && (
                        <div className="fieldItem">
                          <div className={'smallText mb-8'}>
                            <span>{'Remarks'}</span>
                            <img src={info_circle} alt="" />
                          </div>
                          <CustomFieldEditable
                            placeholder="Enter Remarks"
                            style={{ minHeight: '204px' }}
                            value={templateDetail?.decisionInfo?.remarks || ''}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </TemplateWrapper>
            ) : (
              <div className={styles.noContent}>
                <img src={proposal_no_setting} alt="" />
              </div>
            )}
          </div>
        </div>
      )}
    </Spin>
  );
};

export default PrintProposal;

PrintProposal.propTypes = {
  preRead: PropTypes.bool,
  proposal: PropTypes.object,
  handleFetchComplete: PropTypes.func,
  mobilityInfo: PropTypes.object,
};

const TemplateWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  > div {
    flex: 1;
    min-height: 712px;
    border-right: 1px solid var(--color-border-weak, #cbd6e2);

    &:last-child {
      border: none;
    }
  }

  .topHead {
    background: #d9f2f7;
    color: var(--Other-Colors-Typography, #000);
    text-align: center;
    font-family: var(--font-type-default, Inter);
    font-size: var(--font-size-body-md, 16px);
    font-style: normal;
    font-weight: var(--font-weight-bold, 700);
    line-height: var(--font-line-height-body-md, 24px); /* 150% */
    letter-spacing: var(--font-letter-spacing-body-md, 0.3px);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .justification {
    display: flex;
    flex-direction: column;

    .details {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .justificationField {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .details {
    padding: 22px 16px;

    .topInfo {
      display: flex;

      .avatar {
        margin-right: 15px;
        img {
          width: 73px;
          height: 73px;
          border-radius: 50%;
        }
      }

      .name {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
        margin-bottom: 10px;
      }
    }

    .jobGrade {
      .fieldItem {
        display: flex;
      }
    }

    .fieldItem {
      position: relative;
      margin-bottom: 5px;
      .boldText {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .lightText {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .normalLabel {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: var(--font-type-default, Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .normalValue {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--font-type-default, Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.3px;
      }

      .greyLabel {
        color: #15191e;
        font-family: var(--font-type-default, Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 171.429% */
        display: inline-block;
        min-width: 50px;
      }

      .highlightValue {
        color: #15191e;
        font-family: var(--font-type-default, Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
      }

      .textBlock {
        display: block;
      }

      .smallText {
        color: var(--Grays-500, #525252);
        font-family: var(--font-type-default, Inter);
        font-size: 12px;
        font-style: normal;
        font-weight: var(--font-weight-medium, 500);
        line-height: var(--font-line-height-body-sm, 20px); /* 166.667% */

        span {
          margin-right: 10px;
        }
      }
    }

    .placement {
      .fieldItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }

  .decision {
    .fieldItem {
      margin-bottom: 16px;
    }
  }

  .selectField {
    margin-bottom: 16px;
  }

  .inlineTextList {
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }

  .searchList {
    .searchItem {
      color: #000;
      font-family: var(--font-type-default, Inter);
      font-size: var(--font-size-body-sm, 14px);
      font-style: normal;
      font-weight: var(--font-weight-regular, 400);
      line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
      letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s;
      gap: 5px;

      &:last-child {
        margin-bottom: 16px;
      }

      // &:hover {
      //   img {
      //     visibility: visible;
      //     opacity: 1;
      //   }
      // }

      .searchItemName {
        display: flex;
        align-items: flex-start;

        span {
          &:first-child {
            min-width: 24px;
            flex-basis: 24px;
          }
        }
      }

      img {
        flex-basis: 20px;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .mb-8 {
    margin-bottom: 8px !important;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }

  .mb-12 {
    margin-bottom: 12px !important;
  }

  .mb-16 {
    margin-bottom: 16px !important;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }

  .ant-picker.ant-picker-disabled,
  #search,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: white !important;
    color: #3f3c4c !important;
  }
`;

const CustomSelect = styled(Select)`
  width: 100% !important;
  border-radius: 4px;
`;

const CustomSwitch = styled(Switch)`
  cursor: not-allowed !important;
  &.ant-switch-checked {
    background-color: #002a53 !important;
  }
`;

const CustomInput = styled(Input)`
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  &:disabled {
    cursor: no-drop;
    background-color: white !important;
    color: #3f3c4c !important;
  }
`;

const CustomFieldEditable = styled(AutoResizeTextarea)`
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  font-family: var(--font-type-default, Inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);
  word-break: break-word;
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltipContent {
    display: flex;
    align-items: center;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 10px;

    span {
      display: inline-block;
      padding-left: 10px;
    }
  }
`;
