import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { fetchDevelopmentPlan } from '../../../../services/talentProfiles';
import styles from './development-plan-print.module.scss';
import { TP_PRINT_SECTION_NAME } from '../../constants';
import { useDispatch } from 'react-redux';
import { updatePrintData } from '../../../../pages/TalentProfilePage/store/printProfileSlice';

const limit = 4;
const DevelopmentPlanPrint = (props) => {
  const { profileId, roleUser, fetchedData } = props;
  const [page] = useState(1);
  const [listPlan, setListPlan] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchApi() {
      if (!roleUser.roleActive.roleId) return;
      const body = { page, limit, isPrintPdf: true };
      const response = await fetchDevelopmentPlan(profileId, roleUser, body);
      if (response && response?.data && response?.data.result?.developmentPlan && response?.data?.result?.developmentPlan?.length > 0) {
        setListPlan(response?.data?.result?.developmentPlan);
        dispatch(
          updatePrintData({
            sectionName: TP_PRINT_SECTION_NAME.DEVELOPMENT_PLAN,
            data: response?.data?.result?.developmentPlan,
          })
        );
      }
    }
    if (fetchedData) {
      setListPlan(fetchedData);
      return;
    }
    fetchApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, profileId, page, limit, fetchedData]);

  return (
    <div className={styles.SectionPlan} data-testid="development-plan" data-print-section={TP_PRINT_SECTION_NAME.DEVELOPMENT_PLAN}>
      <div className={styles.SectionName}>Development Plan</div>
      <div className={styles.tablePlan}>
        <table className="table">
          <thead>
            <tr className={styles.tablePlanThead}>
              <th scope="col" className="text-center">
                No.
              </th>
              <th scope="col" style={{ width: '45%' }}>
                Development Plan
              </th>
              <th scope="col" className="text-center">
                Date
              </th>
              <th scope="col" className="text-center">
                Platform
              </th>
              <th scope="col" className="text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {!!listPlan && listPlan.length > 0 ? (
              listPlan?.map((plan, idx) => (
                <tr key={idx} className={styles.tablePlanBody}>
                  <td className="text-center">{(page - 1) * limit + idx + 1}</td>
                  <td>{plan.description || '-'}</td>
                  <td className="text-center">{plan.start_date ? moment(plan.start_date).format('D MMMM YYYY') : '-'}</td>
                  <td className="text-center">{plan.library_goal || '-'}</td>
                  <td className="text-center">
                    {(plan.status?.split(' ').length > 1
                      ? plan.status
                          ?.split(' ')
                          ?.map((e) => e?.replace(/\w/, (firstLetter) => firstLetter.toUpperCase()))
                          ?.join(' ')
                      : plan.status?.replace(/\w/, (firstLetter) => firstLetter?.toUpperCase())) || '-'}
                  </td>
                </tr>
              ))
            ) : (
              <tr key="0" className={styles.tablePlanBody}>
                <td className="text-center">-</td>
                <td>-</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DevelopmentPlanPrint;
