import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-assessment.module.scss';
import { Spin } from 'antd';
import AssessmentLeadershipFilter from '../../../components/AssessmentLeadershipFilter/AssessmentLeadershipFilter';
import { PDC_EDGE_OPTIONS, PDC_LEADERSHIP_DOT_COLOR, PDC_LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR, PDC_LEADERSHIP_ROW_NAME } from '../../../constants';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { convertLeadershipChartData, getNoOf2NestedArr } from '../../../../../utils/helper';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import AutoResizeTextarea from '../../../../../components/AutoResizeTextArea/AutoResizeTextArea';

const PROFICIENCY = [1, 2, 3, 4, 5];
const PROFICIENCY_2_LADDER = [1, 2, 3, 4, 5, 6, 7, 8];
const CHILD_PER_PARENT = 2;
const LADDER_DIFFERENCE = PROFICIENCY_2_LADDER.length - PROFICIENCY.length;
const REGEX = new RegExp(/\b\w+\b/g);

const PrintAssessment = (props) => {
  const {
    proposal,
    preRead,
    handleFetchComplete,
    loadingRemarks,
    assessmentRemarks,
    staffId,
    typeAssessment,
    setTypeAssessment,
    yearAssessment,
    setYearAssessment,
  } = props;
  const [rawData, setRawData] = useState({});
  const [loading, setLoading] = useState(true);
  const roleId = useSelector((state) => state.user.roleActive.roleId);
  const [isFetchFirstTime, setIsFetchFirstTime] = useState(true);

  const condition = useMemo(() => {
    if (!rawData.currentRoleLevel || !rawData.nextRoleLevel) return { is2Ladder: false, isSameAssessedRole: true };
    const currentRole = rawData?.currentRoleLevel?.match(REGEX)[0];
    const nextRole = rawData?.nextRoleLevel?.match(REGEX)[0];
    const assessedRole = rawData?.assessedRoleLevel?.match(REGEX)[0];
    const is2Ladder = currentRole !== nextRole || (currentRole === 'SGM' && nextRole === 'SGM');
    const isSameAssessedRole = !(is2Ladder && currentRole !== assessedRole);
    return { is2Ladder, isSameAssessedRole };
  }, [rawData]);

  const currentProficiency = useMemo(() => {
    return condition.is2Ladder ? PROFICIENCY_2_LADDER : PROFICIENCY;
  }, [condition.is2Ladder]);

  const fetchEdgeData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getAssessmentEdgeChart(staffId, typeAssessment, yearAssessment, roleId);
      if (res.status === 200) {
        setRawData(res.data.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      if (isFetchFirstTime) {
        handleFetchComplete('Assessment');
        setIsFetchFirstTime(false);
      }
    }
  }, [staffId, typeAssessment, yearAssessment, roleId]);

  useEffect(() => {
    fetchEdgeData();
  }, [fetchEdgeData]);

  const remarks = useMemo(() => {
    return assessmentRemarks.find((item) => item.staff_id === staffId) || { strengths: [], development_areas: [] };
  }, [assessmentRemarks.length, staffId]);

  const data = useMemo(() => {
    return convertLeadershipChartData(rawData);
  }, [rawData]);

  const renderDot = (value, proficiency) =>
    Object.keys(value.proficiency).map((key) => {
      if (!condition.isSameAssessedRole && value.proficiency[key] && key === PDC_EDGE_OPTIONS[2].toLocaleLowerCase()) {
        return (
          <>
            {Math.floor(value.proficiency[key] + LADDER_DIFFERENCE) === proficiency && (
              <span className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[key].color }} />
            )}
          </>
        );
      }
      return (
        <>
          {Math.floor(value.proficiency[key]) === proficiency && (
            <span className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[key].color }} />
          )}
        </>
      );
    });

  const renderValue = (parentIndex, childIndex, proficiency) => {
    const value = data[parentIndex * CHILD_PER_PARENT + childIndex];
    const nextEffective =
      condition.is2Ladder && value.nextEffectiveProficiency ? value.nextEffectiveProficiency + LADDER_DIFFERENCE : value.nextEffectiveProficiency;
    return (
      <>
        {value.currentEffectiveProficiency === proficiency && (
          <div
            className={styles.effectiveProficiency}
            style={{
              background: PDC_LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.CURRENT,
            }}
          />
        )}
        {nextEffective === proficiency && (
          <div
            id="next"
            className={styles.effectiveProficiency}
            style={{
              background: PDC_LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.NEXT,
            }}
          />
        )}
        {renderDot(value, proficiency)}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleTop}>
        Assessment
        {preRead && <PreReadLabel />}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>
          <div>
            Leadership EDGE for {proposal.birth_name} (assessed by - {rawData.superVisor || 'N/A'})
          </div>
          <AssessmentLeadershipFilter type={typeAssessment} setType={setTypeAssessment} year={yearAssessment} setYear={setYearAssessment} />
        </div>
        <div className={styles.content}>
          <div className={styles.edge}>
            <Spin spinning={loading}>
              <div className={styles.desc}>
                {typeAssessment === 'All' ? (
                  <>
                    {Object.keys(PDC_LEADERSHIP_DOT_COLOR).map((showType) => (
                      <div className={styles.name} key={showType}>
                        <span className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[showType].color }} />
                        <span>{PDC_LEADERSHIP_DOT_COLOR[showType].text}</span>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={styles.name}>
                    <span className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[typeAssessment.toLowerCase()].color }} />
                    <span>{PDC_LEADERSHIP_DOT_COLOR[typeAssessment.toLowerCase()].text}</span>
                  </div>
                )}

                {rawData.currentRoleLevel && (
                  <div className={styles.name}>
                    <span className={styles.effective} style={{ background: PDC_LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.CURRENT }} />
                    {`Effective Level ${rawData.currentRoleLevel || ''}`}
                  </div>
                )}
                {rawData.nextRoleLevel && (
                  <div className={styles.name}>
                    <span className={styles.effective} style={{ background: PDC_LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.NEXT }} />
                    {`Effective Level ${rawData.nextRoleLevel || ''}`}
                  </div>
                )}
              </div>

              <div className={styles.ladder_wrapper}>
                <div className={styles.ladder}>
                  {PROFICIENCY.map((value, idx) => (
                    <span key={value} className={condition.is2Ladder ? styles.upper : ''}>
                      {idx === 0 && (
                        <div className={styles.ladder_info}>{condition.is2Ladder ? rawData.nextRoleLevel : rawData.currentRoleLevel}</div>
                      )}
                      {value}
                    </span>
                  ))}
                </div>
                {condition.is2Ladder && (
                  <div className={styles.ladder}>
                    {[...PROFICIENCY_2_LADDER].map((value, idx) => (
                      <span key={value} className={value > PROFICIENCY.length ? styles.hidden : ''}>
                        {idx === 0 && <div className={styles.ladder_info}>{rawData.currentRoleLevel}</div>}
                        {value <= PROFICIENCY.length ? value : ''}
                      </span>
                    ))}
                  </div>
                )}
              </div>

              <div className={styles.body}>
                {PDC_LEADERSHIP_ROW_NAME.map((item, parentIndex) => (
                  <div className={styles.row} key={item.name}>
                    <div className={styles.parent} style={{ background: item.color }}>
                      {item.name}
                    </div>

                    <div className={styles.child}>
                      {item.childNames.map((childName, childIndex) => (
                        <div className={styles.child_wrapper} key={childName}>
                          <div className={styles.child_name}>
                            <div className={styles.child_no} style={{ background: item.childColor }}>
                              {getNoOf2NestedArr(parentIndex, childIndex)}
                            </div>
                            <div className={styles.child_title}>{childName}</div>
                          </div>

                          <div className={styles.value_wrapper}>
                            {currentProficiency.map((proficiency) => (
                              <div className={styles.value_cell} key={proficiency}>
                                {!isEmpty(data) && renderValue(parentIndex, childIndex, proficiency)}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Spin>
          </div>
          <Spin spinning={loadingRemarks}>
            <div className={styles.remarks}>
              <div className={styles.remarkTitle}>Remarks</div>
              <div className={styles.inputPlace}>
                <div className={styles.label}>Strengths</div>
                <AutoResizeTextarea placeholder="Input Strengths" value={remarks.strengths?.join('\n\n')} style={{ minHeight: '210px' }} />
              </div>
              <div className={styles.inputPlace}>
                <div className={styles.label}>Development Areas</div>
                <AutoResizeTextarea
                  placeholder="Input Development Areas"
                  value={remarks.development_areas?.join('\n\n')}
                  style={{ minHeight: '210px' }}
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default PrintAssessment;

PrintAssessment.propTypes = {
  proposal: PropTypes.object,
  preRead: PropTypes.bool,
  handleFetchComplete: PropTypes.func,
};
