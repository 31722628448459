import React, { useEffect, useState } from 'react';
import * as styles from './auto-resize-text-area.module.scss';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';

const AutoResizeTextarea = (props) => {
  const { value, placeholder, style, className } = props;
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText('')));
  useEffect(() => {
    if (value && JSON.stringify(value).includes('blocks')) {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(value))));
    }
  }, [value]);

  return (
    <div style={style} className={`${styles.defaultStyle} ${className}`}>
      <Editor
        editorState={editorState}
        toolbarHidden={true}
        editorStyle={{
          ...style,
        }}
        placeholder={placeholder || ''}
        readOnly={true}
      />
    </div>
  );
};

export default AutoResizeTextarea;
