import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as styles from './talent-review-print.module.scss';
import { TP_PRINT_SECTION_NAME } from '../../constants';
import { BUSINESS_NAME_TALENT_REVIEW } from '../../../../utils/constants';
import moment from 'moment';

const TalentReviewPrint = React.memo(({ fetchedData }) => {
  const listItems = useMemo(() => {
    if (!fetchedData) return;
    return fetchedData.map((item, index) => {
      const business = BUSINESS_NAME_TALENT_REVIEW.find((value) => value.name === item?.business);
      const styleBackGround = { backgroundColor: `${business?.color}` };
      return (
        <div className={styles.content} key={index}>
          <div className={styles.platform} style={styleBackGround}>
            {business?.symbol}
          </div>
          <div className={styles.right}>
            <p className={styles.date}>{moment(item.reviewDate).format('D MMMM YYYY') || '-'}</p>
            <p>{item.platform}</p>
          </div>
        </div>
      );
    });
  }, [fetchedData]);
  return (
    <div className={styles.wrapper} data-print-section={TP_PRINT_SECTION_NAME.TALENT_REVIEW}>
      <div className={styles.title}>Talent Review</div>
      <div className={styles.list}>{listItems}</div>
    </div>
  );
});

TalentReviewPrint.displayName = 'TalentReviewPrint';

TalentReviewPrint.propTypes = {
  fetchedData: PropTypes.array,
};

export default TalentReviewPrint;
