import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import styles from './work-preference.module.scss';
import talentProfileApi from '../../services/talentProfiles';
import { updatePrintData } from '../../pages/TalentProfilePage/store/printProfileSlice';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';

const INIT_DATA = {
  willing_to_relocate: '',
  option: '',
  constraint_details: '',
};

const WorkPreference = (props) => {
  const { profileId, isPrintTP, fetchedData, tokenTalentProfile } = props;
  const user = useSelector((state) => state.user);
  const [workPreference, setWorkPreference] = useState(INIT_DATA);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchApi = useCallback(async () => {
    if (!user.roleActive) return;
    try {
      setLoading(true);
      const response = await talentProfileApi.fetchWorkPreferenceStatus({
        profileId,
        roleUser: user,
        tokenTalentProfile,
      });
      if (response.status === 200) {
        const result = response?.data?.result;
        setWorkPreference(result || INIT_DATA);
        dispatch(
          updatePrintData({
            sectionName: TP_PRINT_SECTION_NAME.WORK_PREFERENCE,
            data: result,
          })
        );
      }
    } catch (error) {
      setWorkPreference(INIT_DATA);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleActive.roleId, profileId]);

  useEffect(() => {
    if (isPrintTP && fetchedData) {
      setWorkPreference(fetchedData);
      return;
    }
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchApi, fetchedData, isPrintTP]);

  return (
    <div className={styles.SectionPlan} data-testid="historical-talent" data-print-section={TP_PRINT_SECTION_NAME.WORK_PREFERENCE}>
      <div className={styles.SectionName}>
        <span>Work Preference</span>
      </div>
      <Spin spinning={loading} size="small">
        <div className={styles.tablePlan}>
          <table className="table table-borderless">
            <thead className={styles.header}>
              <tr className={styles.tablePlanRow}>
                <th className={styles.leftItem}>
                  <span>Item</span>
                </th>
                <th className={styles.rightItem}>
                  <span>Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tablePlanRow}>
                <th scope="row" className={styles.leftItem}>
                  Willing to relocate
                </th>
                <td className={styles.rightItem}>{workPreference.willing_to_relocate || '-'}</td>
              </tr>
              <tr className={styles.tablePlanRow}>
                <th scope="row" className={styles.leftItem}>
                  Option
                </th>
                <td className={styles.rightItem}>{workPreference.option || '-'}</td>
              </tr>
              <tr className={styles.tablePlanRow}>
                <th scope="row" className={styles.leftItem}>
                  Constraint Details
                </th>
                <td className={styles.rightItem}>{workPreference.constraint_details || '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Spin>
    </div>
  );
};

export default WorkPreference;
