import { get5LatestYear } from '../../utils/helper';

export const TP_PRINT_SECTION_NAME = {
  BASIC_INFO: 'basic_info',
  PROFILE_PICTURE: 'profile_picture',
  STAFF_NAME: 'staff_name',
  STAFF_ID: 'staff_id',
  POSITION_DETAILS: 'position_details',
  POSITION: 'position',
  BUSINESS: 'business',
  SECTOR: 'sector',
  OPU: 'opu',
  DIVISION: 'division',
  DEPARTMENT: 'department',
  PPA_RATING_TABLE: 'ppa_rating_table',
  AGE: 'age',
  DATE_OF_BIRTH: 'date_of_birth',
  RETIREMENT_DATE: 'retirement_date',
  NATIONALITY: 'nationality',
  TOP_TALENT_CATEGORY: 'top_talent_category',
  ABOUT_ME: 'about_me',
  JOB_GRADE: 'job_grade',
  SALARY_GRADE: 'salary_grade',
  SG_SINCE: 'sg_since',
  SUPERIOR: 'superior',
  DATE_IN_POSITION: 'date_in_position',
  DATE_IN_COMPANY: 'date_in_company',
  DATE_IN_PETRONAS: 'date_in_petronas',
  POSITION_ID: 'position_id',
  EMPLOYEE_INSIGHTS: 'employee_insights',
  TALENT_SUMMARY: 'talent_summary',
  CAREER_JOURNEY: 'career_journey',
  CAREER_STATEMENT: 'career_statement',
  ASPIRATION_MATCHING: 'aspiration_matching',
  KEY_STRENGTHS: 'key_strengths',
  DEVELOPMENT_AREAS: 'development_areas',
  PERSONALITY: 'personality',
  BOARD_MEMBERSHIP: 'board_membership',
  QUALIFICATION: 'qualification',
  SPOKEN_LANGUAGE: 'spoken_language',
  EXPERIENCES: 'experiences',
  EXPERIENCE: 'experience',
  EXTERNAL_EXPERIENCE: 'external_experience',
  OTHER_EXPERIENCE: 'other_experience',
  OTHER_CONTRIBUTION: 'other_contribution',
  PROJECT_INVOLVEMENT: 'project_involvement',
  LEADERSHIP_COMPETENCIES: 'leadership_competencies',
  TALENT_DEVELOPMENT: 'talent_development',
  DEVELOPMENT_PLAN: 'development_plan',
  MOBILITY_PLAN: 'mobility_plan',
  CONTRACT_DETAIL: 'contract_detail',
  ASSESSMENT_DATA: 'assessment_data',
  HISTORICAL_TALENT_STATUS: 'historical_talent_status',
  WORK_PREFERENCE: 'work_preference',
  RIGHT_FIT_RECOMMENDATION: 'right_fit_recommendation',
  NOTES: 'notes',
  TALENT_REVIEW: 'talent_review',
};

export const TP_PRINT_SELECT_OPTIONS = [
  {
    label: 'Basic Info',
    value: TP_PRINT_SECTION_NAME.BASIC_INFO,
    custom: 'test',
    children: [
      { label: 'Profile Picture', value: TP_PRINT_SECTION_NAME.PROFILE_PICTURE },
      { label: 'Staff Name', value: TP_PRINT_SECTION_NAME.STAFF_NAME },
      { label: 'Staff ID', value: TP_PRINT_SECTION_NAME.STAFF_ID },
      {
        label: 'Position Details',
        value: TP_PRINT_SECTION_NAME.POSITION_DETAILS,
        children: [
          { label: 'Position', value: TP_PRINT_SECTION_NAME.POSITION },
          { label: 'Business', value: TP_PRINT_SECTION_NAME.BUSINESS },
          { label: 'Sector', value: TP_PRINT_SECTION_NAME.SECTOR },
          { label: 'OPU', value: TP_PRINT_SECTION_NAME.OPU },
          { label: 'Division', value: TP_PRINT_SECTION_NAME.DIVISION },
          { label: 'Department', value: TP_PRINT_SECTION_NAME.DEPARTMENT },
        ],
      },
      { label: 'PPA Rating Table', value: TP_PRINT_SECTION_NAME.PPA_RATING_TABLE },
      { label: 'Age', value: TP_PRINT_SECTION_NAME.AGE },
      { label: 'Date of Birth', value: TP_PRINT_SECTION_NAME.DATE_OF_BIRTH },
      { label: 'Retirement Date/Contract End Date', value: TP_PRINT_SECTION_NAME.RETIREMENT_DATE },
      { label: 'Nationality', value: TP_PRINT_SECTION_NAME.NATIONALITY },
      { label: 'Top Talent Category', value: TP_PRINT_SECTION_NAME.TOP_TALENT_CATEGORY },
      { label: 'About Me', value: TP_PRINT_SECTION_NAME.ABOUT_ME },
      { label: 'Job Grade', value: TP_PRINT_SECTION_NAME.JOB_GRADE },
      { label: 'Salary Grade', value: TP_PRINT_SECTION_NAME.SALARY_GRADE },
      { label: 'Salary Grade Since (Years in SG)', value: TP_PRINT_SECTION_NAME.SG_SINCE },
      { label: 'Superior', value: TP_PRINT_SECTION_NAME.SUPERIOR },
      { label: 'Date in Position (Years in Position)', value: TP_PRINT_SECTION_NAME.DATE_IN_POSITION },
      { label: 'Date in Company', value: TP_PRINT_SECTION_NAME.DATE_IN_COMPANY },
      { label: 'Date in PETRONAS', value: TP_PRINT_SECTION_NAME.DATE_IN_PETRONAS },
      { label: 'Position ID', value: TP_PRINT_SECTION_NAME.POSITION_ID },
    ],
  },
  {
    label: 'Employee Insights',
    value: TP_PRINT_SECTION_NAME.EMPLOYEE_INSIGHTS,
    children: [
      {
        label: 'Talent Summary',
        value: TP_PRINT_SECTION_NAME.TALENT_SUMMARY,
        children: [
          { label: 'Career Journey', value: TP_PRINT_SECTION_NAME.CAREER_JOURNEY },
          { label: 'Career Statement', value: TP_PRINT_SECTION_NAME.CAREER_STATEMENT },
          { label: 'Aspiration Matching', value: TP_PRINT_SECTION_NAME.ASPIRATION_MATCHING },
          { label: 'Key Strengths', value: TP_PRINT_SECTION_NAME.KEY_STRENGTHS },
          { label: 'Development Areas', value: TP_PRINT_SECTION_NAME.DEVELOPMENT_AREAS },
          { label: 'Personality', value: TP_PRINT_SECTION_NAME.PERSONALITY },
          { label: 'Board Membership', value: TP_PRINT_SECTION_NAME.BOARD_MEMBERSHIP },
          { label: 'Qualification', value: TP_PRINT_SECTION_NAME.QUALIFICATION },
          {
            label: 'Approved Succession Planning',
            value: 'approved_succession_planning',
            isRadio: true,
            children: [
              { label: 'All', value: 'All' },
              { label: 'VP', value: 'VP' },
              { label: 'SGM', value: 'SGM' },
              { label: 'GM', value: 'GM' },
            ],
          },
          { label: 'Spoken Language', value: TP_PRINT_SECTION_NAME.SPOKEN_LANGUAGE },
        ],
      },
      {
        label: 'Experiences',
        value: TP_PRINT_SECTION_NAME.EXPERIENCES,
        children: [
          { label: 'Experience', value: TP_PRINT_SECTION_NAME.EXPERIENCE },
          { label: 'External Experience', value: TP_PRINT_SECTION_NAME.EXTERNAL_EXPERIENCE },
          { label: 'Other Experience', value: TP_PRINT_SECTION_NAME.OTHER_EXPERIENCE },
          { label: 'Other Contribution / Involvement', value: TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION },
          { label: 'Project Involvement', value: TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT },
        ],
      },
      {
        label: 'Leadership Competencies',
        value: TP_PRINT_SECTION_NAME.LEADERSHIP_COMPETENCIES,
        children: [
          {
            label: 'EDGE',
            value: 'edge',
            checkable: false,
            children: [
              {
                label: 'Year',
                value: 'year',
                isRadio: true,
                children: get5LatestYear().map((item) => ({ label: item, value: item })),
              },
              {
                label: 'Category',
                value: 'category',
                isRadio: true,
                children: [
                  { label: 'All Chart', value: 'all_chart' },
                  { label: 'SMA', value: 'SMA' },
                  { label: 'External', value: 'External' },
                  { label: 'Model', value: 'Model' },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'External Assessment',
        value: 'external_assessment',
        checkable: false,
        children: [
          {
            label: 'Component',
            value: 'component',
            isRadio: true,
            children: [
              { label: 'All', value: 'ALL' },
              { label: 'General', value: 'GENERAL' },
              { label: 'EDGE', value: 'EDGE' },
              { label: 'Personality Archetype', value: 'PERSONALITY_ARCHETYPES' },
              { label: 'Top Talent Potential(KF)', value: 'TOP_TALENT_POTENTIAL' },
              { label: 'Learning Agilities', value: 'LEARNING_AGILITIES' },
              { label: 'Traits', value: 'TRAITS' },
              { label: 'Drivers', value: 'DRIVERS' },
              { label: 'Derailment Risks', value: 'DERAILMENT_RISKS' },
              { label: 'Competencies', value: 'COMPETENCIES' },
            ],
          },
        ],
      },
      {
        label: 'Talent Development',
        value: TP_PRINT_SECTION_NAME.TALENT_DEVELOPMENT,
        children: [
          { label: 'Development Plan', value: TP_PRINT_SECTION_NAME.DEVELOPMENT_PLAN },
          { label: 'Mobility Plan', value: TP_PRINT_SECTION_NAME.MOBILITY_PLAN },
          { label: 'Contract Details', value: TP_PRINT_SECTION_NAME.CONTRACT_DETAIL },
          { label: 'Assessment Data', value: TP_PRINT_SECTION_NAME.ASSESSMENT_DATA },
          { label: 'Historical Status', value: TP_PRINT_SECTION_NAME.HISTORICAL_TALENT_STATUS },
          { label: 'Work Preference', value: TP_PRINT_SECTION_NAME.WORK_PREFERENCE },
        ],
      },
      {
        label: 'Right Fit Recommendation',
        value: TP_PRINT_SECTION_NAME.RIGHT_FIT_RECOMMENDATION,
      },
      {
        label: 'Notes',
        value: TP_PRINT_SECTION_NAME.NOTES,
      },
      {
        label: 'Talent Review',
        value: TP_PRINT_SECTION_NAME.TALENT_REVIEW,
      },
    ],
  },
];

export const TP_PRINT_DEFAULT_SELECT_OPTIONS = [
  { label: 'Basic Info', value: TP_PRINT_SECTION_NAME.BASIC_INFO },
  { label: 'Profile Picture', value: TP_PRINT_SECTION_NAME.PROFILE_PICTURE },
  { label: 'Staff Name', value: TP_PRINT_SECTION_NAME.STAFF_NAME },
  { label: 'Staff ID', value: TP_PRINT_SECTION_NAME.STAFF_ID },
  { label: 'Position Details', value: TP_PRINT_SECTION_NAME.POSITION_DETAILS },
  { label: 'Position', value: TP_PRINT_SECTION_NAME.POSITION },
  { label: 'Business', value: TP_PRINT_SECTION_NAME.BUSINESS },
  { label: 'Sector', value: TP_PRINT_SECTION_NAME.SECTOR },
  { label: 'OPU', value: TP_PRINT_SECTION_NAME.OPU },
  { label: 'Division', value: TP_PRINT_SECTION_NAME.DIVISION },
  { label: 'Department', value: TP_PRINT_SECTION_NAME.DEPARTMENT },
  { label: 'PPA Rating Table', value: TP_PRINT_SECTION_NAME.PPA_RATING_TABLE },
  { label: 'Age', value: TP_PRINT_SECTION_NAME.AGE },
  { label: 'Date of Birth', value: TP_PRINT_SECTION_NAME.DATE_OF_BIRTH },
  { label: 'Retirement Date/Contract End Date', value: TP_PRINT_SECTION_NAME.RETIREMENT_DATE },
  { label: 'Nationality', value: TP_PRINT_SECTION_NAME.NATIONALITY },
  { label: 'Top Talent Category', value: TP_PRINT_SECTION_NAME.TOP_TALENT_CATEGORY },
  { label: 'About Me', value: TP_PRINT_SECTION_NAME.ABOUT_ME },
  { label: 'Job Grade', value: TP_PRINT_SECTION_NAME.JOB_GRADE },
  { label: 'Salary Grade', value: TP_PRINT_SECTION_NAME.SALARY_GRADE },
  { label: 'Salary Grade Since (Years in SG)', value: TP_PRINT_SECTION_NAME.SG_SINCE },
  { label: 'Superior', value: TP_PRINT_SECTION_NAME.SUPERIOR },
  { label: 'Date in Position (Years in Position)', value: TP_PRINT_SECTION_NAME.DATE_IN_POSITION },
  { label: 'Date in Company', value: TP_PRINT_SECTION_NAME.DATE_IN_COMPANY },
  { label: 'Date in PETRONAS', value: TP_PRINT_SECTION_NAME.DATE_IN_PETRONAS },
  { label: 'Position ID', value: TP_PRINT_SECTION_NAME.POSITION_ID },
  { label: 'Employee Insights', value: TP_PRINT_SECTION_NAME.EMPLOYEE_INSIGHTS },
  { label: 'Talent Summary', value: TP_PRINT_SECTION_NAME.TALENT_SUMMARY },
  { label: 'Career Journey', value: TP_PRINT_SECTION_NAME.CAREER_JOURNEY },
  { label: 'Career Statement', value: TP_PRINT_SECTION_NAME.CAREER_STATEMENT },
  { label: 'Aspiration Matching', value: TP_PRINT_SECTION_NAME.ASPIRATION_MATCHING },
  { label: 'Key Strengths', value: TP_PRINT_SECTION_NAME.KEY_STRENGTHS },
  { label: 'Development Areas', value: TP_PRINT_SECTION_NAME.DEVELOPMENT_AREAS },
  { label: 'Personality', value: TP_PRINT_SECTION_NAME.PERSONALITY },
  { label: 'Board Membership', value: TP_PRINT_SECTION_NAME.BOARD_MEMBERSHIP },
  { label: 'Qualification', value: TP_PRINT_SECTION_NAME.QUALIFICATION },
  { label: 'Spoken Language', value: TP_PRINT_SECTION_NAME.SPOKEN_LANGUAGE },
  { label: 'Experiences', value: TP_PRINT_SECTION_NAME.EXPERIENCES },
  { label: 'Experience', value: TP_PRINT_SECTION_NAME.EXPERIENCE },
  { label: 'External Experience', value: TP_PRINT_SECTION_NAME.EXTERNAL_EXPERIENCE },
  { label: 'Other Experience', value: TP_PRINT_SECTION_NAME.OTHER_EXPERIENCE },
  { label: 'Other Contribution / Involvement', value: TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION },
  { label: 'Project Involvement', value: TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT },
  { label: 'Leadership Competencies', value: TP_PRINT_SECTION_NAME.LEADERSHIP_COMPETENCIES },
  { label: 'Talent Development', value: TP_PRINT_SECTION_NAME.TALENT_DEVELOPMENT },
  { label: 'Development Plan', value: TP_PRINT_SECTION_NAME.DEVELOPMENT_PLAN },
  { label: 'Mobility Plan', value: TP_PRINT_SECTION_NAME.MOBILITY_PLAN },
  { label: 'Contract Details', value: TP_PRINT_SECTION_NAME.CONTRACT_DETAIL },
  { label: 'Assessment Data', value: TP_PRINT_SECTION_NAME.ASSESSMENT_DATA },
  { label: 'Historical Status', value: TP_PRINT_SECTION_NAME.HISTORICAL_TALENT_STATUS },
];

export const TP_PRINT_DEFAULT_RADIO_OPTIONS = {
  approved_succession_planning: 'All',
  year: 2024,
  category: 'SMA',
  component: '',
};

export const TP_PRINT_PARENT_NODES = {
  [TP_PRINT_SECTION_NAME.BASIC_INFO]: {
    parent: null,
    children: [
      TP_PRINT_SECTION_NAME.PROFILE_PICTURE,
      TP_PRINT_SECTION_NAME.STAFF_NAME,
      TP_PRINT_SECTION_NAME.STAFF_ID,
      TP_PRINT_SECTION_NAME.POSITION,
      TP_PRINT_SECTION_NAME.BUSINESS,
      TP_PRINT_SECTION_NAME.SECTOR,
      TP_PRINT_SECTION_NAME.OPU,
      TP_PRINT_SECTION_NAME.DIVISION,
      TP_PRINT_SECTION_NAME.DEPARTMENT,
      TP_PRINT_SECTION_NAME.PPA_RATING_TABLE,
      TP_PRINT_SECTION_NAME.AGE,
      TP_PRINT_SECTION_NAME.DATE_OF_BIRTH,
      TP_PRINT_SECTION_NAME.RETIREMENT_DATE,
      TP_PRINT_SECTION_NAME.NATIONALITY,
      TP_PRINT_SECTION_NAME.TOP_TALENT_CATEGORY,
      TP_PRINT_SECTION_NAME.ABOUT_ME,
      TP_PRINT_SECTION_NAME.JOB_GRADE,
      TP_PRINT_SECTION_NAME.SALARY_GRADE,
      TP_PRINT_SECTION_NAME.SG_SINCE,
      TP_PRINT_SECTION_NAME.SUPERIOR,
      TP_PRINT_SECTION_NAME.DATE_IN_POSITION,
      TP_PRINT_SECTION_NAME.DATE_IN_COMPANY,
      TP_PRINT_SECTION_NAME.DATE_IN_PETRONAS,
      TP_PRINT_SECTION_NAME.POSITION_ID,
    ],
  },
  [TP_PRINT_SECTION_NAME.POSITION_DETAILS]: {
    parent: TP_PRINT_SECTION_NAME.BASIC_INFO,
    children: [
      TP_PRINT_SECTION_NAME.POSITION,
      TP_PRINT_SECTION_NAME.BUSINESS,
      TP_PRINT_SECTION_NAME.SECTOR,
      TP_PRINT_SECTION_NAME.OPU,
      TP_PRINT_SECTION_NAME.DIVISION,
      TP_PRINT_SECTION_NAME.DEPARTMENT,
    ],
  },
  [TP_PRINT_SECTION_NAME.EMPLOYEE_INSIGHTS]: {
    parent: null,
    children: [
      TP_PRINT_SECTION_NAME.CAREER_JOURNEY,
      TP_PRINT_SECTION_NAME.CAREER_STATEMENT,
      TP_PRINT_SECTION_NAME.ASPIRATION_MATCHING,
      TP_PRINT_SECTION_NAME.KEY_STRENGTHS,
      TP_PRINT_SECTION_NAME.DEVELOPMENT_AREAS,
      TP_PRINT_SECTION_NAME.PERSONALITY,
      TP_PRINT_SECTION_NAME.BOARD_MEMBERSHIP,
      TP_PRINT_SECTION_NAME.QUALIFICATION,
      TP_PRINT_SECTION_NAME.SPOKEN_LANGUAGE,
      TP_PRINT_SECTION_NAME.EXPERIENCE,
      TP_PRINT_SECTION_NAME.EXTERNAL_EXPERIENCE,
      TP_PRINT_SECTION_NAME.OTHER_EXPERIENCE,
      TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION,
      TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT,
      TP_PRINT_SECTION_NAME.DEVELOPMENT_PLAN,
      TP_PRINT_SECTION_NAME.MOBILITY_PLAN,
      TP_PRINT_SECTION_NAME.CONTRACT_DETAIL,
      TP_PRINT_SECTION_NAME.ASSESSMENT_DATA,
      TP_PRINT_SECTION_NAME.HISTORICAL_TALENT_STATUS,
      TP_PRINT_SECTION_NAME.WORK_PREFERENCE,
      TP_PRINT_SECTION_NAME.RIGHT_FIT_RECOMMENDATION,
      TP_PRINT_SECTION_NAME.NOTES,
      TP_PRINT_SECTION_NAME.TALENT_REVIEW,
    ],
  },
  [TP_PRINT_SECTION_NAME.TALENT_SUMMARY]: {
    parent: TP_PRINT_SECTION_NAME.EMPLOYEE_INSIGHTS,
    children: [
      TP_PRINT_SECTION_NAME.CAREER_JOURNEY,
      TP_PRINT_SECTION_NAME.CAREER_STATEMENT,
      TP_PRINT_SECTION_NAME.ASPIRATION_MATCHING,
      TP_PRINT_SECTION_NAME.KEY_STRENGTHS,
      TP_PRINT_SECTION_NAME.DEVELOPMENT_AREAS,
      TP_PRINT_SECTION_NAME.PERSONALITY,
      TP_PRINT_SECTION_NAME.BOARD_MEMBERSHIP,
      TP_PRINT_SECTION_NAME.QUALIFICATION,
      TP_PRINT_SECTION_NAME.SPOKEN_LANGUAGE,
    ],
  },
  [TP_PRINT_SECTION_NAME.EXPERIENCES]: {
    parent: TP_PRINT_SECTION_NAME.EMPLOYEE_INSIGHTS,
    children: [
      TP_PRINT_SECTION_NAME.EXPERIENCE,
      TP_PRINT_SECTION_NAME.EXTERNAL_EXPERIENCE,
      TP_PRINT_SECTION_NAME.OTHER_EXPERIENCE,
      TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION,
      TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT,
    ],
  },
  [TP_PRINT_SECTION_NAME.TALENT_DEVELOPMENT]: {
    parent: TP_PRINT_SECTION_NAME.EMPLOYEE_INSIGHTS,
    children: [
      TP_PRINT_SECTION_NAME.DEVELOPMENT_PLAN,
      TP_PRINT_SECTION_NAME.MOBILITY_PLAN,
      TP_PRINT_SECTION_NAME.CONTRACT_DETAIL,
      TP_PRINT_SECTION_NAME.ASSESSMENT_DATA,
      TP_PRINT_SECTION_NAME.HISTORICAL_TALENT_STATUS,
      TP_PRINT_SECTION_NAME.WORK_PREFERENCE,
    ],
  },
};