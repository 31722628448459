import React, { memo } from 'react';

import { handleRenderListPersonality } from '../../../../utils/helper';
import styles from './personality-print.module.scss';
import { TP_PRINT_SECTION_NAME } from '../../constants';

const PersonalityPrint = ({ personality }) => {
  return (
    <div className={styles.Personality} data-print-section={TP_PRINT_SECTION_NAME.PERSONALITY}>
      <h3 className={styles.Personality__h3}>Personality </h3>
      <div className={styles.Personality__table}>
        <table>
          <thead>
            <tr>
              <th>
                <p>Personality Archetypes</p>
              </th>
              <th>
                <p>Details</p>
              </th>
            </tr>
          </thead>
          <tbody>{handleRenderListPersonality(personality)}</tbody>
        </table>
      </div>
    </div>
  );
};
export default memo(PersonalityPrint);
