import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Pagination } from '..';
import { fetchOtherContribution } from '../../services/talentProfiles';
import styles from './other-contribution.module.scss';
import ProjectInvolvement from '../ProjectInvolvement/ProjectInvolvement';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';
import { updatePrintData } from '../../pages/TalentProfilePage/store/printProfileSlice';

const SECTION_ARR = [TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION, TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT];

const OtherContribution = (props) => {
  const { profileId, isPrintPdf, fetchedData, fetchedDataProjectInvolvement, tokenTalentProfile } = props;
  const {
    roleActive: { roleId },
    permissions,
  } = useSelector((state) => state.user);
  const { selectedValues } = useSelector((state) => state.app.talentProfile.printProfile.printSettings);
  const [otherContribution, setOtherContribution] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 4;
  const [totalResults, setTotalResults] = useState(1);
  const dispatch = useDispatch();

  const styleDisplay = useMemo(() => {
    let display = 'flex';
    if (SECTION_ARR.every((r) => !selectedValues.includes(r))) {
      display = 'none';
    }
    if (SECTION_ARR.every((r) => selectedValues.includes(r))) {
      display = 'block';
    }
    return { display };
  }, [selectedValues]);

  const fetchApi = useCallback(
    async (params) => {
      if (!roleId) return;
      try {
        setLoading(true);
        const response = await fetchOtherContribution({ params, roleId, profileId, permissions, tokenTalentProfile });
        if (!isEmpty(response) && response?.data && response.data.result?.data && response?.data?.result?.data?.length > 0) {
          if (!params.isPrintPdf) {
            setOtherContribution(response.data.result.data);
            setTotalResults(response?.data?.result?.total);
          }
          if (params.isPrintPdf) {
            dispatch(updatePrintData({ sectionName: TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION, data: response.data.result.data }));
          }
        }
      } catch (error) {
        setOtherContribution([]);
        setTotalResults(0);
      } finally {
        setLoading(false);
      }
    },
    [roleId, profileId, dispatch, permissions]
  );

  useEffect(() => {
    if (isPrintPdf && fetchedData) {
      setOtherContribution(fetchedData);
    } else if (isPrintPdf && !fetchedData) {
      fetchApi({ page: 1, limit, isPrintPdf });
    } else {
      fetchApi({ page: 1, limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchApi, page, isPrintPdf, fetchedData]);

  return (
    <div className={styles.container} data-testid="other-contribution" style={styleDisplay}>
      <div data-print-section={TP_PRINT_SECTION_NAME.OTHER_CONTRIBUTION} className={styles.otherContribution}>
        <h3 className={styles.otherContribution__h3}>Other Contributions / Involvement </h3>
        <Spin spinning={loading}>
          <div className={styles.otherContribution__table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <p>Description</p>
                  </th>
                  <th className="text-center">
                    <p>Roles</p>
                  </th>
                  <th className="text-center">
                    <p>Level</p>
                  </th>
                  <th className="text-center">
                    <p>Years</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!!otherContribution && otherContribution.length > 0 && (
                  <>
                    {otherContribution?.map((data, idx) => (
                      <tr key={idx} data-testid="data-contribution">
                        <td>{data.description === 'NULL' || data.description === '' ? '-' : data.description}</td>
                        <td className="text-center">{data.role === 'NULL' || data.role === '' ? '-' : data.role}</td>
                        <td className="text-center">{data.contribution === 'NULL' || data.contribution === '' ? '-' : data.contribution}</td>
                        <td className="text-center">
                          {data.year_contribution === 'NULL' || data.year_contribution === '' ? '-' : data.year_contribution}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Spin>
        {otherContribution && otherContribution.length > 0 && !isPrintPdf && (
          <Pagination page={page} rowPerPages={4} totalResults={totalResults} setPage={setPage} data-testid="pagination" />
        )}
      </div>

      <div className={styles.projectInvolvement} data-print-section={TP_PRINT_SECTION_NAME.PROJECT_INVOLVEMENT}>
        <ProjectInvolvement
          staffId={profileId}
          roleId={roleId}
          permissions={permissions}
          isPrintPdf={isPrintPdf}
          fetchedData={fetchedDataProjectInvolvement}
          tokenTalentProfile={tokenTalentProfile}
        />
      </div>
    </div>
  );
};
export default OtherContribution;
