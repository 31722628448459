import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './external-experience.module.scss';
import { BasicTable } from '../../assets/common';
import { Spin } from 'antd';
import talentProfileApi from '../../services/talentProfiles';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updatePrintData } from '../../pages/TalentProfilePage/store/printProfileSlice';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';

const NOT_AVAILABLE = 'Not Available';
const COLUMNS = [
  {
    title: 'Job Title',
    width: 150,
    dataIndex: 'job_title',
    render: (text) => <span className={styles.text1}>{text || NOT_AVAILABLE}</span>,
  },
  {
    title: 'Start Date',
    width: 130,
    dataIndex: 'start_date',
    render: (text) => (
      <span className={styles.text2}>
        {text ? moment(text).format('DD MMM YYYY') : NOT_AVAILABLE}
      </span>
    ),
  },
  {
    title: 'End Date',
    width: 130,
    dataIndex: 'end_date',
    render: (text) => (
      <span className={styles.text2}>
        {text ? moment(text).format('DD MMM YYYY') : NOT_AVAILABLE}
      </span>
    ),
  },
  {
    title: 'Employer Name',
    width: 236,
    dataIndex: 'employer_name',
    render: (text) => <span className={styles.text2}>{text || NOT_AVAILABLE}</span>,
  },
  {
    title: 'Job Function',
    dataIndex: 'job_function',
    width: 150,
    render: (text) => <span className={styles.text2}>{text || NOT_AVAILABLE}</span>,
  },
];
const TABLE_STYLE = {
  headerColor: '#181818',
  headerWeight: 500,
  borderRadius: 6,
  roundPagination: true,
};

const ExternalExperience = ({ staffId, role, isPrinting, fetchedData, tokenTalentProfile }) => {
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    if (!role) return;
    try {
      setLoading(true);
      const res = await talentProfileApi.getExternalExperience(staffId, role, tokenTalentProfile);
      if (res.status === 200) {
        const data = res.data.result.map((item, index) => ({ ...item, id: index }));
        setExperiences(data);
        dispatch(
          updatePrintData({
            sectionName: TP_PRINT_SECTION_NAME.EXTERNAL_EXPERIENCE,
            data,
          })
        );
      }
    } catch (error) {
      console.error(error);
      setExperiences([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.roleActive?.roleId, staffId]);

  useEffect(() => {
    if (isPrinting && fetchedData) {
      setExperiences(fetchedData);
      return;
    }
    getData();
  }, [getData, fetchedData, isPrinting]);

  return (
    <div className={styles.wrapper} data-print-section={TP_PRINT_SECTION_NAME.EXTERNAL_EXPERIENCE}>
      <div className={styles.title}>External Experience</div>
      <Spin spinning={loading}>
        <BasicTable
          rowKey="id"
          styles={TABLE_STYLE}
          dataSource={experiences}
          columns={COLUMNS}
          pagination={false}
        />
      </Spin>
    </div>
  );
};

ExternalExperience.propTypes = {
  staffId: PropTypes.string,
  role: PropTypes.object,
};

export default ExternalExperience;
