import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { profileSelector } from '../../../selector';
import styles from '../overall-profile-user.module.scss';
import { useParams } from 'react-router-dom';
import { TP_PRINT_SECTION_NAME } from '../../../../../components/TalentProfilePrintPreview/constants';

const NameAndPosition = (props) => {
  const { fromTalentReview } = props;
  const { id } = useParams();
  const { firstSectionProfile, loading } = useSelector(profileSelector);

  const displayInfoEmployee = () => {
    return (
      <>
        {firstSectionProfile && firstSectionProfile.birthName && (
          <p
            data-print-section={TP_PRINT_SECTION_NAME.STAFF_NAME}
            onKeyDown={() => {}}
            onClick={fromTalentReview ? () => window.open(`/profile/${firstSectionProfile.staffId || id}`, '_blank') : null}
            style={{
              cursor: `${fromTalentReview ? 'pointer' : 'default'}`,
            }}
          >
            {firstSectionProfile && firstSectionProfile.birthName}
          </p>
        )}
        {firstSectionProfile && firstSectionProfile.positionName && (
          <p data-print-section={TP_PRINT_SECTION_NAME.POSITION}>{firstSectionProfile.positionName}</p>
        )}
        {firstSectionProfile && firstSectionProfile.department && (
          <p data-print-section={TP_PRINT_SECTION_NAME.DEPARTMENT}>{firstSectionProfile.department}</p>
        )}
        {firstSectionProfile && firstSectionProfile.division && (
          <p data-print-section={TP_PRINT_SECTION_NAME.DIVISION}>{firstSectionProfile.division}</p>
        )}
        {firstSectionProfile && firstSectionProfile.businessUnit && (
          <p data-print-section={TP_PRINT_SECTION_NAME.BUSINESS}>{firstSectionProfile.businessUnit}</p>
        )}
        {firstSectionProfile && firstSectionProfile.opu && <p data-print-section={TP_PRINT_SECTION_NAME.OPU}>{firstSectionProfile.opu}</p>}
      </>
    );
  };
  return (
    <div className={styles.userNameAndPosition}>
      <p data-print-section={TP_PRINT_SECTION_NAME.STAFF_ID}>
        STAFF ID:
        {(!loading && firstSectionProfile && firstSectionProfile.staffId) || id}
        {loading && <Skeleton count={1} width={308} height={25} inline={true} />}
      </p>
      {loading && <Skeleton count={5} width={308} height={22} className={styles.skeleton__marginBtn} />}
      {!loading && displayInfoEmployee()}
    </div>
  );
};

NameAndPosition.propTypes = {
  fromTalentReview: PropTypes.bool,
};
export default NameAndPosition;
