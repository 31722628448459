import { Empty, notification, Select, Spin } from 'antd';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BUSINESS_NAME, INIT_PARAMS, PERMISSION } from '../.././utils/constants';
import { BasicAutoComplete, BasicPopover } from '../../assets/common';
import { useComponentVisible } from '../../hooks';
import { fetchBusinessExperiencesLazy, fetchFunctionExperiences, fetchUpdateExperiences, getExperiences } from '../../services/talentProfiles';
import { contract_info, edit } from './../../assets/img';
import styles from './experiences.module.scss';
import moment from 'moment';
import ExperiencesEditHistorical from './ExperiencesEditHistorical';
import { useDispatch } from 'react-redux';
import { updatePrintData } from '../../pages/TalentProfilePage/store/printProfileSlice';
import { TP_PRINT_SECTION_NAME } from '../TalentProfilePrintPreview/constants';

const { Option } = Select;

const INDICATOR_POPOVER_STYLES = {
  hideArrow: false,
  px: 12,
  py: 12,
  bg: '#181818',
  color: '#fff',
  w: 'fit-content',
};

const Indicator = ({ item, setOpen, setItem, isBusiness, total, updatedBy = '', updatedAt = '' }) => {
  return (
    <div className={styles.indicatorPopover}>
      <div>
        <span>Last updated by: </span>
        {updatedBy}
      </div>
      <div>
        <span>Updated on: </span>
        {updatedAt ? moment(updatedAt).format('DD MMM YYYY') : ''}
      </div>
      {total >= 1 && (
        <div
          className={styles.viewEditingHistorical}
          onClick={() => {
            setOpen(true);
            setItem({ value: item, type: isBusiness ? 'business' : 'function' });
          }}
        >
          View Edit History
        </div>
      )}
    </div>
  );
};

const ModalEdit = (props) => {
  const { item, positionCode, isBusiness, businessName, functionName, searchParams, setSearchParams, businesses, functions, setValueEdit, rowIndex } =
    props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const selectRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const renderSelect = useMemo(() => {
    return isBusiness
      ? {
          options: businesses.map((item, index) => (
            <Option key={`${item.rowNum}-${index}`} value={item.businessUnit}>
              {item.name}
            </Option>
          )),
          class: styles.business_select,
          placeholder: 'Search Business',
          label: 'Edit Business',
        }
      : {
          options: (
            <>
              <Option disabled value={-1}>
                <div className={styles.function_header}>
                  <div>Job Family</div>
                  <div>Sub Job Family</div>
                  <div>Function</div>
                </div>
              </Option>
              {isEmpty(functions) && (
                <Option disabled value={0}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Option>
              )}
              {functions.map((item, index) => (
                <Option key={`${item.rowNum}-${index}`} value={item.name}>
                  <div className={styles.function_rows}>
                    <div>{item.job_family}</div>
                    <div>{item.sub_job_family}</div>
                    <div>{item.name}</div>
                  </div>
                </Option>
              ))}
            </>
          ),
          class: styles.function_select,
          placeholder: 'Search by Function, Job Family & Sub-Job Family',
          label: 'Edit Function',
        };
  }, [businesses, functions, isBusiness]);

  const handleScroll = (event) => {
    if (searchParams.isAll || searchParams.fetching) return;
    const {
      target: { scrollTop, offsetHeight, scrollHeight },
    } = event;
    const isSearch = scrollTop + offsetHeight >= scrollHeight - 1;

    if (isSearch) {
      setSearchParams((prev) => ({ ...prev, page: prev.page + 1, fetching: true }));
    }
  };

  const handleSearch = debounce((value) => {
    if (selectRef.current) {
      selectRef.current.scrollTo(0);
    }
    setSearchParams((prev) => ({ ...prev, search: value, page: 1, searching: true }));
  }, 500);

  const handleSave = (value) => {
    if (value) {
      const valueEdit = {
        data: {
          ...item,
          business: isBusiness ? value : businessName,
          function: !isBusiness ? value : functionName,
          type_edit: isBusiness ? 'business' : 'function',
        },
        positionCode,
        index: rowIndex,
      };
      setValueEdit(valueEdit);
      setSearchParams((prev) => ({ ...prev, search: '', page: 1, searching: true }));
      setIsComponentVisible(!isComponentVisible);
      setSelectedValue('');
    }
  };

  const handleCancel = () => {
    setValueEdit({});
    setSearchParams((prev) => ({ ...prev, search: '', page: 1, searching: true }));
    setIsComponentVisible(!isComponentVisible);
    setSelectedValue('');
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div ref={ref} className={styles.edit}>
      <img src={edit} alt="edit" onKeyDown={() => {}} onClick={() => setIsComponentVisible(!isComponentVisible)} data-testid="click-edit" />
      {isComponentVisible && (
        <div className={styles.modal_container}>
          <div className={renderSelect.class}>
            <div className={styles.label}>{renderSelect.label}</div>
            <BasicAutoComplete
              ref={selectRef}
              getPopupContainer={(trigger) => trigger.parentElement}
              virtual={false}
              loading={searchParams.fetching || searchParams.searching}
              showSearch
              filterOption={false}
              placeholder={renderSelect.placeholder}
              onPopupScroll={handleScroll}
              onSearch={handleSearch}
              onChange={handleChange}
              value={selectedValue || undefined}
            >
              {renderSelect.options}
            </BasicAutoComplete>
            <div className={styles.buttonContainer}>
              <button className={styles.cancelButton} onKeyDown={() => {}} onClick={handleCancel}>
                Cancel
              </button>
              <button className={styles.saveButton} onKeyDown={() => {}} onClick={() => handleSave(selectedValue)}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ExperienceList = (props) => {
  const {
    experiences,
    editMode,
    businessParams,
    setBusinessParams,
    functionParams,
    setFunctionParams,
    businesses,
    functions,
    setValueEdit,
    updatingIndex,
  } = props;

  const [selectedItem, setSelectedItem] = useState({ value: {}, type: '' });
  const [openEditHistorical, setOpenEditHistorical] = useState(false);

  const getSymbol = (item) => {
    let result;
    BUSINESS_NAME.forEach((data) => {
      if (data.name.indexOf(item.business) !== -1) {
        result = data.symbol;
      }
    });
    return result || '-';
  };

  const getColor = (item) => {
    let result;
    BUSINESS_NAME.forEach((data) => {
      if (data.name.indexOf(item.business) !== -1) {
        result = data.color;
      }
    });
    return result || 'gray';
  };

  const listItems = experiences.map((item, index) => (
    <Spin spinning={updatingIndex === index} key={`exp-${index}`}>
      <li className={styles.content}>
        <div className={styles.platform} style={{ backgroundColor: getColor(item) }}>
          {getSymbol(item)}
        </div>
        <div className={styles.right}>
          <p className={styles.position}>{item.position}</p>
          <div className={styles.business}>
            {item.department || 'No department'}, {item.division || 'No division'}, {item.company || 'No company'}
          </div>
          <div className={styles.business}>
            {item.business || '-'}
            {item?.updated_business && (
              <BasicPopover
                content={
                  <Indicator
                    isBusiness
                    item={item}
                    updatedBy={item.updated_business?.updated_by}
                    updatedAt={item.updated_business?.updated_at}
                    setOpen={setOpenEditHistorical}
                    setItem={setSelectedItem}
                    total={item.updated_business?.total_business}
                  />
                }
                trigger="hover"
                getPopupContainer={(trigger) => trigger}
                styles={INDICATOR_POPOVER_STYLES}
              >
                <span className={styles.indicator}>
                  <img src={contract_info} alt="info" />
                </span>
              </BasicPopover>
            )}
            {editMode && (
              <ModalEdit
                item={item}
                rowIndex={index}
                isBusiness
                positionCode={item.positionCode}
                businessName={item.business}
                functionName={item.function}
                searchParams={businessParams}
                setSearchParams={setBusinessParams}
                businesses={businesses}
                setValueEdit={setValueEdit}
              />
            )}
          </div>
          <div className={`${styles.business}`}>
            {item.function || '-'}
            {item?.updated_function && (
              <BasicPopover
                content={
                  <Indicator
                    item={item}
                    updatedBy={item.updated_function?.updated_by}
                    updatedAt={item.updated_function?.updated_at}
                    setOpen={setOpenEditHistorical}
                    setItem={setSelectedItem}
                    total={item.updated_function?.total_function}
                  />
                }
                trigger="hover"
                getPopupContainer={(trigger) => trigger}
                styles={INDICATOR_POPOVER_STYLES}
              >
                <span className={styles.indicator}>
                  <img src={contract_info} alt="info" />
                </span>
              </BasicPopover>
            )}
            {editMode && (
              <ModalEdit
                item={item}
                rowIndex={index}
                positionCode={item.positionCode}
                businessName={item.business}
                functionName={item.function}
                searchParams={functionParams}
                setSearchParams={setFunctionParams}
                functions={functions}
                setValueEdit={setValueEdit}
              />
            )}
          </div>
          <div className={styles.time}>
            {item.dateFrom} - {item.dateTo}
          </div>
          <div className={styles.time}>{item.duration || '-'}</div>
          <div className={styles.grade}>
            <span>JG:</span> {item.jg || '-'} &#160; <span>SG:</span> {item.sg || '-'}
          </div>
        </div>
      </li>
    </Spin>
  ));
  return (
    <>
      <ul className={styles.menu} data-testid="experience-list">
        {listItems}
      </ul>
      <ExperiencesEditHistorical item={selectedItem} open={openEditHistorical} setOpen={setOpenEditHistorical} />
    </>
  );
};

const ExperiencePrintList = (props) => {
  const { experiences } = props;
  if (!experiences) return;
  return (
    <div className={styles.container}>
      <table className={styles.experience__table}>
        <thead>
          <tr>
            <th>Position name</th>
            <th>Department</th>
            <th>Division</th>
            <th>Company</th>
            <th>Business</th>
            <th>Start Date - End Date</th>
            <th>SG</th>
            <th>JG</th>
          </tr>
        </thead>
        <tbody>
          {experiences.map((ele, index) => {
            return (
              <tr key={index}>
                <td className="text-left">{ele.position}</td>
                <td>{ele.department}</td>
                <td>{ele.division}</td>
                <td>{ele.company}</td>
                <td>{ele.business}</td>
                <td>
                  {ele.dateFrom} - {ele.dateTo}
                </td>
                <td>{ele.sg}</td>
                <td>{ele.jg}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
const Experiences = (props) => {
  const { profileId, roleUser, isPrinting, fetchedData, tokenTalentProfile } = props;
  const {
    roleActive: { roleId },
    permissions,
  } = roleUser;
  const [experiences, setExperiences] = useState([]);
  const [valueEdit, setValueEdit] = useState({});
  const [functions, setFunctions] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [businessParams, setBusinessParams] = useState(INIT_PARAMS);
  const [functionParams, setFunctionParams] = useState(INIT_PARAMS);
  const [loading, setLoading] = useState(true);
  const [updatingIndex, setUpdatingIndex] = useState(-1);
  const dispatch = useDispatch();

  const isHasEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_EXPERIENCE);
  }, [permissions]);

  const fetchExperiences = useCallback(async () => {
    if (!roleId || !profileId) return;
    try {
      if (updatingIndex !== -1) setLoading(true);
      let response = await getExperiences({
        id: profileId,
        roleId,
        permissions,
        tokenTalentProfile,
      });
      if (response && response.data && response.data.code === 200) {
        const rs = response.data.result;
        const experiencesData =
          rs && rs.length > 0
            ? rs.map((result) => {
                return {
                  ...result,
                  id: result.id,
                  positionCode: result.position_code || '',
                  position: result.position_name || '',
                  department: result.department || '',
                  division: result.division || '',
                  company: result.opu_name || '',
                  dateFrom: result.date_from_str || '',
                  dateTo: result.date_to_str || '',
                  function: result.function || '',
                  sg: result.sg || '',
                  jg: result.job_grade || '',
                  business: result.business || '',
                  duration: result.duration || '',
                  dateFromRaw: result.date_from_raw,
                  dateToRaw: result.date_to_raw,
                };
              })
            : [];
        setExperiences(experiencesData);
        dispatch(updatePrintData({ sectionName: TP_PRINT_SECTION_NAME.EXPERIENCE, data: experiencesData }));
      }
    } catch (error) {
      setExperiences([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, profileId, roleId]);

  useEffect(() => {
    if (isPrinting) return;
    fetchExperiences();
  }, [fetchExperiences, isPrinting]);

  useEffect(() => {
    if (!isPrinting) return;
    if (fetchedData) {
      setExperiences(fetchedData);
      setLoading(false);
    } else {
      fetchExperiences();
    }
  }, [fetchedData, isPrinting, fetchExperiences]);

  useEffect(() => {
    if (!valueEdit.data || !profileId) return;
    const editExperiences = async () => {
      try {
        setUpdatingIndex(valueEdit.index);
        const params = {
          body: valueEdit.data,
          staffId: profileId,
          positionCode: valueEdit.positionCode,
        };
        let response = await fetchUpdateExperiences({ roleId, permissions, params });
        if (response && response.data && response.data.code === 200) {
          await fetchExperiences();
          notification.success({
            message: `Update successful.`,
            placement: 'topRight',
            duration: 2,
          });
        }
      } catch (e) {
        notification.error({
          message: `Update error.`,
          placement: 'topRight',
          duration: 2,
        });
      } finally {
        setUpdatingIndex(-1);
      }
    };

    editExperiences();
  }, [fetchExperiences, valueEdit, profileId, roleId, permissions]);

  const getFunctionList = useCallback(async () => {
    const { search, page, fetching, searching } = functionParams;
    if (!roleId || (!fetching && !searching)) return;
    try {
      let res = await fetchFunctionExperiences({
        roleId,
        permissions,
        search,
        page,
        limit: INIT_PARAMS.LIMIT,
      });
      if (res.status !== 200) throw Error();
      const result = res.data.result;
      const isAll = result.length < INIT_PARAMS.LIMIT ? true : false;

      if (fetching) {
        setFunctions((prev) => [...prev, ...res.data.result]);
        setFunctionParams((prev) => ({ ...prev, fetching: false, isAll }));
      }

      if (searching) {
        setFunctions(result);
        setFunctionParams((prev) => ({ ...prev, searching: false, isAll }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [permissions, roleId, functionParams]);

  const getBusinessList = useCallback(async () => {
    const { search, page, fetching, searching } = businessParams;
    if (!fetching && !searching) return;
    try {
      let res = await fetchBusinessExperiencesLazy({ search, limit: INIT_PARAMS.isAllLIMIT, page }, tokenTalentProfile);
      if (res.status !== 200) throw Error();
      const result = res.data.result;
      const isAll = result.length < INIT_PARAMS.isAllLIMIT ? true : false;

      if (fetching) {
        setBusinesses((prev) => [...prev, ...res.data.result]);
        setBusinessParams((prev) => ({ ...prev, fetching: false, isAll }));
      }

      if (searching) {
        setBusinesses(res.data.result);
        setBusinessParams((prev) => ({ ...prev, searching: false, isAll }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [businessParams]);

  useEffect(() => {
    if (!isHasEditPermission) return;
    getBusinessList();
  }, [isHasEditPermission, getBusinessList]);

  useEffect(() => {
    if (!isHasEditPermission) return;
    getFunctionList();
  }, [isHasEditPermission, getFunctionList]);

  return (
    <div
      data-print-section={TP_PRINT_SECTION_NAME.EXPERIENCE}
      className={styles.experience}
      style={isPrinting ? { background: '#f4f5f8' } : null}
      data-testid="expriences"
    >
      <h3 className={styles.experience__h3}>Experiences</h3>
      <Spin spinning={loading}>
        {!isPrinting && (
          <ExperienceList
            experiences={experiences}
            editMode={isHasEditPermission}
            setValueEdit={setValueEdit}
            businesses={businesses}
            businessParams={businessParams}
            setBusinessParams={setBusinessParams}
            functions={functions}
            functionParams={functionParams}
            setFunctionParams={setFunctionParams}
            updatingIndex={updatingIndex}
          />
        )}
        {isPrinting && <ExperiencePrintList experiences={experiences} />}
      </Spin>
    </div>
  );
};

export default Experiences;
