import React, { memo, useEffect, useMemo, useState } from 'react';
import { every, isNull } from 'lodash';
import PropTypes from 'prop-types';

import { DropdownCustom } from '..';
import { fetchApprovedSP } from '../../services/talentProfiles';
import styles from './approved-sp.module.scss';
import ApprovedSpItem from './ApprovedSpItem';
import CommonLoading from '../CommonLoading/CommonLoading';
import { useSelector } from 'react-redux';

const OptionApprovedSP = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'VP',
    label: 'VP',
  },
  {
    value: 'SGM',
    label: 'SGM',
  },
  {
    value: 'GM',
    label: 'GM',
  },
];

const ORDER_ROW = ['VP', 'SGM', 'GM'];

const ApprovedSP = ({ id, roleUser, isPrintTP, tokenTalentProfile }) => {
  const [approvedSp, setApprovedSp] = useState({
    VP: null,
    SGM: null,
    GM: null,
  });
  const [loading, setLoading] = useState(false);
  const [selectedApprovedSP, setSelectedApprovedSP] = useState(OptionApprovedSP[0]);
  const { approved_succession_planning } = useSelector((state) => state.app.talentProfile.printProfile.printSettings.radioOptions);

  const isAll = useMemo(() => {
    return selectedApprovedSP.value === OptionApprovedSP[0].value;
  }, [selectedApprovedSP.value]);

  const getApprovedSP = async () => {
    setLoading(true);
    try {
      let res = await fetchApprovedSP({
        id,
        selectFilter: selectedApprovedSP.value,
        roleUser,
        tokenTalentProfile,
      });
      if (res.status === 200) {
        const resResult = res.data.result;
        const sortedResult = {};

        ORDER_ROW.forEach((key) => {
          if (resResult.hasOwnProperty(key)) {
            sortedResult[key] = resResult[key];
          }
        });
        setApprovedSp(sortedResult);
      }
    } catch (error) {
      setApprovedSp([]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!roleUser.roleActive.roleId) return;
    getApprovedSP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, id, selectedApprovedSP.value]);

  useEffect(() => {
    if (!id) return;
    setSelectedApprovedSP(OptionApprovedSP[0]);
  }, [id]);

  useEffect(() => {
    if (!isPrintTP) return;
    setSelectedApprovedSP({ label: approved_succession_planning, value: approved_succession_planning });
  }, [approved_succession_planning, isPrintTP]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.label}>Approved Succession Planning </h3>
        {!isPrintTP && (
          <DropdownCustom
            options={OptionApprovedSP}
            selected={selectedApprovedSP}
            setSelected={setSelectedApprovedSP}
            maxWidth="79px"
            maxHeight="40px"
            topbottom="11px"
            leftright="16px"
            space="13px"
            shadow="false"
          />
        )}
      </div>
      <div className={styles.milestone}>
        {!loading ? (
          <div className={styles.table}>
            <div className={styles.table_header}>
              {isAll && !every(approvedSp, isNull) && <div className={styles.role} />}
              <div className={styles.line_title}>
                <p>1st Line</p>
                <p>0 - 3 years</p>
              </div>
              <div className={styles.line_title}>
                <p>2nd Line</p>
                <p>3 - 5 years</p>
              </div>
              <div className={styles.line_title}>
                <p>3rd Line</p>
                <p>{'>'} 5 years</p>
              </div>
            </div>
            <div className={styles.table_data}>
              {approvedSp &&
                Object.keys(approvedSp).map((key) => {
                  const role = approvedSp[key];
                  return (
                    <React.Fragment key={key}>
                      {role && (
                        <div key={key} className={styles.row_data}>
                          {isAll && <div className={styles.cell_key}>{key}</div>}
                          <div className={styles.cell_data}>
                            <ApprovedSpItem isPrint={isPrintTP} line={role.first} />
                          </div>
                          <div className={styles.cell_data}>
                            <ApprovedSpItem isPrint={isPrintTP} line={role.second} />
                          </div>
                          <div className={styles.cell_data}>
                            <ApprovedSpItem isPrint={isPrintTP} line={role.third} />
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        ) : (
          <div style={{ width: '100%' }} className="d-flex justify-content-center">
            <CommonLoading />
          </div>
        )}
      </div>
    </div>
  );
};

ApprovedSP.propTypes = {
  id: PropTypes.string,
  roleUser: PropTypes.object,
  isPrintTP: PropTypes.bool,
};

export default memo(ApprovedSP);
