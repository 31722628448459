import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-compare-aspiration.module.scss';
import { Spin } from 'antd';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import styled from 'styled-components';
import { avatar } from '../../../../../assets/img';
import { isEmpty } from 'lodash';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import AutoResizeTextarea from '../../../../../components/AutoResizeTextArea/AutoResizeTextArea';

const PrintCompareAspiration = ({ preRead, handleFetchComplete, mobility_id, staff_id, mobility_proposal_id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchAspirationRisksSuccessor = async () => {
      try {
        setLoading(true);
        const res = await pdcMeetingApi.getAspirationRiskSuccessor(staff_id, mobility_id, mobility_proposal_id);
        if (res.status === 200) {
          const tempData = !isEmpty(res?.data?.result) ? res?.data?.result : [];
          setData(tempData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        handleFetchComplete('Compare Aspirations & Risks');
      }
    };

    if (!staff_id || !mobility_id || !mobility_proposal_id) return handleFetchComplete('Compare Aspirations & Risks');
    fetchAspirationRisksSuccessor();
  }, [staff_id, mobility_id, mobility_proposal_id]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Compare Aspirations & Risks
        {preRead && <PreReadLabel />}
      </div>
      <Spin spinning={loading}>
        <div className={styles.talent_wrapper}>
          {data.map((item, index) => {
            return (
              <div key={index} className={styles.each_talent}>
                <div className={styles.item1}>
                  <div className={styles.avatar}>
                    <img src={item?.image || avatar} alt="" />
                  </div>
                  <div className={styles.birth_name}>{item?.birth_name || '-'}</div>
                  <div className={styles.jg}>{`JG: ${item?.jg || '-'}`}</div>
                </div>
                <div className={styles.item2}>Aspirations</div>
                <div className={styles.item3}>Other Observations</div>
                <div className={styles.item4}>Risks</div>
                <div className={styles.item5}>
                  <CustomTextarea
                    placeholder="Enter Talent Aspirations"
                    value={item?.aspiration || ''}
                    style={{ minHeight: '202px' }}
                    disabled
                  />
                </div>
                <div className={styles.item6}>
                  <CustomTextarea
                    placeholder="Enter Other Observations"
                    disabled
                    value={item.other_observation || ''}
                    style={{ minHeight: '202px' }}
                  />
                </div>
                <div className={styles.item7}>
                  <CustomTextarea
                    placeholder="Enter Talent Risks"
                    disabled
                    value={item?.risks || ''}
                    style={{ minHeight: '202px' }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
};

export default PrintCompareAspiration;

PrintCompareAspiration.propTypes = {
  preRead: PropTypes.bool,
  proposal: PropTypes.object,
  handleFetchComplete: PropTypes.func,
  mobility_id: PropTypes.number,
  staff_id: PropTypes.string,
  mobility_proposal_id: PropTypes.number,
};

const CustomTextarea = styled(AutoResizeTextarea)`
  resize: none;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #3d3d3d !important;
  word-break: break-word;

  &:disabled {
    cursor: no-drop;
    background-color: white !important;
    border: none !important;
    padding: 0 !important;
    pointer-events: none;
  }
`;
