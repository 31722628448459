import React, { useState, useEffect } from 'react';

import { Card } from 'react-bootstrap';

import { default_news_announcements, view_more_vector } from '../../../assets/img';

const NewsCard = ({ item }) => {
  const [processedImage, setProcessedImage] = useState(null);

  useEffect(() => {
    const processImages = async () => {
      let src = default_news_announcements;
      if (!item.thumbnail_url) return setProcessedImage(default_news_announcements);
        src = await extractFirstFrame(item.thumbnail_url);
        return setProcessedImage(src);
    };
    if (!item) return;
    processImages();
  }, [item]);

  const extractFirstFrame = (gifSrc) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.src = gifSrc;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/png'));
        }
      };

      img.onerror = () => resolve(gifSrc);
    });
  };
  return (
    <Card style={{ width: '398px' }}>
      <div style={{ position: 'relative', backgroundColor: '#E0F3F3' }}>
        <Card.Img
          style={{ height: '200px' }}
          variant="top"
          src={processedImage}
          onError={(e) => {
            e.target.src = default_news_announcements;
          }}
          loading="lazy"
        />
        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            backgroundColor: '#EBE1F1',
            color: '#5B3075',
            padding: '4px 8px',
            borderRadius: '4px',
            fontWeight: '500',
            fontSize: '12px',
          }}
        >
          Badge
        </div>
      </div>
      <Card.Body>
        <Card.Title
          style={{
            fontWeight: '600',
            fontSize: '20px',
            color: '#000000',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 1,
            textOverflow: 'ellipsis',
            height: '24px',
          }}
        >
          {item?.title}
        </Card.Title>
        <Card.Text
          style={{
            fontWeight: '400',
            fontSize: '14px',
            color: '#000000',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 3,
            textOverflow: 'ellipsis',
            height: '51px',
          }}
        >
          {item?.content}
        </Card.Text>
        <a
          href={`/home/news-announcement-detail/${item?.id}`}
          target="_blank"
          rel="noreferrer"
          style={{
            fontWeight: '500',
            fontSize: '14px',
            color: '#007874',
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
          }}
        >
          {'Read More'}
          <img src={view_more_vector} />
        </a>
      </Card.Body>
    </Card>
  );
};

export default NewsCard;
